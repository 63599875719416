import React from 'react';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// STRIPE IMPORT
import '@stripe/stripe-js';

// MATERIAL UI
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

// REDUX
import { connect } from 'react-redux';

// SPINE UI
import { Content, Paper } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { setAppBarTitle } from '../../../store/actions/appActions';
import { fetchApi } from '../../../utils/apiSettings';
import Plan from '../../Public/Plans/components/Plan';
import styles from './styles';

class Plans extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      user: null
    };
  }

  componentDidMount() {
    this.props.setAppBarTitle('plans');
    this.fetchUserInformation();
  }

  fetchUserInformation = () => {
    fetchApi(
      'get',
      '/breeders/v1/account',
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.setState({
          user: data,
          isLoading: false
        });
      },
      () => {}
    );
  };

  handleSelectPlan = (priceId) => {
    const { enqueueSnackbar } = this.props;

    this.setState(
      {
        isLoading: true
      },
      () => {
        fetchApi(
          'post',
          '/breeders/v1/plans/checkout_session',
          {},
          {
            price_id: priceId,
            cancel_url: window.location.href,
            success_url: window.location.href
          },
          false,
          (response) => {
            const { data } = response;

            // eslint-disable-next-line no-undef
            const stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

            // redirect to checkout
            stripe
              .redirectToCheckout({
                sessionId: data.session_id
              })
              .then((result) => {
                enqueueSnackbar(result.error.message, {
                  variant: 'error',
                  autoHideDuration: 3000
                });
              });
          },
          (error) => {
            const { response } = error;

            if (response.status === 503) {
              this.setState({ isLoading: false });

              enqueueSnackbar('STRIPE CONNECTION ERROR', {
                variant: 'error',
                autoHideDuration: 3000
              });
            } else {
              // SHOULD NEVER HAPPEN
              // Breeder NOT FOUND
              // or customer_id NOT FOUND
              this.setState({ isLoading: false });
            }
          }
        );
      }
    );
  };

  openBillingPortal = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        fetchApi(
          'post',
          '/breeders/v1/plans/billing_session',
          {},
          {
            return_url: window.location.href
          },
          false,
          (response) => {
            const { data } = response;
            window.location.href = data.url;
          },
          (error) => {
            const { response } = error;
            const { enqueueSnackbar } = this.props;

            if (response.status === 503) {
              this.setState({ isLoading: false });

              enqueueSnackbar('STRIPE CONNECTION ERROR', {
                variant: 'error',
                autoHideDuration: 3000
              });
            } else {
              // SHOULD NEVER HAPPEN
              // Breeder NOT FOUND
              // or customer_id NOT FOUND
              this.setState({ isLoading: false });
            }
          }
        );
      }
    );
  };

  renderPlans = () => {
    const { intl, classes } = this.props;

    const userPlan = this.state.user ? this.state.user.plan : '';

    const plans = [
      {
        title: 'free',
        smallDescription: intl.formatMessage({ id: 'freeDescription' }),
        price: '0',
        descriptions: [
          intl.formatMessage({ id: 'manageInfiniteNumberOfBirds' }),
          intl.formatMessage({ id: 'manageCouplesUpTo' }, { number: 2 })
        ],
        withBorder: true,
        selectButton: false,
        actualPlan: userPlan === 'FREE',
        handleSelectPlan: () => {}
      },
      {
        title: 'basic',
        smallDescription: intl.formatMessage({ id: 'basicDescription' }),
        price: '5,90',
        descriptions: [
          intl.formatMessage({ id: 'manageInfiniteNumberOfBirds' }),
          intl.formatMessage({ id: 'manageCouplesUpTo' }, { number: 20 }),
          intl.formatMessage({ id: 'createTransferRequests' })
        ],
        withBorder: true,
        selectButton: userPlan === 'FREE',
        actualPlan: userPlan === 'BASIC',
        handleSelectPlan: () => this.handleSelectPlan(process.env.REACT_APP_PRICE_BASIC_PLAN)
      },
      {
        title: 'premium',
        smallDescription: intl.formatMessage({ id: 'premiumDescription' }),
        price: '16,90',
        descriptions: [
          intl.formatMessage({ id: 'manageInfiniteNumberOfBirds' }),
          intl.formatMessage({ id: 'manageCouplesUpTo' }, { number: 60 }),
          intl.formatMessage({ id: 'createTransferRequests' }),
          intl.formatMessage({ id: 'searchForOtherBirdsFromOtherBreeders' })
        ],
        withBorder: true,
        selectButton: userPlan === 'FREE',
        actualPlan: userPlan === 'PREMIUM',
        handleSelectPlan: () => this.handleSelectPlan(process.env.REACT_APP_PRICE_PREMIUM_PLAN)
      },
      {
        title: 'platinum',
        smallDescription: intl.formatMessage({ id: 'platinumDescription' }),
        price: '39,90',
        descriptions: [
          intl.formatMessage({ id: 'manageInfiniteNumberOfBirds' }),
          intl.formatMessage({ id: 'manageCouplesUpTo' }, { number: 300 }),
          intl.formatMessage({ id: 'createTransferRequests' }),
          intl.formatMessage({ id: 'searchForOtherBirdsFromOtherBreeders' })
        ],
        withBorder: false,
        selectButton: userPlan === 'FREE',
        actualPlan: userPlan === 'PLATINUM',
        handleSelectPlan: () => this.handleSelectPlan(process.env.REACT_APP_PRICE_PLATINUM_PLAN)
      }
    ];

    return (
      <Grid container spacing={2} className={classes.gridContainer}>
        {plans.map((item) => (
          <Grid
            key={item.title}
            item
            xs={12}
            className={item.withBorder ? classes.gridBorderItem : ''}
          >
            <Plan
              title={item.title}
              smallDescription={item.smallDescription}
              price={item.price}
              description={item.descriptions}
              selectButton={item.selectButton}
              actualPlan={item.actualPlan}
              handleSelectPlan={item.handleSelectPlan}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  render() {
    const { intl } = this.props;

    const buttons = [
      {
        label: intl.formatMessage({ id: 'managePlan' }),
        disabled: (this.state.user && this.state.user.plan === 'CUSTOM') || this.state.isLoading,
        color: 'primary',
        variant: 'contained',
        onClick: this.openBillingPortal
      }
    ];

    return (
      <Content isLoading={this.state.isLoading} buttons={buttons}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper padding={true}>{this.renderPlans()}</Paper>
          </Grid>
        </Grid>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer,
  user: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(injectIntl(Plans))));
