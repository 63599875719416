import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography } from '@material-ui/core';

// REACT ROUTER DOM
import { NavLink } from 'react-router-dom';

// REACT FEATHER
import { X } from 'react-feather';

// APP IMPORTS
import styles from './styles';
import ROUTES from '../../../../../utils/routes';

class Header extends React.PureComponent {
  onClick = (event) => {
    event.preventDefault();

    this.props.onItemClick();
  };

  render() {
    const { classes } = this.props;

    return (
      <NavLink to={ROUTES.ROOT} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.logoContainer}>
              <div className={classes.logoInnerContainer}>
                <img
                  src={require('../../../../../assets/images/logo.png')}
                  alt={'myownbirds'}
                  className={classes.logo}
                />
              </div>
              {!this.props.isFixed && (
                <div>
                  <IconButton edge={'start'} color={'secondary'} onClick={this.onClick}>
                    <X size={28} color={'#f7b500'} />
                  </IconButton>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h5'} color={'primary'} className={classes.title}>
              My Own Birds
            </Typography>
          </Grid>
        </Grid>
      </NavLink>
    );
  }
}

Header.propTypes = {
  isFixed: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func.isRequired
};

export default withStyles(styles)(Header);
