// PORTUGUESE

export default {
  // app
  logout: 'Sair',
  view: 'Ver',
  add: 'Adicionar',
  remove: 'Remover',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  next: 'Próximo',
  back: 'Anterior',
  yes: 'Sim',
  no: 'Não',
  undefined: 'Indefinido',
  edit: 'Editar',
  goBack: 'Voltar',
  close: 'Fechar',
  rowsPerPage: 'Linhas por página',
  of: 'de',
  home: 'Início',
  startNow: 'Comece agora',

  // sidebar
  dashboard: 'Dashboard',
  users: 'Utilizadores',
  account: 'Conta',
  others: 'Outros',
  plans: 'Planos',
  transfers: 'Cedências',

  // home
  birdsPortal: 'Portal completo para a gestão de aviários',
  breedersCollaboration: 'Com a colaboração de criadores de pássaros',
  birdsManagement: 'Gestão de pássaros',
  couplesAndClutchesManagement: 'Gestão de casais e posturas',
  birdTransfers: 'Cedência de pássaros',
  searchBreedersWithSpecificSpecies: 'Procura de criadores com determinada espécie',

  mainFunctionalities: 'Principais Funcionalidades',
  availableForBreeder: 'Ao dispor de todos os criadores',
  manageBirdsStock: 'Gestão do stock de pássaros',
  manageCouples: 'Gestão de casais',
  manageClutches: 'Gestão de época de reprodução',
  efficientSearch: 'Pesquisa eficiente',
  birdProfile: 'Ficha individual do pássaro',
  simpleTransfer: 'Processo de cedência simplificado',

  // public plans
  increaseProductivity: 'Aumente a sua produtividade durante a criação de pássaros',
  freeDescription:
    'Será gratuito para sempre. O plano perfeito para conhecer algumas das nossas funcionalidades.',
  basicDescription:
    'Ideal para aqueles que querem inicar esta atividade e começar a gerir facilmente os seus planteis.',
  premiumDescription: 'Ideal para aqueles que querem alcançar o nível profissional.',
  platinumDescription: 'O plano perfeito para aqueles que procuram passar dos limites.',
  manageInfiniteNumberOfBirds: 'Gerir um número infinito de pássaros',
  manageCouplesUpTo: 'Gerir até {number} casais',
  createTransferRequests:
    'Criar pedidos e respetivos documentos para ceder pássaros a outros criadores',
  searchForOtherBirdsFromOtherBreeders: 'Procurar pássaros de outros criadores',
  year: 'ano',

  // login
  login: 'Iniciar sessão',
  email: 'E-mail',
  password: 'Palavra-passe',
  rememberMe: 'Lembrar-me',
  forgotPassword: 'Esqueceu-se da palavra-passe?',
  invalidEmail: 'Este e-mail não é válido.',
  emailIsRequired: 'O e-mail é obrigatório.',
  passwordIsRequired: 'A palavra-passe é obrigatória.',
  invalidCredentials: 'Os dados fornecidos estão errados.',

  // species
  meetTheSupportedSpecies: 'Conheça as espécies presentes na plataforma',
  weAreKeenToSupportMore: 'Envie um email para {email} se gostasse de adicionar mais espécies.',

  // register
  perfectPlatform: 'A plataforma ideal para gerires os teus pássaros',
  ourMission:
    'A nossa missão é ajudar os criadores a gerir o seu aviário com facilidade. Existem muitas funcionalidades disponíveis',
  createYourFreeAccount: 'Crie gratuitamente a sua conta',
  createAccount: 'Criar conta',
  acceptTermsAndConditionsRegister: 'Ao avançar aceita os',
  termsAndConditions: 'Termos e Condições',
  registerErrorEmailAlreadyUsed: 'Este e-mail já está a ser utilizado.',
  registerErrorStripeProblem: 'Ocorreu um erro com uma API externa. Tente outra vez, por favor.',
  registerErrorConfirmationRegistrationCode: 'Este código de registo não é válido.',
  registerErrorInvalidEmail: 'Este e-mail não é válido.',
  registerErrorInvalidRecaptchaToken: 'ReCaptcha Token não é válido.',
  invalidPassword: 'Password deve ter no mínimo 5 caracteres',
  registerSuccess: 'Verifique o seu e-mail e introduza o código de registo.',
  registrationCode: 'Código de registo',
  confirmRegistrationCode: 'Confirmar código',

  // forgot password
  sendResetInstructions: 'Enviar instruções',
  backToLogin: 'Voltar para iniciar sessão',
  resetPasswordRequestInfoMessage: 'Foi-lhe enviado um email para recuperar a sua palavra-passe',
  resetPassword: 'Redefinir palavra-passe',
  resetRequestErrorMessage: 'O pedido para redefinir a sua palavra-passe já não é válido',

  // users
  addUser: 'Adicionar utilizador',
  noUsers: 'Não existem utilizadores',
  emailConfirmed: 'E-mail confirmado',
  country: 'País',
  userErrorMessage: 'Utilizador não encontrado',

  // users add
  contact: 'Contacto',
  birthday: 'Data de nascimento',

  // breeder dashboard
  hiBreeder: 'Olá, {breederName}',
  numberOfBirds: 'Pássaros',
  numberOfCouples: 'Casais',
  numberOfClutches: 'Posturas',
  youCurrentlyHave:
    'Atualmente possui o plano {plan}. Se pretende subscrever outra solução, visite a nossa {plansPage}.',
  plansPage: 'página de planos',
  numberOfTransfers: 'Cedências',
  numberOfDead: 'Mortos',

  // account
  language: 'Idioma',
  registeredAt: 'Registado a',
  updatedAt: 'Atualizado a',
  changePassword: 'Alterar palavra-passe',
  currentPassword: 'Palavra-passe atual',
  newPassword: 'Nova palavra-passe',
  confirmPassword: 'Confirmar palavra-passe',
  passwordsDontMatch: 'As palavras-passe não coincidem.',
  editAccount: 'Editar conta',
  changedPasswordErrorMessage: 'A palavra-passe atual não está correta.',
  changedPasswordSuccessMessage: 'A palavra-passe foi alterada com sucesso.',
  changedPersonalInformationInfoMessage: 'A sua informação pessoal foi alterada.',
  breederIdentifiers: 'Identificadores de criador',
  addBreederIdentifier: 'Adicionar identificador',
  setAsDefault: 'Definir como padrão',
  isDefault: 'Valor padrão',
  breederIdentifier: 'Identificador de criador',
  removeBreederIdentifier: 'Remover identificador de criador',
  removeBreederIdentifierSuccessMessage: 'O identificador foi removido',
  removeBreederIdentifierErrorMessage: 'Não foi possível remover o identificador',
  removeBreederIdentifierQuestion: 'Tem certeza que deseja remover este identificador?',
  setAsDefaultQuestion: 'Tem certeza que deseja definir como padrão este identificador?',
  setAsDefaultBreederIdentifierSuccessMessage: 'O identificador foi definido como padrão',
  setAsDefaultBreederIdentifierErrorMessage: 'Não foi possível definir como padrão o identificador',
  editBreederIdentifier: 'Editar identificador de criador',
  editBreederIdentifierSuccessMessage: 'O identificador do criador foi editado',
  breederIdentifierErrorMessage: 'O identificador de criador já está em uso',
  addBreederIdentifierSuccessMessage: 'Um novo identificador de criador foi adicionado',
  identifierDescription:
    'O identificador representa um código atribuído a um criador por uma ' +
    'associação / federação / outros.',
  identifierDescription2: 'O identificador será utilizado no momento de criação de um pássaro.',
  noIdentifiers: 'Sem identificadores',
  closeAccount: 'Fechar conta',
  closeAccountInformation:
    'O encerramento da sua conta é permanente e não poderá ser desfeito! Iremos remover todos os ' +
    'dados associados à sua conta. Se possui alguma subscrição ativa nós iremos cancelá-la ' +
    'imediatamente.',
  closeAccountKeepBirds: 'Os dados associados às cedências de pássaros serão mantidos.',
  closeAccountEmailWillBeSent:
    'Por questões de segurança, enviaremos um email com um código que deverá introduzir para ' +
    'validar o encerramento da sua conta.',
  confirmationCode: 'Código de confirmação',

  // plans
  choosePlan: 'Escolher plano',
  managePlan: 'Gerir plano',
  plan: 'Plano',
  cancelSubscription: 'Cancelar subscrição',
  cancelSubscriptionMessage: 'Tem a certeza que quer cancelar esta subscrição?',
  newSubscriptionErrorMessage:
    'Ocorreu um erro ao tentar adicionar uma nova subscrição. Por favor, tente outra vez!',
  cancelSubscriptionErrorMessage:
    'Ocorreu um erro ao tentar cancelar a subscrição atual. Por favor, tente outra vez!',
  billing: 'Faturação',
  manageYourBirds: 'Gere os teus pássaros',
  manageMaximumCouples: 'Gere no máximo',
  manageMoreThanCouples: 'Gere mais do que',
  doTransferRequests: 'Transfere os teus pássaros',
  viewOtherBirds: 'Vê pássaros de outros criadores',
  free: 'Grátis',
  basic: 'Básico',
  premium: 'Premium',
  platinum: 'Platinum',
  custom: 'Personalizado',
  select: 'Selecionar',
  actualPlan: 'Plano atual',

  // email confirmation
  emailConfirmation: 'Confirmação do e-mail',
  emailWasConfirmed: 'O e-mail foi confirmado com sucesso.',
  emailWasNotConfirmed:
    'Ocorreu um problema ao tentarmos confirmar o email. Se este ainda não foi confirmado, requisite um novo link de confirmação.',
  requestConfirmation: 'Requisitar nova confirmação',

  // admin dashboard
  activeUsers: 'Utilizadores activos',
  accountsClosed: 'Contas fechadas',
  top10CountriesWithBreeders: 'Top 10 países com criadores ativos',
  top10CountriesWithBirds: 'Top 10 países com pássaros ativos',
  top10SpeciesWithBirds: 'Top 10 espécies com pássaros ativos',

  // breeder users
  name: 'Nome',
  addedAt: 'Adicionado a',
  active: 'Ativo',
  addUserIfNotExist:
    'O utilizador será adicionado à sua lista depois de este ter aceite o pedido. Garanta que o e-mail do utilizador está correto.',
  addUserRecommendation: 'Caso o utilizador esteja já registado na plataforma, peça-lhe o e-mail.',
  searchBy: 'Procurar por',
  searchByNameEmailCountry: 'Nome, e-mail ou país do utilizador',

  // breeder birds
  addBird: 'Adicionar pássaro',
  myBirds: 'Os meus pássaros',
  searchBirds: 'Procurar pássaros',
  transferRequests: 'Pedidos de cedência',
  breeder: 'Criador',
  ring: 'Anilha',
  gender: 'Sexo',
  specie: 'Espécie',
  mutation: 'Mutação',
  noBirds: 'Não existem pássaros',
  searchByRing: 'Anilha',
  birthdayStart: 'Início da data de nascimento',
  birthdayEnd: 'Fim da data de nascimento',
  genderAndCountry: 'Sexo e País',
  birdsErrorMessage: 'Erro em obter os seus pássaros',
  speciesErrorMessage: 'Erro em obter todas as espécies',
  mutationsErrorMessage: 'Erro em obter todas as mutações',
  filterSearch: 'Filtros',
  addBirdSuccessMessage: 'Foi registado um novo pássaro com sucesso',
  addBirdErrorMessage: 'Não foi possível registar um novo pássaro',
  observations: 'Observações',
  initialData: 'Dados iniciais',
  isYellow: 'É amarelo',
  isWhiteHead: 'É cabeça branca',
  isCarduelisSpecieHeader:
    'Selecionou a espécie Carduelis Carduelis. Indique se o seu pássaro é amarelo ou de cabeça branca.',
  yellow: 'Amarelo',
  whiteHead: 'Cabeça branca',
  addBreederIdentifierAddNewBirdMessage:
    'Se ainda não possui ou pretende adicionar um novo identificador clique ',
  acquiredAt: 'Adquirido em',
  firstOwner: 'Primeiro dono',
  owner: 'Próprio',
  firstOwnerInformation: 'Informação do primeiro dono',
  firstOwnerInformationMessage:
    'Ao adicionar um pássaro, poderá indicar se é o dono do mesmo ou se este foi adquirido.',
  ringTotal: 'Anilha total',
  ringAlreadyUsed: 'Anilha já em uso',
  number: 'Número',
  extraInformation: 'Informação extra',
  breederName: 'Nome do criador',
  invalidDate: 'Formato da data é inválido. Deverá especificar o dia, mês e o ano (dd/mm/yyyy).',
  birdInformation: 'Informação do pássaro',
  carrierOfMutations: 'Portador de',
  possiblyCarrierOfMutations: 'Possivelmente portador de',

  // bird states
  state: 'Estado',
  transferred: 'Cedido',
  transferredAt: 'Transferido em',
  deathAt: 'Morto em',
  death: 'Morto',
  all: 'Todos',

  // bird information
  children: 'Filhos',
  clutches: 'Posturas',
  parents: 'Progenitores',
  parent: 'Progenitor',
  parent1: 'Pai 1',
  parent2: 'Pai 2',
  acquired: 'Adquirido',
  editBirdSuccessMessage: 'Este pássaro foi editado com sucesso.',
  editBirdErrorMessage: 'Não foi possível editar este pássaro.',
  documents: 'Documentos',
  editBird: 'Editar pássaro',
  noDocuments: 'Este pássaro não tem documentos anexados',
  document: 'Documento',
  noComments: 'Não existem observações',
  removeBird: 'Remover pássaro',
  onlyOneFieldFillError: 'Só pode preencher um campo',
  removeBirdQuestion: 'Tem a certeza que quer remover este pássaro?',
  removeBirdSuccessMessage: 'O pássaro foi removido com sucesso',
  removeBirdErrorMessage: 'Não foi possível remover este pássaro.',
  removeBirdHasChildrenErrorMessage:
    'O pássaro tem filhos. Se quiser alterar o estado deste pássaro, por favor atualize nas secções abaixo.',
  removeBirdWasTransferredErrorMessage:
    'O pássaro foi-lhe transferido. Se quiser alterar o estado deste pássaro, por favor atualize nas secções abaixo.',
  declareDeath: 'Declarar óbito',
  declareDeathSuccessMessage: 'O pássaro foi declarado morto com sucesso.',
  removeImageSuccessMessage: 'A imagem foi removida com sucesso.',
  getBirdErrorMessage: 'O pássaro não existe ou não tem permissão para o visualizar.',

  transfersHistory: 'Histórico de cedências',
  transfer: 'Cedência',
  transferLine: 'Transferido para {country} em {date}.',
  transferLineFrom: 'Transferido de {country} em {date}.',
  removeImage: 'Remover imagem',
  removeImageQuestion: 'Tem a certeza que pretende remover esta imagem?',
  setImageAsDefault: 'Definir imagem como default',
  setImageAsDefaultQuestion: 'Tem a certeza que quer definir esta imagem como default?',

  // bird couples
  couples: 'Casais',
  noCouples: 'Não existem casais',
  addCoupleErrorMessagePlanDontAllow: 'Verifique se o seu plano permite fazer esta ação.',
  addCoupleErrorMessage:
    'Ocorreu um erro a criar o casal. Verifique se introduziu os dados corretamente.',
  parentsAreBrothers: 'Estes pássaros são irmãos ou meios-irmãos.',
  couplesErrorMessage: 'Erro em obter os seus casais',
  couplesSuccessMessage: 'Foi criado um casal com sucesso.',
  inactive: 'Inativo',
  addCouple: 'Adicionar casal',
  addCoupleInformation:
    'Os seguintes pássaros têm de estar registados na nossa plataforma. Se os pretende registar previamente, clique ',
  here: 'aqui.',
  chooseParents: 'Escolher pais',
  searchByParentsRing: 'Identificador do casal',
  male: 'Macho',
  female: 'Fêmea',
  unknown: 'Desconhecido',
  identifier: 'Identificador',
  identifierIsRequired: 'O identificador é obrigatório.',
  identifierMoreThan: 'Identificador tem de ser maior que 0',
  identifierAlreadyUsed: 'Identificador já em uso',
  editIdentifier: 'Editar identificador',
  editCouple: 'Editar casal',
  editCoupleSuccessMessage: 'O casal foi editado com sucesso.',
  editCoupleErrorMessage: 'Ocorreu um problema ao tentarmos editar este casal.',
  setCageIdentifierCoupleInactive:
    'Este casal está inativo ou ficará inativo. Não é possível alterar o identificador da gaiola.',
  setIsActiveCoupleErrorMessage:
    'Não é possível reativar este casal. Um dos pais está morto ou foi transferido.',
  removeCouple: 'Remover casal',
  removeCoupleNotice:
    "Por favor, considere definir como inativo o casal (em 'Editar casal'). Iremos manter os pássaros mas as posturas serão removidas e irá perder as relações entre pássaros.",
  removeCoupleQuestion: 'Pretende remover este casal? Esta ação é irreversível!',
  removeCoupleSuccessMessage: 'O casal foi removido com sucesso.',
  removeCoupleErrorMessage: 'Ocorreu um problema ao tentarmos remover este casal.',

  // couple information
  addChild: 'Adicionar filho',
  addClutch: 'Adicionar postura',
  couple: 'Casal',
  scheduled: 'Agendada',
  finished: 'Terminada',
  addChildErrorMessage: 'Não foi possível adicionar um novo filho a este casal',
  addChildCoupleInactive:
    'Este casal está inativo. Só é possível adicionar um novo filho, ligando-o a uma nova postura.',
  addChildSuccessMessage: 'Foi adicionado um novo filho a este casal',
  addCoupleChildInformationHeader:
    'O filho que irá ser registado neste casal não estará ligado a nenhuma postura. Se quiser essa ligação, clique ',
  child: 'Filho',
  cageIdentifier: 'Identificador da gaiola',
  cage: 'Gaiola',
  qrCodeMessage:
    'Utilize este código QR para identificar este casal e aceder rapidamente a esta página.',

  // clutches
  totalEggs: 'Total de ovos',
  startDate: 'Data de início',
  description: 'Descrição',
  addClutchSuccessMessage: 'Foi adicionada uma nova postura a este casal',
  addClutchErrorMessage: 'Não foi possível adicionar uma nova postura a este casal',
  addClutchCoupleInactive: 'Este casal está inativo. Não é possível adicionar novas posturas.',
  noClutches: 'Não existem posturas',
  clutchesErrorMessage: 'Erro em obter as posturas deste casal',
  childrenCount: '{eggsCount} ovos e {childrenCount} filhos',
  onGoing: 'A decorrer',

  // clutch
  clutch: 'Postura',
  editClutch: 'Editar postura',
  abortedEggs: 'Ovos abortados',
  fertilizedEggs: 'Ovos fecundados',
  removeClutch: 'Remover postura',
  totalEggsExceeded: 'Total de ovos ultrapassado',
  editClutchSuccessMessage: 'Os dados da postura foram atualizados com sucesso',
  editClutchErrorMessage: 'Não foi possível atualizar os dados da postura',
  addClutchChildHeader:
    'Só pode adicionar filhos a esta postura se adicionar mais ovos fecundados.',

  // birds market
  breederEmail: 'Email do criador',
  searchByBreederEmail: 'Email do criador',
  breederInformation: 'Informação do criador',
  bird: 'Pássaro',
  transferRequest: 'Pedido de cedência',
  transferRequestSuccessMessage: 'A cedência foi criada com sucesso.',
  transferRequestErrorMessage: 'Ocorreu um problema ao criar a sua cedência.',
  transferNumberAlreadyExists: 'Este número já existe no ano currente.',
  missingDataBreederReceiverErrorMessage:
    'O destinatário ainda não preencheu dados obrigatórios no seu perfil.',
  specieRequired: 'Tem de escolher uma espécie',
  mutationRequired: 'Tem de escolher uma mutação',
  showInterest: 'Mostrar interesse',
  sendEmailQuestion:
    'Vai ser enviado um e-mail ao criador a informar que tem interesse na mutação {mutation}. ' +
    'O seu nome e e-mail serão partilhados. Pretende fazer o envio?',
  sendEmailSuccessMessage: 'O email foi enviado com sucesso.',

  // transfer requests
  createTransfer: 'Criar cedência',
  holder: 'Titular',
  newOwner: 'Novo proprietário',
  birdMutation: 'Mutação do pássaro',
  noTransferRequests: 'Não existem pedidos de cedência',
  transferRequestsErrorMessage: 'Erro em obter os seus pedidos de cedência',
  chooseBirds: 'Escolher pássaros',
  questionBreederUser: 'Não é utilizador da aplicação?',
  addBreederInformation: 'Adicionar informação do criador',
  zipCode: 'Código postal',
  address: 'Morada',
  sent: 'Enviados',
  received: 'Recebidos',
  type: 'Tipo',
  pending: 'Pendente',
  accepted: 'Aceite',
  refused: 'Recusado',
  removed: 'Removido',
  transfersUnderMaintenance: 'Cedências em manutenção',
  transfersUnderMaintenanceText:
    'As cedências estarão disponíveis com a maior brevidade possível. Estamos a trabalhar em cedências específicas de cada país e vamos garantir uma melhor experiência de utitilização. Será possível:',
  weAreVeryGrateful: 'Estamos muito gratos pela confiança em nós e na nossa plataforma.',
  pleaseContact: 'Por favor, contacte {mailto} se tiver alguma questão.',

  // transfer request
  acceptTransfer: 'Aceitar cedência',
  refuseTransfer: 'Recusar cedência',
  removeTransfer: 'Remover cedência',
  downloadTransferDocument: 'Documento de cedência',
  sharedDocuments: 'Documentos partilhados',
  registerDate: 'Data de registo',
  transferNumberOfBirds: 'Número de pássaros',
  accessCodeTitle: 'Código de acesso aos dados',
  accessCodeMessage:
    'No processo de cedências é possível fornecer os seus dados pessoais ao titular dos pássaros de uma forma muito simples: através do código em baixo que será requisitado durante a criação da cedência.',
  accessCodeDuration:
    'Este código é gerado automaticamente a cada 15 segundos e só poderá ser utilizado uma vez.',
  removeTransferMessage: 'Tem a certeza que pretende remover esta cedência?',
  removeTransferError:
    'Não foi possível rmeover esta cedência uma vez que já foi aprovada ou recusada.',
  removeTransferSuccess: 'A cedência foi removida com sucesso.',

  // create transfer request
  isRegistered: 'Utilizador registado na platforma',
  receptorCode: 'Código de acesso aos dados do novo dono',
  receptorCodeHelperText: 'Este código está na página de cedências do utilizador.',
  receptorGetData: 'Receber dados',
  transferNumber: 'Número da cedência',
  createTransferInfo1:
    'O documento de cedência é baseado no país do utilizador que cria a cedência. Estamos a gerir estes documentos ativamente. Se achar que faltam campos obrigatórios no seu país, indique, por favor, através do email {email} os mesmos.',
  createTransferInfo2: 'Apenas poderá criar a uma cedência assim que a anterior estiver terminada.',
  createTransferInfo3: 'Os campos marcados com * são obrigatórios.',
  createTransferInfo4:
    'O número da cedência é gerado automaticamente mas poderá editar o mesmo, sendo que este deverá ser único no ano corrente.',
  createTransferInfo5:
    'É possível criar cedências para pessoas não registadas na plataforma. Neste caso, a cedência será fechada automaticamente e os pássaros serão marcados como cedidos.',
  createTransferInfo6:
    'No caso do novo dono estar registado na plataforma, é necessário que este lhe indique o código gerado na página de cedências para poder obter os dados pessoais do mesmo. Este processo permite que os pássaros sejam transferidos automaticamente para o novo dono após a aprovação.',
  breederNotFoundOrInvalidCode: 'O Criador não foi encontrado ou o código é inválido.',

  removeTransferRequest: 'Remover pedido de cedência',
  breederRequesterInformation: 'Informação do antigo criador',
  breederReceiverInformation: 'Informação do novo criador',
  stam: 'STAM',
  breederCode: 'Registo Governamental do Criador (ICNF)',
  removeTransferRequestQuestion:
    'Tem a certeza que pretende remover o pedido de cedência? O destinatário não poderá confirmar a transferência destes pássaros.',
  removeTransferRequestSuccessMessage: 'O pedido de cedência foi removido com sucesso',
  removeTransferRequestErrorMessage: 'It was not possible to remove the transfer request',
  addMoreBreederInformation: 'Adicionar informação pessoal',
  addMoreBreederInformationMessage:
    'É necessário inserir alguns dados no seu perfil previamente (morada, código postal, país). Para inserir esses dados, clique ',

  // public transfer request
  transferRequestConfirmation: 'Confirmação do pedido de cedência',
  confirmTransfer: 'Confirmar pedido de cedência',
  transferRequestConfirmationSuccessMessage: 'Pedido de cedência confirmado',
  transferRequestCancelSuccessMessage: 'Pedido de cedência cancelado',
  transferRequestConfirmationErrorMessage: 'Não foi possível confirmar o pedido de cedência',
  transferRequestCancelErrorMessage: 'Não foi possível cancelar o pedido de cedência',

  // admin
  species: 'Espécies',
  addSpecie: 'Adicionar espécie',
  noSpecies: 'Não existem espécies',
  mutations: 'Mutações',
  addMutation: 'Adicionar mutação',
  noMutations: 'Não existem mutações',
  image: 'Imagem',
  birds: 'Pássaros',
  addSpecieSuccessMessage: 'Foi registada uma nova espécie.',
  addSpecieErrorMessage: 'Esta espécie já existe',
  retrieveSpeciesErrorMessage: 'Erro ao obter a espécie',
  retrieveSpecieErrorMessage: 'Erro ao obter a espécie',
  hasClutchDurationDays: 'Adicionar dias de duração de uma postura',
  days: 'Dias',
  clutchDurationDays: 'Duração da postura (dias)',
  addMutationErrorMessage: 'Esta mutação já existe.',
  editSpecie: 'Editar espécie',
  editSpecieSuccessMessage: 'A espécie foi editada com sucesso',
  removeSpecie: 'Remover espécie',
  removeSpecieErrorMessage: 'Erro ao remover esta espécie',
  removeSpecieQuestion:
    'Tem certeza que deseja remover esta espécie? As mutações relacionadas também serão excluídas.',
  retrieveMutationErrorMessage: 'Erro ao obter a mutação',
  editMutation: 'Editar mutação',
  removeMutation: 'Remover mutação',
  removeMutationErrorMessage: 'Erro ao remover esta mutação',
  removeMutationQuestion: 'Tem certeza que deseja remover esta mutação?',

  // other error messages
  serverErrorMessage: 'Ocorreu um erro no servidor. Estamos a investigar.',

  // input file
  dragAndDrop: 'Arrastar e soltar',
  or: 'ou',
  uploadImage: 'Carregar imagem',
  uploadImages: 'Carregar imagens',
  uploadFile: 'Carregar ficheiro',
  uploadFiles: 'Carregar ficheiros',
  errorFileSize: 'Ficheiro demasiado extenso',
  errorWhileUploading: 'Erro ao carregar o ficheiro',

  // hide components
  changeYourPlanAndGetAccess: 'Altera o teu plano e aceda a novas funcionalidades',
  askOtherBreederToContactYou:
    'Informe outro criador que está interessado numa espécie em específico e peça a este que entre em contacto consigo',
  generatePdfFile:
    'Gerar um documento pronto a ser assinado com a informação dos criadores e de todos os pássaros que vão ser cedidos'
};
