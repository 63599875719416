import { getColors } from '../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  card: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#fff',
    borderTop: `4px solid ${colors.sidebarItem}`,
    boxShadow: '0px 64px 120px -40px rgba(0, 0, 0, 0.24)',
    borderRadius: '26px',
    overflow: 'hidden',
    marginTop: '0px',
    marginBottom: '-48px',
    padding: theme.spacing(4)
  },

  //
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: '1em',
    color: theme.palette.common.black
  },
  subTitle: {
    fontWeight: 'bold',
    lineHeight: '1em',
    color: theme.palette.common.black
  },
  version: {
    fontWeight: 'normal',
    lineHeight: '1em',
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(1)
  },
  text: {
    fontWeight: '500',
    lineHeight: '1.5em',
    // letterSpacing: '0.9px',
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2)
  }
});
