import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

// FEATHER ICONS
import { Twitter } from 'react-feather';

// APP IMPORTS
import styles from './styles';

class BirdAvatar extends React.PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.props.images.length > 0 ? (
          <Avatar
            className={`${this.props.big ? classes.avatarContainerBig : classes.avatarContainer} ${
              this.props.clickable ? classes.avatarContainerClickable : ''
            }`}
            src={process.env.REACT_APP_IMAGES_URL + this.props.images[0].preview_path}
            onClick={this.props.onClick}
          />
        ) : (
          <Avatar className={this.props.big ? classes.avatarContainerBig : classes.avatarContainer}>
            <Twitter size={this.props.big ? 70 : 30} />
          </Avatar>
        )}
      </React.Fragment>
    );
  }
}

BirdAvatar.defaultProps = {
  big: false,
  clickable: false,
  onClick: null
};

BirdAvatar.propTypes = {
  big: PropTypes.bool,
  clickable: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      preview_path: PropTypes.string.isRequired
    })
  ).isRequired,
  onClick: PropTypes.func
};

export default withStyles(styles)(BirdAvatar);
