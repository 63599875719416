import React from 'react';

// REDUX
import { connect } from 'react-redux';

// PROP TYPES
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Divider, Grid, List, Tab, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';

// REACT LIGHT BOX
import Lightbox from 'react-image-lightbox';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// REACT FEATHER
import { Edit, Edit2, Paperclip, Trash2 } from 'react-feather';

// SPINE UI
import { Content, Paper, ConfirmationDialog, FormDialog, Button } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { setAppBarTitle } from '../../../store/actions/appActions';
import { fetchApi } from '../../../utils/apiSettings';
import { getCountryName, getGenderName, getMutationName } from '../../../utils/appSettings';
import ROUTES from '../../../utils/routes';
import Tabs from '../Tabs';
import BirdMainInformation from './components/BirdMainInformation';
import BirdSecondaryInformation from './components/BirdSecondaryInformation';
import BirdItem from '../BirdItem';
import BirdAvatar from '../BirdAvatar';
import InputFile from '../../inputs/InputFile';
import DocumentItem from '../DocumentItem';
import InputDate from '../../inputs/InputDate';
import MuiPickersUtilsProvider from '../../inputs/MuiPickersUtilsProvider';
import EditBirdForm from './components/EditBirdForm';
import styles from './styles';

class BirdProfileNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      // used to identify from which page we are coming from
      birdId: null,
      coupleId: null,
      clutchId: null,

      bird: null,
      tabValue: 0,

      // images viewer
      isViewImagesOpened: false,
      photoIndex: 0,
      isSetImageAsDefaultOpened: false,
      isRemoveImageOpened: false,

      // upload images
      images: [],
      pendingImages: [],
      isUploadImagesOpened: false,

      // upload documents
      files: [],
      pendingFiles: [],
      isUploadDocumentsOpened: false,

      // form
      ring: '',
      gender: '',
      comments: '',
      deathAt: null,
      errors: {},
      isRemoveBirdOpened: false,
      isDeclareDeathOpened: false,
      isEditBirdOpened: false
    };
  }

  componentDidMount() {
    this.props.setAppBarTitle('bird');

    this.setState(
      {
        birdId: this.props.match.params.birdId,
        coupleId: this.props.match.params.coupleId,
        clutchId: this.props.match.params.clutchId
      },
      () => {
        this.fetchBird();
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.birdId !== this.props.match.params.birdId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          isLoading: true,
          birdId: this.props.match.params.birdId,
          coupleId: this.props.match.params.coupleId,
          clutchId: this.props.match.params.clutchId
        },
        () => {
          this.fetchBird();
        }
      );
    }
  }

  fetchBird = () => {
    const { intl, enqueueSnackbar } = this.props;

    fetchApi(
      'get',
      `/breeders/v1/birds/${this.state.birdId}`,
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.props.setAppBarTitle(this.getPageTitle(data.ring, data.year), false);

        this.setState({
          isLoading: false,
          isFetching: false,

          bird: {
            ...data,
            images: data.images.map((image) => ({
              image_id: image.image_id,
              path: process.env.REACT_APP_IMAGES_URL + image.path,
              preview_path: process.env.REACT_APP_IMAGES_URL + image.preview_path
            }))
          },

          tabValue: 0,
          photoIndex: 0
        });
      },
      (error) => {
        const { response } = error;

        if (response.status === 404) {
          enqueueSnackbar(intl.formatMessage({ id: 'getBirdErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        } else {
          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        }

        this.props.history.push(ROUTES.ROOT);
      }
    );
  };

  declareDeath = () => {
    const { intl, enqueueSnackbar } = this.props;

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'patch',
          `/breeders/v1/birds/${this.state.birdId}/death`,
          {},
          {
            death_at: this.state.deathAt.format()
          },
          false,
          () => {
            enqueueSnackbar(intl.formatMessage({ id: 'declareDeathSuccessMessage' }), {
              variant: 'success',
              autoHideDuration: 4000
            });

            this.setState({ isDeclareDeathOpened: false }, () => {
              this.fetchBird();
            });
          },
          () => {
            this.setState({
              isFetching: false,
              isDeclareDeathOpened: false
            });

            enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
              variant: 'error',
              autoHideDuration: 4000
            });
          }
        );
      }
    );
  };

  editBird = async () => {
    const { enqueueSnackbar, intl } = this.props;

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'put',
          `/breeders/v1/birds/${this.state.birdId}`,
          {},
          {
            gender: this.state.gender,
            comments: this.state.comments
          },
          false,
          () => {
            enqueueSnackbar(intl.formatMessage({ id: 'editBirdSuccessMessage' }), {
              variant: 'success',
              autoHideDuration: 4000
            });

            this.setState({ isEditBirdOpened: false }, () => {
              this.fetchBird();
            });
          },
          (error) => {
            const { response } = error;

            this.setState({ isFetching: false });

            if (response.status === 400 || response.status === 404) {
              enqueueSnackbar(intl.formatMessage({ id: 'editBirdErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            } else {
              enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            }
          }
        );
      }
    );
  };

  removeBird = () => {
    const { intl, enqueueSnackbar } = this.props;

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'delete',
          `/breeders/v1/birds/${this.state.birdId}`,
          {},
          {},
          false,
          () => {
            enqueueSnackbar(intl.formatMessage({ id: 'removeBirdSuccessMessage' }), {
              variant: 'success',
              autoHideDuration: 4000
            });

            this.props.history.push(ROUTES.BREEDER_MY_BIRDS);
          },
          () => {
            // TODO: a lot of different responses can be retrieved here - check API responses
            this.setState(
              {
                isFetching: false,
                isRemoveBirdOpened: false
              },
              () => {
                enqueueSnackbar(intl.formatMessage({ id: 'removeBirdErrorMessage' }), {
                  variant: 'error',
                  autoHideDuration: 4000
                });
              }
            );
          }
        );
      }
    );
  };

  removeImage = (imageId) => {
    const { intl, enqueueSnackbar } = this.props;

    this.setState(
      {
        isFetching: true,
        isRemoveImageOpened: false,
        isViewImagesOpened: false
      },
      () => {
        fetchApi(
          'delete',
          `/breeders/v1/birds/${this.state.birdId}/images/${imageId}`,
          {},
          {},
          false,
          () => {
            // TODO: replace success message with better message
            enqueueSnackbar(intl.formatMessage({ id: 'removeImageSuccessMessage' }), {
              variant: 'success',
              autoHideDuration: 4000
            });

            // we need fetch the latest bird information again
            this.fetchBird();
          },
          () => {
            this.setState({
              isFetching: false,
              isViewImagesOpened: true,
              isRemoveImageOpened: true
            });

            enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
              variant: 'error',
              autoHideDuration: 4000
            });
          }
        );
      }
    );
  };

  addImage = (imageId) => {
    return new Promise((resolve) => {
      fetchApi(
        'put',
        `/breeders/v1/birds/${this.state.birdId}/images`,
        {},
        {
          image_id: imageId
        },
        false,
        () => {
          resolve();
        },
        () => {
          resolve();
        }
      );
    });
  };

  addDocument = (documentId) => {
    return new Promise((resolve) => {
      fetchApi(
        'put',
        `/breeders/v1/birds/${this.state.birdId}/documents`,
        {},
        {
          document_id: documentId
        },
        false,
        () => {
          resolve();
        },
        () => {
          resolve();
        }
      );
    });
  };

  setImageAsDefault = (imageId) => {
    const { intl, enqueueSnackbar } = this.props;
    this.setState(
      {
        isFetching: true,
        isSetImageAsDefaultOpened: false,
        isViewImagesOpened: false
      },
      () => {
        fetchApi(
          'patch',
          `/breeders/v1/birds/${this.state.birdId}/images`,
          {},
          {
            image_id: imageId
          },
          false,
          () => {
            enqueueSnackbar(intl.formatMessage({ id: 'editBirdSuccessMessage' }), {
              variant: 'success',
              autoHideDuration: 4000
            });

            // we need fetch the latest bird information again
            this.fetchBird();
          },
          () => {
            enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
              variant: 'error',
              autoHideDuration: 4000
            });
          }
        );
      }
    );
  };

  uploadImage = (image) => {
    const formData = new FormData();

    // create payload based on the endpoint ("image" or "file")
    formData.append('file', image.file);

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'post',
          '/breeders/v1/images',
          {
            'Content-Type': 'multipart/form-data'
          },
          formData,
          false,
          (response) => {
            const { data } = response;

            this.setState((state) => ({
              // add file to the list of existing files (already uploaded)
              images: [
                ...state.images,
                {
                  // keep names with underscore so we can handle files that
                  // already exist (that came from another API request)
                  // and this new file
                  image_id: data.image_id,
                  path: data.path,
                  preview_path: data.preview_path
                }
              ],
              // set pending files to 100 (or remove them if needed)
              pendingImages: [
                ...state.pendingImages.map((item) => {
                  if (item.fileURL === image.fileURL)
                    return {
                      ...item,
                      error: false,
                      errorMessage: '',
                      percentageCompleted: 100
                    };
                  return item;
                })
              ],
              isFetching: false
            }));
          },
          () => {
            this.setState((state) => ({
              pendingImages: [
                ...state.pendingImages.map((item) => {
                  if (item.fileURL === image.fileURL)
                    return {
                      ...item,
                      error: true,
                      errorMessage: 'errorWhileUploading'
                    };
                  return item;
                })
              ],
              isFetching: false
            }));
          },
          (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentageCompleted = Math.round((loaded * 100) / total);

            if (percentageCompleted < 100) {
              this.setState((state) => ({
                pendingImages: [
                  ...state.pendingImages.map((item) => {
                    if (item.fileURL === image.fileURL)
                      return {
                        file: image.file,
                        fileName: image.fileName,
                        fileURL: image.fileURL,
                        percentageCompleted: Math.round((loaded * 100) / total),
                        error: false,
                        errorMessage: ''
                      };
                    return item;
                  })
                ]
              }));
            }
          }
        );
      }
    );
  };

  uploadDocument = (file) => {
    const formData = new FormData();

    // create payload based on the endpoint ("image" or "file")
    formData.append('file', file.file);

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'post',
          '/breeders/v1/documents',
          {
            'Content-Type': 'multipart/form-data'
          },
          formData,
          false,
          (response) => {
            const { data } = response;

            this.setState((state) => ({
              // add file to the list of existing files (already uploaded)
              files: [
                ...state.files,
                {
                  // keep names with underscore so we can handle files that
                  // already exist (that came from another API request)
                  // and this new file
                  document_id: data.document_id,
                  path: data.path
                }
              ],
              // set pending files to 100 (or remove them if needed)
              pendingFiles: [
                ...state.pendingFiles.map((item) => {
                  if (item.fileURL === file.fileURL)
                    return {
                      ...item,
                      error: false,
                      errorMessage: '',
                      percentageCompleted: 100
                    };
                  return item;
                })
              ],
              isFetching: false
            }));
          },
          () => {
            this.setState((state) => ({
              pendingFiles: [
                ...state.pendingFiles.map((item) => {
                  if (item.fileURL === file.fileURL)
                    return {
                      ...item,
                      error: true,
                      errorMessage: 'errorWhileUploading'
                    };
                  return item;
                })
              ],
              isFetching: false
            }));
          },
          (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentageCompleted = Math.round((loaded * 100) / total);

            if (percentageCompleted < 100) {
              this.setState((state) => ({
                pendingFiles: [
                  ...state.pendingFiles.map((item) => {
                    if (item.fileURL === file.fileURL)
                      return {
                        file: file.file,
                        fileName: file.fileName,
                        fileURL: file.fileURL,
                        percentageCompleted: Math.round((loaded * 100) / total),
                        error: false,
                        errorMessage: ''
                      };
                    return item;
                  })
                ]
              }));
            }
          }
        );
      }
    );
  };

  addImages = () => {
    const promises = this.state.images.map((image) => this.addImage(image.image_id));
    this.setState(
      {
        isFetching: true
      },
      () => {
        const { intl, enqueueSnackbar } = this.props;

        Promise.all(promises).then(() => {
          // TODO: replace success message with better message
          enqueueSnackbar(intl.formatMessage({ id: 'editBirdSuccessMessage' }), {
            variant: 'success',
            autoHideDuration: 4000
          });

          this.cancelUploadImages();

          // we need fetch the latest bird information again
          this.fetchBird();
        });
      }
    );
  };

  addDocuments = () => {
    const promises = this.state.files.map((file) => this.addDocument(file.document_id));
    this.setState(
      {
        isFetching: true
      },
      () => {
        const { intl, enqueueSnackbar } = this.props;

        Promise.all(promises).then(() => {
          // TODO: replace success message with better message
          enqueueSnackbar(intl.formatMessage({ id: 'editBirdSuccessMessage' }), {
            variant: 'success',
            autoHideDuration: 4000
          });

          this.cancelUploadDocuments();

          // we need fetch the latest bird information again
          this.fetchBird();
        });
      }
    );
  };

  getBreadcrumbs = () => {
    const { intl } = this.props;

    let birdBreadcrumb = {
      title: intl.formatMessage({ id: 'bird' }),
      active: true
    };

    if (this.state.bird) {
      birdBreadcrumb = {
        title: this.getPageTitle(this.state.bird.ring, this.state.bird.year),
        active: true
      };
    }

    if (this.props.breadcrumbType === 'myBirds') {
      return [
        {
          title: intl.formatMessage({ id: 'myBirds' }),
          active: false,
          href: ROUTES.BREEDER_MY_BIRDS,
          onClick: () => {
            this.props.history.push(ROUTES.BREEDER_MY_BIRDS);
          }
        },
        birdBreadcrumb
      ];
    }

    if (this.props.breadcrumbType === 'parent') {
      return [
        {
          title: intl.formatMessage({ id: 'couples' }),
          active: false,
          href: ROUTES.BREEDER_COUPLES,
          onClick: () => {
            this.props.history.push(ROUTES.BREEDER_COUPLES);
          }
        },
        {
          title: intl.formatMessage({ id: 'couple' }),
          href: `${ROUTES.BREEDER_COUPLES}/${this.state.coupleId}`,
          active: false,
          onClick: () => {
            this.props.history.push(`${ROUTES.BREEDER_COUPLES}/${this.state.coupleId}`);
          }
        },
        birdBreadcrumb
      ];
    }

    if (this.props.breadcrumbType === 'coupleChild') {
      return [
        {
          title: intl.formatMessage({ id: 'couples' }),
          active: false,
          href: ROUTES.BREEDER_COUPLES,
          onClick: () => {
            this.props.history.push(ROUTES.BREEDER_COUPLES);
          }
        },
        {
          title: intl.formatMessage({ id: 'couple' }),
          href: `${ROUTES.BREEDER_COUPLES}/${this.state.coupleId}`,
          active: false,
          onClick: () => {
            this.props.history.push(`${ROUTES.BREEDER_COUPLES}/${this.state.coupleId}`);
          }
        },
        birdBreadcrumb
      ];
    }

    if (this.props.breadcrumbType === 'clutchChild') {
      return [
        {
          title: intl.formatMessage({ id: 'couples' }),
          active: false,
          href: ROUTES.BREEDER_COUPLES,
          onClick: () => {
            this.props.history.push(ROUTES.BREEDER_COUPLES);
          }
        },
        {
          title: intl.formatMessage({ id: 'couple' }),
          href: `${ROUTES.BREEDER_COUPLES}/${this.state.coupleId}`,
          active: false,
          onClick: () => {
            this.props.history.push(`${ROUTES.BREEDER_COUPLES}/${this.state.coupleId}`);
          }
        },
        {
          title: intl.formatMessage({ id: 'clutch' }),
          href: `${ROUTES.BREEDER_COUPLES}/${this.state.coupleId}/clutches/${this.state.clutchId}`,
          active: false,
          onClick: () => {
            this.props.history.push(
              `${ROUTES.BREEDER_COUPLES}/${this.state.coupleId}/clutches/${this.state.clutchId}`
            );
          }
        },
        birdBreadcrumb
      ];
    }

    return [];
  };

  getPageTitle = (ring, year) => {
    return `${ring} (${year})`;
  };

  isDeath = () => {
    return this.state.bird && this.state.bird.state === 'death';
  };

  /**
   * Start uploading each image using `uploadImage` function.
   * @param pendingImages
   */
  handleAddImages = (pendingImages) => {
    this.setState(
      (state) => ({
        pendingImages: [...state.pendingImages, ...pendingImages]
      }),
      () => {
        // upload each file automatically
        pendingImages.forEach((file) => {
          if (!file.error) {
            this.uploadImage(file);
          }
        });
      }
    );
  };

  /**
   * Remove image that had a problem during upload.
   * @param imageURL
   */
  handleRemoveImageWithError = (imageURL) => {
    this.setState((state) => ({
      pendingImages: state.pendingImages.filter((item) => item.fileURL !== imageURL)
    }));
  };

  openUploadImages = () => {
    this.setState({
      isUploadImagesOpened: true,
      images: [],
      pendingImages: []
    });
  };

  cancelUploadImages = () => {
    this.setState({
      isUploadImagesOpened: false,
      images: [],
      pendingImages: []
    });
  };

  /**
   * Start uploading each file using `uploadFile` function.
   * @param pendingFiles
   */
  handleAddFiles = (pendingFiles) => {
    this.setState(
      (state) => ({
        pendingFiles: [...state.pendingFiles, ...pendingFiles]
      }),
      () => {
        // upload each file automatically
        pendingFiles.forEach((file) => {
          if (!file.error) {
            this.uploadDocument(file);
          }
        });
      }
    );
  };

  /**
   * Remove file that had a problem during upload.
   * @param fileURL
   */
  handleRemoveFileWithError = (fileURL) => {
    this.setState((state) => ({
      pendingFiles: state.pendingFiles.filter((item) => item.fileURL !== fileURL)
    }));
  };

  openDeclareDeath = () => {
    this.setState({ isDeclareDeathOpened: true });
  };

  cancelDeclareDeath = () => {
    this.setState({
      isDeclareDeathOpened: false,
      deathAt: null
    });
  };

  openUploadDocuments = () => {
    this.setState({
      isUploadDocumentsOpened: true,
      files: [],
      pendingFiles: []
    });
  };

  cancelUploadDocuments = () => {
    this.setState({
      isUploadDocumentsOpened: false,
      files: [],
      pendingFiles: []
    });
  };

  openEditForm = () => {
    this.setState((state) => {
      return {
        isEditBirdOpened: true,
        ring: state.bird.ring,
        gender: state.bird.gender,
        comments: state.bird.comments,
        errors: {}
      };
    });
  };

  cancelEditBird = () => {
    this.setState({
      isEditBirdOpened: false,
      comments: '',
      errors: {}
    });
  };

  handleOnChangeTab = (_, value) => {
    this.setState({
      tabValue: value
    });
  };

  handleInputChange = (event) => {
    const input = event.target;

    this.setState({
      [input.name]: input.value,
      errors: {}
    });
  };

  handleSelectChange = (event) => {
    const input = event.target;

    this.setState({
      [input.name]: input.value,
      errors: {}
    });
  };

  handleDateChange = (name, date) => {
    this.setState({
      [name]: date,
      errors: {}
    });
  };

  renderBirdImage = () => {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.avatarContainer}>
          <BirdAvatar
            big={true}
            images={this.state.bird.default_image ? [this.state.bird.default_image] : []}
            clickable={true}
            onClick={() => {
              this.setState({
                isViewImagesOpened: true
              });
            }}
          />
          {!this.isDeath() && (
            <Avatar className={classes.avatarUploadContainer} onClick={this.openUploadImages}>
              <Edit2 size={20} />
            </Avatar>
          )}
        </div>
      </div>
    );
  };

  renderImageViewer = () => {
    const { classes } = this.props;
    const { isViewImagesOpened, bird, photoIndex } = this.state;

    const toolbarLightbox = [
      <Trash2
        className={classes.lightboxButton}
        onClick={() => {
          this.setState({ isRemoveImageOpened: true });
        }}
        size={20}
      />
    ];

    if (bird.images.length > 1) {
      toolbarLightbox.push(
        <Edit
          className={classes.lightboxButton}
          onClick={() => {
            this.setState({ isSetImageAsDefaultOpened: true });
          }}
          size={20}
        />
      );
    }

    return (
      <React.Fragment>
        {isViewImagesOpened && (
          <Lightbox
            reactModalStyle={{ overlay: { zIndex: 1200 } }}
            mainSrc={bird.images[photoIndex].path}
            nextSrc={bird.images[(photoIndex + 1) % bird.images.length].path}
            prevSrc={bird.images[(photoIndex + bird.images.length - 1) % bird.images.length].path}
            onCloseRequest={() => this.setState({ isViewImagesOpened: false, photoIndex: 0 })}
            imagePadding={50}
            onMovePrevRequest={() =>
              this.setState((state) => ({
                photoIndex:
                  (state.photoIndex + state.bird.images.length - 1) % state.bird.images.length
              }))
            }
            onMoveNextRequest={() =>
              this.setState((state) => ({
                photoIndex: (state.photoIndex + 1) % state.bird.images.length
              }))
            }
            toolbarButtons={photoIndex === 0 ? [toolbarLightbox[0]] : toolbarLightbox}
          />
        )}
      </React.Fragment>
    );
  };

  renderBirds = (list) => {
    const { intl } = this.props;

    if (list.length === 0) {
      return (
        <Typography variant={'body1'} color={'textSecondary'}>
          {intl.formatMessage({ id: 'noBirds' })}
        </Typography>
      );
    }

    return (
      <Grid container spacing={2}>
        {list.map((item) => (
          <Grid item key={item.bird_id} xs={12} lg={6}>
            <BirdItem
              imagePreviewPath={item.default_image ? item.default_image : null}
              ring={item.ring}
              year={item.year}
              gender={getGenderName(item.gender, this.props.intl)}
              mutationName={getMutationName(item, this.props.intl)}
              countryName={getCountryName(
                item.country.id,
                this.props.user.user ? this.props.user.user.locale : null
              )}
              onClick={() => {
                this.props.history.push(`${ROUTES.BREEDER_MY_BIRDS}/${item.bird_id}`);
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  renderDocuments = () => {
    const { documents } = this.state.bird;

    const { intl } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button
            type={'button'}
            label={intl.formatMessage({ id: 'uploadFiles' })}
            onClick={this.openUploadDocuments}
            icon={<Paperclip size={20} />}
            color={'primary'}
            variant={'contained'}
            disabled={this.state.isLoading || this.state.isFetching || this.isDeath()}
          />
        </Grid>
        <Grid item xs={12}>
          {documents.length === 0 ? (
            <Typography variant={'body1'} color={'textSecondary'}>
              {intl.formatMessage({ id: 'noDocuments' })}
            </Typography>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper padding={false}>
                  <List disablePadding={true}>
                    {documents.map((item, index) => (
                      <React.Fragment key={item.document_id}>
                        <DocumentItem
                          title={item.document_id}
                          translateTitle={false}
                          path={item.path}
                        />
                        {index + 1 !== documents.length && <Divider />}
                      </React.Fragment>
                    ))}
                  </List>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  render() {
    const { intl, classes } = this.props;

    const buttons = [
      {
        label: intl.formatMessage({ id: 'editBird' }),
        disabled: this.state.isLoading || this.state.isFetching,
        color: 'primary',
        variant: 'contained',
        onClick: this.openEditForm
      },
      {
        label: intl.formatMessage({ id: 'removeBird' }),
        disabled: this.state.isLoading || this.state.isFetching,
        color: 'red',
        variant: 'contained',
        onClick: () => {
          this.setState({ isRemoveBirdOpened: true });
        }
      }
    ];

    if (!this.isDeath()) {
      buttons.push({
        label: intl.formatMessage({ id: 'declareDeath' }),
        disabled: this.state.isLoading || this.state.isFetching || this.isDeath(),
        color: 'default',
        variant: 'contained',
        onClick: this.openDeclareDeath
      });
    }

    return (
      <Content
        isLoading={this.state.isLoading}
        isFetching={this.state.isFetching}
        breadcrumbs={this.getBreadcrumbs()}
        buttons={buttons}
      >
        <ConfirmationDialog
          isOpened={this.state.isSetImageAsDefaultOpened}
          title={intl.formatMessage({ id: 'setImageAsDefault' })}
          message={intl.formatMessage({ id: 'setImageAsDefaultQuestion' })}
          maxWidth={'sm'}
          onConfirm={() => {
            this.setImageAsDefault(this.state.bird.images[this.state.photoIndex].image_id);
          }}
          onCancel={() => {
            this.setState({ isSetImageAsDefaultOpened: false });
          }}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          disabled={this.state.isLoading || this.state.isFetching}
        />
        <ConfirmationDialog
          isOpened={this.state.isRemoveImageOpened}
          title={intl.formatMessage({ id: 'removeImage' })}
          message={intl.formatMessage({ id: 'removeImageQuestion' })}
          maxWidth={'sm'}
          onConfirm={() => {
            this.removeImage(this.state.bird.images[this.state.photoIndex].image_id);
          }}
          onCancel={() => {
            this.setState({ isRemoveImageOpened: false });
          }}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          disabled={this.state.isLoading || this.state.isFetching}
        />
        <ConfirmationDialog
          isOpened={this.state.isRemoveBirdOpened}
          title={intl.formatMessage({ id: 'removeBird' })}
          message={intl.formatMessage({ id: 'removeBirdQuestion' })}
          maxWidth={'sm'}
          onConfirm={this.removeBird}
          onCancel={() => {
            this.setState({
              isRemoveBirdOpened: false
            });
          }}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          disabled={this.state.isLoading || this.state.isFetching}
        />
        <FormDialog
          isOpened={this.state.isUploadImagesOpened}
          title={intl.formatMessage({ id: 'uploadImages' })}
          maxWidth={'sm'}
          onConfirm={this.addImages}
          onCancel={this.cancelUploadImages}
          onConfirmDisabled={this.state.isLoading || this.state.isFetching}
          onCancelDisabled={this.state.isLoading || this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <InputFile
            inputTitle={'uploadImages'}
            mimeType={'image/jpeg,image/png'}
            allowMultipleFiles={true}
            handleAddFiles={this.handleAddImages}
            handleRemoveFileWithError={this.handleRemoveImageWithError}
            pendingFiles={this.state.pendingImages}
          />
        </FormDialog>
        <FormDialog
          isOpened={this.state.isUploadDocumentsOpened}
          title={intl.formatMessage({ id: 'uploadFiles' })}
          maxWidth={'sm'}
          onConfirm={this.addDocuments}
          onCancel={this.cancelUploadDocuments}
          onConfirmDisabled={this.state.isLoading || this.state.isFetching}
          onCancelDisabled={this.state.isLoading || this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <InputFile
            inputTitle={'uploadFiles'}
            mimeType={'text/plain,application/pdf,application/msword'}
            allowMultipleFiles={true}
            handleAddFiles={this.handleAddFiles}
            handleRemoveFileWithError={this.handleRemoveFileWithError}
            pendingFiles={this.state.pendingFiles}
          />
        </FormDialog>
        <FormDialog
          isOpened={this.state.isDeclareDeathOpened}
          title={intl.formatMessage({ id: 'declareDeath' })}
          maxWidth={'sm'}
          onConfirm={this.declareDeath}
          onCancel={this.cancelDeclareDeath}
          onConfirmDisabled={this.state.isLoading || this.state.isFetching}
          onCancelDisabled={this.state.isLoading || this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <MuiPickersUtilsProvider>
            <InputDate
              required={true}
              name={'deathAt'}
              label={intl.formatMessage({ id: 'deathAt' })}
              value={this.state.deathAt}
              onChange={(date) => this.handleDateChange('deathAt', date)}
              okLabel={'Ok'}
              cancelLabel={intl.formatMessage({ id: 'cancel' })}
              error={!!this.state.errors.deathAt}
              helperText={this.state.errors.deathAt || ''}
              disabled={this.state.isLoading || this.state.isFetching}
            />
          </MuiPickersUtilsProvider>
        </FormDialog>
        <FormDialog
          isOpened={this.state.isEditBirdOpened}
          title={intl.formatMessage({ id: 'editBird' })}
          maxWidth={'sm'}
          onConfirm={this.editBird}
          onCancel={this.cancelEditBird}
          onConfirmDisabled={this.state.isLoading || this.state.isFetching}
          onCancelDisabled={this.state.isLoading || this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <EditBirdForm
            isLoading={this.state.isLoading || this.state.isFetching}
            handleInputChange={this.handleInputChange}
            handleSelectChange={this.handleSelectChange}
            bird={this.state.bird || {}}
            errors={this.state.errors}
            ring={this.state.ring}
            gender={this.state.gender}
            comments={this.state.comments}
          />
        </FormDialog>
        {!this.state.isLoading && this.state.bird && (
          <React.Fragment>
            {this.renderImageViewer()}
            <Grid container spacing={2} justifyContent={'center'}>
              <Grid item xs={12}>
                {this.renderBirdImage()}
              </Grid>
              <Grid item xs={12} sm={6}>
                <BirdMainInformation
                  ring={this.state.bird.ring}
                  birthday={this.state.bird.birthday}
                  countryId={this.state.bird.country.id}
                  gender={this.state.bird.gender}
                  specie={this.state.bird.specie}
                  mutation={this.state.bird.specie.mutation}
                  isWhiteHead={this.state.bird.is_white_head}
                  isYellow={this.state.bird.is_yellow}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <BirdSecondaryInformation
                  state={this.state.bird.state}
                  deathAt={this.state.bird.death_at}
                  firstOwner={this.state.bird.first_owner}
                  splitOptions={this.state.bird.split_options}
                  possibleSplitOptions={this.state.bird.possible_split_options}
                  comments={this.state.bird.comments}
                  registeredAt={this.state.bird.created_at}
                />
              </Grid>
              <Grid item xs={12}>
                <Paper padding={false}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Tabs
                        value={this.state.tabValue}
                        variant={'fullWidth'}
                        onChange={this.handleOnChangeTab}
                      >
                        <Tab value={0} label={intl.formatMessage({ id: 'parents' })} />
                        <Tab value={1} label={intl.formatMessage({ id: 'children' })} />
                        <Tab value={2} label={intl.formatMessage({ id: 'documents' })} />
                      </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.tabsInnerContainer}>
                        {this.state.tabValue === 0 && (
                          <React.Fragment>
                            {this.renderBirds(this.state.bird.parents)}
                          </React.Fragment>
                        )}
                        {this.state.tabValue === 1 && (
                          <React.Fragment>
                            {this.renderBirds(this.state.bird.children)}
                          </React.Fragment>
                        )}
                        {this.state.tabValue === 2 && (
                          <React.Fragment>{this.renderDocuments()}</React.Fragment>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Content>
    );
  }
}

BirdProfileNew.propTypes = {
  history: PropTypes.object.isRequired,
  breadcrumbType: PropTypes.oneOf(['myBirds', 'parent', 'coupleChild', 'clutchChild']).isRequired
};

const mapStateToProps = (state) => ({
  app: state.appReducer,
  user: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title, translateTitle) => {
    dispatch(setAppBarTitle(title, translateTitle));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(injectIntl(BirdProfileNew))));
