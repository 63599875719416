import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { Grid } from '@material-ui/core';

// SPINE UI
import {
  Content,
  FormDialog,
  ConfirmationDialog,
  PaperListInformation
} from '@oliveirahugo68/spine-ui';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// YUP
import * as Yup from 'yup';

// APP IMPORTS
import { withSnackbar } from 'notistack';
import { setAppBarTitle } from '../../../../store/actions/appActions';
import { fetchApi } from '../../../../utils/apiSettings';
import { fromUTCtoLocal } from '../../../../utils/timeFormat';
import ROUTES from '../../../../utils/routes';
import AddMutation from '../Specie/components/AddMutation';

class Mutation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      specieId: null,
      mutationId: null,

      mutation: null,

      // edit mutation form
      isEditMutationOpened: false,
      name: '',

      // upload images
      images: [],
      pendingImages: [],

      errors: {},

      isRemoveMutationOpened: false
    };

    this.nameInputRef = React.createRef();
  }

  componentDidMount() {
    this.props.setAppBarTitle('mutations');

    this.setState(
      {
        specieId: this.props.match.params.id,
        mutationId: this.props.match.params.mutationId
      },
      () => {
        this.fetchMutation();
      }
    );
  }

  fetchMutation = () => {
    const { enqueueSnackbar, intl } = this.props;

    fetchApi(
      'get',
      `/admin/v1/species/${this.state.specieId}/mutations/${this.state.mutationId}`,
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.setState(
          {
            isLoading: false,
            mutation: data
          },
          () => {
            this.props.setAppBarTitle(data.name, false);
          }
        );
      },
      (error) => {
        const { response } = error;

        if (response.status === 404) {
          enqueueSnackbar(intl.formatMessage({ id: 'retrieveMutationErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        } else {
          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        }

        this.props.history.push(`${ROUTES.ADMIN_SPECIES}/${this.state.specieId}`);
      }
    );
  };

  handleInputChange = (event) => {
    const input = event.target;

    this.setState({
      [input.name]: input.value,
      errors: {}
    });
  };

  handleAddImage = (newPendingResources) => {
    const { intl, enqueueSnackbar } = this.props;

    if (newPendingResources === 0) {
      enqueueSnackbar(intl.formatMessage({ id: 'addImageErrorMessage' }), {
        variant: 'error'
      });
    } else {
      this.setState(
        (state) => ({
          pendingImages: [...state.pendingImages, ...newPendingResources]
        }),
        () => {
          // upload each resource automatically
          newPendingResources.forEach((image) => {
            if (!image.error) {
              this.uploadImage(image);
            }
          });
        }
      );
    }
  };

  uploadImage = (image) => {
    const formData = new FormData();
    formData.append('image', image.file);

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'post',
          '/v1/images',
          {
            'Content-Type': 'multipart/form-data'
          },
          formData,
          false,
          (response) => {
            const { data } = response;

            this.setState((state) => ({
              // add file to the list of existing files (already uploaded)
              images: [
                ...state.images,
                {
                  // keep names with underscore so we can handle files that
                  // already exist (that came from another API request)
                  // and this new file
                  id: data.id,
                  path: data.path,
                  preview_path: data.preview_path
                }
              ],
              // set pending files to 100 (or remove them if needed)
              pendingImages: [
                ...state.pendingImages.map((item) => {
                  if (item.fileURL === image.fileURL)
                    return {
                      ...item,
                      error: false,
                      errorMessage: '',
                      percentageCompleted: 100
                    };
                  return item;
                })
              ],
              isFetching: false
            }));
          },
          () => {
            this.setState((state) => ({
              pendingImages: [
                ...state.pendingImages.map((item) => {
                  if (item.fileURL === image.fileURL)
                    return {
                      ...item,
                      error: true,
                      errorMessage: 'errorWhileUploading'
                    };
                  return item;
                })
              ],
              isFetching: false
            }));
          },
          (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentageCompleted = Math.round((loaded * 100) / total);

            if (percentageCompleted < 100) {
              this.setState((state) => ({
                pendingImages: [
                  ...state.pendingImages.map((item) => {
                    if (item.fileURL === image.fileURL)
                      return {
                        file: image.file,
                        fileName: image.fileName,
                        fileURL: image.fileURL,
                        percentageCompleted: Math.round((loaded * 100) / total),
                        error: false,
                        errorMessage: ''
                      };
                    return item;
                  })
                ]
              }));
            }
          }
        );
      }
    );
  };

  /**
   * Remove image that had a problem during upload.
   * @param imageURL
   */
  handleRemoveImageWithError = (imageURL) => {
    this.setState((state) => ({
      pendingImages: state.pendingImages.filter((item) => item.fileURL !== imageURL)
    }));
  };

  openEditMutation = () => {
    this.setState(
      (state) => ({
        isEditMutationOpened: true,
        name: state.mutation.name,
        images: [],
        pendingImages: [],
        errors: {}
      }),
      () => {
        this.nameInputRef.current.focus();
      }
    );
  };

  cancelEditMutation = () => {
    this.setState({
      isEditMutationOpened: false,
      name: '',
      images: [],
      pendingImages: [],
      errors: {}
    });
  };

  handleEditMutation = async () => {
    const { enqueueSnackbar, intl } = this.props;

    const schema = Yup.object().shape({
      name: Yup.string().required(),
      imageIds: Yup.array().notRequired()
    });

    const dataToSubmit = {
      name: this.state.name,
      imageIds: this.state.images
    };

    try {
      await schema.validate(dataToSubmit, { abortEarly: false });

      this.setState(
        {
          isFetching: true
        },
        () => {
          fetchApi(
            'put',
            `/admin/v1/species/${this.state.specieId}/mutations/${this.state.mutation.id}`,
            {},
            {
              name: dataToSubmit.name,
              image_id: dataToSubmit.imageIds.length > 0 ? dataToSubmit.imageIds[0].id : null
            },
            false,
            (response) => {
              const { data } = response;

              // TODO: add proper message
              enqueueSnackbar('TODO', {
                variant: 'success',
                autoHideDuration: 4000
              });

              this.setState({
                isFetching: false,
                isEditMutationOpened: false,
                mutation: data
              });
            },
            (error) => {
              const { response } = error;

              if (response.status === 409) {
                this.setState({
                  isFetching: false,
                  errors: {
                    name: intl.formatMessage({ id: 'addMutationErrorMessage' })
                  }
                });
              } else {
                this.setState({ isFetching: false });

                enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                  variant: 'error',
                  autoHideDuration: 4000
                });
              }
            }
          );
        }
      );
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        this.setState({
          errors: errorMessages
        });
      }
    }
  };

  confirmRemoveMutation = () => {
    const { enqueueSnackbar, intl } = this.props;

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'delete',
          `/admin/v1/species/${this.state.specieId}/mutations/${this.state.mutation.id}`,
          {},
          {},
          false,
          () => {
            this.props.history.push(`${ROUTES.ADMIN_SPECIES}/${this.state.specieId}`);
          },
          () => {
            this.setState({ isFetching: false });

            enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
              variant: 'error',
              autoHideDuration: 4000
            });
          }
        );
      }
    );
  };

  render() {
    const { intl } = this.props;

    const breadcrumbs = [
      {
        title: intl.formatMessage({ id: 'species' }),
        active: false,
        href: ROUTES.ADMIN_SPECIES,
        onClick: () => {
          this.props.history.push(ROUTES.ADMIN_SPECIES);
        }
      },
      {
        title: !this.state.isLoading ? this.state.specieId : intl.formatMessage({ id: 'specie' }),
        active: false,
        href: !this.state.isLoading ? `${ROUTES.ADMIN_SPECIES}/${this.state.specieId}` : '...',
        onClick: () => {
          this.props.history.push(`${ROUTES.ADMIN_SPECIES}/${this.state.specieId}`);
        }
      },
      {
        title: !this.state.isLoading
          ? this.state.mutation.name
          : intl.formatMessage({ id: 'mutation' }),
        active: true
      }
    ];

    const buttons = [
      {
        label: intl.formatMessage({ id: 'editMutation' }),
        disabled: this.state.isLoading,
        color: 'primary',
        variant: 'contained',
        onClick: this.openEditMutation
      },
      {
        label: intl.formatMessage({ id: 'removeMutation' }),
        disabled: this.state.isLoading,
        color: 'red',
        variant: 'outlined',
        onClick: () => {
          this.setState({
            isRemoveMutationOpened: true
          });
        }
      }
    ];

    return (
      <Content
        isLoading={this.state.isLoading}
        isFetching={this.state.isFetching}
        breadcrumbs={breadcrumbs}
        buttons={buttons}
      >
        <FormDialog
          isOpened={this.state.isEditMutationOpened}
          title={intl.formatMessage({ id: 'editMutation' })}
          maxWidth={'sm'}
          onConfirm={this.handleEditMutation}
          onCancel={this.cancelEditMutation}
          onConfirmDisabled={this.state.isLoading}
          onCancelDisabled={this.state.isLoading}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <AddMutation
            isLoading={this.state.isLoading || this.state.isFetching}
            nameInputRef={this.nameInputRef}
            name={this.state.name}
            pendingFiles={this.state.pendingImages}
            handleAddFiles={this.handleAddImage}
            handleRemoveFileWithError={this.handleRemoveImageWithError}
            errors={this.state.errors}
            handleInputChange={this.handleInputChange}
          />
        </FormDialog>
        <ConfirmationDialog
          isOpened={this.state.isRemoveMutationOpened}
          title={intl.formatMessage({ id: 'removeMutation' })}
          maxWidth={'sm'}
          message={intl.formatMessage({ id: 'removeMutationQuestion' })}
          onConfirm={this.confirmRemoveMutation}
          onCancel={() => {
            this.setState({
              isRemoveMutationOpened: false
            });
          }}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          disabled={this.state.isLoading}
        />
        {!this.state.isLoading && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PaperListInformation
                items={[
                  {
                    title: 'id',
                    value: this.state.mutation.id
                  },
                  {
                    title: intl.formatMessage({ id: 'name' }),
                    value: this.state.mutation.name
                  },
                  {
                    title: intl.formatMessage({ id: 'registeredAt' }),
                    value: fromUTCtoLocal(this.state.mutation.created_at).format('LL')
                  }
                ]}
              />
            </Grid>
          </Grid>
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title, translateTitle = true) => {
    dispatch(setAppBarTitle(title, translateTitle));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(injectIntl(Mutation)));
