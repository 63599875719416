import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { Divider, Grid, List } from '@material-ui/core';
import { withSnackbar } from 'notistack';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// REACT FEATHER
import { Paperclip } from 'react-feather';

// SPINE UI
import {
  Content,
  Paper,
  Table,
  ConfirmationDialog,
  PaperListInformation,
  Button
} from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { fetchApi } from '../../../../utils/apiSettings';
import { setAppBarTitle } from '../../../../store/actions/appActions';
import { getCountryName, getGenderName, getMutationName } from '../../../../utils/appSettings';
import { fromUTCtoLocal } from '../../../../utils/timeFormat';
import ROUTES from '../../../../utils/routes';
import BirdItem from '../../../../components/core/BirdItem';
import DocumentItem from '../../../../components/core/DocumentItem';

class Transfer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      // data
      transfer: null,

      // form
      isRemoveTransferOpened: false
    };
  }

  componentDidMount() {
    this.props.setAppBarTitle('transfer');

    this.fetchTransfer();
  }

  fetchTransfer = () => {
    fetchApi(
      'get',
      `/breeders/v1/transfers/${this.props.match.params.transferId}`,
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.setState({
          transfer: data,
          isLoading: false
        });
      },
      () => {
        this.props.history.push(ROUTES.BREEDER_TRANSFER_REQUESTS);
      }
    );
  };

  handleDeleteTransfer = () => {
    const { enqueueSnackbar, intl } = this.props;

    this.setState({ isFetching: true }, () => {
      fetchApi(
        'delete',
        `/breeders/v1/transfers/${this.state.transfer.transfer_id}`,
        {},
        {},
        false,
        () => {
          enqueueSnackbar(intl.formatMessage({ id: 'removeTransferSuccess' }), {
            variant: 'success',
            autoHideDuration: 4000
          });

          this.props.history.push(ROUTES.BREEDER_TRANSFER_REQUESTS);
        },
        (error) => {
          const { response } = error;

          // It has been approved or refused
          if (response.status === 400) {
            enqueueSnackbar(intl.formatMessage({ id: 'removeTransferError' }), {
              variant: 'error',
              autoHideDuration: 4000
            });
          } else {
            enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
              variant: 'error',
              autoHideDuration: 4000
            });
          }

          this.setState({ isFetching: false });
        }
      );
    });
  };

  openRemoveTransfer = () => {
    this.setState({
      isRemoveTransferOpened: true
    });
  };

  closeRemoveTransfer = () => {
    this.setState({
      isRemoveTransferOpened: false
    });
  };

  getAppButtons = () => {
    const { intl } = this.props;

    const holderButtons = [
      ...(this.state.transfer && this.state.transfer.state !== 'ACCEPTED'
        ? [
            {
              label: intl.formatMessage({ id: 'removeTransfer' }),
              disabled: this.state.isLoading || this.state.isFetching,
              color: 'red',
              variant: 'contained',
              onClick: () => this.openRemoveTransfer()
            }
          ]
        : [])
    ];

    const newOwnerButtons = [
      ...(this.state.transfer && this.state.transfer.state === 'SENT'
        ? [
            {
              label: intl.formatMessage({ id: 'acceptTransfer' }),
              disabled: this.state.isLoading || this.state.isFetching,
              color: 'primary',
              variant: 'contained',
              onClick: () => {}
            },
            {
              label: intl.formatMessage({ id: 'refuseTransfer' }),
              disabled: this.state.isLoading || this.state.isFetching,
              color: 'red',
              variant: 'contained',
              onClick: () => {}
            }
          ]
        : [])
    ];

    if (this.props.user && this.props.user.user && this.state.transfer) {
      return this.props.user.user.breeder_id === this.state.transfer.requester_id
        ? holderButtons
        : newOwnerButtons;
    }
    return [];
  };

  renderDocuments = () => {
    const { intl } = this.props;
    let firstDocument = null;
    let otherDocuments = [];

    if (this.state.transfer.documents.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      firstDocument = this.state.transfer.documents[0];
      otherDocuments = this.state.transfer.documents.slice(1);
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} />
        <Grid item xs={12}>
          <Paper padding={false}>
            <List disablePadding={true}>
              {firstDocument && (
                <DocumentItem
                  title={intl.formatMessage({ id: 'downloadTransferDocument' })}
                  path={firstDocument.path}
                />
              )}
              {otherDocuments.map((item) => (
                <React.Fragment>
                  <Divider />
                  <DocumentItem title={intl.formatMessage({ id: 'document' })} path={item.path} />
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button
            type={'button'}
            label={intl.formatMessage({ id: 'uploadFiles' })}
            disabled={true}
            color={'primary'}
            variant={'contained'}
            icon={<Paperclip size={20} />}
            onClick={() => {}}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { intl } = this.props;

    const transferState = this.state.transfer ? this.state.transfer.state : '';
    const transferStateValues = {
      SENT: intl.formatMessage({ id: 'pending' }),
      ACCEPTED: intl.formatMessage({ id: 'accepted' }),
      REFUSED: intl.formatMessage({ id: 'refused' }),
      '': ''
    };

    const holder = this.state.transfer ? this.state.transfer.requester : '';
    const newOwner = this.state.transfer ? this.state.transfer.receptor : '';

    const breadcrumbs = [
      {
        title: intl.formatMessage({ id: 'transfers' }),
        active: false,
        href: ROUTES.BREEDER_TRANSFER_REQUESTS,
        onClick: () => {
          this.props.history.push(ROUTES.BREEDER_TRANSFER_REQUESTS);
        }
      },
      {
        title:
          this.state.isLoading && this.state.transfer === null
            ? intl.formatMessage({ id: 'transfers' })
            : this.state.transfer.number,
        translateTitle: this.state.isLoading && this.state.transfer === null,
        active: true
      }
    ];

    return (
      <Content
        isLoading={this.state.isLoading}
        isFetching={this.state.isFetching}
        breadcrumbs={breadcrumbs}
        buttons={this.getAppButtons()}
      >
        <ConfirmationDialog
          isOpened={this.state.isRemoveTransferOpened}
          title={intl.formatMessage({ id: 'removeTransfer' })}
          maxWidth={'sm'}
          message={intl.formatMessage({ id: 'removeTransferMessage' })}
          onConfirm={this.handleDeleteTransfer}
          onCancel={this.closeRemoveTransfer}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          disabled={this.state.isLoading || this.state.isFetching}
        />
        {this.state.transfer && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={5} xl={4}>
                  <PaperListInformation
                    items={[
                      {
                        title: intl.formatMessage({ id: 'transferNumber' }),
                        value: this.state.transfer.number
                      },
                      {
                        title: intl.formatMessage({ id: 'state' }),
                        value: transferStateValues[transferState]
                      },
                      {
                        title: intl.formatMessage({ id: 'registerDate' }),
                        value: fromUTCtoLocal(this.state.transfer.created_at).format('LLL')
                      },
                      {
                        title: intl.formatMessage({ id: 'transferNumberOfBirds' }),
                        value: this.state.transfer.count_birds
                      }
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={5} xl={4}>
                  <Paper title={intl.formatMessage({ id: 'sharedDocuments' })}>
                    {this.renderDocuments()}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper title={intl.formatMessage({ id: 'holder' })}>
                <PaperListInformation
                  items={[
                    {
                      title: intl.formatMessage({ id: 'name' }),
                      value: holder.name
                    },
                    {
                      title: intl.formatMessage({ id: 'country' }),
                      value: holder.country
                        ? getCountryName(
                            holder.country.id,
                            this.props.user.user ? this.props.user.user.locale : null
                          )
                        : ''
                    },
                    {
                      title: intl.formatMessage({ id: 'address' }),
                      value: holder.address || ''
                    },
                    {
                      title: intl.formatMessage({ id: 'zipCode' }),
                      value: holder.zip_code || ''
                    },
                    {
                      title: intl.formatMessage({ id: 'contact' }),
                      value: holder.contact || ''
                    },
                    {
                      title: intl.formatMessage({ id: 'email' }),
                      value: holder.email
                    }
                  ]}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper title={intl.formatMessage({ id: 'newOwner' })}>
                {newOwner && (
                  <PaperListInformation
                    items={[
                      {
                        title: intl.formatMessage({ id: 'name' }),
                        value: newOwner.name
                      },
                      {
                        title: intl.formatMessage({ id: 'country' }),
                        value: newOwner.country
                          ? getCountryName(
                              newOwner.country.id,
                              this.props.user.user ? this.props.user.user.locale : null
                            )
                          : ''
                      },
                      {
                        title: intl.formatMessage({ id: 'address' }),
                        value: newOwner.address || ''
                      },
                      {
                        title: intl.formatMessage({ id: 'zipCode' }),
                        value: newOwner.zip_code || ''
                      },
                      {
                        title: intl.formatMessage({ id: 'contact' }),
                        value: newOwner.contact || ''
                      },
                      {
                        title: intl.formatMessage({ id: 'email' }),
                        value: newOwner.email
                      }
                    ]}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Table
                title={intl.formatMessage({ id: 'birds' })}
                webHeaders={[
                  { title: intl.formatMessage({ id: 'ring' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'year' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'gender' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'specie' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'country' }), align: 'left' }
                ]}
                webData={this.state.transfer.birds.map((item) => [
                  item.ring,
                  item.year,
                  getGenderName(item.gender, this.props.intl),
                  getMutationName(item, this.props.intl),
                  getCountryName(
                    item.country.id,
                    this.props.user.user ? this.props.user.user.locale : null
                  )
                ])}
                renderMobile={true}
                mobileData={this.state.transfer.birds.map((item) => (
                  <BirdItem
                    imagePreviewPath={null}
                    ring={item.ring}
                    year={item.year}
                    gender={getGenderName(item.gender, this.props.intl)}
                    mutationName={getMutationName(item, this.props.intl)}
                    countryName={getCountryName(
                      item.country.id,
                      this.props.user.user ? this.props.user.user.locale : null
                    )}
                  />
                ))}
                noDataMessage={intl.formatMessage({ id: 'noBirds' })}
              />
            </Grid>
          </Grid>
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer,
  user: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(injectIntl(Transfer)));
