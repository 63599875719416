import React from 'react';
import PropTypes from 'prop-types';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// MATERIAL UI
import { Grid } from '@material-ui/core';

// SPINE UI
import { Input } from '@oliveirahugo68/spine-ui';

class EditClutch extends React.PureComponent {
  render() {
    const { intl } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Input
            inputRef={this.props.fertilizedEggsInputRef}
            name={'fertilizedCount'}
            type={'number'}
            autoComplete={'off'}
            label={intl.formatMessage({ id: 'fertilizedEggs' })}
            value={this.props.fertilizedCount}
            onChange={this.props.handleInputChange}
            error={!!this.props.errors.fertilizedCount}
            helperText={this.props.errors.fertilizedCount || ''}
            disabled={this.props.isLoading}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            name={'abortedCount'}
            type={'number'}
            autoComplete={'off'}
            label={intl.formatMessage({ id: 'abortedEggs' })}
            value={this.props.abortedCount}
            onChange={this.props.handleInputChange}
            error={!!this.props.errors.abortedCount}
            helperText={this.props.errors.abortedCount || ''}
            disabled={this.props.isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name={'description'}
            type={'text'}
            multiline={true}
            autoComplete={'off'}
            label={intl.formatMessage({ id: 'description' })}
            value={this.props.description}
            onChange={this.props.handleInputChange}
            error={!!this.props.errors.description}
            helperText={this.props.errors.description || ''}
            disabled={this.props.isLoading}
          />
        </Grid>
      </Grid>
    );
  }
}

EditClutch.defaultProps = {};

EditClutch.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fertilizedEggsInputRef: PropTypes.object.isRequired,
  fertilizedCount: PropTypes.string.isRequired,
  abortedCount: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired
};

export default injectIntl(EditClutch);
