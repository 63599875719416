export default [
  // TOTAL of 241 countries
  {
    id: 1,
    name: 'Afeganistão',
    code: 'AF'
  },
  {
    id: 3,
    name: 'Albânia',
    code: 'AL'
  },
  {
    id: 81,
    name: 'Alemanha',
    code: 'DE'
  },
  {
    id: 6,
    name: 'Andorra',
    code: 'AD'
  },
  {
    id: 7,
    name: 'Angola',
    code: 'AO'
  },
  {
    id: 8,
    name: 'Anguilla',
    code: 'AI'
  },
  {
    id: 9,
    name: 'Antártida',
    code: 'AQ'
  },
  {
    id: 10,
    name: 'Antígua e Barbuda',
    code: 'AG'
  },
  {
    id: 11,
    name: 'Argentina',
    code: 'AR'
  },
  {
    id: 4,
    name: 'Argélia',
    code: 'DZ'
  },
  {
    id: 12,
    name: 'Armênia',
    code: 'AM'
  },
  {
    id: 13,
    name: 'Aruba',
    code: 'AW'
  },
  {
    id: 191,
    name: 'Arábia Saudita',
    code: 'SA'
  },
  {
    id: 14,
    name: 'Austrália',
    code: 'AU'
  },
  {
    id: 16,
    name: 'Azerbaijão',
    code: 'AZ'
  },
  {
    id: 17,
    name: 'Bahamas',
    code: 'BS'
  },
  {
    id: 18,
    name: 'Bahrein',
    code: 'BH'
  },
  {
    id: 19,
    name: 'Bangladesh',
    code: 'BD'
  },
  {
    id: 20,
    name: 'Barbados',
    code: 'BB'
  },
  {
    id: 23,
    name: 'Belize',
    code: 'BZ'
  },
  {
    id: 24,
    name: 'Benim',
    code: 'BJ'
  },
  {
    id: 25,
    name: 'Bermudas',
    code: 'BM'
  },
  {
    id: 21,
    name: 'Bielorrússia',
    code: 'BY'
  },
  {
    id: 27,
    name: 'Bolívia',
    code: 'BO'
  },
  {
    id: 29,
    name: 'Botswana',
    code: 'BW'
  },
  {
    id: 31,
    name: 'Brasil',
    code: 'BR'
  },
  {
    id: 33,
    name: 'Brunei',
    code: 'BN'
  },
  {
    id: 34,
    name: 'Bulgária',
    code: 'BG'
  },
  {
    id: 35,
    name: 'Burkina Faso',
    code: 'BF'
  },
  {
    id: 36,
    name: 'Burundi',
    code: 'BI'
  },
  {
    id: 26,
    name: 'Butão',
    code: 'BT'
  },
  {
    id: 22,
    name: 'Bélgica',
    code: 'BE'
  },
  {
    id: 28,
    name: 'Bósnia e Herzegovina',
    code: 'BA'
  },
  {
    id: 40,
    name: 'Cabo Verde',
    code: 'CV'
  },
  {
    id: 38,
    name: 'Camarões',
    code: 'CM'
  },
  {
    id: 37,
    name: 'Camboja',
    code: 'KH'
  },
  {
    id: 39,
    name: 'Canadá',
    code: 'CA'
  },
  {
    id: 178,
    name: 'Catar',
    code: 'QA'
  },
  {
    id: 113,
    name: 'Cazaquistão',
    code: 'KZ'
  },
  {
    id: 43,
    name: 'Chade',
    code: 'TD'
  },
  {
    id: 44,
    name: 'Chile',
    code: 'CL'
  },
  {
    id: 45,
    name: 'China',
    code: 'CN'
  },
  {
    id: 57,
    name: 'Chipre',
    code: 'CY'
  },
  {
    id: 58,
    name: 'Chéquia',
    code: 'CZ'
  },
  {
    id: 48,
    name: 'Colômbia',
    code: 'CO'
  },
  {
    id: 49,
    name: 'Comores',
    code: 'KM'
  },
  {
    id: 116,
    name: 'Coreia do Norte',
    code: 'KP'
  },
  {
    id: 117,
    name: 'Coreia do Sul',
    code: 'KR'
  },
  {
    id: 53,
    name: 'Costa Rica',
    code: 'CR'
  },
  {
    id: 54,
    name: 'Costa do Marfim',
    code: 'CI'
  },
  {
    id: 55,
    name: 'Croácia',
    code: 'HR'
  },
  {
    id: 56,
    name: 'Cuba',
    code: 'CU'
  },
  {
    id: 59,
    name: 'Dinamarca',
    code: 'DK'
  },
  {
    id: 60,
    name: 'Djibouti',
    code: 'DJ'
  },
  {
    id: 61,
    name: 'Dominica',
    code: 'DM'
  },
  {
    id: 64,
    name: 'Egito',
    code: 'EG'
  },
  {
    id: 65,
    name: 'El Salvador',
    code: 'SV'
  },
  {
    id: 228,
    name: 'Emirados Árabes Unidos',
    code: 'AE'
  },
  {
    id: 63,
    name: 'Equador',
    code: 'EC'
  },
  {
    id: 67,
    name: 'Eritreia',
    code: 'ER'
  },
  {
    id: 197,
    name: 'Eslováquia',
    code: 'SK'
  },
  {
    id: 198,
    name: 'Eslovênia',
    code: 'SI'
  },
  {
    id: 203,
    name: 'Espanha',
    code: 'ES'
  },
  {
    id: 208,
    name: 'Essuatíni',
    code: 'SZ'
  },
  {
    id: 143,
    name: 'Estados Federados da Micronésia',
    code: 'FM'
  },
  {
    id: 230,
    name: 'Estados Unidos',
    code: 'US'
  },
  {
    id: 68,
    name: 'Estónia',
    code: 'EE'
  },
  {
    id: 69,
    name: 'Etiópia',
    code: 'ET'
  },
  {
    id: 72,
    name: 'Fiji',
    code: 'FJ'
  },
  {
    id: 173,
    name: 'Filipinas',
    code: 'PH'
  },
  {
    id: 73,
    name: 'Finlândia',
    code: 'FI'
  },
  {
    id: 74,
    name: 'França',
    code: 'FR'
  },
  {
    id: 78,
    name: 'Gabão',
    code: 'GA'
  },
  {
    id: 82,
    name: 'Gana',
    code: 'GH'
  },
  {
    id: 80,
    name: 'Geórgia',
    code: 'GE'
  },
  {
    id: 83,
    name: 'Gibraltar',
    code: 'GI'
  },
  {
    id: 86,
    name: 'Granada',
    code: 'GD'
  },
  {
    id: 85,
    name: 'Gronelândia',
    code: 'GL'
  },
  {
    id: 84,
    name: 'Grécia',
    code: 'GR'
  },
  {
    id: 87,
    name: 'Guadalupe',
    code: 'GP'
  },
  {
    id: 88,
    name: 'Guam',
    code: 'GU'
  },
  {
    id: 89,
    name: 'Guatemala',
    code: 'GT'
  },
  {
    id: 90,
    name: 'Guernsey',
    code: 'GG'
  },
  {
    id: 93,
    name: 'Guiana',
    code: 'GY'
  },
  {
    id: 75,
    name: 'Guiana Francesa',
    code: 'GF'
  },
  {
    id: 91,
    name: 'Guiné',
    code: 'GN'
  },
  {
    id: 66,
    name: 'Guiné Equatorial',
    code: 'GQ'
  },
  {
    id: 92,
    name: 'Guiné-Bissau',
    code: 'GW'
  },
  {
    id: 79,
    name: 'Gâmbia',
    code: 'GM'
  },
  {
    id: 94,
    name: 'Haiti',
    code: 'HT'
  },
  {
    id: 97,
    name: 'Honduras',
    code: 'HN'
  },
  {
    id: 98,
    name: 'Hong Kong',
    code: 'HK'
  },
  {
    id: 99,
    name: 'Hungria',
    code: 'HU'
  },
  {
    id: 30,
    name: 'Ilha Bouvet',
    code: 'BV'
  },
  {
    id: 95,
    name: 'Ilha Heard e Ilhas McDonald',
    code: 'HM'
  },
  {
    id: 162,
    name: 'Ilha Norfolk',
    code: 'NF'
  },
  {
    id: 106,
    name: 'Ilha de Man',
    code: 'IM'
  },
  {
    id: 46,
    name: 'Ilha do Natal',
    code: 'CX'
  },
  {
    id: 41,
    name: 'Ilhas Cayman',
    code: 'KY'
  },
  {
    id: 47,
    name: 'Ilhas Cocos (Keeling)',
    code: 'CC'
  },
  {
    id: 52,
    name: 'Ilhas Cook',
    code: 'CK'
  },
  {
    id: 71,
    name: 'Ilhas Feroé',
    code: 'FO'
  },
  {
    id: 202,
    name: 'Ilhas Geórgia do Sul e Sandwich do Sul',
    code: 'GS'
  },
  {
    id: 70,
    name: 'Ilhas Malvinas',
    code: 'FK'
  },
  {
    id: 137,
    name: 'Ilhas Marshall',
    code: 'MH'
  },
  {
    id: 231,
    name: 'Ilhas Menores Distantes dos Estados Unidos',
    code: 'UM'
  },
  {
    id: 199,
    name: 'Ilhas Salomão',
    code: 'SB'
  },
  {
    id: 238,
    name: 'Ilhas Virgens Americanas',
    code: 'VI'
  },
  {
    id: 237,
    name: 'Ilhas Virgens Britânicas',
    code: 'VG'
  },
  {
    id: 2,
    name: 'Ilhas Åland',
    code: 'AX'
  },
  {
    id: 102,
    name: 'Indonésia',
    code: 'ID'
  },
  {
    id: 104,
    name: 'Iraque',
    code: 'IQ'
  },
  {
    id: 105,
    name: 'Irlanda',
    code: 'IE'
  },
  {
    id: 103,
    name: 'Irão',
    code: 'IR'
  },
  {
    id: 100,
    name: 'Islândia',
    code: 'IS'
  },
  {
    id: 107,
    name: 'Israel',
    code: 'IL'
  },
  {
    id: 108,
    name: 'Itália',
    code: 'IT'
  },
  {
    id: 241,
    name: 'Iêmen',
    code: 'YE'
  },
  {
    id: 109,
    name: 'Jamaica',
    code: 'JM'
  },
  {
    id: 110,
    name: 'Japão',
    code: 'JP'
  },
  {
    id: 111,
    name: 'Jersey',
    code: 'JE'
  },
  {
    id: 112,
    name: 'Jordânia',
    code: 'JO'
  },
  {
    id: 115,
    name: 'Kiribati',
    code: 'KI'
  },
  {
    id: 118,
    name: 'Kuwait',
    code: 'KW'
  },
  {
    id: 120,
    name: 'Laos',
    code: 'LA'
  },
  {
    id: 123,
    name: 'Lesoto',
    code: 'LS'
  },
  {
    id: 121,
    name: 'Letônia',
    code: 'LV'
  },
  {
    id: 124,
    name: 'Libéria',
    code: 'LR'
  },
  {
    id: 126,
    name: 'Liechtenstein',
    code: 'LI'
  },
  {
    id: 127,
    name: 'Lituânia',
    code: 'LT'
  },
  {
    id: 128,
    name: 'Luxemburgo',
    code: 'LU'
  },
  {
    id: 122,
    name: 'Líbano',
    code: 'LB'
  },
  {
    id: 125,
    name: 'Líbia',
    code: 'LY'
  },
  {
    id: 129,
    name: 'Macau',
    code: 'MO'
  },
  {
    id: 130,
    name: 'Macedônia do Norte',
    code: 'MK'
  },
  {
    id: 131,
    name: 'Madagáscar',
    code: 'MG'
  },
  {
    id: 132,
    name: 'Malawi',
    code: 'MW'
  },
  {
    id: 134,
    name: 'Maldivas',
    code: 'MV'
  },
  {
    id: 135,
    name: 'Mali',
    code: 'ML'
  },
  {
    id: 136,
    name: 'Malta',
    code: 'MT'
  },
  {
    id: 133,
    name: 'Malásia',
    code: 'MY'
  },
  {
    id: 163,
    name: 'Marianas Setentrionais',
    code: 'MP'
  },
  {
    id: 148,
    name: 'Marrocos',
    code: 'MA'
  },
  {
    id: 138,
    name: 'Martinica',
    code: 'MQ'
  },
  {
    id: 139,
    name: 'Mauritânia',
    code: 'MR'
  },
  {
    id: 140,
    name: 'Maurícia',
    code: 'MU'
  },
  {
    id: 141,
    name: 'Mayotte',
    code: 'YT'
  },
  {
    id: 150,
    name: 'Mianmar',
    code: 'MM'
  },
  {
    id: 144,
    name: 'Moldávia',
    code: 'MD'
  },
  {
    id: 146,
    name: 'Mongólia',
    code: 'MN'
  },
  {
    id: 147,
    name: 'Montserrat',
    code: 'MS'
  },
  {
    id: 149,
    name: 'Moçambique',
    code: 'MZ'
  },
  {
    id: 142,
    name: 'México',
    code: 'MX'
  },
  {
    id: 145,
    name: 'Mónaco',
    code: 'MC'
  },
  {
    id: 151,
    name: 'Namíbia',
    code: 'NA'
  },
  {
    id: 152,
    name: 'Nauru',
    code: 'NR'
  },
  {
    id: 153,
    name: 'Nepal',
    code: 'NP'
  },
  {
    id: 158,
    name: 'Nicarágua',
    code: 'NI'
  },
  {
    id: 160,
    name: 'Nigéria',
    code: 'NG'
  },
  {
    id: 161,
    name: 'Niue',
    code: 'NU'
  },
  {
    id: 164,
    name: 'Noruega',
    code: 'NO'
  },
  {
    id: 156,
    name: 'Nova Caledônia',
    code: 'NC'
  },
  {
    id: 157,
    name: 'Nova Zelândia',
    code: 'NZ'
  },
  {
    id: 159,
    name: 'Níger',
    code: 'NE'
  },
  {
    id: 165,
    name: 'Omã',
    code: 'OM'
  },
  {
    id: 167,
    name: 'Palau',
    code: 'PW'
  },
  {
    id: 168,
    name: 'Palestina',
    code: 'PS'
  },
  {
    id: 169,
    name: 'Panamá',
    code: 'PA'
  },
  {
    id: 170,
    name: 'Papua-Nova Guiné',
    code: 'PG'
  },
  {
    id: 166,
    name: 'Paquistão',
    code: 'PK'
  },
  {
    id: 171,
    name: 'Paraguai',
    code: 'PY'
  },
  {
    id: 154,
    name: 'Países Baixos',
    code: 'NL'
  },
  {
    id: 172,
    name: 'Peru',
    code: 'PE'
  },
  {
    id: 174,
    name: 'Pitcairn',
    code: 'PN'
  },
  {
    id: 76,
    name: 'Polinésia Francesa',
    code: 'PF'
  },
  {
    id: 175,
    name: 'Polónia',
    code: 'PL'
  },
  {
    id: 177,
    name: 'Porto Rico',
    code: 'PR'
  },
  {
    id: 176,
    name: 'Portugal',
    code: 'PT'
  },
  {
    id: 119,
    name: 'Quirguistão',
    code: 'KG'
  },
  {
    id: 114,
    name: 'Quênia',
    code: 'KE'
  },
  {
    id: 229,
    name: 'Reino Unido',
    code: 'GB'
  },
  {
    id: 42,
    name: 'República Centro-Africana',
    code: 'CF'
  },
  {
    id: 51,
    name: 'República Democrática do Congo',
    code: 'CD'
  },
  {
    id: 62,
    name: 'República Dominicana',
    code: 'DO'
  },
  {
    id: 50,
    name: 'República do Congo',
    code: 'CG'
  },
  {
    id: 179,
    name: 'Reunião',
    code: 'RE'
  },
  {
    id: 180,
    name: 'Roménia',
    code: 'RO'
  },
  {
    id: 182,
    name: 'Ruanda',
    code: 'RW'
  },
  {
    id: 181,
    name: 'Rússia',
    code: 'RU'
  },
  {
    id: 240,
    name: 'Saara Ocidental',
    code: 'EH'
  },
  {
    id: 186,
    name: 'Saint-Pierre e Miquelon',
    code: 'PM'
  },
  {
    id: 188,
    name: 'Samoa',
    code: 'WS'
  },
  {
    id: 5,
    name: 'Samoa Americana',
    code: 'AS'
  },
  {
    id: 189,
    name: 'San Marino',
    code: 'SM'
  },
  {
    id: 183,
    name: 'Santa Helena, Ascensão e Tristão da Cunha',
    code: 'SH'
  },
  {
    id: 185,
    name: 'Santa Lúcia',
    code: 'LC'
  },
  {
    id: 194,
    name: 'Seicheles',
    code: 'SC'
  },
  {
    id: 192,
    name: 'Senegal',
    code: 'SN'
  },
  {
    id: 195,
    name: 'Serra Leoa',
    code: 'SL'
  },
  {
    id: 196,
    name: 'Singapura',
    code: 'SG'
  },
  {
    id: 200,
    name: 'Somália',
    code: 'SO'
  },
  {
    id: 204,
    name: 'Sri Lanka',
    code: 'LK'
  },
  {
    id: 205,
    name: 'Sudão',
    code: 'SD'
  },
  {
    id: 206,
    name: 'Suriname',
    code: 'SR'
  },
  {
    id: 209,
    name: 'Suécia',
    code: 'SE'
  },
  {
    id: 210,
    name: 'Suíça',
    code: 'CH'
  },
  {
    id: 207,
    name: 'Svalbard e Jan Mayen',
    code: 'SJ'
  },
  {
    id: 184,
    name: 'São Cristóvão e Névis',
    code: 'KN'
  },
  {
    id: 190,
    name: 'São Tomé e Príncipe',
    code: 'ST'
  },
  {
    id: 187,
    name: 'São Vicente e Granadinas',
    code: 'VC'
  },
  {
    id: 211,
    name: 'Síria',
    code: 'SY'
  },
  {
    id: 215,
    name: 'Tailândia',
    code: 'TH'
  },
  {
    id: 212,
    name: 'Taiwan',
    code: 'TW'
  },
  {
    id: 213,
    name: 'Tajiquistão',
    code: 'TJ'
  },
  {
    id: 214,
    name: 'Tanzânia',
    code: 'TZ'
  },
  {
    id: 77,
    name: 'Terras Austrais e Antárticas Francesas',
    code: 'TF'
  },
  {
    id: 32,
    name: 'Território Britânico do Oceano Índico',
    code: 'IO'
  },
  {
    id: 216,
    name: 'Timor-Leste',
    code: 'TL'
  },
  {
    id: 217,
    name: 'Togo',
    code: 'TG'
  },
  {
    id: 219,
    name: 'Tonga',
    code: 'TO'
  },
  {
    id: 218,
    name: 'Toquelau',
    code: 'TK'
  },
  {
    id: 220,
    name: 'Trinidad e Tobago',
    code: 'TT'
  },
  {
    id: 221,
    name: 'Tunísia',
    code: 'TN'
  },
  {
    id: 224,
    name: 'Turks e Caicos',
    code: 'TC'
  },
  {
    id: 223,
    name: 'Turquemenistão',
    code: 'TM'
  },
  {
    id: 222,
    name: 'Turquia',
    code: 'TR'
  },
  {
    id: 225,
    name: 'Tuvalu',
    code: 'TV'
  },
  {
    id: 227,
    name: 'Ucrânia',
    code: 'UA'
  },
  {
    id: 226,
    name: 'Uganda',
    code: 'UG'
  },
  {
    id: 232,
    name: 'Uruguai',
    code: 'UY'
  },
  {
    id: 233,
    name: 'Uzbequistão',
    code: 'UZ'
  },
  {
    id: 234,
    name: 'Vanuatu',
    code: 'VU'
  },
  {
    id: 96,
    name: 'Vaticano',
    code: 'VA'
  },
  {
    id: 235,
    name: 'Venezuela',
    code: 'VE'
  },
  {
    id: 236,
    name: 'Vietnã',
    code: 'VN'
  },
  {
    id: 239,
    name: 'Wallis e Futuna',
    code: 'WF'
  },
  {
    id: 243,
    name: 'Zimbabwe',
    code: 'ZW'
  },
  {
    id: 242,
    name: 'Zâmbia',
    code: 'ZM'
  },
  {
    id: 201,
    name: 'África do Sul',
    code: 'ZA'
  },
  {
    id: 15,
    name: 'Áustria',
    code: 'AT'
  },
  {
    id: 101,
    name: 'Índia',
    code: 'IN'
  }
];
