import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import styles from './styles';

class UnableToCreateTransfers extends React.PureComponent {
  render() {
    const { intl, classes } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant={'body1'} className={classes.informationText}>
            {intl.formatMessage({ id: 'transfersUnderMaintenanceText' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ul className={classes.bulletList}>
            <li>
              <Typography variant={'body1'} className={classes.informationText}>
                {intl.formatMessage({ id: 'createTransferRequests' })}
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'} className={classes.informationText}>
                {intl.formatMessage({ id: 'generatePdfFile' })}
              </Typography>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'body1'} className={classes.informationText}>
            {intl.formatMessage({ id: 'weAreVeryGrateful' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'body1'} className={classes.informationText}>
            {intl.formatMessage(
              { id: 'pleaseContact' },
              {
                mailto: (
                  <a
                    href={'mailto:info@myownbirds.com'}
                    target={'_top'}
                    className={classes.informationTextMailTo}
                  >
                    info@myownbirds.com
                  </a>
                )
              }
            )}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(UnableToCreateTransfers)));
