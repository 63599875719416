// ENGLISH

export default {
  // app
  logout: 'Logout',
  view: 'View',
  add: 'Add',
  remove: 'Remove',
  cancel: 'Cancel',
  confirm: 'Confirm',
  next: 'Next',
  back: 'Back',
  yes: 'Yes',
  no: 'No',
  undefined: 'Undefined',
  edit: 'Edit',
  goBack: 'Go back',
  close: 'Close',
  rowsPerPage: 'Rows per page',
  of: 'of',
  home: 'Home',
  startNow: 'Start now',

  // sidebar
  dashboard: 'Dashboard',
  users: 'Users',
  account: 'Account',
  others: 'Others',
  plans: 'Plans',
  transfers: 'Transfers',

  // home
  birdsPortal: 'Complete aviary management portal',
  breedersCollaboration: "A collaboration with bird's breeders",
  birdsManagement: 'Birds management',
  couplesAndClutchesManagement: 'Couples and clutches management',
  birdTransfers: 'Bird transfers',
  searchBreedersWithSpecificSpecies: 'Search for breeders with specific species',

  mainFunctionalities: 'Main Functionalities',
  availableForBreeder: 'Available to all breeders',
  manageBirdsStock: 'Bird stock management',
  manageCouples: 'Couples management',
  manageClutches: 'Procreation season management',
  efficientSearch: 'Efficient search',
  birdProfile: 'Individual bird record',
  simpleTransfer: 'Simple transfer process',

  // public plans
  increaseProductivity: 'Increase your productivity while breeding birds',
  freeDescription:
    "It's free forever. The perfect plan to start using some of our functionalities.",
  basicDescription:
    'Ideal for those who want to start this activity and start managing easily their aviary.',
  premiumDescription: 'Ideal for those who want to reach the professional level.',
  platinumDescription: 'The perfect plan for those looking to cross the line.',
  manageInfiniteNumberOfBirds: 'Manage an infinite number of birds',
  manageCouplesUpTo: 'Manage up to {number} couples',
  createTransferRequests:
    'Create orders and related documents to transfer birds to another breeder',
  searchForOtherBirdsFromOtherBreeders: 'Search for other birds from other breeders',
  year: 'year',

  // login
  login: 'Login',
  email: 'Email',
  password: 'Password',
  rememberMe: 'Remember me',
  forgotPassword: 'Forgot your password?',
  invalidEmail: 'This email is not valid.',
  emailIsRequired: 'The email is required.',
  passwordIsRequired: 'The password is required',
  invalidCredentials: 'Invalid credentials.',

  // species
  meetTheSupportedSpecies: 'Meet the species present in the platform',
  weAreKeenToSupportMore: 'Email us at {email} if you would like to add more species.',

  // register
  perfectPlatform: 'The perfect platform to manage your birds',
  ourMission:
    'Our mission is to help breeders manage their aviary easily. We have a lot of features available:',
  createYourFreeAccount: 'Create your free account',
  createAccount: 'Create account',
  acceptTermsAndConditionsRegister: 'By moving forward you agree with',
  termsAndConditions: 'Terms & Conditions',
  registerErrorEmailAlreadyUsed: 'This email is already being used.',
  registerErrorStripeProblem: 'Error with external API. Please, try again.',
  registerErrorConfirmationRegistrationCode: 'This registration code is not valid.',
  registerErrorInvalidEmail: 'This email is not valid.',
  registerErrorInvalidRecaptchaToken: 'ReCaptcha Token is not valid.',
  invalidPassword: 'Password must be at least 5 characters',
  registerSuccess: 'Check your email and enter the registration code.',
  registrationCode: 'Registration code',
  confirmRegistrationCode: 'Confirm code',

  // forgot password
  sendResetInstructions: 'Send reset instructions',
  backToLogin: 'Back to login',
  resetPasswordRequestInfoMessage: 'An email has been sent to you to reset your password',
  resetPassword: 'Reset password',
  resetRequestErrorMessage: 'The request to reset your password is no longer valid',

  // users
  addUser: 'Add user',
  noUsers: 'There are no users',
  emailConfirmed: 'Email confirmed',
  country: 'Country',
  userErrorMessage: 'User not found',

  // users add
  contact: 'Contact',
  birthday: 'Birthday',

  // breeder dashboard
  hiBreeder: 'Hi, {breederName}',
  numberOfBirds: 'Birds',
  numberOfCouples: 'Couples',
  numberOfClutches: 'Clutches',
  youCurrentlyHave:
    'You currently have the {plan} plan. If you want to subscribe another solution, please visit our {plansPage}.',
  plansPage: 'plans page',
  numberOfTransfers: 'Transfers',
  numberOfDead: 'Dead',

  // account
  language: 'Language',
  registeredAt: 'Registered at',
  updatedAt: 'Updated at',
  changePassword: 'Change password',
  currentPassword: 'Current password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  passwordsDontMatch: "The passwords don't match.",
  editAccount: 'Edit account',
  changedPasswordErrorMessage: 'The current password is not correct.',
  changedPasswordSuccessMessage: 'The password has been successfully changed.',
  changedPersonalInformationInfoMessage: 'Your personal information was changed.',
  breederIdentifiers: 'Breeder identifiers',
  addBreederIdentifier: 'Add identifier',
  setAsDefault: 'Set as default',
  isDefault: 'Default value',
  breederIdentifier: 'Breeder identifier',
  removeBreederIdentifier: 'Remove breeder identifier',
  removeBreederIdentifierSuccessMessage: 'The identifier was removed',
  removeBreederIdentifierErrorMessage: 'It was not possible remove the identifier',
  removeBreederIdentifierQuestion: 'Are you sure you want to remove this identifier?',
  setAsDefaultQuestion: 'Are you sure you want to set as default this identifier?',
  setAsDefaultBreederIdentifierSuccessMessage: 'The identifier was set as default',
  setAsDefaultBreederIdentifierErrorMessage:
    'It was not possible to set this identifier as the default.',
  editBreederIdentifier: 'Edit breeder identifier',
  editBreederIdentifierSuccessMessage: 'The breeder identifier was edited',
  breederIdentifierErrorMessage: 'The breeder identifier is already in use.',
  addBreederIdentifierSuccessMessage: 'A new breeder identifier has been added',
  identifierDescription:
    'The identifier represents a code assigned to the breeder by an ' +
    'association / federation / others.',
  identifierDescription2: 'It will be used when creating a bird.',
  noIdentifiers: 'No identifiers',
  closeAccount: 'Close account',
  closeAccountInformation:
    'Closing your account is permanent and cannot be undone! This will permanently remove all ' +
    'associated data for your user. If you have any subscription we will cancel it immediately.',
  closeAccountKeepBirds: 'Information regarding bird transfers will be kept.',
  closeAccountEmailWillBeSent:
    'For security reasons, we will send an email containing a code that you need to fill to confirm that you want to close your account.',
  confirmationCode: 'Confirmation code',

  // plans
  choosePlan: 'Choose plan',
  managePlan: 'Manage plan',
  plan: 'Plan',
  cancelSubscription: 'Cancel subscription',
  cancelSubscriptionMessage: 'Are you sure you want to cancel this subscription?',
  newSubscriptionErrorMessage: 'Error on adding a new subscription. Please, try again!',
  cancelSubscriptionErrorMessage: 'Error on cancel this subscription. Please, try again!',
  billing: 'Billing',
  manageYourBirds: 'Manage your birds',
  manageMaximumCouples: 'Manage a maximum of',
  manageMoreThanCouples: 'Manage more than',
  doTransferRequests: 'Do transfer requests for your birds',
  viewOtherBirds: 'View birds from other breeders',
  free: 'Free',
  basic: 'Basic',
  premium: 'Premium',
  platinum: 'Platinum',
  custom: 'Custom',
  select: 'Select',
  actualPlan: 'Actual plan',

  // email confirmation
  emailConfirmation: 'Email confirmation',
  emailWasConfirmed: 'Your email has been confirmed successfully.',
  emailWasNotConfirmed:
    "There was a problem while trying to confirm your email. If you haven't confirmed yet, request a confirmation link again.",
  requestConfirmation: 'Request new confirmation',

  // admin dashboard
  activeUsers: 'Active users',
  accountsClosed: 'Accounts closed',
  top10CountriesWithBreeders: 'Top 10 countries with active breeders',
  top10CountriesWithBirds: 'Top 10 countries with active birds',
  top10SpeciesWithBirds: 'Top 10 species with active birds',

  // breeder users
  name: 'Name',
  addedAt: 'Added at',
  active: 'Active',
  addUserIfNotExist:
    "The user will be added to your list after the user has accepted the request. Make sure the user's email is correct.",
  addUserRecommendation:
    'If the user is already registered on our platform, ask him/her for the email.',
  searchBy: 'Search by',
  searchByNameEmailCountry: "Name, email or user's country",

  // breeder birds
  addBird: 'Add bird',
  myBirds: 'My Birds',
  searchBirds: 'Search Birds',
  transferRequests: 'Transfer Requests',
  breeder: 'Breeder',
  ring: 'Ring',
  gender: 'Gender',
  specie: 'Specie',
  mutation: 'Mutation',
  noBirds: 'There are no birds',
  searchByRing: 'Ring',
  birthdayStart: 'Birthday start',
  birthdayEnd: 'Birthday end',
  genderAndCountry: 'Gender and Country',
  birdsErrorMessage: 'Error on retrieving your birds',
  speciesErrorMessage: 'Error on retrieving all species',
  mutationsErrorMessage: 'Error on retrieving all mutations',
  filterSearch: 'Filters',
  addBirdSuccessMessage: 'The bird was added successfully.',
  addBirdErrorMessage: 'It was not possible to add a new bird',
  observations: 'Observations',
  initialData: 'Initial data',
  isYellow: 'Is yellow',
  isWhiteHead: 'Is white head',
  isCarduelisSpecieHeader:
    'You have selected the specie Carduelis Carduelis. Select if your bird is yellow or white head.',
  yellow: 'Yellow',
  whiteHead: 'White Head',
  addBreederIdentifierAddNewBirdMessage: "If you don't have or want to add a new identifier click ",
  acquiredAt: 'Acquired at',
  firstOwner: 'First owner',
  owner: 'Owner',
  firstOwnerInformation: 'First owner information',
  firstOwnerInformationMessage:
    'When adding a bird, you can indicate if you own the bird or if it was acquired.',
  ringTotal: 'Ring total',
  ringAlreadyUsed: 'Ring already used',
  number: 'Number',
  extraInformation: 'Extra information',
  breederName: "Breeder's name",
  invalidDate: 'Invalid date format. You must specify the day, month and year (dd/mm/yyyy).',
  birdInformation: "Bird's information",
  carrierOfMutations: 'Carrier of',
  possiblyCarrierOfMutations: 'Possibly carrier of',

  // bird states
  state: 'State',
  transferred: 'Transferred',
  transferredAt: 'Transferred at',
  death: 'Death',
  deathAt: 'Death at',
  all: 'All',

  // bird information
  children: 'Children',
  clutches: 'Clutches',
  parents: 'Progenitor',
  parent: 'Progenitor',
  parent1: 'Parent 1',
  parent2: 'Parent 2',
  acquired: 'Acquired',
  editBirdSuccessMessage: 'This bird was edited successfully.',
  editBirdErrorMessage: 'It was not possible edit this bird.',
  documents: 'Documents',
  editBird: 'Edit bird',
  noDocuments: "This bird doesn't have documents attached",
  document: 'Document',
  noComments: 'No comments',
  removeBird: 'Remove bird',
  onlyOneFieldFillError: 'You can only fill one field',
  removeBirdQuestion: 'Are you sure you want to remove this bird?',
  removeBirdSuccessMessage: 'The bird was removed successfully',
  removeBirdErrorMessage: 'It was not possible remove this bird.',
  removeBirdHasChildrenErrorMessage:
    'The bird has children. If you want to change the bird information, please update these sections below.',
  removeBirdWasTransferredErrorMessage:
    'The bird was transferred to you. If you want to change the bird information, please update these sections below.',
  declareDeath: 'Declare death',
  declareDeathSuccessMessage: 'The bird was successfully declared dead.',
  removeImageSuccessMessage: 'The image was removed successfully.',
  getBirdErrorMessage: "The bird does not exist or you don't have access to it.",

  transfersHistory: 'Transfers history',
  transfer: 'Transfer',
  transferLine: 'Transferred to {country} at {date}.',
  transferLineFrom: 'Transferred from {country} at {date}.',
  removeImage: 'Remove image',
  removeImageQuestion: 'Are you sure you want to remove this image?',
  setImageAsDefault: 'Set image as default',
  setImageAsDefaultQuestion: 'Are you sure you want to set this image as default?',

  // bird couples
  couples: 'Couples',
  noCouples: 'No couples',
  addCoupleErrorMessagePlanDontAllow: 'Check if your plan allows you to do this.',
  addCoupleErrorMessage:
    'There was an error creating the couple. Check that you entered the data correctly.',
  parentsAreBrothers: 'These birds are brothers or stepbrothers.',
  couplesErrorMessage: 'Error on retrieving your couples',
  coupleErrorMessage: 'Error on retrieving your couple',
  couplesSuccessMessage: 'A couple was created successfully.',
  inactive: 'Inactive',
  addCouple: 'Add couple',
  addCoupleInformation:
    'The following birds must be already registered on our platform. If you want to register them in advance, click ',
  here: 'here.',
  chooseParents: 'Choose parents',
  searchByParentsRing: 'Couple identifier',
  male: 'Male',
  female: 'Female',
  unknown: 'Unknown',
  identifier: 'Identifier',
  identifierAlreadyUsed: 'The cage identifier is already being used.',
  identifierIsRequired: 'The cage identifier is required.',
  identifierMoreThan: 'Identifier must be greater than 0',
  editIdentifier: 'Edit identifier',
  editCouple: 'Edit couple',
  editCoupleSuccessMessage: 'The couple was edited successfully.',
  editCoupleErrorMessage: 'There was a problem while trying to edit this couple.',
  setCageIdentifierCoupleInactive:
    'This couple is inactive or will be inactive. It is not possible to change the cage identifier.',
  setIsActiveCoupleErrorMessage:
    'Unable to reactivate this couple. One of the parents is dead or has been transferred.',
  removeCouple: 'Remove couple',
  removeCoupleNotice:
    "Please consider setting this couple as inactive (in 'Edit couple'). We will keep the related birds but we will remove the clutches and you will lose track of bird's relationships.",
  removeCoupleQuestion: 'Do you pretend to remove this couple? This is irreversible!',
  removeCoupleSuccessMessage: 'The couple was removed successfully.',
  removeCoupleErrorMessage: 'There was a problem while trying to remove this couple.',

  // couple information
  addChild: 'Add child',
  addClutch: 'Add clutch',
  couple: 'Couple',
  scheduled: 'Scheduled',
  finished: 'Finished',
  addChildErrorMessage: 'It was not possible to add a new child',
  addChildCoupleInactive:
    'This couple is inactive. It is only possible to add a new child if you want to link it to an existing clutch.',
  addChildSuccessMessage: 'A new child has been added to this couple',
  addCoupleChildInformationHeader:
    'The child you are about to create is not linked to any clutch. If you want to link it to any clutch, click ',
  child: 'Child',
  cageIdentifier: 'Cage identifier',
  cage: 'Cage',
  qrCodeMessage: 'Use this QR code to identify this couple and quickly access this page.',

  // clutches
  totalEggs: 'Total eggs',
  startDate: 'Start date',
  description: 'Description',
  addClutchSuccessMessage: 'A new clutch has been added to this couple',
  addClutchErrorMessage: 'It was not possible to add a new clutch',
  addClutchCoupleInactive: 'This couple is inactive. It is not possible to add new clutches.',
  noClutches: 'No clutches',
  clutchesErrorMessage: 'Error on retrieving the clutches from this couple',
  childrenCount: '{eggsCount} eggs and {childrenCount} children',
  onGoing: 'Ongoing',

  // clutch
  clutch: 'Clutch',
  editClutch: 'Edit clutch',
  abortedEggs: 'Aborted eggs',
  fertilizedEggs: 'Fertilized eggs',
  removeClutch: 'Remove clutch',
  totalEggsExceeded: 'Total eggs exceeded',
  editClutchSuccessMessage: 'The clutch data has been updated successfully',
  editClutchErrorMessage: 'It was not possible update the clutch data',
  addClutchChildHeader: 'You can only add a child to this clutch if you add more fertilized eggs.',

  // birds market
  breederEmail: 'Breeder email',
  searchByBreederEmail: "Breeder's email",
  breederInformation: "Breeder's information",
  bird: 'Bird',
  transferRequest: 'Transfer request',
  transferRequestSuccessMessage: 'The transfer was created successfully.',
  transferRequestErrorMessage: 'There was a problem while trying to create your transfer.',
  transferNumberAlreadyExists: 'This number already exists for the current year.',
  missingDataBreederReceiverErrorMessage:
    'The receiver has not yet filled in mandatory data in his profile.',
  specieRequired: 'You have to choose a specie',
  mutationRequired: 'You have to choose a mutation',
  showInterest: 'Show interest',
  sendEmailQuestion:
    'An email will be sent to the breeder information that you have an interest in ' +
    'the mutation {mutation}. Your name and email will be shared. Do you want to send it?',
  sendEmailSuccessMessage: 'The email was sent successfully.',

  // transfer requests
  createTransfer: 'Create transfer',
  holder: 'Holder',
  newOwner: 'New owner',
  birdMutation: 'Bird mutation',
  noTransferRequests: 'No transfer requests',
  transferRequestsErrorMessage: 'Error on retrieving your transfer requests',
  chooseBirds: 'Choose birds',
  questionBreederUser: 'Not a user of the application?',
  addBreederInformation: 'Add breeder information',
  zipCode: 'Zip Code',
  address: 'Address',
  birds: 'Birds',
  sent: 'Sent',
  received: 'Received',
  type: 'Type',
  pending: 'Pending',
  accepted: 'Accepted',
  refused: 'Refused',
  removed: 'Removed',
  transfersUnderMaintenance: 'Transfers under maintenance',
  transfersUnderMaintenanceText:
    'Transfers will be back online as soon as possible. We are working on country-specific transfers and we will ensure the best possible experience for our users. You will be able to:',
  weAreVeryGrateful: 'We are very grateful for your trust in us and our site.',
  pleaseContact: 'Please contact {mailto} if you have any questions.',

  // transfer request
  acceptTransfer: 'Accept transfer',
  refuseTransfer: 'Refuse transfer',
  removeTransfer: 'Remove transfer',
  downloadTransferDocument: 'Transfer document',
  sharedDocuments: 'Shared documents',
  registerDate: 'Register date',
  transferNumberOfBirds: 'Number of birds',
  accessCodeTitle: 'Information access code',
  accessCodeMessage:
    'In the transfer process, it is possible to provide your personal information to the owner of the birds in a very simple way: just provide the code below that will be requested during the transfer creation.',
  accessCodeDuration:
    'This code is automatically generated every 15 seconds and can only be used once.',
  removeTransferMessage: 'Are you sure you want to remove this transfer?',
  removeTransferError: 'Unable to delete this transfer. It has been approved or refused.',
  removeTransferSuccess: 'The transfer was removed successfully.',

  // create transfer request
  isRegistered: 'Is registered on the platform',
  receptorCode: 'New owner data access code',
  receptorCodeHelperText: "This code is on the user's transfers page.",
  receptorGetData: 'Get data',
  transferNumber: 'Transfer number',
  createTransferInfo1:
    'The transfer document is based on the country of the user creating it. We are actively managing these documents. If you think that mandatory fields are missing in your country, please indicate them via email to {email}.',
  createTransferInfo2:
    'You will only be able to create a transfer once the previous one is finished.',
  createTransferInfo3: 'Fields marked with * are required.',
  createTransferInfo4:
    'The transfer number is automatically generated but you can edit it, and it must be unique in the current year.',
  createTransferInfo5:
    'It is possible to create transfers for people not registered on the platform. In this case, the transfer will be closed automatically and the birds will be marked as transferred.',
  createTransferInfo6:
    'If the new owner is registered on the platform, it is necessary for him to indicate the code generated in the transfer area in order to obtain his personal data. This process allows birds to be automatically transferred to the new owner upon approval.',
  breederNotFoundOrInvalidCode: 'Breeder not found or invalid code.',

  removeTransferRequest: 'Remove transfer request',
  breederRequesterInformation: 'Breeder requester information',
  breederReceiverInformation: 'Breeder receiver information',
  stam: 'STAM',
  breederCode: 'Breeder Government Code',
  removeTransferRequestQuestion:
    'Are you sure you want to remove the transfer request? The receiver will not be able to confirm the transfer of these birds.',
  removeTransferRequestSuccessMessage: 'The transfer request was removed successfully',
  removeTransferRequestErrorMessage: 'It was not possible to remove the transfer request',
  addMoreBreederInformation: 'Add personal information',
  addMoreBreederInformationMessage:
    'There is information missing in your profile (address, zip code, country). Please. enter the missing data ',

  // public transfer request
  transferRequestConfirmation: 'Transfer request confirmation',
  confirmTransfer: 'Confirm transfer request',
  transferRequestConfirmationSuccessMessage: 'Transfer request confirmed',
  transferRequestCancelSuccessMessage: 'Transfer request canceled',
  transferRequestConfirmationErrorMessage: 'It was not possible to confirm transfer request',
  transferRequestCancelErrorMessage: 'It was not possible to cancel transfer request',

  // admin
  species: 'Species',
  addSpecie: 'Add specie',
  noSpecies: 'No species',
  mutations: 'Mutations',
  addMutation: 'Add mutation',
  noMutations: 'No mutations',
  image: 'Image',
  addSpecieSuccessMessage: 'A new specie has been registered',
  addSpecieErrorMessage: 'This specie already exists.',
  retrieveSpeciesErrorMessage: 'Error on retrieving the species',
  retrieveSpecieErrorMessage: 'Error on retrieving the specie',
  hasClutchDurationDays: 'Add days of clutch duration',
  days: 'Days',
  clutchDurationDays: 'Clutch duration (days)',
  addMutationErrorMessage: 'This mutation already exists.',
  editSpecie: 'Edit specie',
  editSpecieSuccessMessage: 'The specie has been successfully edited',
  removeSpecie: 'Remove specie',
  removeSpecieErrorMessage: 'Error on removing this specie',
  removeSpecieQuestion:
    'Are you sure you want to remove this specie? The mutations related will be deleted too.',
  retrieveMutationErrorMessage: 'Error on retrieving the mutation',
  editMutation: 'Edit mutation',
  removeMutation: 'Remove mutation',
  removeMutationErrorMessage: 'Error on removing this mutation',
  removeMutationQuestion: 'Are you sure you want to remove this mutation?',

  // other error messages
  serverErrorMessage: 'An internal server error occurred. We are investigating.',

  // input file
  dragAndDrop: 'Drag and drop',
  or: 'or',
  uploadImage: 'Upload image',
  uploadImages: 'Upload images',
  uploadFile: 'Upload file',
  uploadFiles: 'Upload files',
  errorFileSize: 'File is too big',
  errorWhileUploading: 'Unable to upload this file',

  // hide components
  changeYourPlanAndGetAccess: 'Change your plan and get access to new features',
  askOtherBreederToContactYou:
    'Inform another breeder that you are interested in one specific specie and ask him to contact you',
  generatePdfFile:
    "Generate a document ready to be signed with breeder's information and all birds that will be ceded"
};
