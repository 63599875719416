import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Avatar, IconButton, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// REACT FEATHER
import { ChevronRight, Target } from 'react-feather';

// APP IMPORTS
import styles from './styles';

class TransferItem extends React.PureComponent {
  render() {
    const { classes, intl } = this.props;

    return (
      <React.Fragment>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className={classes.container} onClick={this.props.onClick}>
          <div className={classes.avatarContainer}>
            <Avatar className={classes.avatar}>
              <Target size={30} />
            </Avatar>
          </div>
          <div className={classes.innerContainer}>
            <div className={classes.stateContainer}>
              <Typography variant={'h6'} className={classes.stateText}>
                {`${this.props.number} (${this.props.state})`}
              </Typography>
            </div>
            <div>
              <Typography variant={'body1'} className={classes.breedersText}>
                {`${this.props.requesterName} | ${this.props.receptorName}`}
              </Typography>
            </div>
            <div>
              <Typography variant={'body1'} className={classes.createdAtText}>
                {`${this.props.birdsNumber} ${intl
                  .formatMessage({ id: 'birds' })
                  .toLowerCase()} | ${this.props.createdAt}`}
              </Typography>
            </div>
          </div>
          <div className={classes.button}>
            <IconButton color={'default'} size={'small'} onClick={this.props.onClick}>
              <ChevronRight size={20} />
            </IconButton>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TransferItem.propTypes = {
  number: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  requesterName: PropTypes.string.isRequired,
  receptorName: PropTypes.string.isRequired,
  birdsNumber: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default withStyles(styles)(injectIntl(TransferItem));
