import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// QUERY STRING
import queryString from 'query-string';

// SPINE UI
import { Content, Paper, Table, Select, InformationDialog } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { withSnackbar } from 'notistack';
import { setAppBarTitle } from '../../../store/actions/appActions';
import { fromUTCtoLocal } from '../../../utils/timeFormat';
import { fetchApi } from '../../../utils/apiSettings';
import ROUTES from '../../../utils/routes';
import TooltipIconButton from '../../../components/buttons/TooltipIconButton';
import TransferItem from './components/TransferItem';
import SubscribePlanToCreateTransfers from './components/SubscribePlanToCreateTransfers';
import UnableToCreateTransfers from './components/UnableToCreateTransfers';
import WaitTransferToComplete from './components/WaitTransferToComplete';
import styles from './styles';

const PER_PAGE_DEFAULT = 25;
const PAGE_SIZES = [15, PER_PAGE_DEFAULT, 50, 100];

class TransferRequests extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      breederHasPendingTransfers: false,

      breederTransferAccessCode: '',
      breederTransferAccessCodePercentage: 100,

      transfers: [],
      total: 0,
      type: 'sent',

      // sorting
      sortBy: 'registeredAt',
      sortDirection: 'desc',

      // pagination
      page: 0,
      perPage: PER_PAGE_DEFAULT,

      // dialogs
      isSubscribePlanOpened: false,
      isUnableToCreateTransfersOpened: false,
      isWaitForTransferToCompleteIsOpened: false
    };
  }

  componentDidMount() {
    this.props.setAppBarTitle('transfers');

    this.parsePaginationAndFiltersFromURLParameters(() => {
      Promise.all([this.fetchTransferRequests(), this.fetchBreederNewCode()]).then(() => {
        this.setState({
          isLoading: false
        });

        const newCodeInterval = 15000; // 15 seconds
        const percentageInterval = 10; // 10%

        this.percentageInterval = setInterval(async () => {
          if (this.state.breederTransferAccessCodePercentage - percentageInterval <= 0) {
            await this.fetchBreederNewCode();
            this.setState({ breederTransferAccessCodePercentage: 100 });
          } else {
            this.setState((state) => ({
              breederTransferAccessCodePercentage:
                state.breederTransferAccessCodePercentage - percentageInterval
            }));
          }
        }, newCodeInterval / percentageInterval);
      });
    });
  }

  componentWillUnmount() {
    clearInterval(this.percentageInterval);
  }

  parsePaginationAndFiltersFromURLParameters = (handle) => {
    const parsed = queryString.parse(window.location.search);
    if (parsed) {
      this.setState(
        {
          type: parsed.type || 'sent',
          // pagination
          page: parsed.page !== undefined ? parseInt(parsed.page, 10) : 0,
          perPage: parsed.per_page !== undefined ? parseInt(parsed.per_page, 10) : PER_PAGE_DEFAULT,
          sortBy: parsed.sort_by || 'registeredAt',
          sortDirection: parsed.sort_direction || 'desc'
        },
        () => {
          handle();
        }
      );
    }
  };

  setURLParameters = () => {
    this.props.history.push(
      `${window.location.pathname}?${queryString.stringify({
        type: this.state.type,
        // pagination
        page: this.state.page,
        per_page: this.state.perPage,
        sort_by: this.state.sortBy,
        sort_direction: this.state.sortDirection
      })}`
    );
  };

  fetchTransferRequests = () => {
    const { enqueueSnackbar, intl } = this.props;

    let endpoint = `/breeders/v1/transfers?`;
    endpoint += queryString.stringify({
      // pagination
      page_number: this.state.page,
      per_page: this.state.perPage,
      order_field: this.state.sortBy === 'registeredAt' ? 'created_at' : this.state.sortBy,
      order_direction: this.state.sortDirection,
      type: this.state.type
    });

    return new Promise((resolve) => {
      fetchApi(
        'get',
        endpoint,
        {},
        {},
        false,
        (response) => {
          const { data } = response;

          // check this for the first time
          if (this.state.type === 'sent') {
            const pending = data.data.find((transfer) => transfer.state === 'SENT');
            if (pending) {
              this.setState({ breederHasPendingTransfers: true });
            } else {
              this.setState({ breederHasPendingTransfers: false });
            }
          }

          this.setState(
            {
              transfers: data.data,
              total: data.total_count
            },
            () => {
              resolve();
            }
          );
        },
        () => {
          resolve();

          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        }
      );
    });
  };

  fetchBreederNewCode = () => {
    const { enqueueSnackbar, intl } = this.props;

    return new Promise((resolve) => {
      fetchApi(
        'post',
        '/breeders/v1/breeders/breeder_code',
        {},
        {},
        false,
        (response) => {
          const { data } = response;

          this.setState(
            {
              breederTransferAccessCode: data.code
            },
            () => {
              resolve();
            }
          );
        },
        () => {
          resolve();
          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        }
      );
    });
  };

  handleTableChange = (page, perPage, sortBy, sortDirection) => {
    this.setState(
      {
        isFetching: true,
        page: page,
        perPage: perPage,
        sortBy: sortBy,
        sortDirection: sortDirection
      },
      () => {
        this.setURLParameters();
        Promise.all([this.fetchTransferRequests()]).then(() => {
          this.setState({ isFetching: false });
        });
      }
    );
  };

  handleSelectChange = (event) => {
    const input = event.target;

    this.setState(
      {
        isFetching: true,
        [input.name]: input.value
      },
      () => {
        this.setURLParameters();
        Promise.all([this.fetchTransferRequests()]).then(() => {
          this.setState({
            isFetching: false
          });
        });
      }
    );
  };

  renderTransferAccessCode = () => {
    const { intl, classes } = this.props;

    return (
      <Paper title={intl.formatMessage({ id: 'accessCodeTitle' })}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant={'body1'} className={classes.itemText}>
              {intl.formatMessage({ id: 'accessCodeMessage' })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.descriptionItemContainer}>
              <div className={classes.itemIcon}>
                <CircularProgress
                  size={40}
                  variant={'determinate'}
                  value={this.state.breederTransferAccessCodePercentage}
                />
              </div>
              <Typography variant={'h5'} className={classes.itemText}>
                {this.state.breederTransferAccessCode}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'body1'} className={classes.itemText}>
              {intl.formatMessage({ id: 'accessCodeDuration' })}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  render() {
    const { intl, classes } = this.props;

    const buttons = [
      {
        label: intl.formatMessage({ id: 'createTransfer' }),
        disabled: this.state.isLoading || this.state.isFetching,
        color: 'primary',
        variant: 'contained',
        onClick: () => {
          const plan =
            this.props.user.user && this.props.user.user.plan
              ? this.props.user.user.plan
              : undefined;

          if (plan && plan === 'FREE') {
            this.setState({ isSubscribePlanOpened: true });
          } else if (this.state.breederHasPendingTransfers) {
            this.setState({ isWaitForTransferToCompleteIsOpened: true });
          } else {
            // this.props.history.push(ROUTES.BREEDER_TRANSFER_REQUESTS_CREATE);
            this.setState({
              isUnableToCreateTransfersOpened: true
            });
          }
        }
      }
    ];

    const transferStateValues = {
      SENT: intl.formatMessage({ id: 'pending' }),
      ACCEPTED: intl.formatMessage({ id: 'accepted' }),
      REFUSED: intl.formatMessage({ id: 'refused' }),
      '': ''
    };

    return (
      <Content
        isLoading={this.state.isLoading}
        isFetching={this.state.isFetching}
        buttons={buttons}
      >
        <InformationDialog
          isOpened={this.state.isSubscribePlanOpened}
          title={intl.formatMessage({ id: 'changeYourPlanAndGetAccess' })}
          maxWidth={'md'}
          onCancel={() =>
            this.setState({
              isSubscribePlanOpened: false
            })
          }
          closeLabel={intl.formatMessage({ id: 'close' })}
          onCancelDisabled={this.state.isLoading || this.state.isFetching}
        >
          <SubscribePlanToCreateTransfers />
        </InformationDialog>
        <InformationDialog
          isOpened={this.state.isWaitForTransferToCompleteIsOpened}
          title={intl.formatMessage({ id: 'createTransfer' })}
          maxWidth={'sm'}
          onCancel={() =>
            this.setState({
              isWaitForTransferToCompleteIsOpened: false
            })
          }
          closeLabel={intl.formatMessage({ id: 'close' })}
          onCancelDisabled={this.state.isLoading || this.state.isFetching}
        >
          <WaitTransferToComplete />
        </InformationDialog>
        <InformationDialog
          isOpened={this.state.isUnableToCreateTransfersOpened}
          title={intl.formatMessage({ id: 'transfersUnderMaintenance' })}
          maxWidth={'sm'}
          onCancel={() =>
            this.setState({
              isUnableToCreateTransfersOpened: false
            })
          }
          closeLabel={intl.formatMessage({ id: 'close' })}
          onCancelDisabled={this.state.isLoading || this.state.isFetching}
        >
          <UnableToCreateTransfers />
        </InformationDialog>
        {!this.state.isLoading && (
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              {this.renderTransferAccessCode()}
            </Grid>
            <Grid item xs={12}>
              <div className={classes.filterFormContainer}>
                <div className={classes.typeButton}>
                  <Select
                    showEmpty={false}
                    name={'type'}
                    label={intl.formatMessage({ id: 'type' })}
                    required={false}
                    value={this.state.type}
                    onChange={this.handleSelectChange}
                    disabled={this.state.isLoading}
                    autoComplete={'off'}
                    options={[
                      {
                        key: 'sent',
                        value: 'sent',
                        name: intl.formatMessage({ id: 'sent' })
                      },
                      {
                        key: 'received',
                        value: 'received',
                        name: intl.formatMessage({ id: 'received' })
                      }
                    ]}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Table
                webHeaders={[
                  {
                    title: intl.formatMessage({ id: 'number' }),
                    align: 'left',
                    sort: false,
                    sortKey: 'number'
                  },
                  {
                    title: intl.formatMessage({ id: 'state' }),
                    align: 'left'
                  },
                  {
                    title: intl.formatMessage({ id: 'holder' }),
                    align: 'left'
                  },
                  {
                    title: intl.formatMessage({ id: 'newOwner' }),
                    align: 'left'
                  },
                  {
                    title: intl.formatMessage({ id: 'birds' }),
                    align: 'left'
                  },
                  {
                    title: intl.formatMessage({ id: 'registeredAt' }),
                    align: 'left',
                    sort: true,
                    sortKey: 'registeredAt'
                  },
                  { title: '', align: 'right' }
                ]}
                webData={this.state.transfers.map((item) => [
                  item.number,
                  transferStateValues[item.state],
                  item.requester.name,
                  item.receptor ? item.receptor.name : '-',
                  item.count_birds,
                  fromUTCtoLocal(item.created_at).format('LLL'),
                  <TooltipIconButton
                    title={'view'}
                    iconName={'chevron-right'}
                    handleClick={() => {
                      this.props.history.push(
                        `${ROUTES.BREEDER_TRANSFER_REQUESTS}/${item.transfer_id}`
                      );
                    }}
                  />
                ])}
                renderMobile={true}
                mobileData={this.state.transfers.map((item) => (
                  <TransferItem
                    number={item.number}
                    state={transferStateValues[item.state]}
                    requesterName={item.requester.name}
                    receptorName={item.receptor ? item.receptor.name : '-'}
                    birdsNumber={item.count_birds}
                    createdAt={fromUTCtoLocal(item.created_at).format('LLL')}
                    onClick={() => {
                      this.props.history.push(
                        `${ROUTES.BREEDER_TRANSFER_REQUESTS}/${item.transfer_id}`
                      );
                    }}
                  />
                ))}
                noDataMessage={intl.formatMessage({ id: 'noTransferRequests' })}
                // handle changes
                onChange={this.handleTableChange}
                // sorting
                useSorting={true}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                // pagination
                usePagination={true}
                totalCount={this.state.total}
                page={this.state.page}
                rowsPerPage={this.state.perPage}
                rowsPerPageOptions={PAGE_SIZES}
              />
            </Grid>
          </Grid>
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer,
  user: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(injectIntl(TransferRequests))));
