export default (theme) => ({
  centeredImage: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  },
  imageExample: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 6,
    width: '90%',
    opacity: 0.8
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
});
