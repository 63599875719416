import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Tabs as MuiTabs } from '@material-ui/core';

// APP IMPORTS
import styles from './styles';

function Tabs({ ...props }) {
  return <MuiTabs {...props} />;
}

export default withStyles(styles)(Tabs);
