export default (theme) => ({
  breederInformationText: {
    color: theme.palette.common.black
  },
  planText: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textTransform: 'lowercase'
  },
  bulletList: {
    paddingLeft: theme.spacing(3),
    color: theme.palette.common.black
  },
  featureText: {
    color: theme.palette.common.black
  }
});
