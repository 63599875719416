import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { Grid, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// YUP
import * as Yup from 'yup';

// SPINE UI
import {
  Content,
  PaperListInformation,
  Paper,
  FormDialog,
  Table,
  Input,
  ConfirmationDialog,
  Button
} from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import {
  DEFAULT_LOCALE,
  clearStorage,
  getUserRole,
  setUserLanguage,
  setUserToken
} from '../../utils/userSettings';
import { getCountries, getCountryName } from '../../utils/appSettings';
import { setAppBarTitle, setAppLocale } from '../../store/actions/appActions';
import { setUserInformation } from '../../store/actions/userActions';
import { fetchApi } from '../../utils/apiSettings';
import { fromUTCtoLocal } from '../../utils/timeFormat';
import ChangePassword from './components/ChangePassword';
import EditAccount from './components/EditAccount';
import ROLES from '../../utils/roles';
import BreederInformation from './components/BreederInformation';
import ROUTES from '../../utils/routes';
import TooltipIconButton from '../../components/buttons/TooltipIconButton';

class Account extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      isBreeder: false,
      user: null,

      // edit password form
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',

      // breeder identifier
      isAddIdentifierOpened: false,
      isEditIdentifierOpened: false,
      breederIdentifier: '',
      breederIdentifierId: null,
      isRemoveIdentifierOpened: false,
      isSetAsDefaultIdentifierOpened: false,

      // close account
      isCloseAccountOpened: false,
      isConfirmationCodeOpened: false,
      closeAccountGeneratedCode: '', // generated when requesting to close account
      confirmationCode: '', // sent to user's email

      // edit account form
      name: '',
      locale: '',
      contact: '',
      breederCode: '',
      zipCode: '',
      address: '',
      country: '',
      ptIcnf: '',
      ptNif: '',
      ptCc: '',

      // forms
      errors: {},
      isChangePasswordOpened: false,
      isEditAccountOpened: false
    };

    this.currentPasswordInputRef = React.createRef();
    this.nameInputRef = React.createRef();
    this.breederIdentifierInputRef = React.createRef();
    this.breederEditIdentifierInputRef = React.createRef();
  }

  componentDidMount() {
    this.props.setAppBarTitle('account');

    if (getUserRole() === ROLES.BREEDER) {
      this.fetchBreederInformation();
    } else {
      this.fetchUserInformation();
    }
  }

  fetchBreederInformation = () => {
    const { intl, enqueueSnackbar } = this.props;

    fetchApi(
      'get',
      '/breeders/v1/account',
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.setState(
          {
            user: data,
            isBreeder: true,
            isLoading: false
          },
          () => {
            this.props.setUserInformation(data);
            this.props.setAppLocale(data.locale || DEFAULT_LOCALE);
            setUserLanguage(data.locale || DEFAULT_LOCALE);
          }
        );
      },
      () => {
        enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
          variant: 'error',
          autoHideDuration: 3000
        });

        this.props.history.push(ROUTES.ROOT);
      }
    );
  };

  fetchUserInformation = () => {
    const { intl, enqueueSnackbar } = this.props;

    fetchApi(
      'get',
      '/v1/users/current',
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.setState(
          {
            user: data,
            isLoading: false
          },
          () => {
            this.props.setUserInformation(data);
            this.props.setAppLocale(data.locale || DEFAULT_LOCALE);
            setUserLanguage(data.locale || DEFAULT_LOCALE);
          }
        );
      },
      () => {
        enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
          variant: 'error',
          autoHideDuration: 3000
        });

        this.props.history.push(ROUTES.ROOT);
      }
    );
  };

  openChangePassword = () => {
    this.setState(
      {
        isChangePasswordOpened: true
      },
      () => {
        this.currentPasswordInputRef.current.focus();
      }
    );
  };

  cancelChangePassword = () => {
    this.setState({
      isChangePasswordOpened: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      errors: {}
    });
  };

  handleChangePassword = async () => {
    const { intl, enqueueSnackbar } = this.props;

    const schema = Yup.object().shape({
      currentPassword: Yup.string().required(intl.formatMessage({ id: 'passwordIsRequired' })),
      newPassword: Yup.string().min(5).required(),
      confirmPassword: Yup.string().min(5).required()
    });

    const dataToSubmit = {
      currentPassword: this.state.currentPassword,
      newPassword: this.state.newPassword,
      confirmPassword: this.state.confirmPassword
    };

    try {
      await schema.validate(dataToSubmit, { abortEarly: false });

      if (dataToSubmit.newPassword !== dataToSubmit.confirmPassword) {
        this.setState({
          errors: {
            newPassword: intl.formatMessage({ id: 'passwordsDontMatch' }),
            confirmPassword: intl.formatMessage({ id: 'passwordsDontMatch' })
          }
        });
      } else {
        this.setState(
          {
            isFetching: true
          },
          () => {
            fetchApi(
              'patch',
              '/v1/users/current/change_password',
              {},
              {
                old_password: dataToSubmit.currentPassword,
                new_password: dataToSubmit.newPassword
              },
              false,
              (response) => {
                const { data } = response;

                setUserToken(data.jwt_token, true);

                this.setState({
                  isChangePasswordOpened: false,
                  isFetching: false,
                  currentPassword: '',
                  newPassword: '',
                  confirmPassword: '',
                  errors: {}
                });

                enqueueSnackbar(intl.formatMessage({ id: 'changedPasswordSuccessMessage' }), {
                  variant: 'success',
                  autoHideDuration: 4000
                });
              },
              (error) => {
                const { response } = error;

                if (response.status === 400) {
                  this.setState({
                    isFetching: false,
                    errors: {
                      currentPassword: intl.formatMessage({
                        id: 'changedPasswordErrorMessage'
                      })
                    }
                  });

                  enqueueSnackbar(intl.formatMessage({ id: 'changedPasswordErrorMessage' }), {
                    variant: 'error',
                    autoHideDuration: 4000
                  });
                } else {
                  enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                    variant: 'error',
                    autoHideDuration: 4000
                  });

                  this.setState({
                    isFetching: false
                  });
                }
              }
            );
          }
        );
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        this.setState({
          errors: errorMessages
        });
      }
    }
  };

  openEditAccount = () => {
    this.setState(
      (state) => ({
        isEditAccountOpened: true,
        name: state.user.name,
        locale: state.user.locale,
        contact: state.user.contact || '',
        zipCode: state.user.zip_code || '',
        address: state.user.address || '',
        country: state.user.country ? state.user.country.id : '',
        ptIcnf: state.user.pt_icnf || '',
        ptNif: state.user.pt_nif || '',
        ptCc: state.user.pt_cc || '',
        errors: {}
      }),
      () => {
        this.nameInputRef.current.focus();
      }
    );
  };

  cancelEditAccount = () => {
    this.setState({
      isEditAccountOpened: false,
      name: '',
      locale: '',
      zipCode: '',
      address: '',
      country: '',
      contact: '',
      errors: {}
    });
  };

  handleEditAccount = () => {
    const { intl, enqueueSnackbar } = this.props;

    this.setState({ isFetching: true }, () => {
      // transform user name (remove spaces and capitalize first letter)
      const toUserName = (phrase) => {
        return phrase
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      };

      fetchApi(
        'put',
        '/breeders/v1/account',
        {},
        {
          name: toUserName(this.state.name.trim()),
          locale: this.state.locale,
          country_id: this.state.country,
          zip_code: this.state.zipCode ? this.state.zipCode.trim() : null,
          address: this.state.address ? this.state.address.trim() : null,
          contact: this.state.contact ? this.state.contact.trim() : null,
          pt_icnf: this.state.ptIcnf ? this.state.ptIcnf.trim() : null,
          pt_nif: this.state.ptNif ? this.state.ptNif.trim() : null,
          pt_cc: this.state.ptCc ? this.state.ptCc.trim() : null
        },
        false,
        (response) => {
          const { data } = response;

          this.setState(
            {
              isEditAccountOpened: false,
              isFetching: false,
              user: data
            },
            () => {
              this.props.setUserInformation(data);
              this.props.setAppLocale(data.locale || DEFAULT_LOCALE);
              setUserLanguage(data.locale || DEFAULT_LOCALE);
            }
          );

          enqueueSnackbar(intl.formatMessage({ id: 'changedPersonalInformationInfoMessage' }), {
            variant: 'success',
            autoHideDuration: 4000
          });
        },
        () => {
          this.setState({ isFetching: false });

          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        }
      );
    });
  };

  handleInputChange = (event) => {
    const input = event.target;
    this.setState({
      [input.name]: input.value,
      errors: {}
    });
  };

  handleSelectChange = (event) => {
    const input = event.target;

    this.setState({
      [input.name]: input.value,
      errors: {}
    });
  };

  handleAddBreederIdentifier = () => {
    const { intl, enqueueSnackbar } = this.props;

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'post',
          '/breeders/v1/account/identifiers',
          {},
          {
            identifier: this.state.breederIdentifier.trim().toUpperCase()
          },
          false,
          (response) => {
            const { data } = response;

            this.setState(
              (state) => {
                const breederIdentifiers = [...state.user.breeder_identifiers, data];

                return {
                  user: {
                    ...state.user,
                    breeder_identifiers: breederIdentifiers
                  },
                  isFetching: false,
                  isAddIdentifierOpened: false
                };
              },
              () => {
                this.props.setUserInformation(this.state.user);
              }
            );

            enqueueSnackbar(intl.formatMessage({ id: 'addBreederIdentifierSuccessMessage' }), {
              variant: 'success',
              autoHideDuration: 4000
            });
          },
          (error) => {
            const { response } = error;
            if (response.status === 409) {
              this.setState({
                isFetching: false,
                errors: {
                  breederIdentifier: intl.formatMessage({ id: 'breederIdentifierErrorMessage' })
                }
              });
            } else {
              this.setState({ isFetching: false, isAddIdentifierOpened: false });

              enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            }
          }
        );
      }
    );
  };

  openAddBreederIdentifier = () => {
    this.setState(
      {
        isAddIdentifierOpened: true,
        breederIdentifier: '',
        errors: {}
      },
      () => {
        this.breederIdentifierInputRef.current.focus();
      }
    );
  };

  cancelAddBreederIdentifier = () => {
    this.setState({
      isAddIdentifierOpened: false,
      breederIdentifier: '',
      errors: {}
    });
  };

  openEditBreederIdentifier = (breederIdentifierId, breederIdentifier) => {
    this.setState(
      {
        isEditIdentifierOpened: true,
        breederIdentifier: breederIdentifier,
        breederIdentifierId: breederIdentifierId,
        errors: {}
      },
      () => {
        this.breederEditIdentifierInputRef.current.focus();
      }
    );
  };

  handleEditBreederIdentifier = () => {
    const { intl, enqueueSnackbar } = this.props;

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'put',
          `/breeders/v1/account/identifiers/${this.state.breederIdentifierId}`,
          {},
          {
            identifier: this.state.breederIdentifier.trim().toUpperCase()
          },
          false,
          (response) => {
            const { data } = response;

            this.setState(
              (state) => {
                const breederIdentifiers = state.user.breeder_identifiers.map((item) => {
                  if (item.id === state.breederIdentifierId) {
                    return data;
                  }
                  return item;
                });

                return {
                  user: {
                    ...state.user,
                    breeder_identifiers: breederIdentifiers
                  },
                  isFetching: false,
                  isEditIdentifierOpened: false
                };
              },
              () => this.props.setUserInformation(this.state.user)
            );

            enqueueSnackbar(intl.formatMessage({ id: 'editBreederIdentifierSuccessMessage' }), {
              variant: 'success',
              autoHideDuration: 4000
            });
          },
          (error) => {
            const { response } = error;
            if (response.status === 409) {
              this.setState({
                isFetching: false,
                errors: {
                  breederIdentifier: intl.formatMessage({ id: 'breederIdentifierErrorMessage' })
                }
              });
            } else {
              this.setState({ isFetching: false, isEditIdentifierOpened: false });

              enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            }
          }
        );
      }
    );
  };

  cancelEditBreederIdentifier = () => {
    this.setState({
      isEditIdentifierOpened: false,
      breederIdentifier: '',
      breederIdentifierId: null,
      errors: {}
    });
  };

  handleSetAsDefaultBreederIdentifier = () => {
    const { intl, enqueueSnackbar } = this.props;

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'patch',
          `/breeders/v1/account/identifiers/${this.state.breederIdentifierId}`,
          {},
          {},
          false,
          (response) => {
            const { data } = response;

            this.setState(
              (state) => {
                const breederIdentifiers = state.user.breeder_identifiers.map((item) => {
                  if (item.id === state.breederIdentifierId) {
                    return data;
                  }
                  return {
                    ...item,
                    is_default: false
                  };
                });

                return {
                  user: {
                    ...state.user,
                    breeder_identifiers: breederIdentifiers
                  },
                  isFetching: false,
                  isSetAsDefaultIdentifierOpened: false
                };
              },
              () => this.props.setUserInformation(this.state.user)
            );

            enqueueSnackbar(
              intl.formatMessage({ id: 'setAsDefaultBreederIdentifierSuccessMessage' }),
              {
                variant: 'success',
                autoHideDuration: 4000
              }
            );
          },
          (error) => {
            const { response } = error;

            this.setState({ isFetching: false, isSetAsDefaultIdentifierOpened: false });

            // only 500 should ever happen
            if (response.status === 500) {
              enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            } else {
              enqueueSnackbar(
                intl.formatMessage({ id: 'setAsDefaultBreederIdentifierErrorMessage' }),
                {
                  variant: 'error',
                  autoHideDuration: 4000
                }
              );
            }
          }
        );
      }
    );
  };

  openSetAsDefaultBreederIdentifier = (breederIdentifierId) => {
    this.setState({
      isSetAsDefaultIdentifierOpened: true,
      breederIdentifierId: breederIdentifierId
    });
  };

  handleRemoveBreederIdentifier = () => {
    const { intl, enqueueSnackbar } = this.props;

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'delete',
          `/breeders/v1/account/identifiers/${this.state.breederIdentifierId}`,
          {},
          {},
          false,
          () => {
            this.setState(
              (state) => {
                const breederIdentifiers = state.user.breeder_identifiers.filter(
                  (item) => item.id !== state.breederIdentifierId
                );

                if (breederIdentifiers.length > 0) {
                  breederIdentifiers[0].is_default = true;
                }

                return {
                  user: {
                    ...state.user,
                    breeder_identifiers: breederIdentifiers
                  },
                  isFetching: false,
                  isRemoveIdentifierOpened: false
                };
              },
              () => this.props.setUserInformation(this.state.user)
            );

            enqueueSnackbar(intl.formatMessage({ id: 'removeBreederIdentifierSuccessMessage' }), {
              variant: 'success',
              autoHideDuration: 4000
            });
          },
          (error) => {
            const { response } = error;

            this.setState({ isFetching: false, isRemoveIdentifierOpened: false });

            // only 500 should ever happen
            if (response.status === 500) {
              enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            } else {
              enqueueSnackbar(
                intl.formatMessage({ id: 'setAsDefaultBreederIdentifierErrorMessage' }),
                {
                  variant: 'error',
                  autoHideDuration: 4000
                }
              );
            }
          }
        );
      }
    );
  };

  openRemoveBreederIdentifier = (breederIdentifierId) => {
    this.setState({
      isRemoveIdentifierOpened: true,
      breederIdentifierId: breederIdentifierId
    });
  };

  openCloseAccount = () => {
    this.setState({
      isCloseAccountOpened: true
    });
  };

  cancelCloseAccount = () => {
    this.setState({
      isCloseAccountOpened: false
    });
  };

  handleCloseAccount = () => {
    const { intl, enqueueSnackbar } = this.props;

    this.setState(
      {
        isFetching: true
      },
      () => {
        // when email was already sent
        if (this.state.isConfirmationCodeOpened && this.state.closeAccountGeneratedCode) {
          fetchApi(
            'delete',
            '/breeders/v1/account/close',
            {},
            {
              confirmation_code: this.state.confirmationCode, // sent to user's email
              generated_code: this.state.closeAccountGeneratedCode // received when user said that wanted to close account
            },
            false,
            () => {
              clearStorage();
              setTimeout(() => {
                window.location.reload();
              }, 500);
            },
            () => {
              this.setState({
                isFetching: false,
                isCloseAccountOpened: false,
                isConfirmationCodeOpened: false,
                closeAccountGeneratedCode: ''
              });

              enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                variant: 'error'
              });
            }
          );
        } else {
          // send email
          fetchApi(
            'post',
            '/breeders/v1/account/close',
            {},
            {},
            false,
            (response) => {
              const { data } = response;

              this.setState({
                isFetching: false,
                isConfirmationCodeOpened: true,
                closeAccountGeneratedCode: data.code
              });
            },
            () => {
              this.setState({
                isFetching: false,
                isCloseAccountOpened: false,
                isConfirmationCodeOpened: false,
                closeAccountGeneratedCode: ''
              });

              enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 3000
              });
            }
          );
        }
      }
    );
  };

  languageName = (locale) => {
    switch (locale) {
      case 'pt_PT':
        return 'Português';
      case 'es_ES':
        return 'Español';
      case 'fr_FR':
        return 'Français';
      case 'it_IT':
        return 'Italiano';
      default:
        return 'English';
    }
  };

  render() {
    const { intl } = this.props;

    const buttons = this.state.isBreeder
      ? [
          {
            label: intl.formatMessage({ id: 'edit' }),
            disabled: false,
            color: 'primary',
            variant: 'contained',
            onClick: this.openEditAccount
          },
          {
            label: intl.formatMessage({ id: 'changePassword' }),
            disabled: false,
            color: 'primary',
            variant: 'outlined',
            onClick: this.openChangePassword
          },
          {
            label: intl.formatMessage({ id: 'closeAccount' }),
            disabled: false,
            color: 'red',
            variant: 'contained',
            onClick: this.openCloseAccount
          }
        ]
      : [
          {
            label: intl.formatMessage({ id: 'changePassword' }),
            disabled: false,
            color: 'primary',
            variant: 'outlined',
            onClick: this.openChangePassword
          }
        ];

    return (
      <Content
        isLoading={this.state.isLoading}
        isFetching={this.state.isFetching}
        buttons={buttons}
      >
        <FormDialog
          isOpened={this.state.isCloseAccountOpened}
          title={intl.formatMessage({ id: 'closeAccount' })}
          maxWidth={'sm'}
          onConfirm={this.handleCloseAccount}
          onCancel={this.cancelCloseAccount}
          disabled={this.state.isLoading || this.state.isFetching}
          onConfirmDisabled={this.state.isFetching}
          onCancelDisabled={this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant={'body1'} color={'textSecondary'}>
                {intl.formatMessage({ id: 'closeAccountInformation' })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} color={'textSecondary'} style={{ fontWeight: 'bold' }}>
                {intl.formatMessage({ id: 'closeAccountKeepBirds' })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} color={'textSecondary'}>
                {intl.formatMessage({ id: 'closeAccountEmailWillBeSent' })}
              </Typography>
            </Grid>
            {this.state.isConfirmationCodeOpened && (
              <Grid item xs={12}>
                <Input
                  name={'confirmationCode'}
                  type={'text'}
                  label={intl.formatMessage({ id: 'confirmationCode' })}
                  onChange={this.handleInputChange}
                  value={this.state.confirmationCode}
                  required={true}
                  disabled={this.state.isFetching}
                  inputProps={{
                    maxLength: 4
                  }}
                />
              </Grid>
            )}
          </Grid>
        </FormDialog>
        <ConfirmationDialog
          isOpened={this.state.isRemoveIdentifierOpened}
          title={intl.formatMessage({ id: 'closeAccount' })}
          maxWidth={'sm'}
          message={intl.formatMessage({ id: 'removeBreederIdentifierQuestion' })}
          onConfirm={this.handleRemoveBreederIdentifier}
          onCancel={() => {
            this.setState({
              isRemoveIdentifierOpened: false
            });
          }}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          disabled={this.state.isLoading || this.state.isFetching}
        />
        <ConfirmationDialog
          isOpened={this.state.isRemoveIdentifierOpened}
          title={intl.formatMessage({ id: 'removeBreederIdentifier' })}
          maxWidth={'sm'}
          message={intl.formatMessage({ id: 'removeBreederIdentifierQuestion' })}
          onConfirm={this.handleRemoveBreederIdentifier}
          onCancel={() => {
            this.setState({
              isRemoveIdentifierOpened: false
            });
          }}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          disabled={this.state.isLoading || this.state.isFetching}
        />
        <ConfirmationDialog
          isOpened={this.state.isSetAsDefaultIdentifierOpened}
          title={intl.formatMessage({ id: 'setAsDefault' })}
          maxWidth={'sm'}
          message={intl.formatMessage({ id: 'setAsDefaultQuestion' })}
          onConfirm={this.handleSetAsDefaultBreederIdentifier}
          onCancel={() => {
            this.setState({
              isSetAsDefaultIdentifierOpened: false
            });
          }}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          disabled={this.state.isLoading || this.state.isFetching}
        />
        <FormDialog
          isOpened={this.state.isEditIdentifierOpened}
          title={intl.formatMessage({ id: 'editBreederIdentifier' })}
          maxWidth={'sm'}
          onConfirm={this.handleEditBreederIdentifier}
          onCancel={this.cancelEditBreederIdentifier}
          onConfirmDisabled={this.state.isFetching}
          onCancelDisabled={this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <Grid item xs={12}>
            <Input
              inputRef={this.breederEditIdentifierInputRef}
              name={'breederIdentifier'}
              type={'text'}
              label={intl.formatMessage({ id: 'breederIdentifier' })}
              value={this.state.breederIdentifier}
              onChange={this.handleInputChange}
              error={!!this.state.errors.breederIdentifier}
              helperText={this.state.errors.breederIdentifier || ''}
              required={true}
              disabled={this.state.isFetching}
              placeholder={'Z123'}
              inputProps={{ maxLength: 20 }}
              autoComplete={'off'}
            />
          </Grid>
        </FormDialog>
        <FormDialog
          isOpened={this.state.isAddIdentifierOpened}
          title={intl.formatMessage({ id: 'addBreederIdentifier' })}
          maxWidth={'sm'}
          onConfirm={this.handleAddBreederIdentifier}
          onCancel={this.cancelAddBreederIdentifier}
          onConfirmDisabled={this.state.isFetching}
          onCancelDisabled={this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'on'}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant={'body1'} color={'textSecondary'}>
                {intl.formatMessage({ id: 'identifierDescription' })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Input
                inputRef={this.breederIdentifierInputRef}
                name={'breederIdentifier'}
                type={'text'}
                label={intl.formatMessage({ id: 'breederIdentifier' })}
                value={this.state.breederIdentifier}
                onChange={this.handleInputChange}
                error={!!this.state.errors.breederIdentifier}
                helperText={this.state.errors.breederIdentifier || ''}
                required={true}
                disabled={this.state.isFetching}
                inputProps={{ maxLength: 20 }}
                placeholder={'Z123'}
              />
            </Grid>
          </Grid>
        </FormDialog>
        <FormDialog
          isOpened={this.state.isChangePasswordOpened}
          title={intl.formatMessage({ id: 'changePassword' })}
          maxWidth={'sm'}
          onConfirm={this.handleChangePassword}
          onCancel={this.cancelChangePassword}
          onConfirmDisabled={this.state.isFetching}
          onCancelDisabled={this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'on'}
        >
          <ChangePassword
            isLoading={this.state.isLoading || this.state.isFetching}
            currentPasswordInputRef={this.currentPasswordInputRef}
            currentPassword={this.state.currentPassword}
            newPassword={this.state.newPassword}
            confirmPassword={this.state.confirmPassword}
            errors={this.state.errors}
            handleInputChange={this.handleInputChange}
          />
        </FormDialog>
        <FormDialog
          isOpened={this.state.isEditAccountOpened}
          title={intl.formatMessage({ id: 'editAccount' })}
          maxWidth={'sm'}
          onConfirm={this.handleEditAccount}
          onCancel={this.cancelEditAccount}
          onConfirmDisabled={this.state.isFetching}
          onCancelDisabled={this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <EditAccount
            isLoading={this.state.isLoading || this.state.isFetching}
            nameInputRef={this.nameInputRef}
            name={this.state.name}
            locale={this.state.locale}
            contact={this.state.contact ? this.state.contact : ''}
            breederCode={this.state.breederCode ? this.state.breederCode : ''}
            address={this.state.address ? this.state.address : ''}
            zipCode={this.state.zipCode ? this.state.zipCode : ''}
            country={this.state.country ? this.state.country : ''}
            ptIcnf={this.state.ptIcnf ? this.state.ptIcnf : ''}
            ptNif={this.state.ptNif ? this.state.ptNif : ''}
            ptCc={this.state.ptCc ? this.state.ptCc : ''}
            allCountries={getCountries(this.state.locale)}
            errors={this.state.errors}
            handleInputChange={this.handleInputChange}
            handleSelectChange={this.handleSelectChange}
          />
        </FormDialog>
        {!this.state.isLoading && (
          <Grid container spacing={2}>
            <Grid item xs={12} lg={10}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <PaperListInformation
                    items={[
                      { title: intl.formatMessage({ id: 'name' }), value: this.state.user.name },
                      {
                        title: intl.formatMessage({ id: 'email' }),
                        value: this.state.user.email
                      },
                      {
                        title: intl.formatMessage({ id: 'contact' }),
                        value: this.state.user.contact ? this.state.user.contact : '-'
                      },
                      {
                        title: intl.formatMessage({ id: 'plan' }),
                        value: this.state.user.plan
                          ? intl.formatMessage({ id: this.state.user.plan.toLowerCase() })
                          : '-'
                      },
                      {
                        title: intl.formatMessage({ id: 'language' }),
                        value: this.languageName(this.state.user.locale)
                      },
                      {
                        title: intl.formatMessage({ id: 'registeredAt' }),
                        value: fromUTCtoLocal(this.state.user.created_at).format('LL')
                      }
                    ]}
                  />
                </Grid>
                {this.state.isBreeder && (
                  <Grid item xs={12} md={6}>
                    <BreederInformation
                      countryId={this.state.user.country ? this.state.user.country.id : null}
                      country={
                        this.state.user.country
                          ? getCountryName(this.state.user.country.id, this.state.locale)
                          : '-'
                      }
                      address={this.state.user.address || '-'}
                      zipCode={this.state.user.zip_code || '-'}
                      ptIcnf={this.state.user.pt_icnf || '-'}
                      ptNif={this.state.user.pt_nif || '-'}
                      ptCc={this.state.user.pt_cc || '-'}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {this.state.isBreeder && (
              <Grid item xs={12}>
                <Paper title={intl.formatMessage({ id: 'breederIdentifiers' })}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} />
                    <Grid item xs={12}>
                      <Button
                        type={'button'}
                        color={'primary'}
                        label={intl.formatMessage({ id: 'addBreederIdentifier' })}
                        variant={'contained'}
                        disabled={this.state.isFetching}
                        onClick={this.openAddBreederIdentifier}
                        disableElevation={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={'body1'} color={'textSecondary'}>
                        {`${intl.formatMessage({
                          id: 'identifierDescription'
                        })} ${intl.formatMessage({ id: 'identifierDescription2' })}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Table
                        webHeaders={[
                          { title: intl.formatMessage({ id: 'identifier' }), align: 'left' },
                          {
                            title: intl.formatMessage({ id: 'isDefault' }),
                            align: 'left'
                          },
                          { title: '', align: 'right' }
                        ]}
                        webData={this.state.user.breeder_identifiers.map((item) => [
                          item.identifier,
                          item.is_default ? intl.formatMessage({ id: 'yes' }) : '',
                          <div>
                            <TooltipIconButton
                              title={'edit'}
                              iconName={'edit'}
                              handleClick={() => {
                                this.openEditBreederIdentifier(item.id, item.identifier);
                              }}
                            />
                            <TooltipIconButton
                              title={'remove'}
                              iconName={'trash'}
                              handleClick={() => {
                                this.openRemoveBreederIdentifier(item.id);
                              }}
                            />
                            <TooltipIconButton
                              title={'setAsDefault'}
                              iconName={'more-horizontal'}
                              handleClick={() => {
                                this.openSetAsDefaultBreederIdentifier(item.id);
                              }}
                            />
                          </div>
                        ])}
                        noDataMessage={intl.formatMessage({ id: 'noIdentifiers' })}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer,
  user: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  },
  setUserInformation: (user) => {
    dispatch(setUserInformation(user));
  },
  setAppLocale: (locale) => {
    dispatch(setAppLocale(locale));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(injectIntl(Account)));
