// UTILS
import { getColors } from '../../../../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    border: `1px solid ${colors.border}`,
    borderRadius: '6px',
    backgroundColor: theme.palette.common.white
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    marginLeft: theme.spacing(1)
  },
  innerContainer: {
    width: '100%'
  },
  startedAtContainer: {
    maxWidth: '320px'
  },
  startedAtText: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textTransform: 'uppercase',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  countText: {
    color: theme.palette.secondary.dark,
    fontWeight: 500
  },
  hasFinishedText: {
    color: theme.palette.common.black,
    fontWeight: 500
  }
});
