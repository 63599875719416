export default [
  // TOTAL of 241 countries
  {
    id: 1,
    name: 'Afganistán',
    code: 'AF'
  },
  {
    id: 3,
    name: 'Albania',
    code: 'AL'
  },
  {
    id: 81,
    name: 'Alemania',
    code: 'DE'
  },
  {
    id: 6,
    name: 'Andorra',
    code: 'AD'
  },
  {
    id: 7,
    name: 'Angola',
    code: 'AO'
  },
  {
    id: 8,
    name: 'Anguila',
    code: 'AI'
  },
  {
    id: 10,
    name: 'Antigua y Barbuda',
    code: 'AG'
  },
  {
    id: 9,
    name: 'Antártida',
    code: 'AQ'
  },
  {
    id: 191,
    name: 'Arabia Saudita',
    code: 'SA'
  },
  {
    id: 4,
    name: 'Argelia',
    code: 'DZ'
  },
  {
    id: 11,
    name: 'Argentina',
    code: 'AR'
  },
  {
    id: 12,
    name: 'Armenia',
    code: 'AM'
  },
  {
    id: 13,
    name: 'Aruba',
    code: 'AW'
  },
  {
    id: 14,
    name: 'Australia',
    code: 'AU'
  },
  {
    id: 15,
    name: 'Austria',
    code: 'AT'
  },
  {
    id: 16,
    name: 'Azerbaiyán',
    code: 'AZ'
  },
  {
    id: 17,
    name: 'Bahamas',
    code: 'BS'
  },
  {
    id: 19,
    name: 'Bangladés',
    code: 'BD'
  },
  {
    id: 20,
    name: 'Barbados',
    code: 'BB'
  },
  {
    id: 18,
    name: 'Baréin',
    code: 'BH'
  },
  {
    id: 23,
    name: 'Belice',
    code: 'BZ'
  },
  {
    id: 24,
    name: 'Benín',
    code: 'BJ'
  },
  {
    id: 25,
    name: 'Bermudas',
    code: 'BM'
  },
  {
    id: 21,
    name: 'Bielorrusia',
    code: 'BY'
  },
  {
    id: 150,
    name: 'Birmania',
    code: 'MM'
  },
  {
    id: 27,
    name: 'Bolivia',
    code: 'BO'
  },
  {
    id: 28,
    name: 'Bosnia y Herzegovina',
    code: 'BA'
  },
  {
    id: 29,
    name: 'Botsuana',
    code: 'BW'
  },
  {
    id: 31,
    name: 'Brasil',
    code: 'BR'
  },
  {
    id: 33,
    name: 'Brunéi',
    code: 'BN'
  },
  {
    id: 34,
    name: 'Bulgaria',
    code: 'BG'
  },
  {
    id: 35,
    name: 'Burkina Faso',
    code: 'BF'
  },
  {
    id: 36,
    name: 'Burundi',
    code: 'BI'
  },
  {
    id: 26,
    name: 'Bután',
    code: 'BT'
  },
  {
    id: 22,
    name: 'Bélgica',
    code: 'BE'
  },
  {
    id: 40,
    name: 'Cabo Verde',
    code: 'CV'
  },
  {
    id: 37,
    name: 'Camboya',
    code: 'KH'
  },
  {
    id: 38,
    name: 'Camerún',
    code: 'CM'
  },
  {
    id: 39,
    name: 'Canadá',
    code: 'CA'
  },
  {
    id: 178,
    name: 'Catar',
    code: 'QA'
  },
  {
    id: 43,
    name: 'Chad',
    code: 'TD'
  },
  {
    id: 44,
    name: 'Chile',
    code: 'CL'
  },
  {
    id: 45,
    name: 'China',
    code: 'CN'
  },
  {
    id: 57,
    name: 'Chipre',
    code: 'CY'
  },
  {
    id: 48,
    name: 'Colombia',
    code: 'CO'
  },
  {
    id: 49,
    name: 'Comoras',
    code: 'KM'
  },
  {
    id: 116,
    name: 'Corea del Norte',
    code: 'KP'
  },
  {
    id: 117,
    name: 'Corea del Sur',
    code: 'KR'
  },
  {
    id: 53,
    name: 'Costa Rica',
    code: 'CR'
  },
  {
    id: 54,
    name: 'Costa de Marfil',
    code: 'CI'
  },
  {
    id: 55,
    name: 'Croacia',
    code: 'HR'
  },
  {
    id: 56,
    name: 'Cuba',
    code: 'CU'
  },
  {
    id: 59,
    name: 'Dinamarca',
    code: 'DK'
  },
  {
    id: 61,
    name: 'Dominica',
    code: 'DM'
  },
  {
    id: 63,
    name: 'Ecuador',
    code: 'EC'
  },
  {
    id: 64,
    name: 'Egipto',
    code: 'EG'
  },
  {
    id: 65,
    name: 'El Salvador',
    code: 'SV'
  },
  {
    id: 228,
    name: 'Emiratos Árabes Unidos',
    code: 'AE'
  },
  {
    id: 67,
    name: 'Eritrea',
    code: 'ER'
  },
  {
    id: 197,
    name: 'Eslovaquia',
    code: 'SK'
  },
  {
    id: 198,
    name: 'Eslovenia',
    code: 'SI'
  },
  {
    id: 203,
    name: 'España',
    code: 'ES'
  },
  {
    id: 230,
    name: 'Estados Unidos',
    code: 'US'
  },
  {
    id: 68,
    name: 'Estonia',
    code: 'EE'
  },
  {
    id: 69,
    name: 'Etiopía',
    code: 'ET'
  },
  {
    id: 173,
    name: 'Filipinas',
    code: 'PH'
  },
  {
    id: 73,
    name: 'Finlandia',
    code: 'FI'
  },
  {
    id: 72,
    name: 'Fiyi',
    code: 'FJ'
  },
  {
    id: 74,
    name: 'Francia',
    code: 'FR'
  },
  {
    id: 78,
    name: 'Gabón',
    code: 'GA'
  },
  {
    id: 79,
    name: 'Gambia',
    code: 'GM'
  },
  {
    id: 80,
    name: 'Georgia',
    code: 'GE'
  },
  {
    id: 82,
    name: 'Ghana',
    code: 'GH'
  },
  {
    id: 83,
    name: 'Gibraltar',
    code: 'GI'
  },
  {
    id: 86,
    name: 'Granada',
    code: 'GD'
  },
  {
    id: 84,
    name: 'Grecia',
    code: 'GR'
  },
  {
    id: 85,
    name: 'Groenlandia',
    code: 'GL'
  },
  {
    id: 87,
    name: 'Guadalupe',
    code: 'GP'
  },
  {
    id: 88,
    name: 'Guam',
    code: 'GU'
  },
  {
    id: 89,
    name: 'Guatemala',
    code: 'GT'
  },
  {
    id: 75,
    name: 'Guayana Francesa',
    code: 'GF'
  },
  {
    id: 90,
    name: 'Guernsey',
    code: 'GG'
  },
  {
    id: 91,
    name: 'Guinea',
    code: 'GN'
  },
  {
    id: 66,
    name: 'Guinea Ecuatorial',
    code: 'GQ'
  },
  {
    id: 92,
    name: 'Guinea-Bisáu',
    code: 'GW'
  },
  {
    id: 93,
    name: 'Guyana',
    code: 'GY'
  },
  {
    id: 94,
    name: 'Haití',
    code: 'HT'
  },
  {
    id: 97,
    name: 'Honduras',
    code: 'HN'
  },
  {
    id: 98,
    name: 'Hong Kong',
    code: 'HK'
  },
  {
    id: 99,
    name: 'Hungría',
    code: 'HU'
  },
  {
    id: 101,
    name: 'India',
    code: 'IN'
  },
  {
    id: 102,
    name: 'Indonesia',
    code: 'ID'
  },
  {
    id: 104,
    name: 'Irak',
    code: 'IQ'
  },
  {
    id: 105,
    name: 'Irlanda',
    code: 'IE'
  },
  {
    id: 103,
    name: 'Irán',
    code: 'IR'
  },
  {
    id: 30,
    name: 'Isla Bouvet',
    code: 'BV'
  },
  {
    id: 162,
    name: 'Isla Norfolk',
    code: 'NF'
  },
  {
    id: 106,
    name: 'Isla de Man',
    code: 'IM'
  },
  {
    id: 46,
    name: 'Isla de Navidad',
    code: 'CX'
  },
  {
    id: 100,
    name: 'Islandia',
    code: 'IS'
  },
  {
    id: 41,
    name: 'Islas Caimán',
    code: 'KY'
  },
  {
    id: 47,
    name: 'Islas Cocos',
    code: 'CC'
  },
  {
    id: 52,
    name: 'Islas Cook',
    code: 'CK'
  },
  {
    id: 71,
    name: 'Islas Feroe',
    code: 'FO'
  },
  {
    id: 202,
    name: 'Islas Georgias del Sur y Sandwich del Sur',
    code: 'GS'
  },
  {
    id: 95,
    name: 'Islas Heard y McDonald',
    code: 'HM'
  },
  {
    id: 70,
    name: 'Islas Malvinas',
    code: 'FK'
  },
  {
    id: 163,
    name: 'Islas Marianas del Norte',
    code: 'MP'
  },
  {
    id: 137,
    name: 'Islas Marshall',
    code: 'MH'
  },
  {
    id: 174,
    name: 'Islas Pitcairn',
    code: 'PN'
  },
  {
    id: 199,
    name: 'Islas Salomón',
    code: 'SB'
  },
  {
    id: 224,
    name: 'Islas Turcas y Caicos',
    code: 'TC'
  },
  {
    id: 231,
    name: 'Islas Ultramarinas Menores de los Estados Unidos',
    code: 'UM'
  },
  {
    id: 237,
    name: 'Islas Vírgenes Británicas',
    code: 'VG'
  },
  {
    id: 238,
    name: 'Islas Vírgenes de los Estados Unidos',
    code: 'VI'
  },
  {
    id: 107,
    name: 'Israel',
    code: 'IL'
  },
  {
    id: 108,
    name: 'Italia',
    code: 'IT'
  },
  {
    id: 109,
    name: 'Jamaica',
    code: 'JM'
  },
  {
    id: 110,
    name: 'Japón',
    code: 'JP'
  },
  {
    id: 111,
    name: 'Jersey',
    code: 'JE'
  },
  {
    id: 112,
    name: 'Jordania',
    code: 'JO'
  },
  {
    id: 113,
    name: 'Kazajistán',
    code: 'KZ'
  },
  {
    id: 114,
    name: 'Kenia',
    code: 'KE'
  },
  {
    id: 119,
    name: 'Kirguistán',
    code: 'KG'
  },
  {
    id: 115,
    name: 'Kiribati',
    code: 'KI'
  },
  {
    id: 118,
    name: 'Kuwait',
    code: 'KW'
  },
  {
    id: 120,
    name: 'Laos',
    code: 'LA'
  },
  {
    id: 123,
    name: 'Lesoto',
    code: 'LS'
  },
  {
    id: 121,
    name: 'Letonia',
    code: 'LV'
  },
  {
    id: 124,
    name: 'Liberia',
    code: 'LR'
  },
  {
    id: 125,
    name: 'Libia',
    code: 'LY'
  },
  {
    id: 126,
    name: 'Liechtenstein',
    code: 'LI'
  },
  {
    id: 127,
    name: 'Lituania',
    code: 'LT'
  },
  {
    id: 128,
    name: 'Luxemburgo',
    code: 'LU'
  },
  {
    id: 122,
    name: 'Líbano',
    code: 'LB'
  },
  {
    id: 129,
    name: 'Macao',
    code: 'MO'
  },
  {
    id: 130,
    name: 'Macedonia del Norte',
    code: 'MK'
  },
  {
    id: 131,
    name: 'Madagascar',
    code: 'MG'
  },
  {
    id: 133,
    name: 'Malasia',
    code: 'MY'
  },
  {
    id: 132,
    name: 'Malaui',
    code: 'MW'
  },
  {
    id: 134,
    name: 'Maldivas',
    code: 'MV'
  },
  {
    id: 136,
    name: 'Malta',
    code: 'MT'
  },
  {
    id: 135,
    name: 'Malí',
    code: 'ML'
  },
  {
    id: 148,
    name: 'Marruecos',
    code: 'MA'
  },
  {
    id: 138,
    name: 'Martinica',
    code: 'MQ'
  },
  {
    id: 140,
    name: 'Mauricio',
    code: 'MU'
  },
  {
    id: 139,
    name: 'Mauritania',
    code: 'MR'
  },
  {
    id: 141,
    name: 'Mayotte',
    code: 'YT'
  },
  {
    id: 143,
    name: 'Micronesia',
    code: 'FM'
  },
  {
    id: 144,
    name: 'Moldavia',
    code: 'MD'
  },
  {
    id: 146,
    name: 'Mongolia',
    code: 'MN'
  },
  {
    id: 147,
    name: 'Montserrat',
    code: 'MS'
  },
  {
    id: 149,
    name: 'Mozambique',
    code: 'MZ'
  },
  {
    id: 142,
    name: 'México',
    code: 'MX'
  },
  {
    id: 145,
    name: 'Mónaco',
    code: 'MC'
  },
  {
    id: 151,
    name: 'Namibia',
    code: 'NA'
  },
  {
    id: 152,
    name: 'Nauru',
    code: 'NR'
  },
  {
    id: 153,
    name: 'Nepal',
    code: 'NP'
  },
  {
    id: 158,
    name: 'Nicaragua',
    code: 'NI'
  },
  {
    id: 160,
    name: 'Nigeria',
    code: 'NG'
  },
  {
    id: 161,
    name: 'Niue',
    code: 'NU'
  },
  {
    id: 164,
    name: 'Noruega',
    code: 'NO'
  },
  {
    id: 156,
    name: 'Nueva Caledonia',
    code: 'NC'
  },
  {
    id: 157,
    name: 'Nueva Zelanda',
    code: 'NZ'
  },
  {
    id: 159,
    name: 'Níger',
    code: 'NE'
  },
  {
    id: 165,
    name: 'Omán',
    code: 'OM'
  },
  {
    id: 166,
    name: 'Pakistán',
    code: 'PK'
  },
  {
    id: 167,
    name: 'Palaos',
    code: 'PW'
  },
  {
    id: 168,
    name: 'Palestina',
    code: 'PS'
  },
  {
    id: 169,
    name: 'Panamá',
    code: 'PA'
  },
  {
    id: 170,
    name: 'Papúa Nueva Guinea',
    code: 'PG'
  },
  {
    id: 171,
    name: 'Paraguay',
    code: 'PY'
  },
  {
    id: 154,
    name: 'Países Bajos',
    code: 'NL'
  },
  {
    id: 172,
    name: 'Perú',
    code: 'PE'
  },
  {
    id: 76,
    name: 'Polinesia Francesa',
    code: 'PF'
  },
  {
    id: 175,
    name: 'Polonia',
    code: 'PL'
  },
  {
    id: 176,
    name: 'Portugal',
    code: 'PT'
  },
  {
    id: 177,
    name: 'Puerto Rico',
    code: 'PR'
  },
  {
    id: 229,
    name: 'Reino Unido',
    code: 'GB'
  },
  {
    id: 42,
    name: 'República Centroafricana',
    code: 'CF'
  },
  {
    id: 58,
    name: 'República Checa',
    code: 'CZ'
  },
  {
    id: 51,
    name: 'República Democrática del Congo',
    code: 'CD'
  },
  {
    id: 62,
    name: 'República Dominicana',
    code: 'DO'
  },
  {
    id: 50,
    name: 'República del Congo',
    code: 'CG'
  },
  {
    id: 240,
    name: 'República Árabe Saharaui Democrática',
    code: 'EH'
  },
  {
    id: 179,
    name: 'Reunión',
    code: 'RE'
  },
  {
    id: 182,
    name: 'Ruanda',
    code: 'RW'
  },
  {
    id: 180,
    name: 'Rumania',
    code: 'RO'
  },
  {
    id: 181,
    name: 'Rusia',
    code: 'RU'
  },
  {
    id: 188,
    name: 'Samoa',
    code: 'WS'
  },
  {
    id: 5,
    name: 'Samoa Americana',
    code: 'AS'
  },
  {
    id: 184,
    name: 'San Cristóbal y Nieves',
    code: 'KN'
  },
  {
    id: 189,
    name: 'San Marino',
    code: 'SM'
  },
  {
    id: 186,
    name: 'San Pedro y Miquelón',
    code: 'PM'
  },
  {
    id: 187,
    name: 'San Vicente y las Granadinas',
    code: 'VC'
  },
  {
    id: 183,
    name: 'Santa Elena, Ascensión y Tristán de Acuña',
    code: 'SH'
  },
  {
    id: 185,
    name: 'Santa Lucía',
    code: 'LC'
  },
  {
    id: 190,
    name: 'Santo Tomé y Príncipe',
    code: 'ST'
  },
  {
    id: 192,
    name: 'Senegal',
    code: 'SN'
  },
  {
    id: 194,
    name: 'Seychelles',
    code: 'SC'
  },
  {
    id: 195,
    name: 'Sierra Leona',
    code: 'SL'
  },
  {
    id: 196,
    name: 'Singapur',
    code: 'SG'
  },
  {
    id: 211,
    name: 'Siria',
    code: 'SY'
  },
  {
    id: 200,
    name: 'Somalia',
    code: 'SO'
  },
  {
    id: 204,
    name: 'Sri Lanka',
    code: 'LK'
  },
  {
    id: 208,
    name: 'Suazilandia',
    code: 'SZ'
  },
  {
    id: 201,
    name: 'Sudáfrica',
    code: 'ZA'
  },
  {
    id: 205,
    name: 'Sudán',
    code: 'SD'
  },
  {
    id: 209,
    name: 'Suecia',
    code: 'SE'
  },
  {
    id: 210,
    name: 'Suiza',
    code: 'CH'
  },
  {
    id: 206,
    name: 'Surinam',
    code: 'SR'
  },
  {
    id: 207,
    name: 'Svalbard y Jan Mayen',
    code: 'SJ'
  },
  {
    id: 215,
    name: 'Tailandia',
    code: 'TH'
  },
  {
    id: 212,
    name: 'Taiwán (República de China)',
    code: 'TW'
  },
  {
    id: 214,
    name: 'Tanzania',
    code: 'TZ'
  },
  {
    id: 213,
    name: 'Tayikistán',
    code: 'TJ'
  },
  {
    id: 32,
    name: 'Territorio Británico del Océano Índico',
    code: 'IO'
  },
  {
    id: 77,
    name: 'Tierras Australes y Antárticas Francesas',
    code: 'TF'
  },
  {
    id: 216,
    name: 'Timor Oriental',
    code: 'TL'
  },
  {
    id: 217,
    name: 'Togo',
    code: 'TG'
  },
  {
    id: 218,
    name: 'Tokelau',
    code: 'TK'
  },
  {
    id: 219,
    name: 'Tonga',
    code: 'TO'
  },
  {
    id: 220,
    name: 'Trinidad y Tobago',
    code: 'TT'
  },
  {
    id: 223,
    name: 'Turkmenistán',
    code: 'TM'
  },
  {
    id: 222,
    name: 'Turquía',
    code: 'TR'
  },
  {
    id: 225,
    name: 'Tuvalu',
    code: 'TV'
  },
  {
    id: 221,
    name: 'Túnez',
    code: 'TN'
  },
  {
    id: 227,
    name: 'Ucrania',
    code: 'UA'
  },
  {
    id: 226,
    name: 'Uganda',
    code: 'UG'
  },
  {
    id: 232,
    name: 'Uruguay',
    code: 'UY'
  },
  {
    id: 233,
    name: 'Uzbekistán',
    code: 'UZ'
  },
  {
    id: 234,
    name: 'Vanuatu',
    code: 'VU'
  },
  {
    id: 96,
    name: 'Vaticano, Ciudad del',
    code: 'VA'
  },
  {
    id: 235,
    name: 'Venezuela',
    code: 'VE'
  },
  {
    id: 236,
    name: 'Vietnam',
    code: 'VN'
  },
  {
    id: 239,
    name: 'Wallis y Futuna',
    code: 'WF'
  },
  {
    id: 241,
    name: 'Yemen',
    code: 'YE'
  },
  {
    id: 60,
    name: 'Yibuti',
    code: 'DJ'
  },
  {
    id: 242,
    name: 'Zambia',
    code: 'ZM'
  },
  {
    id: 243,
    name: 'Zimbabue',
    code: 'ZW'
  },
  {
    id: 2,
    name: 'Åland',
    code: 'AX'
  }
];
