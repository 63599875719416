import React from 'react';
import PropTypes from 'prop-types';

// REACT REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

// REACT ROUTER
import { withRouter } from 'react-router-dom';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import { setAppLocale } from '../../../../../../store/actions/appActions';
import PublicLanguageBar from './components/PublicLanguageBar';
import PublicAppBar from './components/PublicAppBar';
import PublicFooter from './components/PublicFooter';
import styles from './styles';

class PublicAppContainer extends React.PureComponent {
  componentDidMount() {
    const browserLanguage = window.navigator.language;

    if (browserLanguage.includes('pt')) {
      this.props.setAppLocale('pt_PT');
    } else if (browserLanguage.includes('es')) {
      this.props.setAppLocale('es_ES');
    } else if (browserLanguage.includes('fr')) {
      this.props.setAppLocale('fr_FR');
    } else if (browserLanguage.includes('it')) {
      this.props.setAppLocale('it_IT');
    } else {
      this.props.setAppLocale('en_GB');
    }
  }

  render() {
    const { classes } = this.props;

    const maxWidth = 'lg';

    return (
      <main className={classes.content}>
        <div className={classes.innerContainer}>
          <PublicLanguageBar maxWidth={maxWidth} />
          <PublicAppBar maxWidth={maxWidth} />
          <div className={classes.contentContainer}>{this.props.children}</div>
        </div>
        <div className={classes.footerContainer}>
          <Container maxWidth={maxWidth}>
            <PublicFooter />
          </Container>
        </div>
      </main>
    );
  }
}

PublicAppContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setAppLocale: (locale) => {
    dispatch(setAppLocale(locale));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(withRouter(PublicAppContainer))));
