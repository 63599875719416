import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// REACT ROUTER DOM
import { Link } from 'react-router-dom';

// APP IMPORTS
import ROUTES from '../../../../../utils/routes';
import styles from './styles';

class SubscribePlanToCreateTransfers extends React.PureComponent {
  render() {
    const { intl, classes } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ul className={classes.bulletList}>
            <li>
              <Typography variant={'body1'} className={classes.featureText}>
                {intl.formatMessage({ id: 'createTransferRequests' })}
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'} className={classes.featureText}>
                {intl.formatMessage({ id: 'generatePdfFile' })}
              </Typography>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'body1'} className={classes.breederInformationText}>
            {intl.formatMessage(
              {
                id: 'youCurrentlyHave'
              },
              {
                plan: (
                  <span className={classes.planText}>
                    {intl.formatMessage({
                      id:
                        this.props.user.user && this.props.user.user.plan
                          ? this.props.user.user.plan.toLowerCase()
                          : 'free'
                    })}
                  </span>
                ),
                plansPage: (
                  <Link to={ROUTES.BREEDER_PLANS} className={classes.planText}>
                    {intl.formatMessage({ id: 'plansPage' })}
                  </Link>
                )
              }
            )}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(SubscribePlanToCreateTransfers)));
