// UTILS
import { getColors } from '../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  root: {
    borderBottom: `1px solid ${colors.border}`,
    '& .MuiButtonBase-root': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      borderRadius: '6px'
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      borderRadius: '6px'
    },
    '& .MuiTabs-indicator': {
      color: theme.palette.common.black,
      borderBottom: `2px solid ${theme.palette.common.black}`,
      backgroundColor: theme.palette.common.black,
      borderRadius: '6px'
    }
  }
});
