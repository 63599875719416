import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// REACT FEATHER
import { ChevronRight } from 'react-feather';

// APP IMPORTS
import styles from './styles';

class CoupleItem extends React.PureComponent {
  renderBirdInformation = (ring, mutation, isMale) => {
    const { intl, classes } = this.props;

    return (
      <div className={isMale ? classes.partnerContainerWithBorder : classes.partnerContainer}>
        <div className={classes.informationContainer}>
          <div className={classes.ringContainer}>
            <Typography variant={'h6'} className={classes.ringText}>
              {ring}
            </Typography>
          </div>
          <div className={classes.ringContainer}>
            <Typography variant={'body1'} className={classes.mutationText}>
              {mutation}
            </Typography>
          </div>
          <div className={classes.ringContainer}>
            <Typography variant={'body1'} className={classes.genderText}>
              {intl.formatMessage({ id: isMale ? 'male' : 'female' })}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { intl, classes } = this.props;

    return (
      <React.Fragment>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className={classes.container} onClick={this.props.onClick}>
          <div className={classes.headerContainer}>
            <Typography variant={'body1'} className={classes.headerText}>
              {`${intl.formatMessage({ id: 'cage' })} ${this.props.cage}`}
            </Typography>
            <Typography variant={'body2'} className={classes.headerText}>
              {this.props.specieName}
            </Typography>
            <Typography variant={'body1'} className={classes.headerText}>
              {this.props.year}
            </Typography>
          </div>
          <div className={classes.innerContainer}>
            <div className={classes.partnersContainer}>
              {this.renderBirdInformation(
                this.props.male.ring,
                this.props.male.specie.mutation.name,
                true
              )}
              {this.renderBirdInformation(
                this.props.female.ring,
                this.props.female.specie.mutation.name,
                false
              )}
            </div>
            <div className={classes.button}>
              <IconButton color={'default'} size={'small'} onClick={this.props.onClick}>
                <ChevronRight size={20} />
              </IconButton>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CoupleItem.propTypes = {
  cage: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  specieName: PropTypes.string.isRequired,
  male: PropTypes.shape({
    ring: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    specie: PropTypes.shape({
      mutation: PropTypes.shape({
        name: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired,
  female: PropTypes.shape({
    ring: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    specie: PropTypes.shape({
      mutation: PropTypes.shape({
        name: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired
};

export default withStyles(styles)(injectIntl(CoupleItem));
