import COUNTRIES_EN from '../assets/countries/countries_en';
import COUNTRIES_PT from '../assets/countries/countries_pt';
import COUNTRIES_ES from '../assets/countries/countries_es';
import COUNTRIES_FR from '../assets/countries/countries_fr';
import COUNTRIES_IT from '../assets/countries/countries_it';

export function getCountries(locale = 'en_GB') {
  let countries = COUNTRIES_EN;

  if (locale === 'pt_PT') {
    countries = COUNTRIES_PT;
  } else if (locale === 'es_ES') {
    countries = COUNTRIES_ES;
  } else if (locale === 'fr_FR') {
    countries = COUNTRIES_FR;
  } else if (locale === 'it_IT') {
    countries = COUNTRIES_IT;
  }

  return countries.map((item) => ({
    key: item.id,
    value: item.id,
    name: item.name
  }));
}

export function getCountryName(countryId, locale = 'en_GB') {
  const country = getCountries(locale).find((item) => item.value === countryId);

  return country ? country.name : '';
}

export function getMutationName(bird, intl) {
  if (bird.is_yellow && bird.is_white_head) {
    return `${bird.specie.name} - ${intl.formatMessage({
      id: 'yellow'
    })} - ${intl.formatMessage({ id: 'whiteHead' })} - ${bird.specie.mutation.name}`;
  }
  if (bird.is_yellow && !bird.is_white_head) {
    return `${bird.specie.name} - ${intl.formatMessage({ id: 'yellow' })} - ${
      bird.specie.mutation.name
    }`;
  }
  if (!bird.is_yellow && bird.is_white_head) {
    return `${bird.specie.name} - ${intl.formatMessage({ id: 'whiteHead' })} - ${
      bird.specie.mutation.name
    }`;
  }
  return `${bird.specie.name} - ${bird.specie.mutation.name}`;
}

export function getGenderName(gender, intl) {
  if (gender === 'M') {
    return intl.formatMessage({ id: 'male' });
  }
  if (gender === 'F') {
    return intl.formatMessage({ id: 'female' });
  }
  return intl.formatMessage({ id: 'unknown' });
}
