export default (theme) => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '50px',
    // backgroundColor: colors.logo,
    // padding: theme.spacing(0.5),
    // boxShadow: '0px 0px 15px 2px rgba(0, 0, 0, 0.24)',
    textDecoration: 'none',
    margin: theme.spacing(1)
  },
  logoImage: {
    objectFit: 'contain',
    width: '100%',
    borderRadius: '6px'
  }
});
