import React from 'react';

// REDUX
import { connect } from 'react-redux';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// MATERIAL UI
import { Typography } from '@material-ui/core';

class ToolbarTitle extends React.PureComponent {
  render() {
    const { app, intl } = this.props;

    // hope this is not causing bad performance
    if (app.title) {
      document.title = app.translateTitle ? intl.formatMessage({ id: app.title }) : app.title;
    } else {
      document.title = 'MyOwnBirds';
    }

    return (
      <React.Fragment>
        {app.title && (
          <Typography variant={'h5'} style={{ color: '#000', fontWeight: '600' }}>
            {app.translateTitle ? intl.formatMessage({ id: app.title }) : app.title}
          </Typography>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ToolbarTitle));
