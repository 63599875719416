import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Grid, Container, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// REACT ROUTER
import { NavLink } from 'react-router-dom';

// SPINE UI
import { Input } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { fetchApi } from '../../../utils/apiSettings';
import ROUTES from '../../../utils/routes';
import PublicPaper from '../../../components/core/PublicPaper';
import PublicButton from '../../../components/buttons/PublicButton';
import styles from './styles';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      // form
      email: '',
      errors: {}
    };

    this.emailInputRef = React.createRef();
  }

  componentDidMount() {
    this.emailInputRef.current.focus();
  }

  handleInputChange = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    this.setState({
      [input.name]: value,
      errors: {}
    });
  };

  handleOnSubmit = async (event) => {
    event.preventDefault();
    const { enqueueSnackbar, intl } = this.props;

    this.setState(
      {
        isLoading: true
      },
      () => {
        fetchApi(
          'post',
          '/v1/accounts/reset_password_request',
          {},
          {
            email: this.state.email
          },
          true,
          () => {
            enqueueSnackbar(intl.formatMessage({ id: 'resetPasswordRequestInfoMessage' }), {
              variant: 'success',
              autoHideDuration: 5000
            });

            setTimeout(() => {
              this.props.history.push(ROUTES.LOGIN);
            }, 2500);
          },
          (error) => {
            const { response } = error;

            this.setState({ isLoading: false });

            if (response && response.status === 404) {
              this.setState({
                errors: {
                  email: intl.formatMessage({ id: 'invalidEmail' })
                }
              });
            } else {
              enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 5000
              });
            }
          }
        );
      }
    );
  };

  render() {
    const { intl, classes } = this.props;

    return (
      <Container>
        <div className={classes.formCard}>
          <PublicPaper title={'forgotPassword'}>
            <form autoComplete={'on'} onSubmit={this.handleOnSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    inputRef={this.emailInputRef}
                    name={'email'}
                    type={'email'}
                    autoComplete={'email'}
                    label={intl.formatMessage({ id: 'email' })}
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    error={!!this.state.errors.email}
                    helperText={this.state.errors.email || ''}
                    required={true}
                    disabled={this.state.isLoading}
                  />
                </Grid>
                <Grid item xs={12} />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <PublicButton title={'sendResetInstructions'} isLoading={this.state.isLoading} />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <NavLink to={ROUTES.LOGIN} className={classes.link}>
                    <Typography variant={'body1'} className={classes.text}>
                      {intl.formatMessage({ id: 'backToLogin' })}
                    </Typography>
                  </NavLink>
                </Grid>
              </Grid>
            </form>
          </PublicPaper>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(withSnackbar(injectIntl(ForgotPassword)));
