import React from 'react';

// REDUX
import { connect } from 'react-redux';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import { setAppBarTitle } from '../../../../../../store/actions/appActions';
import AddBirdForm from '../../../../../../components/forms/AddBirdForm';
import ROUTES from '../../../../../../utils/routes';

class AddChild extends React.PureComponent {
  componentDidMount() {
    this.props.setAppBarTitle('addChild');
  }

  handleOnSuccess = (birdId) => {
    const { coupleId, clutchId } = this.props.match.params;
    this.props.history.push(
      `${ROUTES.BREEDER_COUPLES}/${coupleId}/clutches/${clutchId}/children/${birdId}`
    );
  };

  handleOnCancel = () => {
    const { coupleId, clutchId } = this.props.match.params;
    this.props.history.push(`${ROUTES.BREEDER_COUPLES}/${coupleId}/clutches/${clutchId}`);
  };

  render() {
    const { coupleId, clutchId } = this.props.match.params;

    return (
      <AddBirdForm
        shouldDisplayFirstOwnerForm={false}
        coupleId={coupleId || ''}
        clutchId={clutchId || ''}
        onCancel={this.handleOnCancel}
        onSuccess={this.handleOnSuccess}
      />
    );
  }
}

const mapStateToProps = (state) => ({ app: state.appReducer });

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddChild));
