import { green } from '@material-ui/core/colors';

export default (theme) => ({
  planContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  planContainerTitle: {
    [theme.breakpoints.up('md')]: {
      height: '120px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '120px'
    }
  },
  actualPlanContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center'
  },
  actualPlan: {
    color: green[600],
    border: '2px solid green',
    borderRadius: '5px',
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(2)
  },
  planTitle: {
    color: theme.palette.common.black
  },
  planSmallDescription: {
    marginTop: '3px',
    fontWeight: 500,
    color: theme.palette.common.black
  },
  planPrice: {
    fontWeight: 700,
    color: theme.palette.common.black,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0)
    }
  },
  yearText: {
    fontWeight: 500,
    color: theme.palette.secondary.main
  },
  descriptionItemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start'
  },
  itemIcon: {
    color: theme.palette.common.black,
    marginTop: '3px',
    marginLeft: '-1px',
    marginRight: '4px'
  },
  itemText: {
    color: theme.palette.common.black
  },
  submitButton: {
    marginBottom: theme.spacing(3),
    // marginLeft: theme.spacing(2),
    width: '100%'
  }
});
