import theme1Palette from './main/palette';
import theme1Colors from './main/colors';
import theme1Sizes from './main/sizes';

/**
 * Get the current palette colors based on the env name.
 */
export function getPalette() {
  return theme1Palette;
}

/**
 * Get the current colors based on the env name.
 */
export function getColors() {
  return theme1Colors;
}

/**
 * Get the current sizes based on the env name.
 */
export function getSizes() {
  return theme1Sizes;
}
