export default (theme) => ({
  filterFormContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  filterInput: {
    [theme.breakpoints.up('md')]: {
      width: '60%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  stateButton: {
    width: '200px',
    marginRight: theme.spacing(1)
  }
});
