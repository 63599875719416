// FRENCH

export default {
  // app
  logout: 'Se déconnecter',
  view: 'Voir',
  add: 'Ajouter',
  remove: 'Retirer',
  cancel: 'Annuler',
  confirm: 'Confirmé',
  next: 'Prochain',
  back: 'Précédent',
  yes: 'Oui',
  no: 'Non',
  undefined: 'indéfini',
  edit: 'Éditer',
  goBack: 'Retourner',
  close: 'Fermer',
  rowsPerPage: 'Lignes par page',
  of: 'de',
  home: 'Accueil',
  startNow: "S'inscrire",

  // sidebar
  dashboard: 'Tableau de bord',
  users: 'Utilisateurs',
  account: 'Compte',
  others: 'Autres',
  plans: 'Plans',
  transfers: 'Transferts',

  // home
  birdsPortal: 'Portail complet pour la gestion aviaire',
  breedersCollaboration: "Une collaboration avec des éleveurs d'oiseaux",
  birdsManagement: "Gestion d'oiseaux",
  couplesAndClutchesManagement: 'Gestion des couples et des embrayages',
  birdTransfers: "Transferts d'oiseaux",
  searchBreedersWithSpecificSpecies: "Recherche d'éleveurs avec des espèces spécifiques",

  mainFunctionalities: 'Principales fonctionnalités',
  availableForBreeder: 'Disponible pour tous les éleveurs:',
  manageBirdsStock: "Gestion des stocks d'oiseaux",
  manageCouples: 'Gestion des couples',
  manageClutches: 'Gestion de la saison de procréation',
  efficientSearch: 'Recherche efficace',
  birdProfile: 'Registre individuel des oiseaux',
  simpleTransfer: 'Processus de transfert simple',

  // public plans
  increaseProductivity:
    'Augmentez votre productivité et votre contrôle lors de la reproduction des oiseaux',
  freeDescription:
    "C'est gratuit pour toujours. Le plan parfait pour commencer à utiliser certaines de nos fonctionnalités.",
  basicDescription:
    'Idéal pour ceux qui souhaitent se lancer dans cette activité et commencer à gérer facilement leur volière.',
  premiumDescription: 'IIdéal pour ceux qui veulent atteindre le niveau professionnel.',
  platinumDescription: 'Le plan parfait pour ceux qui cherchent à franchir la ligne.',
  manageInfiniteNumberOfBirds: "Gérez un nombre infini d'oiseaux",
  manageCouplesUpTo: "Gérez jusqu'à {number} couples",
  createTransferRequests:
    'Créer des commandes et des documents associés pour transférer les oiseaux à un autre éleveur',
  searchForOtherBirdsFromOtherBreeders: "Rechercher d'autres oiseaux d'autres éleveurs",
  year: 'année',

  // login
  login: "S'identifier",
  email: 'Email',
  password: 'Mot de passe',
  rememberMe: 'Rappelle moi',
  forgotPassword: 'Mot de passe oublié?',
  invalidEmail: "Cet e-mail n'est pas valide.",
  emailIsRequired: "L'email est obligatoire.",
  passwordIsRequired: 'Le mot de passe est obligatoire',
  invalidCredentials: "Les informations d'identification invalides.",

  // species
  meetTheSupportedSpecies: 'Rencontrez les espèces présentes sur la plateforme',
  weAreKeenToSupportMore:
    "Envoyez-nous un courriel à {email} si vous souhaitez en ajouter plus d'espèces.",

  // register
  perfectPlatform: 'La plateforme parfaite pour gérer vos oiseaux',
  ourMission:
    "Notre mission est d'aider les éleveurs à gérer facilement leur volière. Nous avons de nombreuses fonctionnalités disponibles:",
  createYourFreeAccount: 'Créez votre compte gratuit',
  createAccount: 'Créer un compte',
  acceptTermsAndConditionsRegister: "En avançant, vous êtes d'accord avec",
  termsAndConditions: 'Termes et Conditions',
  registerErrorEmailAlreadyUsed: 'Cet e-mail est déjà utilisé.',
  registerErrorStripeProblem: "Erreur avec l'API externe. Veuillez réessayer.",
  registerErrorConfirmationRegistrationCode: "Ce code d'enregistrement n'est pas valide.",
  registerErrorInvalidEmail: "Cet e-mail n'est pas valide.",
  registerErrorInvalidRecaptchaToken: "Le ReCaptcha n'est pas valide.",
  invalidPassword: 'Le mot de passe doit contenir au moins 5 caractères',
  registerSuccess: "Vérifiez votre email et entrez le code d'enregistrement.",
  registrationCode: "Code d'enregistrement",
  confirmRegistrationCode: 'Confirmer le code',

  // forgot password
  sendResetInstructions: 'Envoyer les instructions de réinitialisation',
  backToLogin: 'Retour connexion',
  resetPasswordRequestInfoMessage: 'Un email vous a été envoyé pour récupérer votre mot de passe',
  resetPassword: 'Réinitialiser le mot de passe',
  resetRequestErrorMessage:
    "La demande de réinitialisation de votre mot de passe n'est plus valide",

  // users
  addUser: 'Ajouter utilisateur',
  noUsers: "Il n'y a pas d'utilisateurs",
  emailConfirmed: 'Email vérifié',
  country: 'Pays',
  userErrorMessage: 'Utilisateur non trouvé',

  // users add
  contact: 'Contact',
  birthday: 'Date de naissance',

  // breeder dashboard
  hiBreeder: 'Salut {breederName}',
  numberOfBirds: 'Des oiseaux',
  numberOfCouples: 'Des couples',
  numberOfClutches: 'Les postures',
  youCurrentlyHave:
    'Vous avez actuellement le plan {plan}. Si vous souhaitez vous abonner à une autre solution, visitez notre {plansPage}.',
  plansPage: 'page plans',
  numberOfTransfers: 'Les transferts',
  numberOfDead: 'Mort',

  // account
  language: 'Langue',
  registeredAt: 'Enregistré en',
  updatedAt: 'Mis à jour à',
  changePassword: 'Changer le mot de passe',
  currentPassword: 'Mot de passe actuel',
  newPassword: 'Nouveau mot de passe',
  confirmPassword: 'Confirmez le mot de passe',
  passwordsDontMatch: 'Les mots de passe ne correspondent pas.',
  editAccount: 'Éditer compte',
  changedPasswordErrorMessage: "Le mot de passe actuel n'est pas correct.",
  changedPasswordSuccessMessage: 'Le mot de passe a été modifié avec succès.',
  changedPersonalInformationInfoMessage: 'Vos informations personnelles ont été modifiées.',
  breederIdentifiers: "Identificateurs d'éleveur",
  addBreederIdentifier: 'Ajouter un identifiant',
  setAsDefault: 'Définir par défaut',
  isDefault: 'Valeur par défaut',
  breederIdentifier: "Identifiant de l'éleveur",
  removeBreederIdentifier: "Supprimer l'identifiant de l'éleveur",
  removeBreederIdentifierSuccessMessage: "L'identifiant a été supprimé",
  removeBreederIdentifierErrorMessage: "Il n'a pas été possible de supprimer l'identifiant",
  removeBreederIdentifierQuestion: 'Êtes-vous sûr de vouloir supprimer cet identifiant?',
  setAsDefaultQuestion: 'Êtes-vous sûr de vouloir définir cet identifiant par défaut?',
  setAsDefaultBreederIdentifierSuccessMessage: "L'identifiant a été défini par défaut",
  setAsDefaultBreederIdentifierErrorMessage:
    "Il n'a pas été possible de définir par défaut l'identifiant",
  editBreederIdentifier: "Modifier l'identifiant de l'éleveur",
  editBreederIdentifierSuccessMessage: "L'identifiant de l'éleveur a été modifié",
  breederIdentifierErrorMessage: "L'identifiant de l'éleveur est déjà utilisé",
  addBreederIdentifierSuccessMessage: "Un nouvel identifiant d'éleveur a été ajouté",
  identifierDescription:
    "L'identifiant représente un code attribué à un créateur par une association " +
    '/ fédération / autres.',
  identifierDescription2: "L'identifiant sera utilisé lors de la création d'un oiseau.",
  noIdentifiers: 'Aucun identifiant',
  closeAccount: 'Fermer le compte',
  closeAccountInformation:
    'La clôture de votre compte est définitive et irréversible! Cela ' +
    'supprimera définitivement toutes les données associées de votre utilisateur. Si vous avez ' +
    "un abonnement, nous l'annulerons immédiatement.",
  closeAccountKeepBirds: "Les informations concernant les transferts d'oiseaux seront conservées.",
  closeAccountEmailWillBeSent:
    'Pour des raisons de sécurité, nous vous enverrons un e-mail contenant un code que vous devez ' +
    'insérer pour confirmer que vous souhaitez fermer votre compte.',
  confirmationCode: 'Code de confirmation',

  // plans
  managePlan: 'Gérer le plan',
  plan: 'Plan',
  free: 'Libre',
  basic: 'Basique',
  premium: 'Prime',
  platinum: 'Platine',
  custom: 'Personnalisé',
  select: 'Sélectionner',
  actualPlan: 'Plan actuel',

  // email confirmation
  emailConfirmation: "Confirmation de l'émail",
  emailWasConfirmed: "L'e-mail a été confirmé avec succès.",
  emailWasNotConfirmed:
    "Un problème est survenu lors de la vérification de l'e-mail. Si cela n'a pas encore été confirmé, demandez un nouveau lien de confirmation.",
  requestConfirmation: 'Demander une nouvelle confirmation',

  // admin dashboard
  activeUsers: 'Utilisateurs actifs',
  accountsClosed: 'Comptes fermés',
  top10CountriesWithBreeders: 'Top 10 des pays avec des éleveurs actifs',
  top10CountriesWithBirds: 'Top 10 des pays avec des oiseaux actifs',
  top10SpeciesWithBirds: 'Top 10 des espèces avec des oiseaux actifs',

  // breeder users
  name: 'Nom',
  addedAt: 'Ajouté à',
  active: 'Actif',
  addUserIfNotExist:
    "L'utilisateur sera ajouté à votre liste une fois que l'utilisateur aura accepté la demande. Assurez-vous que l'adresse e-mail de l'utilisateur est correcte.",
  addUserRecommendation: 'Caso o utilizador esteja já registado na plataforma, peça-lhe o e-mail.',
  searchBy: 'Recherché par',
  searchByNameEmailCountry: "Nom d'utilisateur, email ou pays",

  // breeder birds
  addBird: 'Ajouter oiseau',
  myBirds: 'Mes oiseaux',
  searchBirds: "Recherche d'oiseaux",
  transferRequests: 'Demandes de transfert',
  breeder: 'Éleveur',
  ring: 'Bague',
  gender: 'Le sexe',
  specie: 'Espèce',
  mutation: 'Mutation',
  noBirds: "Il n'y a pas d'oiseaux",
  searchByRing: 'Bague',
  birthdayStart: 'Date de naissance début',
  birthdayEnd: 'Date de fin de naissance',
  genderAndCountry: 'Genre et pays',
  birdsErrorMessage: "Erreur lors de l'obtention de vos oiseaux",
  speciesErrorMessage: "Erreur lors de l'obtention de toutes les espèces",
  mutationsErrorMessage: "Erreur lors de l'obtention de toutes les mutations",
  filterSearch: 'Filtres',
  addBirdSuccessMessage: 'Un nouvel oiseau a été enregistré avec succès',
  addBirdErrorMessage: "Il n'a pas été possible d'enregistrer un nouvel oiseau",
  observations: 'Observations',
  initialData: 'Donnée initiale',
  isYellow: 'Est jaune',
  isWhiteHead: 'Est la tête blanche',
  isCarduelisSpecieHeader:
    "Vous avez sélectionné l'espèce Carduelis Carduelis. Sélectionnez si votre oiseau est jaune ou a la tête blanche",
  yellow: 'Jaune',
  whiteHead: 'Tête blanche',
  addBreederIdentifierAddNewBirdMessage:
    "Si vous souhaitez ajouter ou n'avez pas d'identifiant, cliquez sur ",
  acquiredAt: 'Acquis à',
  firstOwner: 'Premier propriétaire',
  owner: 'Propriétaire',
  firstOwnerInformation: 'Informations sur le premier propriétaire',
  firstOwnerInformationMessage:
    "Lors de l'ajout d'un oiseau, vous pouvez indiquer si vous possédez l'oiseau ou s'il a été acheté.",
  ringTotal: 'Total de la Bague',
  ringAlreadyUsed: 'Bague déjà utilisée',
  number: 'Nombre',
  extraInformation: 'Informations supplémentaires',
  breederName: "Nom de l'éleveur",
  invalidDate:
    "Format de date non valide. Vous devez spécifier le jour, le mois et l'année (dd/mm/yyyy).",
  birdInformation: "Informations sur l'oiseau",
  carrierOfMutations: 'Porteur de',
  possiblyCarrierOfMutations: 'Éventuellement porteur de',

  // bird states
  state: 'Etat',
  transferred: 'Cédé',
  transferredAt: 'Cédé le',
  deathAt: 'Mort le',
  death: 'Mort',
  all: 'Tout',

  // bird information
  children: 'Jeunes',
  clutches: 'Postures',
  parents: 'Progéniteurs',
  parent: 'Progéniteur',
  parent1: 'Père 1',
  parent2: 'Père 2',
  acquired: 'Acquis',
  editBirdSuccessMessage: 'Cet oiseau a été modifié avec succès.',
  editBirdErrorMessage: "Cet oiseau n'a pas pu être modifié.",
  documents: 'Des documents',
  editBird: "Éditer l'oiseau",
  noDocuments: "Cet oiseau n'a aucun document joint",
  document: 'Document',
  noComments: "Il n'y a aucun commentaire",
  removeBird: "Retirer l'oiseau",
  onlyOneFieldFillError: "Vous ne pouvez remplir qu'un seul champ",
  removeBirdQuestion: 'Voulez-vous vraiment retirer cet oiseau?',
  removeBirdSuccessMessage: "L'oiseau a été retiré avec succès",
  removeBirdErrorMessage: "Cet oiseau n'a pas pu être retiré.",
  removeBirdHasChildrenErrorMessage:
    "L'oiseau a des enfants. Si vous souhaitez modifier les informations sur les oiseaux, veuillez mettre à jour ces sections ci-dessous.",
  removeBirdWasTransferredErrorMessage:
    "L'oiseau vous a été transféré. Si vous souhaitez modifier les informations sur les oiseaux, veuillez mettre à jour ces sections ci-dessous.",
  declareDeath: 'Déclarer la mort',
  declareDeathSuccessMessage: "L'oiseau a été déclaré mort avec succès.",
  removeImageSuccessMessage: "L'image a été supprimée avec succès.",

  transfersHistory: 'Historique des transferts',
  transfer: 'Transfert',
  transferLine: 'Transféré au {country} en {date}.',
  transferLineFrom: 'Transféré du {country} en {date}.',
  removeImage: "Supprimer l'image",
  removeImageQuestion: 'Êtes-vous sûr de vouloir supprimer cette image?',
  setImageAsDefault: "Définir l'image par défaut",
  setImageAsDefaultQuestion: 'Êtes-vous sûr de vouloir définir cette image par défaut?',
  getBirdErrorMessage: "L'oiseau n'existe pas ou vous n'y avez pas accès.",

  // bird couples
  couples: 'Des couples',
  noCouples: "Il n'y a pas de couples",
  addCoupleErrorMessagePlanDontAllow: 'Vérifiez si votre plan vous permet de le faire.',
  addCoupleErrorMessage:
    "Une erreur s'est produite lors de la création du couple. Vérifiez que vous avez correctement saisi les données.",
  parentsAreBrothers: 'Ces oiseaux sont frères ou demi-frères.',
  couplesErrorMessage: "Erreur lors de l'obtention de vos couples",
  couplesSuccessMessage: 'Un couple a été créé avec succès.',
  inactive: 'Inactif',
  addCouple: 'Ajouter couple',
  addCoupleInformation:
    "Les oiseaux suivants doivent déjà être enregistrés sur notre plateforme. Si vous souhaitez les enregistrer à l'avance, cliquez ",
  here: 'ici.',
  chooseParents: 'Choisissez les parents',
  searchByParentsRing: 'Identifiant du couple',
  male: 'Mâle',
  female: 'Femelle',
  unknown: 'Inconnu',
  identifier: 'Identifant',
  identifierIsRequired: "L'identifiant est requis.",
  identifierMoreThan: "L'identifiant doit être supérieur à 0",
  identifierAlreadyUsed: 'Identifiant déjà utilisé',
  editIdentifier: "Modifier l'identifiant",
  editCouple: 'Modifier le couple',
  editCoupleSuccessMessage: 'Le couple a été édité avec succès.',
  editCoupleErrorMessage:
    'Un problème est survenu lors de la tentative de modification de ce couple.',
  setCageIdentifierCoupleInactive:
    'Ce couple est inactif ou le sera. Il n’est pas possible de modifier l’identifiant de la cage.',
  setIsActiveCoupleErrorMessage:
    "Impossible de réactiver ce couple. L'un des parents est décédé ou a été transféré.",
  removeCouple: 'Retirer le couple',
  removeCoupleNotice:
    "Veuillez envisager de définir ce couple comme inactif (dans 'Modifier le couple'). Nous garderons les oiseaux liés mais nous supprimerons les griffes et vous perdrez la trace des relations des oiseaux.",
  removeCoupleQuestion: "Faites-vous semblant de supprimer ce couple? C'est irréversible!",
  removeCoupleSuccessMessage: 'Le couple a été supprimé avec succès.',
  removeCoupleErrorMessage:
    'Un problème est survenu lors de la tentative de suppression de ce couple.',

  // couple information
  addChild: 'Ajouter enfant',
  addClutch: 'Ajouter posture',
  couple: 'Couple',
  scheduled: 'Programmé',
  finished: 'Fini',
  addChildErrorMessage: "Il n'a pas été possible d'ajouter un nouvel enfant à ce couple",
  addChildCoupleInactive:
    "Ce couple est inactif. Il est uniquement possible d'ajouter un nouvel enfant à lier à un posture existant.",
  addChildSuccessMessage: 'Un nouvel enfant a été ajouté à ce couple',
  addCoupleChildInformationHeader:
    "L'enfant qui sera inscrit dans ce couple ne sera lié à aucune posture. Si vous voulez ce lien, cliquez ",
  child: 'Enfant',
  cageIdentifier: 'Identifiant de la cage',
  cage: 'Cage',
  qrCodeMessage:
    'Utilisez ce QR code pour identifier ce couple et accéder rapidement à cette page.',

  // clutches
  totalEggs: 'Total des oeufs',
  startDate: 'Date de début',
  description: 'La description',
  addClutchSuccessMessage: 'Une nouvelle posture a été ajoutée à ce couple',
  addClutchErrorMessage: "Il n'a pas été possible d'ajouter une nouvelle posture à ce couple",
  addClutchCoupleInactive:
    "Ce couple est inactif. Il n'est pas possible d'ajouter de nouveaux postures.",
  noClutches: "Il n'y a pas de postures",
  clutchesErrorMessage: "Erreur dans l'obtention des postures de ce couple",
  childrenCount: '{eggsCount} œufs et {childrenCount} enfants',
  onGoing: 'En cours',

  // clutch
  clutch: 'Posture',
  editClutch: 'Éditer posture',
  abortedEggs: 'Oeufs avortés',
  fertilizedEggs: 'Oeufs fécondés',
  removeClutch: 'Retirer la posture',
  totalEggsExceeded: "Nombre total d'oeufs dépassé",
  editClutchSuccessMessage: 'Les données de posture ont été mises à jour avec succès',
  editClutchErrorMessage: "Les données de posture n'ont pas pu être mises à jour",
  addClutchChildHeader:
    "Vous ne pouvez ajouter des enfants à cette posture que si vous ajoutez plus d'oeufs fécondés.",

  // birds market
  breederEmail: 'E-mail du éleveur',
  searchByBreederEmail: 'E-mail du éleveur',
  breederInformation: "Informations sur l'éleveur",
  bird: 'Oiseau',
  transferRequest: 'Demande de transfert',
  transferRequestSuccessMessage: 'Le transfert a été créé avec succès.',
  transferRequestErrorMessage:
    'Un problème est survenu lors de la tentative de création de votre transfert.',
  transferNumberAlreadyExists: "Ce numéro existe déjà pour l'année en cours.",
  specieRequired: 'Vous devez choisir une espèce',
  mutationRequired: 'Vous devez choisir une mutation',
  showInterest: "Montrer de l'intérêt",
  sendEmailQuestion:
    "Un e-mail sera envoyé à l'éleveur pour lui indiquer que vous êtes intéressé par la mutation " +
    "{mutation}. Votre nom et votre adresse e-mail seront partagés. Voulez-vous l'envoyer?",
  sendEmailSuccessMessage: "L'e-mail a été envoyé avec succès.",

  // transfer requests
  createTransfer: 'Créer un transfert',
  holder: 'Titulaire',
  newOwner: 'Nouveau propriétaire',
  birdMutation: "Mutation d'oiseau",
  noTransferRequests: "Il n'y a aucune demande de transfert",
  transferRequestsErrorMessage: "Erreur lors de la réception de vos demandes d'affectation",
  chooseBirds: 'Choisir les oiseaux',
  questionBreederUser: "N'est-il pas un utilisateur de l'application?",
  addBreederInformation: 'Ajouter des informations sur le éleveur',
  zipCode: 'Code postal',
  address: 'Adresse',
  sent: 'Expédié',
  received: 'Reçu',
  type: 'Type',
  pending: 'En attente',
  accepted: 'Accepté',
  refused: 'Refusé',
  removed: 'Supprimé',
  transfersUnderMaintenance: 'Transferts sous maintenance',
  transfersUnderMaintenanceText:
    'Les transferts seront de retour en ligne dès que possible. Nous travaillons sur des transferts spécifiques à chaque pays et nous assurerons la meilleure expérience possible à nos utilisateurs. Vous serez en mesure de:',
  weAreVeryGrateful:
    "Nous vous sommes très reconnaissants de la confiance que vous nous accordez ainsi qu'à notre site.",
  pleaseContact: 'Veuillez contacter {mailto} si vous avez des questions.',

  // transfer request
  acceptTransfer: 'Accepter le transfert',
  refuseTransfer: 'Refuser le transfert',
  removeTransfer: 'Supprimer le transfert',
  downloadTransferDocument: 'Le document de transfert',
  sharedDocuments: 'Documents partagés',
  registerDate: "Date d'inscription",
  transferNumberOfBirds: "Nombre d'oiseaux",
  accessCodeTitle: "Code d'accès aux informations",
  accessCodeMessage:
    'Dans le processus de transfert, il est possible de fournir vos informations personnelles au propriétaire des oiseaux de manière très simple : il suffit de fournir le code ci-dessous qui vous sera demandé lors de la création du transfert.',
  accessCodeDuration:
    "Ce code est généré automatiquement toutes les 15 secondes et ne peut être utilisé qu'une seule fois.",
  removeTransferMessage: 'Voulez-vous vraiment supprimer ce transfert?',
  removeTransferError: 'Impossible de supprimer ce transfert. Il a été approuvé ou refusé.',
  removeTransferSuccess: 'Le transfert a été supprimé avec succès.',

  // create transfer request
  isRegistered: 'Est inscrit sur la plateforme',
  receptorCode: "Code d'accès aux données du propriétaire",
  receptorCodeHelperText: "Ce code se trouve sur la page des transferts de l'utilisateur.",
  receptorGetData: 'Obtenir des données',
  transferNumber: 'Numéro de transfert',
  createTransferInfo1:
    "Le document de transfert est basé sur le pays de l'utilisateur qui le crée. Nous gérons activement ces documents. Si vous pensez que des champs obligatoires sont manquants dans votre pays, veuillez les indiquer par e-mail à {email}.",
  createTransferInfo2: "Vous ne pourrez créer un virement qu'une fois le précédent terminé.",
  createTransferInfo3: "Les champs marqué d'une étoile sont obligatoires.",
  createTransferInfo4:
    "Le numéro de virement est généré automatiquement mais vous pouvez le modifier, et il doit être unique dans l'année en cours.",
  createTransferInfo5:
    'Il est possible de créer des virements pour les personnes non inscrites sur la plateforme. Dans ce cas, le transfert sera clôturé automatiquement et les oiseaux seront marqués comme transférés.',
  createTransferInfo6:
    "Si le nouveau propriétaire est inscrit sur la plateforme, il lui est nécessaire d'indiquer le code généré dans la zone de transfert afin d'obtenir ses données personnelles. Ce processus permet aux oiseaux d'être automatiquement transférés au nouveau propriétaire après approbation.",
  breederNotFoundOrInvalidCode: 'Éleveur introuvable ou code invalide.',

  removeTransferRequest: "Retirer l'ordre d'attribution",
  breederRequesterInformation: "Informations sur l'ancien éleveur",
  breederReceiverInformation: 'Informations sur le nouveau éleveur',
  stam: 'STAM',
  breederCode: "Code du Gouvernement de l'éleveu",
  removeTransferRequestQuestion:
    'Voulez-vous vraiment retirer la demande de transfert? Le destinataire ne pourra pas confirmer le transfert de ces oiseaux.',
  removeTransferRequestSuccessMessage: 'La demande de transfert a bien été supprimé',
  removeTransferRequestErrorMessage: "Il n'a pas été possible de retirer la demande de transfert",
  addMoreBreederInformation: 'Ajouter des informations personnelles',
  addMoreBreederInformationMessage:
    "Il est nécessaire d'insérer préalablement certaines données dans votre profil (adresse, code postal, pays). Pour saisir ces données, cliquez ",

  // public transfer request
  transferRequestConfirmation: 'Confirmation de la demande de transfert',
  confirmTransfer: 'Confirmer la demande de transfert',
  transferRequestConfirmationSuccessMessage: 'Demande de transfert confirmée',
  transferRequestCancelSuccessMessage: 'Demande de transfert annulée',
  transferRequestConfirmationErrorMessage: 'Impossible de confirmer la demande de transfert',
  transferRequestCancelErrorMessage: "Impossible d'annuler la demande de transfert",
  missingDataBreederReceiverErrorMessage:
    "Le destinataire n'a pas encore renseigné les données obligatoires dans son profil.",

  // admin
  species: 'Espèce',
  addSpecie: 'Ajouter des espèces',
  noSpecies: "Il n'y a pas d'espèces",
  mutations: 'Les mutations',
  addMutation: 'Ajouter mutation',
  noMutations: "Il n'y a pas de mutations",
  image: 'Image',
  birds: 'Des oiseaux',
  addSpecieSuccessMessage: 'Une nouvelle espèce a été enregistrée',
  addSpecieErrorMessage: 'Cette espèce existe déjà.',
  retrieveSpeciesErrorMessage: "Erreur lors de la récupération de l'espèce",
  retrieveSpecieErrorMessage: "Erreur lors de la récupération de l'espèce",
  hasClutchDurationDays: 'Ajouter des jours de durée de posture',
  days: 'Jours',
  clutchDurationDays: 'Durée de la posture (jours)',
  addMutationErrorMessage: 'Cette mutation existe déjà.',
  editSpecie: "Modifier l'espèce",
  editSpecieSuccessMessage: "L'espèce a été modifiée avec succès",
  removeSpecie: 'Supprimer les espèces',
  removeSpecieErrorMessage: 'Erreur lors de la suppression de cette espèce',
  removeSpecieQuestion:
    'Voulez-vous vraiment retirer cette espèce? Les mutations associées seront également supprimées.',
  retrieveMutationErrorMessage: 'Erreur lors de la récupération de la mutation',
  editMutation: 'Modifier la mutation',
  removeMutation: 'Supprimer la mutation',
  removeMutationErrorMessage: 'Erreur lors de la suppression de cette mutation',
  removeMutationQuestion: 'Voulez-vous vraiment retirer cette mutation?',

  // other error messages
  serverErrorMessage: "Une erreur s'est produite sur le serveur. Nous enquêtons.",

  // input file
  dragAndDrop: 'Glisser déposer',
  or: 'ou',
  uploadImage: 'Importer image',
  uploadImages: 'Importer des images',
  uploadFile: 'Importer un fichier',
  uploadFiles: 'Importer des fichiers',
  errorFileSize: 'Le fichier est trop volumineux',
  errorWhileUploading: 'Impossible de importer ce fichier',

  // hide components
  changeYourPlanAndGetAccess: 'Modifiez votre plan et accédez à de nouvelles fonctionnalités',
  askOtherBreederToContactYou:
    'Informez un autre éleveur que vous êtes intéressé par une mutation spécifique et demandez-lui de vous contacter',
  generatePdfFile:
    'Générer un document prêt à être signé avec les informations des éleveurs et de tous les oiseaux qui seront cédés'
};
