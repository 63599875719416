import { getColors } from '../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  gridContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridAutoColumns: '1fr',
      gridAutoFlow: 'column'
    }
  },
  gridBorderItem: {
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${colors.border}`
    },
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${colors.border}`
    }
  }
});
