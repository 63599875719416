import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { Typography, Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';

// REACT FEATHER
import { Clipboard } from 'react-feather';

// YUP
import * as Yup from 'yup';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// MOMENT
import moment from 'moment';

// SPINE UI
import {
  Select,
  Input,
  FormDialog,
  Content,
  Table,
  PaperInformation,
  PaperListInformation
} from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { fromUTCtoLocal } from '../../../../utils/timeFormat';
import { fetchApi } from '../../../../utils/apiSettings';
import { setAppBarTitle } from '../../../../store/actions/appActions';
import { getCountryName, getGenderName, getMutationName } from '../../../../utils/appSettings';
import ROUTES from '../../../../utils/routes';
import AddClutchForm from './components/AddClutchForm';
import TooltipIconButton from '../../../../components/buttons/TooltipIconButton';
import BirdAvatar from '../../../../components/core/BirdAvatar';
import BirdItem from '../../../../components/core/BirdItem';
import ClutchItem from './components/ClutchItem';

class Couple extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      // breeder information
      coupleUUID: '',

      // couple information
      couple: null,

      // forms
      errors: {},

      // add clutch form
      isAddClutchOpened: false,
      eggsCount: 0,
      description: '',
      startedAt: moment.utc(), // can be null

      // edit couple
      isEditCoupleOpened: false,
      cageIdentifier: '',
      isActive: true,

      // remove couple
      isRemoveCoupleOpened: false
    };

    this.eggsCountInputRef = React.createRef();
  }

  componentDidMount() {
    this.props.setAppBarTitle('couple');

    this.setState(
      {
        coupleUUID: this.props.match.params.id
      },
      () => this.fetchCouple()
    );
  }

  fetchCouple = () => {
    const { intl, enqueueSnackbar } = this.props;

    fetchApi(
      'get',
      `/breeders/v1/couples/${this.state.coupleUUID}`,
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.setState({
          isLoading: false,
          couple: data,

          // edit couple form
          cageIdentifier: data.identifier,
          isActive: data.is_active
        });
      },
      (error) => {
        const { response } = error;

        if (response.status === 404) {
          this.props.history.push(ROUTES.ROOT);
        } else if (response.status === 400) {
          enqueueSnackbar(intl.formatMessage({ id: 'coupleErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        } else {
          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });

          this.props.history.push(ROUTES.ROOT);
        }
      }
    );
  };

  getFinishedClutches = (clutches) => {
    let total = 0;

    clutches.forEach((clutch) => {
      if (clutch.has_finished) total += 1;
    });

    return total;
  };

  openAddClutch = () => {
    this.setState(
      {
        eggsCount: '0',
        description: '',
        startedAt: moment.utc(),
        errors: {},
        isAddClutchOpened: true
      },
      () => {
        this.eggsCountInputRef.current.focus();
      }
    );
  };

  handleAddClutch = async () => {
    const { enqueueSnackbar, intl } = this.props;

    const schema = Yup.object().shape({
      eggsCount: Yup.number().required().moreThan(0),
      startedAt: Yup.date().required(),
      description: Yup.string().notRequired()
    });

    const dataToSubmit = {
      eggsCount: parseInt(this.state.eggsCount, 10),
      startedAt: this.state.startedAt,
      description: this.state.description
    };

    try {
      await schema.validate(dataToSubmit, { abortEarly: false });

      this.setState(
        {
          isLoading: true,
          isAddClutchOpened: false
        },
        () => {
          fetchApi(
            'post',
            `/breeders/v1/couples/${this.state.coupleUUID}/clutches`,
            {},
            {
              started_at: dataToSubmit.startedAt.format(),
              eggs_count: dataToSubmit.eggsCount,
              description: dataToSubmit.description
            },
            false,
            (response) => {
              const { data } = response;

              this.props.history.push(
                `${ROUTES.BREEDER_COUPLES}/${this.state.coupleUUID}/clutches/${data.clutch_id}`
              );

              enqueueSnackbar(intl.formatMessage({ id: 'addClutchSuccessMessage' }), {
                variant: 'success',
                autoHideDuration: 4000
              });
            },
            (error) => {
              const { response } = error;

              this.setState({
                isLoading: false
              });

              if (response.status === 400 || response.status === 404) {
                enqueueSnackbar(intl.formatMessage({ id: 'addClutchErrorMessage' }), {
                  variant: 'error',
                  autoHideDuration: 4000
                });
              } else {
                enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                  variant: 'error',
                  autoHideDuration: 4000
                });
              }
            }
          );
        }
      );
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        this.setState({
          errors: errorMessages
        });
      }
    }
  };

  cancelAddClutch = () => {
    this.setState({
      eggsCount: '0',
      description: '',
      startedAt: moment.utc(),
      errors: {},
      isAddClutchOpened: false
    });
  };

  // EDIT COUPLE
  openEditCouple = () => {
    this.setState((state) => ({
      isEditCoupleOpened: true,
      cageIdentifier: state.couple.identifier,
      isActive: state.couple.is_active,
      errors: {}
    }));
  };

  cancelEditCouple = () => {
    this.setState({
      isEditCoupleOpened: false,
      errors: {}
    });
  };

  handleEditCouple = () => {
    const { intl, enqueueSnackbar } = this.props;

    if (
      this.state.cageIdentifier !== this.state.couple.identifier &&
      (this.state.isActive === false || this.state.isActive === 'false')
    ) {
      this.setState({
        errors: {
          cageIdentifier: intl.formatMessage({ id: 'setCageIdentifierCoupleInactive' })
        }
      });
    } else {
      this.setState(
        {
          isFetching: true
        },
        () => {
          fetchApi(
            'put',
            `/breeders/v1/couples/${this.state.coupleUUID}`,
            {},
            {
              identifier: this.state.cageIdentifier,
              is_active: this.state.isActive
            },
            false,
            (response) => {
              const { data } = response;

              this.setState({
                isFetching: false,
                isEditCoupleOpened: false,

                couple: data,

                // edit couple form
                cageIdentifier: data.identifier,
                isActive: data.is_active
              });

              enqueueSnackbar(intl.formatMessage({ id: 'editCoupleSuccessMessage' }), {
                variant: 'success',
                autoHideDuration: 4000
              });
            },
            (error) => {
              const { response } = error;

              if (response.status === 400) {
                if (response.data.message === 'Identifier already used.') {
                  this.setState({
                    isFetching: false,
                    errors: {
                      cageIdentifier: intl.formatMessage({ id: 'identifierAlreadyUsed' })
                    }
                  });
                } else {
                  this.setState({
                    isFetching: false,
                    isEditCoupleOpened: false
                  });
                  enqueueSnackbar(intl.formatMessage({ id: 'setIsActiveCoupleErrorMessage' }), {
                    variant: 'error',
                    autoHideDuration: 4000
                  });
                }
              } else {
                enqueueSnackbar(intl.formatMessage({ id: 'editCoupleErrorMessage' }), {
                  variant: 'error',
                  autoHideDuration: 4000
                });

                this.setState({
                  isFetching: false,
                  isEditCoupleOpened: false
                });
              }
            }
          );
        }
      );
    }
  };

  // REMOVE COUPLE
  openRemoveCouple = () => {
    this.setState({
      isRemoveCoupleOpened: true
    });
  };

  cancelRemoveCouple = () => {
    this.setState({
      isRemoveCoupleOpened: false
    });
  };

  handleRemoveCouple = () => {
    this.setState(
      {
        isFetching: true
      },
      () => {
        const { intl, enqueueSnackbar } = this.props;

        fetchApi(
          'delete',
          `/breeders/v1/couples/${this.state.coupleUUID}`,
          {},
          {},
          false,
          () => {
            enqueueSnackbar(intl.formatMessage({ id: 'removeCoupleSuccessMessage' }), {
              variant: 'success',
              autoHideDuration: 4000
            });

            this.props.history.push(ROUTES.BREEDER_COUPLES);
          },
          () => {
            enqueueSnackbar(intl.formatMessage({ id: 'removeCoupleErrorMessage' }), {
              variant: 'error',
              autoHideDuration: 4000
            });

            this.setState({
              isFetching: false,
              isRemoveCoupleOpened: false
            });
          }
        );
      }
    );
  };

  handleDateChange = (name, date) => {
    this.setState({
      [name]: date,
      errors: {}
    });
  };

  handleInputChange = (event) => {
    const input = event.target;

    if ((input.name === 'cageIdentifier' || input.name === 'eggsCount') && input.value !== '') {
      this.setState({
        [input.name]: parseInt(input.value, 10),
        errors: {}
      });
    } else {
      this.setState({
        [input.name]: input.value,
        errors: {}
      });
    }
  };

  handleSelectChange = (event) => {
    const input = event.target;

    this.setState({
      [input.name]: input.value,
      errors: {}
    });
  };

  renderPartnersItems = (partners) => {
    const { intl } = this.props;
    const items = [];

    if (partners && partners.length >= 2) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 2; i++) {
        items.push({
          onClick: () => {
            this.props.history.push(
              `${ROUTES.BREEDER_COUPLES}/${this.state.coupleUUID}/parent/${partners[i].bird_id}`
            );
          },
          component: (
            <BirdItem
              withBorder={false}
              imagePreviewPath={partners[i].default_image ? partners[i].default_image : null}
              ring={partners[i].ring}
              year={partners[i].year}
              gender={getGenderName(partners[i].gender, intl)}
              mutationName={getMutationName(partners[i], intl)}
              countryName={getCountryName(
                partners[i].country.id,
                this.props.user.user ? this.props.user.user.locale : null
              )}
              onClick={() => {}}
            />
          )
        });
      }
    }

    return items;
  };

  render() {
    const { intl } = this.props;

    const buttons = [
      {
        label: intl.formatMessage({ id: 'editCouple' }),
        disabled: this.state.isFetching,
        color: 'primary',
        variant: 'contained',
        onClick: this.openEditCouple
      },
      {
        label: intl.formatMessage({ id: 'removeCouple' }),
        disabled: this.state.isFetching,
        color: 'red',
        variant: 'contained',
        onClick: this.openRemoveCouple
      },
      {
        label: intl.formatMessage({ id: 'addClutch' }),
        disabled:
          this.state.isFetching || (this.state.couple !== null && !this.state.couple.is_active),
        color: 'primary',
        variant: 'contained',
        onClick: this.openAddClutch
      }
    ];

    const breadcrumbs = [
      {
        title: intl.formatMessage({ id: 'couples' }),
        active: false,
        href: ROUTES.BREEDER_COUPLES,
        onClick: () => {
          this.props.history.push(ROUTES.BREEDER_COUPLES);
        }
      },
      {
        title: intl.formatMessage({ id: 'couple' }),
        active: true
      }
    ];

    return (
      <Content
        isLoading={this.state.isLoading}
        isFetching={this.state.isFetching}
        breadcrumbs={breadcrumbs}
        buttons={buttons}
      >
        <FormDialog
          isOpened={this.state.isAddClutchOpened}
          title={intl.formatMessage({ id: 'addClutch' })}
          maxWidth={'sm'}
          onConfirm={this.handleAddClutch}
          onCancel={this.cancelAddClutch}
          onConfirmDisabled={this.state.isFetching}
          onCancelDisabled={this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <AddClutchForm
            isLoading={this.state.isLoading || this.state.isFetching}
            eggsCountInputRef={this.eggsCountInputRef}
            eggsCount={this.state.eggsCount}
            description={this.state.description}
            startedAt={this.state.startedAt}
            errors={this.state.errors}
            handleInputChange={this.handleInputChange}
            handleDateChange={this.handleDateChange}
          />
        </FormDialog>
        <FormDialog
          isOpened={this.state.isRemoveCoupleOpened}
          title={intl.formatMessage({ id: 'removeCouple' })}
          maxWidth={'sm'}
          onConfirm={this.handleRemoveCouple}
          onConfirmDisabled={this.state.isFetching}
          onCancel={this.cancelRemoveCouple}
          onCancelDisabled={this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'body1'} color={'textSecondary'} style={{ fontWeight: 'bold' }}>
                {intl.formatMessage({ id: 'removeCoupleNotice' })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} color={'textSecondary'}>
                {intl.formatMessage({ id: 'removeCoupleQuestion' })}
              </Typography>
            </Grid>
          </Grid>
        </FormDialog>
        <FormDialog
          isOpened={this.state.isEditCoupleOpened}
          title={intl.formatMessage({ id: 'editCouple' })}
          maxWidth={'sm'}
          onConfirm={this.handleEditCouple}
          onCancel={this.cancelEditCouple}
          onConfirmDisabled={this.state.isFetching}
          onCancelDisabled={this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Input
                name={'cageIdentifier'}
                type={'number'}
                autoComplete={'off'}
                label={intl.formatMessage({ id: 'cageIdentifier' })}
                value={this.state.cageIdentifier}
                onChange={this.handleInputChange}
                required={true}
                error={!!this.state.errors.cageIdentifier}
                helperText={this.state.errors.cageIdentifier || ''}
                disabled={this.state.isFetching}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                showEmpty={false}
                required={true}
                name={'isActive'}
                label={intl.formatMessage({ id: 'active' })}
                value={this.state.isActive}
                onChange={this.handleSelectChange}
                disabled={this.state.isFetching}
                options={[
                  {
                    key: 'yes',
                    value: true,
                    name: intl.formatMessage({ id: 'yes' })
                  },
                  {
                    key: 'no',
                    value: false,
                    name: intl.formatMessage({ id: 'no' })
                  }
                ]}
              />
            </Grid>
          </Grid>
        </FormDialog>
        {!this.state.isLoading && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <PaperListInformation
                items={[
                  {
                    title: intl.formatMessage({ id: 'cageIdentifier' }),
                    value: this.state.couple.identifier
                  },
                  {
                    title: intl.formatMessage({ id: 'year' }),
                    value: this.state.couple.year
                  },
                  {
                    title: intl.formatMessage({ id: 'state' }),
                    value:
                      this.state.couple !== null && this.state.couple.is_active
                        ? intl.formatMessage({ id: 'active' })
                        : intl.formatMessage({ id: 'inactive' })
                  },
                  {
                    title: intl.formatMessage({ id: 'startDate' }),
                    value: fromUTCtoLocal(this.state.couple.started_at).format('LL')
                  },
                  ...this.renderPartnersItems(this.state.couple.partners)
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PaperInformation
                    title={`${intl.formatMessage({ id: 'clutches' })} (${intl.formatMessage({
                      id: 'finished'
                    })} / ${intl.formatMessage({ id: 'scheduled' })})`}
                    information={`${this.getFinishedClutches(this.state.couple.clutches)} / ${
                      this.state.couple.clutches.length
                    }`}
                    icon={Clipboard}
                    translateTitle={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PaperInformation
                    title={intl.formatMessage({ id: 'children' })}
                    information={this.state.couple.children.length}
                    icon={Clipboard}
                    translateTitle={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Table
                title={intl.formatMessage({ id: 'clutches' })}
                noDataMessage={intl.formatMessage({ id: 'noClutches' })}
                webHeaders={[
                  { title: intl.formatMessage({ id: 'finished' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'totalEggs' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'fertilizedEggs' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'abortedEggs' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'children' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'startDate' }), align: 'left' },
                  { title: '', align: 'right' }
                ]}
                webData={this.state.couple.clutches.map((item) => [
                  item.has_finished
                    ? intl.formatMessage({ id: 'yes' })
                    : intl.formatMessage({ id: 'no' }),
                  item.eggs_count,
                  item.fertilized_count,
                  item.aborted_count,
                  item.children_count,
                  fromUTCtoLocal(item.started_at).format('LL'),
                  <TooltipIconButton
                    title={'view'}
                    iconName={'chevron-right'}
                    handleClick={() => {
                      this.props.history.push(
                        `${ROUTES.BREEDER_COUPLES}/${this.state.coupleUUID}/clutches/${item.clutch_id}`
                      );
                    }}
                  />
                ])}
                // mobiles
                renderMobile={true}
                mobileData={this.state.couple.clutches.map((item) => (
                  <ClutchItem
                    startedAt={item.started_at}
                    hasFinished={item.has_finished}
                    eggsCount={item.eggs_count}
                    childrenCount={item.children_count}
                    onClick={() => {
                      this.props.history.push(
                        `${ROUTES.BREEDER_COUPLES}/${this.state.coupleUUID}/clutches/${item.clutch_id}`
                      );
                    }}
                  />
                ))}
              />
            </Grid>
            <Grid item xs={12}>
              <Table
                title={intl.formatMessage({ id: 'children' })}
                noDataMessage={intl.formatMessage({ id: 'noBirds' })}
                webHeaders={[
                  { title: ' ', align: 'left' },
                  { title: intl.formatMessage({ id: 'ring' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'year' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'gender' }), align: 'left' },
                  {
                    title: intl.formatMessage({ id: 'mutation' }),
                    align: 'left'
                  },
                  {
                    title: intl.formatMessage({ id: 'country' }),
                    align: 'left'
                  },
                  {
                    title: intl.formatMessage({ id: 'state' }),
                    align: 'left'
                  },
                  { title: '', align: 'right' }
                ]}
                webData={this.state.couple.children.map((item) => [
                  <BirdAvatar images={item.default_image ? [item.default_image] : []} />,
                  item.ring,
                  item.year,
                  getGenderName(item.gender, this.props.intl),
                  getMutationName(item, this.props.intl),
                  getCountryName(
                    item.country.id,
                    this.props.user.user ? this.props.user.user.locale : null
                  ),
                  intl.formatMessage({ id: item.state }),
                  <TooltipIconButton
                    title={'view'}
                    iconName={'chevron-right'}
                    handleClick={() => {
                      this.props.history.push(
                        `${ROUTES.BREEDER_COUPLES}/${this.state.coupleUUID}/children/${item.bird_id}`
                      );
                    }}
                  />
                ])}
                // mobiles
                renderMobile={true}
                mobileData={this.state.couple.children.map((item) => (
                  <BirdItem
                    imagePreviewPath={item.default_image ? item.default_image : null}
                    ring={item.ring}
                    year={item.year}
                    gender={getGenderName(item.gender, this.props.intl)}
                    mutationName={getMutationName(item, this.props.intl)}
                    countryName={getCountryName(
                      item.country.id,
                      this.props.user.user ? this.props.user.user.locale : null
                    )}
                    onClick={() => {
                      this.props.history.push(
                        `${ROUTES.BREEDER_COUPLES}/${this.state.coupleUUID}/children/${item.bird_id}`
                      );
                    }}
                  />
                ))}
              />
            </Grid>
          </Grid>
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer,
  user: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(injectIntl(Couple)));
