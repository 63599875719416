import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { withSnackbar } from 'notistack';
import { Grid } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// QUERY STRING
import queryString from 'query-string';

// SPINE UI
import { Content, Table, ConfirmationDialog, Button, Select } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { fetchApi } from '../../../utils/apiSettings';
import { setAppBarTitle } from '../../../store/actions/appActions';
import { getCountries, getCountryName } from '../../../utils/appSettings';

const PER_PAGE_DEFAULT = 25;
const PAGE_SIZES = [15, PER_PAGE_DEFAULT, 50, 100];

class SearchBirds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      birds: [],
      total: 0,

      // sorting
      sortBy: 'specie',
      sortDirection: 'asc',

      // pagination
      page: 0,
      perPage: PER_PAGE_DEFAULT,

      // filter form
      mutation: '',
      specie: '',
      country: '',

      allSpecies: [],
      allMutations: [],
      errors: {},

      // send email form
      isSendEmailOpened: false,
      mutationEmail: '',
      userIdEmail: null
    };
  }

  componentDidMount() {
    this.props.setAppBarTitle('searchBirds');

    this.parsePaginationAndFiltersFromURLParameters(() => {
      Promise.all([this.fetchAllSpecies()]).then(() => {
        this.setState({
          isLoading: false
        });
      });
    });
  }

  fetchSearchBirds = () => {
    const { enqueueSnackbar, intl } = this.props;

    const params = {
      per_page: this.state.rowsPerPage,
      page_number: this.state.page,
      order_field: this.state.orderBy,
      order_direction: this.state.orderDirection
    };

    if (this.state.specie) {
      params.specie_id = this.state.specie;
    }

    if (this.state.mutation) {
      params.mutation_id = this.state.mutation;
    }

    if (this.state.country) {
      params.breeder_country_id = this.state.country;
    }

    let endpoint = `/v1/search/birds?`;
    endpoint += queryString.stringify(params);

    return new Promise((resolve) => {
      fetchApi(
        'get',
        endpoint,
        {},
        {},
        false,
        (response) => {
          const { data } = response;

          this.setState(
            {
              birds: data.data,
              total: data.total_count
            },
            () => resolve()
          );
        },
        () => {
          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });

          resolve();
        }
      );
    });
  };

  fetchAllSpecies = () => {
    const { enqueueSnackbar, intl } = this.props;

    return new Promise((resolve) => {
      fetchApi(
        'get',
        '/v1/public/species',
        {},
        {},
        false,
        (response) => {
          const { data } = response;

          this.setState(
            {
              allSpecies: data
            },
            () => resolve()
          );
        },
        (error) => {
          const { response } = error;

          if (response.status === 400) {
            enqueueSnackbar(intl.formatMessage({ id: 'speciesErrorMessage' }), {
              variant: 'error',
              autoHideDuration: 4000
            });
          } else {
            enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
              variant: 'error',
              autoHideDuration: 4000
            });
          }
        }
      );
    });
  };

  parsePaginationAndFiltersFromURLParameters = (handle) => {
    const parsed = queryString.parse(window.location.search);
    if (parsed) {
      this.setState(
        {
          // pagination
          page: parsed.page !== undefined ? parseInt(parsed.page, 10) : 0,
          perPage: parsed.per_page !== undefined ? parseInt(parsed.per_page, 10) : PER_PAGE_DEFAULT,
          sortBy: parsed.sort_by || 'specie',
          sortDirection: parsed.sort_direction || 'desc'
        },
        () => {
          handle();
        }
      );
    }
  };

  setURLParameters = () => {
    this.props.history.push(
      `${window.location.pathname}?${queryString.stringify({
        // pagination
        page: this.state.page,
        per_page: this.state.perPage,
        sort_by: this.state.sortBy,
        sort_direction: this.state.sortDirection
      })}`
    );
  };

  handleTableChange = (page, perPage, sortBy, sortDirection) => {
    this.setState(
      {
        isFetching: true,
        page: page,
        perPage: perPage,
        sortBy: sortBy,
        sortDirection: sortDirection
      },
      () => {
        this.setURLParameters();
        Promise.all([this.fetchSearchBirds()]).then(() => {
          this.setState({ isFetching: false });
        });
      }
    );
  };

  handleSelectChange = (event) => {
    const input = event.target;

    if (input.name === 'specie' && input.value === '') {
      this.setState({
        specie: '',
        mutation: '',
        birds: [],
        errors: {}
      });
    } else {
      this.setState(
        {
          isFetching: true,
          [input.name]: input.value,
          errors: {}
        },
        () => {
          if (input.name === 'specie') {
            this.setState((state) => {
              const specie = state.allSpecies.find((s) => s.id === parseInt(input.value, 10));
              return {
                isFetching: false,
                allMutations: specie.mutations
              };
            });
          } else {
            this.setURLParameters();
            Promise.all([this.fetchSearchBirds()]).then(() => {
              this.setState({ isFetching: false });
            });
          }
        }
      );
    }
  };

  handleSendEmail = () => {
    const { enqueueSnackbar, intl } = this.props;

    this.setState(
      {
        isFetching: true,
        isSendEmailOpened: false
      },
      () => {
        fetchApi(
          'post',
          '/v1/search/birds/notify',
          {},
          {
            user_id: this.state.userIdEmail,
            mutation_name: this.state.mutationEmail
          },
          false,
          () => {
            this.setState({
              isFetching: false
            });

            enqueueSnackbar(intl.formatMessage({ id: 'sendEmailSuccessMessage' }), {
              variant: 'success',
              autoHideDuration: 4000
            });
          },
          (error) => {
            const { response } = error;

            this.setState({
              isFetching: false
            });

            if (response.status === 400) {
              enqueueSnackbar(intl.formatMessage({ id: 'birdsErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            } else {
              enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            }
          }
        );
      }
    );
  };

  render() {
    const { intl } = this.props;

    const hideBasedOnPlan = ['FREE', 'BASIC'].includes(
      this.props.user.user ? this.props.user.user.plan : ''
    );

    return (
      <Content isLoading={this.state.isLoading} isFetching={this.state.isFetching}>
        <ConfirmationDialog
          isOpened={this.state.isSendEmailOpened}
          title={intl.formatMessage({ id: 'showInterest' })}
          maxWidth={'sm'}
          message={intl.formatMessage(
            {
              id: 'sendEmailQuestion'
            },
            { mutation: <b>{this.state.mutationEmail}</b> }
          )}
          translateMessage={false}
          onConfirm={this.handleSendEmail}
          onCancel={() => {
            this.setState({
              isSendEmailOpened: false,
              mutationEmail: '',
              userIdEmail: null
            });
          }}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          disabled={this.state.isLoading || this.state.isFetching}
        />
        {!this.state.isLoading && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Select
                    name={'specie'}
                    label={intl.formatMessage({ id: 'specie' })}
                    value={this.state.specie}
                    onChange={this.handleSelectChange}
                    error={!!this.state.errors.specie}
                    helperText={this.state.errors.specie || ''}
                    disabled={this.state.isLoading || this.state.isFetching || hideBasedOnPlan}
                    autoComplete={'off'}
                    options={this.state.allSpecies.map((specie) => {
                      return {
                        key: specie.id,
                        value: specie.id,
                        name: specie.name
                      };
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Select
                    name={'mutation'}
                    label={intl.formatMessage({ id: 'mutation' })}
                    value={this.state.mutation}
                    onChange={this.handleSelectChange}
                    error={!!this.state.errors.mutation}
                    helperText={this.state.errors.mutation || ''}
                    disabled={
                      this.state.isLoading ||
                      this.state.isFetching ||
                      this.state.specie === '' ||
                      hideBasedOnPlan
                    }
                    autoComplete={'off'}
                    options={this.state.allMutations.map((mutation) => {
                      return {
                        key: mutation.id,
                        value: mutation.id,
                        name: mutation.name
                      };
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Select
                    emptyLabel={intl.formatMessage({ id: 'all' })}
                    name={'country'}
                    label={intl.formatMessage({ id: 'country' })}
                    value={this.state.country}
                    onChange={this.handleSelectChange}
                    error={!!this.state.errors.country}
                    helperText={this.state.errors.country || ''}
                    disabled={this.state.isLoading || this.state.isFetching || hideBasedOnPlan}
                    autoComplete={'off'}
                    options={getCountries(
                      this.props.user.user ? this.props.user.user.locale : null
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Table
                webHeaders={[
                  {
                    title: intl.formatMessage({ id: 'breeder' }),
                    align: 'left',
                    sort: true,
                    sortKey: 'breeder'
                  },
                  {
                    title: intl.formatMessage({ id: 'country' }),
                    align: 'left',
                    sort: true,
                    sortKey: 'country'
                  },
                  {
                    title: intl.formatMessage({ id: 'specie' }),
                    align: 'left',
                    sort: true,
                    sortKey: 'specie'
                  },
                  { title: '', align: 'right' }
                ]}
                webData={this.state.birds.map((item) => [
                  item.breeder_name,
                  item.breeder_country_id
                    ? getCountryName(
                        item.breeder_country_id,
                        this.props.user.user ? this.props.user.user.locale : null
                      )
                    : '-',
                  `${item.specie_name} | ${item.mutation_name}`,
                  <Button
                    key={item.id}
                    label={intl.formatMessage({ id: 'showInterest' })}
                    type={'button'}
                    color={'primary'}
                    variant={'contained'}
                    disabled={this.state.isFetching || this.state.isLoading}
                    onClick={() => {
                      this.setState({
                        isSendEmailOpened: true,
                        mutationEmail: `${item.specie_name} - ${item.mutation_name}`,
                        userIdEmail: item.breeder_id
                      });
                    }}
                  />
                ])}
                noDataMessage={intl.formatMessage({ id: 'noBirds' })}
                // handle changes
                onChange={this.handleTableChange}
                // sorting
                useSorting={true}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                // pagination
                usePagination={true}
                totalCount={this.state.total}
                page={this.state.page}
                rowsPerPage={this.state.perPage}
                rowsPerPageOptions={PAGE_SIZES}
              />
            </Grid>
          </Grid>
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer,
  user: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(injectIntl(SearchBirds)));
