// ITALIAN

export default {
  // app
  logout: 'Disconnettersi',
  view: 'Visualizza',
  add: 'Aggiungi',
  remove: 'Rimuovere',
  cancel: 'Annulla',
  confirm: 'Confermare',
  next: 'Il prossimo',
  back: 'Indietro',
  yes: 'Sì',
  no: 'No',
  undefined: 'Non definito',
  edit: 'Modificare',
  goBack: 'Torna indietro',
  close: 'Vicino',
  rowsPerPage: 'Righe per pagina',
  of: 'di',
  home: 'Home',
  startNow: 'Registrati',

  // sidebar
  dashboard: 'Pannello di controllo',
  users: 'Utenti',
  account: 'Account',
  others: 'Altri',
  plans: 'Piani',
  transfers: 'Trasferimenti',

  // home
  birdsPortal: 'Portale completo di gestione aviaria',
  breedersCollaboration: 'Collaborazione con allevatori di uccelli',
  birdsManagement: 'Gestione degli uccelli',
  couplesAndClutchesManagement: 'Gestione coppie e frizioni',
  birdTransfers: 'Trasferimenti di uccelli',
  searchBreedersWithSpecificSpecies: 'Cerca allevatori con specie specifiche',

  mainFunctionalities: 'Caratteristiche principal',
  availableForBreeder: 'Disponibile per tutti gli allevatori',
  manageBirdsStock: 'Gestione degli stock di uccelli',
  manageCouples: 'Gestione delle coppie',
  manageClutches: 'Gestire la stagione riproduttiva',
  efficientSearch: 'Ricerca efficiente',
  birdProfile: 'Registro individuale degli uccelli',
  simpleTransfer: 'Processo di trasferimento semplice',

  // public plans
  increaseProductivity:
    "Aumenta la produttività e il controllo durante l'allevamento degli uccelli",
  freeDescription:
    'È gratis per sempre. Il piano perfetto per iniziare a utilizzare alcune delle nostre funzionalità.',
  basicDescription:
    'IIdeale per chi vuole iniziare questa attività e iniziare a gestire facilmente la propria voliera.',
  premiumDescription: 'Ideale per chi vuole raggiungere il livello professionale.',
  platinumDescription: 'Il piano perfetto per chi cerca di oltrepassare il limite.',
  manageInfiniteNumberOfBirds: 'Gestisci un numero infinito di uccelli',
  manageCouplesUpTo: 'Gestisci fino a {number} coppie',
  createTransferRequests:
    'Crea ordini e documenti correlati per trasferire gli uccelli a un altro allevatore',
  searchForOtherBirdsFromOtherBreeders: 'Cerca altri uccelli di altri allevatori',
  year: 'anno',

  // login
  login: 'Accedi',
  email: 'E-mail',
  password: 'Password',
  rememberMe: 'Ricordati di me',
  forgotPassword: 'Hai dimenticato la password?',
  invalidEmail: 'Questa email non è valida.',
  emailIsRequired: "L'email è richiesta.",
  passwordIsRequired: 'La password è richiesta',
  invalidCredentials: 'Credenziali non valide.',

  // species
  meetTheSupportedSpecies: 'Incontra le specie presenti sulla piattaforma',
  weAreKeenToSupportMore: 'Scrivici a {email} se desideri aggiungerne più specie.',

  // register
  perfectPlatform: 'La piattaforma perfetta per gestire i tuoi uccelli',
  ourMission:
    'La nostra missione è aiutare gli allevatori a gestire facilmente la loro voliera. Abbiamo molte funzionalità disponibili:',
  createYourFreeAccount: 'Crea il tuo account gratuito',
  createAccount: 'Creare account',
  acceptTermsAndConditionsRegister: "Andando avanti sei d'accordo con",
  termsAndConditions: 'Termini & Condizioni',
  registerErrorEmailAlreadyUsed: 'Questa email è già in uso.',
  registerErrorStripeProblem: 'Errore con API esterna. Per favore riprova.',
  registerErrorConfirmationRegistrationCode: 'Questo codice di registrazione non è valido.',
  registerErrorInvalidEmail: 'Questa email non è valida',
  registerErrorInvalidRecaptchaToken: 'Il ReCaptcha non è valido.',
  invalidPassword: 'La password deve contenere almeno 5 caratteri',
  registerSuccess: 'Controlla la tua email e inserisci il codice di registrazione.',
  registrationCode: 'Codice di registrazione',
  confirmRegistrationCode: 'Conferma il codice',

  // forgot password
  sendResetInstructions: 'Invia istruzioni di ripristino',
  backToLogin: 'Torna al login',
  resetPasswordRequestInfoMessage: "Ti è stata inviata un'e-mail per reimpostare la password",
  resetPassword: 'Resetta la password',
  resetRequestErrorMessage: 'La richiesta di reimpostazione della password non è più valida',

  // users
  addUser: 'Aggiungi utente',
  noUsers: 'Non ci sono utenti',
  emailConfirmed: 'Email confermata',
  country: 'Paese',
  userErrorMessage: 'Utente non trovato',

  // users add
  contact: 'Contatto',
  birthday: 'Data di nascita',

  // breeder dashboard
  hiBreeder: 'Ciao {breederName}',
  numberOfBirds: 'Uccelli',
  numberOfCouples: 'Coppie',
  numberOfClutches: 'Posture',
  youCurrentlyHave:
    "Attualmente hai il piano {plan}. Se desideri iscriverti a un'altra soluzione, visita la nostra {plansPage}.",
  plansPage: 'pagina dei piani',
  numberOfTransfers: 'Trasferimenti',
  numberOfDead: 'Morto',

  // account
  language: 'Linguaggio',
  registeredAt: 'Registrato a',
  updatedAt: 'Aggiornato a',
  changePassword: 'Cambia la password',
  currentPassword: 'Password attuale',
  newPassword: 'Nuova password',
  confirmPassword: 'Conferma password',
  passwordsDontMatch: 'Le password non corrispondono.',
  editAccount: 'Modifica account',
  changedPasswordErrorMessage: 'La password attuale non è corretta.',
  changedPasswordSuccessMessage: 'La password è stata modificata con successo.',
  changedPersonalInformationInfoMessage: 'Le tue informazioni personali sono state modificate.',
  breederIdentifiers: 'Identificatori degli allevatori',
  addBreederIdentifier: 'Aggiungi identificatore',
  setAsDefault: 'Imposta come predefinito',
  isDefault: 'Valore predefinito',
  breederIdentifier: 'Idenfiticatore allevatore',
  removeBreederIdentifier: 'Rimuovi identificatore allevatore',
  removeBreederIdentifierSuccessMessage: "L'identificatore è stato rimosso",
  removeBreederIdentifierErrorMessage: "Non è stato possibile rimuovere l'identificatore",
  removeBreederIdentifierQuestion: 'Sei sicuro di voler rimuovere questo identificatore?',
  setAsDefaultQuestion: 'Sei sicuro di voler impostare come predefinito questo identificatore?',
  setAsDefaultBreederIdentifierSuccessMessage:
    "L'identificatore è stato impostato come predefinito",
  setAsDefaultBreederIdentifierErrorMessage:
    "Non è stato possibile impostare come predefinito l'identificatore",
  editBreederIdentifier: 'Modifica identificatore allevatore',
  editBreederIdentifierSuccessMessage: "L'identificatore del selezionatore è stato modificato",
  breederIdentifierErrorMessage: "L'identificatore del selezionatore è già utilizzato",
  addBreederIdentifierSuccessMessage: 'È stato aggiunto un nuovo identificatore di allevatore',
  identifierDescription:
    "L'identificatore rappresenta un codice assegnato a un creatore da " +
    "un'associazione / federazione / altri.",
  identifierDescription2: "L'identificatore verrà utilizzato durante la creazione di un uccello.",
  noIdentifiers: 'Nessun identificatore',
  closeAccount: "Chiudere l'account",
  closeAccountInformation:
    'La chiusura del tuo account è definitiva e non può essere annullata! ' +
    'Questo rimuoverà definitivamente tutti i dati associati per il tuo utente. Se hai un ' +
    'abbonamento, lo annulleremo immediatamente.',
  closeAccountKeepBirds:
    'Le informazioni relative ai trasferimenti di uccelli verranno conservate.',
  closeAccountEmailWillBeSent:
    "Per motivi di sicurezza, invieremo un'e-mail contenente un codice che dovrai inserire per " +
    'confermare che desideri chiudere il tuo account.',
  confirmationCode: 'Codice di conferma',

  // plans
  managePlan: 'Gestisci piano',
  plan: 'Piano',
  free: 'Gratuito',
  basic: 'Di base',
  premium: 'Premium',
  platinum: 'Platino',
  custom: 'Personalizzato',
  select: 'Selezionare',
  actualPlan: 'Piano attuale',

  // email confirmation
  emailConfirmation: 'Conferma via email',
  emailWasConfirmed: 'La tua email è stata confermata con successo.',
  emailWasNotConfirmed:
    'Si è verificato un problema durante il tentativo di confermare la tua email. Se non hai ancora confermato, richiedi nuovamente un link di conferma.',
  requestConfirmation: 'Richiedi nuova conferma',

  // admin dashboard
  activeUsers: 'Utenti attivi',
  accountsClosed: 'Conti chiusi',
  top10CountriesWithBreeders: 'Top 10 paesi con riproduttori attivi',
  top10CountriesWithBirds: 'Top 10 paesi con uccelli attivi',
  top10SpeciesWithBirds: 'Top 10 specie con uccelli attivi',

  // breeder users
  name: 'Nome',
  addedAt: 'Aggiunto a',
  active: 'Attivo',
  addUserIfNotExist:
    "L'utente verrà aggiunto alla tua lista dopo che l'utente avrà accettato la richiesta. Assicurati che l'email dell'utente sia corretta.",
  addUserRecommendation:
    "Se l'utente è già registrato sulla nostra piattaforma, chiedigli l'email.",
  searchBy: 'Cercato da',
  searchByNameEmailCountry: "Nome, email o paese dell'utente",

  // breeder birds
  addBird: 'Aggiungi un Uccello',
  myBirds: 'I miei uccelli',
  searchBirds: 'Cerca uccelli',
  transferRequests: 'Richieste di trasferimento',
  breeder: 'Allevatore',
  ring: 'Anello',
  gender: 'Genere',
  specie: 'Specie',
  mutation: 'Mutazione',
  noBirds: 'Non ci sono uccelli',
  searchByRing: 'Anello',
  birthdayStart: 'Inizio del compleanno',
  birthdayEnd: 'Fine del compleanno',
  genderAndCountry: 'Genere e Paese',
  birdsErrorMessage: 'Errore durante il recupero dei tuoi uccelli',
  speciesErrorMessage: 'Errore durante il recupero di tutte le specie',
  mutationsErrorMessage: 'Errore durante il recupero di tutte le mutazioni',
  filterSearch: 'Filtri',
  addBirdSuccessMessage: 'È stato registrato un nuovo uccello',
  addBirdErrorMessage: 'Non è stato possibile aggiungere un nuovo uccello',
  observations: 'Osservazioni',
  initialData: 'Dati iniziali',
  isYellow: 'È giallo',
  isWhiteHead: 'È la testa bianca',
  isCarduelisSpecieHeader:
    'Hai selezionato la specie Carduelis Carduelis. Seleziona se il tuo uccello è giallo o ha la testa bianca',
  yellow: 'Giallo',
  whiteHead: 'Testa bianca',
  addBreederIdentifierAddNewBirdMessage:
    'Se si desidera aggiungere o non si dispone di un identificatore, fare clic su ',
  acquiredAt: 'Acquisito a',
  firstOwner: 'Primo proprietario',
  owner: 'Proprietario',
  firstOwnerInformation: 'Informazioni sul primo proprietario',
  firstOwnerInformationMessage:
    "Quando aggiungi un uccello, puoi indicare se possiedi l'uccello o se è stato acquistato.",
  ringTotal: 'Anello totale',
  ringAlreadyUsed: 'Anello già utilizzato',
  number: 'Numero',
  extraInformation: 'Ulteriori informazioni',
  breederName: "Nome dell'allevatore",
  invalidDate:
    "Formato data non valido. È necessario specificare il giorno, il mese e l'anno (dd/mm/yyyy).",
  birdInformation: 'Informazioni sugli uccelli',
  carrierOfMutations: 'Portatore di',
  possiblyCarrierOfMutations: 'Possibilmente portatore di',

  // bird states
  state: 'Stato',
  transferred: 'Venduto',
  transferredAt: 'Venduto a',
  death: 'Morto',
  deathAt: 'Morto a',
  all: 'Tutto',

  // bird information
  children: 'Giovani',
  clutches: 'Posture',
  parents: 'Genitori',
  parent: 'Genitore',
  parent1: 'Padre 1',
  parent2: 'Padre 2',
  acquired: 'Acquisito',
  editBirdSuccessMessage: 'Questo uccello è stato modificato con successo.',
  editBirdErrorMessage: 'Non è stato possibile modificare questo uccello.',
  documents: 'Documenti',
  editBird: 'Modifica uccello',
  noDocuments: 'Questo uccello non ha documenti allegati',
  document: 'Documento',
  noComments: 'Non ci sono commenti',
  removeBird: 'Rimuovi uccello',
  onlyOneFieldFillError: 'Puoi riempire solo un campo',
  removeBirdQuestion: 'Sei sicuro di voler rimuovere questo uccello?',
  removeBirdSuccessMessage: "L'uccello è stato rimosso con successo",
  removeBirdErrorMessage: 'Non è stato possibile rimuovere questo uccello.',
  removeBirdHasChildrenErrorMessage:
    "L'uccello ha figli. Se desideri modificare le informazioni sugli uccelli, aggiorna queste sezioni di seguito.",
  removeBirdWasTransferredErrorMessage:
    "L'uccello è stato trasferito a te. Se desideri modificare le informazioni sugli uccelli, aggiorna queste sezioni di seguito. ",
  declareDeath: 'Dichiarare la morte',
  declareDeathSuccessMessage: "L'uccello è stato dichiarato morto con successo.",
  removeImageSuccessMessage: "L'immagine è stata rimossa con successo.",

  transfersHistory: 'Cronologia dei trasferimenti',
  transfer: 'Transferimento',
  transferLine: 'Transferito in {country} nel {date}.',
  transferLineFrom: 'Transferito da {country} nel {date}.',
  removeImage: 'Rimuovi immagine',
  removeImageQuestion: 'Sei sicuro di voler rimuovere questa immagine?',
  setImageAsDefault: 'Imposta immagine come predefinita',
  setImageAsDefaultQuestion: 'Sei sicuro di voler impostare questa immagine come predefinita?',
  getBirdErrorMessage: "L'uccello non esiste o non hai accesso ad esso.",

  // bird couples
  couples: 'Coppie',
  noCouples: 'Nessuna coppia',
  addCoupleErrorMessagePlanDontAllow: 'Controlla se il tuo piano ti consente di farlo.',
  addCoupleErrorMessage:
    'Si è verificato un errore durante la creazione della coppia. Verifica di aver inserito correttamente i dati.',
  parentsAreBrothers: 'Questi uccelli sono fratelli o fratellastri.',
  couplesErrorMessage: 'Errore durante il recupero delle tue coppie',
  coupleErrorMessage: 'Errore durante il recupero della tua coppia',
  couplesSuccessMessage: 'Una coppia è stata creata con successo.',
  inactive: 'Inattivo',
  addCouple: 'Aggiungi coppia',
  addCoupleInformation:
    'I seguenti uccelli devono essere già registrati sulla nostra piattaforma. Se vuoi registrarli in anticipo, clicca ',
  here: 'qui.',
  chooseParents: 'Scegli i genitori',
  searchByParentsRing: 'Identificatore di coppia',
  male: 'Maschio',
  female: 'Femmina',
  unknown: 'Sconosciuto',
  identifier: 'Identificatore',
  identifierIsRequired: 'Identifier is required.',
  identifierMoreThan: "L'identificatore deve essere maggiore di 0",
  identifierAlreadyUsed: 'Identificatore già utilizzato',
  editIdentifier: 'Modifica identificatore',
  editCouple: 'Modifica coppia',
  editCoupleSuccessMessage: 'La coppia è stata modificata con successo.',
  editCoupleErrorMessage:
    'Si è verificato un problema durante il tentativo di modificare questa coppia.',
  setCageIdentifierCoupleInactive:
    "Questa coppia è inattiva o lo sarà. Non è possibile modificare l'identificatore della gabbia.",
  setIsActiveCoupleErrorMessage:
    'Impossibile riattivare questa coppia. Uno dei genitori è morto o è stato trasferito. ',
  removeCouple: 'Rimuovi coppia',
  removeCoupleNotice:
    "PConsidera l'idea di impostare questa coppia come inattiva (in 'Modifica coppia'). Manterremo gli uccelli correlati ma rimuoveremo le frizioni e perderai traccia delle relazioni degli uccelli.",
  removeCoupleQuestion: 'Fingi di rimuovere questa coppia? Questo è irreversibile!',
  removeCoupleSuccessMessage: 'La coppia è stata rimossa con successo.',
  removeCoupleErrorMessage:
    'Si è verificato un problema durante il tentativo di rimuovere questa coppia.',

  // couple information
  addChild: 'Aggiungi giovani',
  addClutch: 'Aggiungi posture',
  couple: 'Coppia',
  scheduled: 'Programmata',
  finished: 'Finita',
  addChildErrorMessage: 'Non è stato possibile aggiungere un nuovo giovani',
  addChildCoupleInactive:
    'Questa coppia è inattiva. È possibile solo aggiungere un nuovo figlio da collegare a una posture esistente.',
  addChildSuccessMessage: 'A questa coppia è stato aggiunto un nuovo giovani',
  addCoupleChildInformationHeader:
    'Il giovani che stai per creare non è legato a nessuna posture. Se vuoi collegarlo a qualsiasi posture, clicca ',
  child: 'Giovani',
  cageIdentifier: 'Identificatore della gabbia',
  cage: 'Gabbia',
  qrCodeMessage:
    'Usa questo codice QR per identificare questa coppia e accedere rapidamente a questa pagina.',

  // clutches
  totalEggs: 'Uova totali',
  startDate: "Data d'inizio",
  description: 'Descrizione',
  addClutchSuccessMessage: 'A questa coppia è stata aggiunta una nuova posture',
  addClutchErrorMessage: 'Non è stato possibile aggiungere una nuova posture',
  addClutchCoupleInactive: 'Questa coppia è inattiva. Non è possibile aggiungere nuove posture.',
  noClutches: 'Niente posture',
  clutchesErrorMessage: 'Errore nel recupero delle posture da questa coppia',
  childrenCount: '{eggsCount} uova e {childrenCount} bambini',
  onGoing: 'In corso',

  // clutch
  clutch: 'Posture',
  editClutch: 'Modifica la posture',
  abortedEggs: 'Uova abortite',
  fertilizedEggs: 'Uova fecondate',
  removeClutch: 'Rimuovere la posture',
  totalEggsExceeded: 'Uova totali superate',
  editClutchSuccessMessage: 'I dati della posture sono stati aggiornati correttamente',
  editClutchErrorMessage: 'Non è stato possibile aggiornare i dati della posture',
  addClutchChildHeader:
    'Puoi aggiungere un giovani a questa posture solo se aggiungi più uova fecondate.',

  // birds market
  breederEmail: 'E-mail allevatore',
  searchByBreederEmail: 'E-mail allevatore',
  breederInformation: "Informazioni sull'allevatore",
  bird: 'Uccello',
  transferRequest: 'Richiesta di trasferimento',
  transferRequestSuccessMessage: 'Il trasferimento è stato creato con successo.',
  transferRequestErrorMessage:
    'Si è verificato un problema durante il tentativo di creare il trasferimento.',
  transferNumberAlreadyExists: "Questo numero esiste già per l'anno in corso.",
  specieRequired: 'Devi scegliere una specie',
  mutationRequired: 'Devi scegliere una mutazione',
  showInterest: 'Mostrare interesse',
  sendEmailQuestion:
    "Verrà inviata un'e-mail all'allevatore con le informazioni sul tuo interesse per la " +
    'mutazione {mutation}. Il tuo nome e la tua email verranno condivisi. Vuoi inviarlo?',
  sendEmailSuccessMessage: "L'email è stata inviata con successo.",

  // transfer requests
  createTransfer: 'Crea trasferimento',
  holder: 'Titolare',
  newOwner: 'Nuovo proprietario',
  birdMutation: 'Mutazione degli uccelli',
  noTransferRequests: 'Nessuna richiesta di trasferimento',
  transferRequestsErrorMessage: 'Errore durante il recupero delle richieste di trasferimento',
  chooseBirds: 'Scegli gli uccelli',
  questionBreederUser: "Non sei un utente dell'applicazione?",
  addBreederInformation: "Aggiungi le informazioni sull'allevatore",
  zipCode: 'Codice postale',
  address: 'Indirizzo',
  birds: 'Uccelli',
  sent: 'Inviato',
  received: 'Ricevuto',
  type: 'Il tipo',
  pending: 'In sospeso',
  accepted: 'Accettato',
  refused: 'Rifiutato',
  removed: 'Cancellato',
  transfersUnderMaintenance: 'Trasferimenti in manutenzione',
  transfersUnderMaintenanceText:
    'I trasferimenti torneranno online il prima possibile. Stiamo lavorando su trasferimenti specifici per paese e garantiremo la migliore esperienza possibile ai nostri utenti. Sarai capace di:',
  weAreVeryGrateful: 'Siamo molto grati per la vostra fiducia in noi e nel nostro sito.',
  pleaseContact: 'Si prega di contattare {mailto} in caso di domande.',

  // transfer request
  acceptTransfer: 'Accetta trasferimento',
  refuseTransfer: 'Rifiuta trasferimento',
  removeTransfer: 'Rimuovi trasferimento',
  downloadTransferDocument: 'Documento di trasferimento',
  sharedDocuments: 'Documenti condivisi',
  registerDate: 'Data di registrazione',
  transferNumberOfBirds: 'Numero di uccelli',
  accessCodeTitle: 'Codice di accesso alle informazioni',
  accessCodeMessage:
    'Nella procedura di trasferimento è possibile fornire i propri dati personali al proprietario degli uccelli in modo molto semplice: è sufficiente fornire il codice sottostante che verrà richiesto durante la creazione del trasferimento.',
  accessCodeDuration:
    'Questo codice viene generato automaticamente ogni 15 secondi e può essere utilizzato solo una volta.',
  removeTransferMessage: 'Sei sicuro di voler rimuovere questo trasferimento?',
  removeTransferError: 'Impossibile eliminare questo trasferimento. È stato approvato o rifiutato.',
  removeTransferSuccess: 'Il trasferimento è stato rimosso con successo.',

  // create transfer request
  isRegistered: 'È registrato sulla piattaforma',
  receptorCode: 'Codice di accesso ai dati del proprietario',
  receptorCodeHelperText: "Questo codice si trova nella pagina dei trasferimenti dell'utente.",
  receptorGetData: 'Ottieni dati',
  transferNumber: 'Numero di trasferimento',
  createTransferInfo1:
    "Il documento di trasferimento si basa sul paese dell'utente che lo crea. Stiamo gestendo attivamente questi documenti. Se ritieni che nel tuo Paese manchino dei campi obbligatori, indicali via email a {email}.",
  createTransferInfo2: 'Potrai creare un trasferimento solo una volta terminato il precedente.',
  createTransferInfo3: 'I campi contrassegnati da * sono obbligatori.',
  createTransferInfo4:
    "Il numero di bonifico viene generato automaticamente ma è possibile modificarlo e deve essere univoco nell'anno in corso.",
  createTransferInfo5:
    'È possibile creare trasferimenti per persone non registrate sulla piattaforma. In questo caso, il trasferimento verrà chiuso automaticamente e gli uccelli verranno contrassegnati come trasferiti.',
  createTransferInfo6:
    "Se il nuovo titolare è registrato sulla piattaforma, è necessario che indichi il codice generato nell'area di trasferimento per ottenere i suoi dati personali. Questo processo consente di trasferire automaticamente gli uccelli al nuovo proprietario dopo l'approvazione.",
  breederNotFoundOrInvalidCode: 'Allevatore non trovato o codice non valido.',

  removeTransferRequest: 'Rimuovi richiesta di trasferimento',
  breederRequesterInformation: 'Informazioni sul richiedente allevatore',
  breederReceiverInformation: "Informazioni sull'allevatore",
  stam: 'STAM',
  breederCode: 'Registro del Governo del creatore (codice)',
  removeTransferRequestQuestion:
    'Sei sicuro di voler rimuovere la richiesta di trasferimento? Il ricevitore non sarà in grado di confermare il trasferimento di questi uccelli.',
  removeTransferRequestSuccessMessage: 'La richiesta di trasferimento è stata rimossa con successo',
  removeTransferRequestErrorMessage:
    'Non è stato possibile rimuovere la richiesta di trasferimento',
  addMoreBreederInformation: 'Aggiungi informazioni personali',
  addMoreBreederInformationMessage:
    'È necessario inserire in precedenza alcuni dettagli sul proprio profilo (indirizzo, codice postale, paese). Inserisci questi dati ',

  // public transfer request
  transferRequestConfirmation: 'Conferma della richiesta di trasferimento',
  confirmTransfer: 'Conferma la richiesta di trasferimento',
  transferRequestConfirmationSuccessMessage: 'Richiesta di trasferimento confermata',
  transferRequestCancelSuccessMessage: 'Richiesta di trasferimento annullata',
  transferRequestConfirmationErrorMessage:
    'Non è stato possibile confermare la richiesta di trasferimento',
  transferRequestCancelErrorMessage:
    'Non è stato possibile annullare la richiesta di trasferimento',
  missingDataBreederReceiverErrorMessage:
    'Il destinatario non ha ancora inserito i dati obbligatori nel proprio profilo.',

  // admin
  species: 'Specie',
  addSpecie: 'Aggiungi specie',
  noSpecies: 'Nessuna specie',
  mutations: 'Mutazioni',
  addMutation: 'Aggiungi la mutazione',
  noMutations: 'Nessuna mutazione',
  image: 'Immagine',
  addSpecieSuccessMessage: 'È stata registrata una nuova specie',
  addSpecieErrorMessage: 'Questa specie esiste già.',
  retrieveSpeciesErrorMessage: 'Errore durante il recupero della specie',
  retrieveSpecieErrorMessage: 'Errore durante il recupero della specie',
  hasClutchDurationDays: 'Aggiungi giorni di durata della postura',
  days: 'Giorni',
  clutchDurationDays: 'Durata della postura (giorni)',
  addMutationErrorMessage: 'Questa mutazione esiste già.',
  editSpecie: 'Modifica specie',
  editSpecieSuccessMessage: 'La specie è stata modificata con successo',
  removeSpecie: 'Rimuovi specie',
  removeSpecieErrorMessage: 'Errore durante la rimozione di questa specie',
  removeSpecieQuestion:
    'Sei sicuro di voler rimuovere questa specie? Verranno eliminate anche le mutazioni correlate.',
  retrieveMutationErrorMessage: 'Errore durante il recupero della mutazione',
  editMutation: 'Modifica mutazione',
  removeMutation: 'Rimuovi mutazione',
  removeMutationErrorMessage: 'Errore nella rimozione di questa mutazione',
  removeMutationQuestion: 'Sei sicuro di voler rimuovere questa mutazione?',

  // other error messages
  serverErrorMessage: 'Si è verificato un errore interno del server. Stiamo indagando.',

  // input file
  dragAndDrop: 'Trascinare e rilasciare',
  or: 'o',
  uploadImage: 'Carica immagine',
  uploadImages: 'Carica immagini',
  uploadFile: 'Caricare un file',
  uploadFiles: 'Caricare files',
  errorFileSize: 'Il file è troppo grande',
  errorWhileUploading: 'Impossibile caricare questo file',

  // hide components
  changeYourPlanAndGetAccess: 'Cambia il tuo piano e accedi a nuove funzionalità',
  askOtherBreederToContactYou:
    'Informa un altro allevatore che sei interessato a una specifica mutazione e chiedigli di contattarti',
  generatePdfFile:
    'Genera un documento pronto per essere firmato con le informazioni degli allevatori e di tutti gli uccelli che verranno ceduti'
};
