import { combineReducers } from 'redux';

// reducers
import appReducer from './appReducer';
import userReducer from './userReducer';

export default combineReducers({
  appReducer,
  userReducer
});
