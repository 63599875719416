import React from 'react';
import PropTypes from 'prop-types';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import { setAppLocale } from '../../../../../../../../store/actions/appActions';
import { setUserLanguage } from '../../../../../../../../utils/userSettings';
import styles from './styles';

class PublicLanguageBar extends React.PureComponent {
  languageName = (locale) => {
    switch (locale) {
      case 'pt_PT':
        return 'Português';
      case 'es_ES':
        return 'Español';
      case 'fr_FR':
        return 'Français';
      case 'it_IT':
        return 'Italiano';
      default:
        return 'English';
    }
  };

  onClickLanguage = (locale) => {
    setUserLanguage(locale);
    this.props.setAppLocale(locale || process.env.REACT_APP_DEFAULT_LOCALE);
  };

  render() {
    const { classes } = this.props;

    const languages = ['pt_PT', 'es_ES', 'fr_FR', 'it_IT', 'en_GB'];

    return (
      <div className={classes.container}>
        <Container maxWidth={this.props.maxWidth}>
          <div className={classes.languageContainer}>
            {languages.map((item) => (
              <Typography
                key={item}
                variant={'body2'}
                color={'primary'}
                className={classes.languageText}
                onClick={() => this.onClickLanguage(item)}
              >
                {this.languageName(item)}
              </Typography>
            ))}
          </div>
        </Container>
      </div>
    );
  }
}

PublicLanguageBar.propTypes = {
  maxWidth: PropTypes.string.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setAppLocale: (locale) => {
    dispatch(setAppLocale(locale));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(PublicLanguageBar)));
