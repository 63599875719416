import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';

// MOMENT
import moment from 'moment';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// REACT ROUTER DOM
import { Link } from 'react-router-dom';

// SPINE UI
import { Select, Input, Paper, Content, Button, MultipleSelect } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { fetchApi } from '../../../utils/apiSettings';
import { getCountries } from '../../../utils/appSettings';
import ROUTES from '../../../utils/routes';
import MuiPickersUtilsProvider from '../../inputs/MuiPickersUtilsProvider';
import InputDate from '../../inputs/InputDate';
import InputFile from '../../inputs/InputFile';
import styles from './styles';

class AddBirdForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      // general information
      breederId: '',
      breederLocale: 'en_GB',
      breederIdentifiers: [],
      species: [],
      mutations: [], // calculated in run time based in the `birdSpecieId`

      // form
      errors: {},

      // post information
      firstOwnerName: '',
      birdAcquiredAt: moment.utc(), // can be null
      birdSpecieId: '',
      birdMutationId: '',
      birdBirthday: moment.utc(), // can be null
      birdCountryId: '',
      birdGender: 'M', // or 'F'
      birdIsYellow: 'no', // or 'yes'
      birdIsWhiteHead: 'no', // or 'yes'
      birdSplitOptions: [],
      birdPossibleSplitOptions: [],
      images: [], // images already uploaded
      files: [], // files already uploaded
      birdObservations: '',

      // temp information
      isFirstOwner: 'owner', // or 'acquired'
      breederIdentifier: '',
      number: 1, // the ring number
      pendingImages: [], // images being uploaded
      pendingFiles: [], // files being uploaded
      parentsSameSpecie: false
    };
  }

  componentDidMount() {
    this.fetchBreederInformation();
  }

  /**
   * Identify where we are coming from COMMON, COUPLE or CLUTCH page.
   * @returns {string}
   */
  identifyPageRoute = () => {
    if (!this.props.coupleId && !this.props.clutchId) {
      return 'COMMON';
    }

    if (this.props.coupleId && !this.props.clutchId) {
      return 'COUPLE'; // TODO: should never happen
    }

    return 'CLUTCH';
  };

  fetchBreederInformation = () => {
    fetchApi(
      'get',
      '/breeders/v1/account',
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        const defaultIdentifier = data.breeder_identifiers.find((item) => item.is_default);

        this.setState(
          {
            breederId: data.id,
            breederLocale: data.locale,
            breederIdentifiers: data.breeder_identifiers.map((item) => ({
              key: item.id,
              value: item.identifier,
              name: item.identifier
            })),
            birdCountryId: data.country && data.country.id ? data.country.id : '',
            breederIdentifier: defaultIdentifier ? defaultIdentifier.identifier : ''
          },
          () => {
            this.fetchSpecies();
          }
        );
      },
      () => {}
    );
  };

  setStateSpecies = (species) => {
    const speciesFormatted = species.map((specie) => ({
      key: specie.id,
      value: specie.id,
      name: specie.name,
      mutations: specie.mutations.map((mutation) => ({
        key: mutation.id,
        value: mutation.id,
        name: mutation.name
      }))
    }));

    const mutationsFormatted = speciesFormatted.length && speciesFormatted[0].mutations;

    this.setState({
      species: speciesFormatted,
      mutations: mutationsFormatted,
      birdSpecieId: speciesFormatted.length ? speciesFormatted[0].value : '',
      birdMutationId: mutationsFormatted.length ? mutationsFormatted[0].value : '',
      isLoading: false
    });
  };

  fetchSpecies = () => {
    const { intl, enqueueSnackbar } = this.props;

    fetchApi(
      'get',
      '/v1/public/species',
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        const pageRoute = this.identifyPageRoute();

        if (pageRoute === 'COMMON') {
          this.setStateSpecies(data);
        } else {
          this.fetchCouple(this.state.breederId, data);
        }
      },
      (error) => {
        const { response } = error;

        if (response.status === 400) {
          enqueueSnackbar(intl.formatMessage({ id: 'coupleErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        } else {
          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        }
      }
    );
  };

  fetchCouple = (userId, species) => {
    const { intl, enqueueSnackbar } = this.props;

    fetchApi(
      'get',
      `/breeders/v1/couples/${this.props.coupleId}`,
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        const parentSpecieId1 = data.partners[0].specie.id;
        const parentSpecieId2 = data.partners[1].specie.id;

        if (parentSpecieId1 === parentSpecieId2) {
          this.setState(
            {
              parentsSameSpecie: true,
              couple: data
            },
            () => {
              const specieFound = species.find((specie) => specie.id === parentSpecieId1);

              this.setStateSpecies([specieFound]);
            }
          );
        } else {
          this.setStateSpecies(species);
        }
      },
      (error) => {
        const { response } = error;

        if (response.status === 400) {
          enqueueSnackbar(intl.formatMessage({ id: 'coupleErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        } else {
          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        }
      }
    );
  };

  uploadImage = (image) => {
    const formData = new FormData();

    // create payload based on the endpoint ("image" or "file")
    formData.append('file', image.file);

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'post',
          '/breeders/v1/images',
          {
            'Content-Type': 'multipart/form-data'
          },
          formData,
          false,
          (response) => {
            const { data } = response;

            this.setState((state) => ({
              // add file to the list of existing files (already uploaded)
              images: [
                ...state.images,
                {
                  // keep names with underscore so we can handle files that
                  // already exist (that came from another API request)
                  // and this new file
                  id: data.image_id,
                  path: data.path,
                  preview_path: data.preview_path
                }
              ],
              // set pending files to 100 (or remove them if needed)
              pendingImages: [
                ...state.pendingImages.map((item) => {
                  if (item.fileURL === image.fileURL)
                    return {
                      ...item,
                      error: false,
                      errorMessage: '',
                      percentageCompleted: 100
                    };
                  return item;
                })
              ],
              isFetching: false
            }));
          },
          () => {
            this.setState((state) => ({
              pendingImages: [
                ...state.pendingImages.map((item) => {
                  if (item.fileURL === image.fileURL)
                    return {
                      ...item,
                      error: true,
                      errorMessage: 'errorWhileUploading'
                    };
                  return item;
                })
              ],
              isFetching: false
            }));
          },
          (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentageCompleted = Math.round((loaded * 100) / total);

            if (percentageCompleted < 100) {
              this.setState((state) => ({
                pendingImages: [
                  ...state.pendingImages.map((item) => {
                    if (item.fileURL === image.fileURL)
                      return {
                        file: image.file,
                        fileName: image.fileName,
                        fileURL: image.fileURL,
                        percentageCompleted: Math.round((loaded * 100) / total),
                        error: false,
                        errorMessage: ''
                      };
                    return item;
                  })
                ]
              }));
            }
          }
        );
      }
    );
  };

  uploadFile = (file) => {
    const formData = new FormData();

    // create payload based on the endpoint ("image" or "file")
    formData.append('file', file.file);

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'post',
          '/breeders/v1/documents',
          {
            'Content-Type': 'multipart/form-data'
          },
          formData,
          false,
          (response) => {
            const { data } = response;

            this.setState((state) => ({
              // add file to the list of existing files (already uploaded)
              files: [
                ...state.files,
                {
                  // keep names with underscore so we can handle files that
                  // already exist (that came from another API request)
                  // and this new file
                  id: data.document_id,
                  path: data.path
                }
              ],
              // set pending files to 100 (or remove them if needed)
              pendingFiles: [
                ...state.pendingFiles.map((item) => {
                  if (item.fileURL === file.fileURL)
                    return {
                      ...item,
                      error: false,
                      errorMessage: '',
                      percentageCompleted: 100
                    };
                  return item;
                })
              ],
              isFetching: false
            }));
          },
          () => {
            this.setState((state) => ({
              pendingFiles: [
                ...state.pendingFiles.map((item) => {
                  if (item.fileURL === file.fileURL)
                    return {
                      ...item,
                      error: true,
                      errorMessage: 'errorWhileUploading'
                    };
                  return item;
                })
              ],
              isFetching: false
            }));
          },
          (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentageCompleted = Math.round((loaded * 100) / total);

            if (percentageCompleted < 100) {
              this.setState((state) => ({
                pendingFiles: [
                  ...state.pendingFiles.map((item) => {
                    if (item.fileURL === file.fileURL)
                      return {
                        file: file.file,
                        fileName: file.fileName,
                        fileURL: file.fileURL,
                        percentageCompleted: Math.round((loaded * 100) / total),
                        error: false,
                        errorMessage: ''
                      };
                    return item;
                  })
                ]
              }));
            }
          }
        );
      }
    );
  };

  handleAddBird = (event) => {
    event.preventDefault();

    this.setState(
      {
        isFetching: true
      },
      () => {
        const { intl, enqueueSnackbar } = this.props;
        const pageRoute = this.identifyPageRoute();

        let endpoint = '/breeders/v1';
        if (pageRoute === 'COMMON') {
          endpoint += '/birds';
        } else if (pageRoute === 'CLUTCH' && this.state.couple) {
          endpoint += `/couples/${this.props.coupleId}/clutches/${this.props.clutchId}/children`;
        }

        const payload = {
          ring: `${this.state.breederIdentifier} ${this.state.number}`,
          birthday: this.state.birdBirthday.format(),
          gender: this.state.birdGender,
          split_options: this.state.birdSplitOptions,
          possible_split_options: this.state.birdPossibleSplitOptions,
          mutation_id: this.state.birdMutationId,
          specie_id: this.state.birdSpecieId, // not need
          image_ids: this.state.images.map((item) => item.id),
          document_ids: this.state.files.map((item) => item.id),
          country_id: this.state.birdCountryId,
          comments: this.state.birdObservations,
          is_white_head: this.state.birdIsWhiteHead === 'yes',
          is_yellow: this.state.birdIsYellow === 'yes'
        };

        if (this.state.isFirstOwner === 'acquired') {
          payload.first_owner_name = this.state.firstOwnerName;
          payload.acquired_at = this.state.birdAcquiredAt.format();
        }

        fetchApi(
          'post',
          endpoint,
          {},
          payload,
          false,
          (response) => {
            const { data } = response;

            enqueueSnackbar(intl.formatMessage({ id: 'addBirdSuccessMessage' }), {
              variant: 'success',
              autoHideDuration: 4000
            });

            this.props.onSuccess(data.bird_id);
          },
          (error) => {
            const { response } = error;

            this.setState({ isFetching: false });

            if (response.status === 409) {
              this.setState({
                errors: {
                  number: intl.formatMessage({ id: 'ringAlreadyUsed' })
                }
              });

              enqueueSnackbar(intl.formatMessage({ id: 'ringAlreadyUsed' }), {
                variant: 'error',
                autoHideDuration: 3000
              });
            } else if (response.status === 404) {
              enqueueSnackbar(intl.formatMessage({ id: 'addBirdErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            } else if (response.status === 403) {
              if (pageRoute === 'COUPLE') {
                enqueueSnackbar(intl.formatMessage({ id: 'addChildCoupleInactive' }), {
                  variant: 'error',
                  autoHideDuration: 5000
                });
              } else {
                enqueueSnackbar(intl.formatMessage({ id: 'addBirdErrorMessage' }), {
                  variant: 'error',
                  autoHideDuration: 4000
                });
              }
            } else {
              enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            }
          }
        );
      }
    );
  };

  handleInputChange = (event) => {
    const input = event.target;

    this.setState({
      [input.name]: input.value,
      errors: {}
    });
  };

  handleSelectChange = (event) => {
    const input = event.target;

    if (input.name === 'isFirstOwner' && input.value === 'acquired') {
      this.setState({
        breederIdentifier: '',
        number: 1,
        [input.name]: input.value,
        errors: {}
      });
    } else if (input.name === 'isFirstOwner' && input.value === 'owner') {
      this.setState((state) => ({
        breederIdentifier:
          state.breederIdentifiers.length > 0 ? state.breederIdentifiers[0].value : '',
        number: 1,
        [input.name]: input.value,
        errors: {}
      }));
    } else if (input.name === 'birdSpecieId') {
      this.setState((state) => {
        const specie = state.species.find((item) => item.value === parseInt(input.value, 10));

        return {
          mutations: specie ? specie.mutations : [],
          birdMutationId: specie && specie.mutations.length ? specie.mutations[0].value : '',
          [input.name]: parseInt(input.value, 10),
          errors: {}
        };
      });
    } else if (input.name === 'birdMutationId') {
      this.setState({
        [input.name]: parseInt(input.value, 10),
        errors: {}
      });
    } else {
      this.setState({
        [input.name]: input.value,
        errors: {}
      });
    }
  };

  handleDateChange = (name, date) => {
    this.setState({
      [name]: date,
      errors: {}
    });
  };

  /**
   * Start uploading each image using `uploadImage` function.
   * @param pendingImages
   */
  handleAddImages = (pendingImages) => {
    this.setState(
      (state) => ({
        pendingImages: [...state.pendingImages, ...pendingImages]
      }),
      () => {
        // upload each file automatically
        pendingImages.forEach((file) => {
          if (!file.error) {
            this.uploadImage(file);
          }
        });
      }
    );
  };

  /**
   * Remove image that had a problem during upload.
   * @param imageURL
   */
  handleRemoveImageWithError = (imageURL) => {
    this.setState((state) => ({
      pendingImages: state.pendingImages.filter((item) => item.fileURL !== imageURL)
    }));
  };

  /**
   * Start uploading each file using `uploadFile` function.
   * @param pendingFiles
   */
  handleAddFiles = (pendingFiles) => {
    this.setState(
      (state) => ({
        pendingFiles: [...state.pendingFiles, ...pendingFiles]
      }),
      () => {
        // upload each file automatically
        pendingFiles.forEach((file) => {
          if (!file.error) {
            this.uploadFile(file);
          }
        });
      }
    );
  };

  /**
   * Remove file that had a problem during upload.
   * @param fileURL
   */
  handleRemoveFileWithError = (fileURL) => {
    this.setState((state) => ({
      pendingFiles: state.pendingFiles.filter((item) => item.fileURL !== fileURL)
    }));
  };

  renderFirstOwnerForm = () => {
    const { intl, classes } = this.props;

    return (
      <Paper title={intl.formatMessage({ id: 'breederInformation' })}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'body1'} className={classes.text}>
              {intl.formatMessage({ id: 'firstOwnerInformationMessage' })}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              showEmpty={false}
              required={true}
              name={'isFirstOwner'}
              label={intl.formatMessage({ id: 'firstOwner' })}
              value={this.state.isFirstOwner}
              onChange={this.handleSelectChange}
              disabled={this.state.isFetching}
              options={[
                {
                  key: 'owner',
                  value: 'owner',
                  name: intl.formatMessage({ id: 'yes' })
                },
                {
                  key: 'acquired',
                  value: 'acquired',
                  name: intl.formatMessage({ id: 'acquired' })
                }
              ]}
            />
          </Grid>
          {this.state.isFirstOwner !== 'owner' && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Input
                    required={true}
                    name={'firstOwnerName'}
                    type={'text'}
                    label={intl.formatMessage({ id: 'breederName' })}
                    value={this.state.firstOwnerName}
                    onChange={this.handleInputChange}
                    error={!!this.state.errors.firstOwnerName}
                    helperText={this.state.errors.firstOwnerName || ''}
                    inputProps={{ maxLength: 100 }}
                    disabled={this.state.isFetching}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDate
                    required={true}
                    name={'birdAcquiredAt'}
                    label={intl.formatMessage({ id: 'acquiredAt' })}
                    value={this.state.birdAcquiredAt}
                    onChange={(date) => this.handleDateChange('birdAcquiredAt', date)}
                    okLabel={'Ok'}
                    cancelLabel={intl.formatMessage({ id: 'cancel' })}
                    error={!!this.state.errors.birdAcquiredAt}
                    helperText={this.state.errors.birdAcquiredAt || ''}
                    disabled={this.state.isFetching}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  };

  renderRingForm = () => {
    const { intl, classes } = this.props;

    return (
      <Paper title={intl.formatMessage({ id: 'ring' })}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'body1'} className={classes.text}>
              {intl.formatMessage({ id: 'identifierDescription' })}
            </Typography>
            {this.state.isFirstOwner === 'owner' && (
              <Typography variant={'body1'} className={classes.text}>
                {intl.formatMessage({ id: 'addBreederIdentifierAddNewBirdMessage' })}
                <Link to={ROUTES.ACCOUNT} className={classes.here}>
                  {intl.formatMessage({ id: 'here' })}
                </Link>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {this.state.isFirstOwner === 'owner' ? (
                  <React.Fragment>
                    <Select
                      showEmpty={false}
                      required={true}
                      name={'breederIdentifier'}
                      label={intl.formatMessage({ id: 'breederIdentifier' })}
                      value={this.state.breederIdentifier}
                      onChange={this.handleSelectChange}
                      options={this.state.breederIdentifiers}
                      error={!!this.state.errors.breederIdentifier}
                      helperText={this.state.errors.breederIdentifier || ''}
                      disabled={this.state.isFetching || !this.state.breederIdentifiers.length}
                    />
                  </React.Fragment>
                ) : (
                  <Input
                    required={true}
                    name={'breederIdentifier'}
                    type={'text'}
                    label={intl.formatMessage({ id: 'breederIdentifier' })}
                    value={this.state.breederIdentifier}
                    onChange={this.handleInputChange}
                    error={!!this.state.errors.breederIdentifier}
                    helperText={this.state.errors.breederIdentifier || ''}
                    disabled={this.state.isFetching}
                    inputProps={{ maxLength: 20 }}
                    placeholder={'Z123'}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  required={true}
                  name={'number'}
                  type={'text'}
                  label={intl.formatMessage({ id: 'number' })}
                  value={this.state.number}
                  onChange={this.handleInputChange}
                  error={!!this.state.errors.number}
                  helperText={this.state.errors.number || ''}
                  disabled={this.state.isFetching}
                  placeholder={'123'}
                />
              </Grid>
            </Grid>
          </Grid>
          {this.state.breederIdentifier !== '' && (
            <Grid item xs={12}>
              <div className={classes.ringContainer}>
                <div className={classes.ringInnerContainer}>
                  <Typography variant={'h4'} className={classes.ringText}>
                    {`${this.state.breederIdentifier}   ${this.state.number}`}
                  </Typography>
                  <Typography variant={'body1'} className={classes.ringSmallText}>
                    {intl.formatMessage({ id: 'ring' })}
                  </Typography>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  };

  renderBirdForm = () => {
    const { intl } = this.props;

    const specialSpecie1 = parseInt(process.env.REACT_APP_CARDUELIS_MAJOR_SPECIE_ID, 10);
    const specialSpecie2 = parseInt(process.env.REACT_APP_CARDUELIS_PARVA_SPECIE_ID, 10);

    return (
      <Paper title={intl.formatMessage({ id: 'birdInformation' })}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Select
              showEmpty={false}
              required={true}
              name={'birdSpecieId'}
              label={intl.formatMessage({ id: 'specie' })}
              value={this.state.birdSpecieId}
              onChange={this.handleSelectChange}
              error={!!this.state.errors.birdSpecieId}
              helperText={this.state.errors.birdSpecieId || ''}
              disabled={
                this.state.isFetching || !this.state.species.length || this.state.parentsSameSpecie
              }
              options={this.state.species}
            />
          </Grid>
          {this.state.birdSpecieId &&
            [specialSpecie1, specialSpecie2].includes(this.state.birdSpecieId) && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Select
                      showEmpty={false}
                      required={true}
                      name={'birdIsYellow'}
                      label={intl.formatMessage({ id: 'isYellow' })}
                      value={this.state.birdIsYellow}
                      onChange={this.handleSelectChange}
                      disabled={this.state.isFetching}
                      options={[
                        {
                          key: 'yes',
                          value: 'yes',
                          name: intl.formatMessage({ id: 'yes' })
                        },
                        {
                          key: 'no',
                          value: 'no',
                          name: intl.formatMessage({ id: 'no' })
                        }
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Select
                      showEmpty={false}
                      required={true}
                      name={'birdIsWhiteHead'}
                      label={intl.formatMessage({ id: 'isWhiteHead' })}
                      value={this.state.birdIsWhiteHead}
                      onChange={this.handleSelectChange}
                      disabled={this.state.isFetching}
                      options={[
                        {
                          key: 'yes',
                          value: 'yes',
                          name: intl.formatMessage({ id: 'yes' })
                        },
                        {
                          key: 'no',
                          value: 'no',
                          name: intl.formatMessage({ id: 'no' })
                        }
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          <Grid item xs={12}>
            <Select
              showEmpty={false}
              required={true}
              name={'birdMutationId'}
              label={intl.formatMessage({ id: 'mutation' })}
              value={this.state.birdMutationId}
              onChange={this.handleSelectChange}
              error={!!this.state.errors.birdMutationId}
              helperText={this.state.errors.birdMutationId || ''}
              disabled={this.state.isFetching || !this.state.mutations.length}
              options={this.state.mutations}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <InputDate
              required={true}
              name={'birdBirthdayNew'}
              label={intl.formatMessage({ id: 'birthday' })}
              value={this.state.birdBirthday}
              onChange={(date) => this.handleDateChange('birdBirthday', date)}
              okLabel={'Ok'}
              cancelLabel={intl.formatMessage({ id: 'cancel' })}
              error={!!this.state.errors.birdBirthday}
              helperText={this.state.errors.birdBirthday || ''}
              disabled={this.state.isFetching}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={5}>
            <Select
              showEmpty={true}
              required={true}
              name={'birdCountryId'}
              label={intl.formatMessage({ id: 'country' })}
              value={this.state.birdCountryId}
              onChange={this.handleSelectChange}
              disabled={this.state.isFetching}
              options={getCountries(this.state.breederLocale)}
            />
          </Grid>
          <Grid item xs={5} md={3} lg={2}>
            <Select
              showEmpty={false}
              required={true}
              name={'birdGender'}
              label={intl.formatMessage({ id: 'gender' })}
              value={this.state.birdGender}
              onChange={this.handleSelectChange}
              disabled={this.state.isFetching}
              options={[
                {
                  key: 'M',
                  value: 'M',
                  name: 'M'
                },
                {
                  key: 'F',
                  value: 'F',
                  name: 'F'
                },
                {
                  key: 'U',
                  value: 'U',
                  name: intl.formatMessage({ id: 'unknown' })
                }
              ].filter((item) => {
                if (this.props.clutchId) {
                  return true;
                }

                return item.value !== 'U';
              })}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };

  renderExtraInformationForm = () => {
    const { intl } = this.props;

    return (
      <Paper title={intl.formatMessage({ id: 'extraInformation' })}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MultipleSelect
              required={false}
              name={'birdSplitOptions'}
              label={intl.formatMessage({ id: 'carrierOfMutations' })}
              options={this.state.mutations}
              values={this.state.birdSplitOptions}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MultipleSelect
              required={false}
              name={'birdPossibleSplitOptions'}
              label={intl.formatMessage({ id: 'possiblyCarrierOfMutations' })}
              options={this.state.mutations}
              values={this.state.birdPossibleSplitOptions}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              multiline={true}
              name={'birdObservations'}
              type={'text'}
              label={intl.formatMessage({ id: 'observations' })}
              value={this.state.birdObservations}
              onChange={this.handleInputChange}
              disabled={this.state.isFetching}
              inputProps={{ maxLength: 1000 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputFile
              inputTitle={'uploadImages'}
              mimeType={'image/jpeg,image/png'}
              allowMultipleFiles={true}
              handleAddFiles={this.handleAddImages}
              handleRemoveFileWithError={this.handleRemoveImageWithError}
              pendingFiles={this.state.pendingImages}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputFile
              inputTitle={'uploadFiles'}
              mimeType={'text/plain,application/pdf,application/msword'}
              allowMultipleFiles={true}
              handleAddFiles={this.handleAddFiles}
              handleRemoveFileWithError={this.handleRemoveFileWithError}
              pendingFiles={this.state.pendingFiles}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };

  render() {
    const { intl, classes } = this.props;

    return (
      <MuiPickersUtilsProvider>
        <Content isLoading={this.state.isLoading} isFetching={this.state.isFetching}>
          <form autoComplete={'off'} onSubmit={this.handleAddBird}>
            <Grid container spacing={3}>
              {this.props.shouldDisplayFirstOwnerForm && (
                <Grid item xs={12}>
                  {this.renderFirstOwnerForm()}
                </Grid>
              )}
              <Grid item xs={12}>
                {this.renderRingForm()}
              </Grid>
              <Grid item xs={12}>
                {this.renderBirdForm()}
              </Grid>
              <Grid item xs={12}>
                {this.renderExtraInformationForm()}
              </Grid>
              <Grid item xs={12}>
                <div className={classes.formButtonsContainer}>
                  <Button
                    type={'button'}
                    label={intl.formatMessage({ id: 'cancel' })}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={this.props.onCancel}
                    disabled={this.state.isLoading || this.state.isFetching}
                  />
                  <Button
                    type={'submit'}
                    label={intl.formatMessage({ id: 'confirm' })}
                    variant={'contained'}
                    color={'primary'}
                    disabled={this.state.isLoading || this.state.isFetching}
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </Content>
      </MuiPickersUtilsProvider>
    );
  }
}

AddBirdForm.defaultProps = {
  shouldDisplayFirstOwnerForm: true,
  coupleId: null,
  clutchId: null
};

AddBirdForm.propTypes = {
  shouldDisplayFirstOwnerForm: PropTypes.bool,
  // this component might be used to add a normal bird
  // or to add a bird directly from a clutch (which require us to have both 'coupleId' and 'clutchId')
  // or to add a bird from a couple
  coupleId: PropTypes.string,
  clutchId: PropTypes.string,
  // used to go back
  onCancel: PropTypes.func.isRequired,
  // used to send the user the new bird's page
  onSuccess: PropTypes.func.isRequired
};

export default withStyles(styles)(withSnackbar(injectIntl(AddBirdForm)));
