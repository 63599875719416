// UTILS
import { getColors } from '../../../utils/themes/theme';
import TYPOGRAPHY from '../../../utils/themes/common/typography';

const colors = getColors();

export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: colors.sidebar
  },

  // items
  itemsContainer: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },

  // public items
  publicListItem: {
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  publicListItemStartNow: {
    width: '75%',
    cursor: 'pointer',
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: colors.sidebarItem,
    borderRadius: '20px',
    textAlign: 'center',
    marginLeft: theme.spacing(2),
    transition: 'cubic-bezier(0.4, 0, 0.2, 1) 100ms',
    '&:hover': {
      backgroundColor: theme.palette.common.black
    }
  },
  publicActiveListItem: {
    '& $publicListItemText': {
      color: colors.sidebarItem,
      textDecoration: 'underline'
    },
    transition: 'background-color 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  publicListItemText: {
    fontSize: TYPOGRAPHY.subtitle1.fontSize,
    fontWeight: '600',
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    transition: 'color 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      color: colors.sidebarItem,
      textDecoration: 'underline'
    }
  },
  publicListItemTextStartNow: {
    fontSize: TYPOGRAPHY.subtitle1.fontSize,
    fontWeight: '600',
    textTransform: 'uppercase',
    color: theme.palette.common.white
  },

  // private items
  listItem: {
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderRadius: '4px',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: colors.sidebarItem
    }
  },
  activeListItem: {
    borderLeft: `4px solid ${colors.sidebarItemActive}`,
    borderRadius: '4px',
    backgroundColor: colors.sidebarItem,
    '& $listItemText': {
      color: colors.sidebarItemActive,
      fontWeight: 'bold'
    },
    '& $listItemIcon': {
      marginLeft: '-4px',
      color: colors.sidebarItemActive
    },
    transition: 'background-color 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  listItemText: {
    ...TYPOGRAPHY.h6,
    fontWeight: '500',
    // textTransform: 'uppercase',
    color: theme.palette.common.white
  },
  listItemIcon: {
    marginRight: '-25px',
    color: theme.palette.common.white
  },
  footerButtonsContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1)
  },
  groupTitle: {
    color: theme.palette.secondary.main,
    fontWeight: '600',
    marginLeft: '28px',
    marginTop: theme.spacing(2),
    marginBottom: '-5px'
  },

  logoutButton: {
    marginLeft: theme.spacing(2)
  }
});
