import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import styles from './styles';

class PublicButton extends React.PureComponent {
  render() {
    const { intl, classes } = this.props;

    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={this.props.type}
        className={classes.button}
        onClick={() => {
          if (this.props.type !== 'submit') {
            this.props.onClick();
          }
        }}
        disabled={this.props.disabled}
      >
        {this.props.isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <Typography variant={'body2'} className={classes.text}>
            {this.props.translateTitle
              ? intl.formatMessage({ id: this.props.title })
              : this.props.title}
          </Typography>
        )}
      </button>
    );
  }
}

PublicButton.defaultProps = {
  title: '',
  translateTitle: true,
  type: 'submit',
  disabled: false,
  onClick: () => {}
};

PublicButton.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  translateTitle: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default withStyles(styles)(injectIntl(PublicButton));
