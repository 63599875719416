import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';

// FEATHER ICONS
import { Check } from 'react-feather';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import styles from './styles';

class Plan extends React.PureComponent {
  render() {
    const { intl, classes } = this.props;

    const planTitle = this.props.actualPlan ? (
      <div className={classes.planContainerTitle}>
        <div className={classes.actualPlanContainer}>
          <Typography variant={'h5'} className={classes.planTitle}>
            {intl.formatMessage({ id: this.props.title })}
          </Typography>
          <Typography variant={'body1'} className={classes.actualPlan}>
            {intl.formatMessage({ id: 'actualPlan' })}
          </Typography>
        </div>
        <Typography variant={'body2'} className={classes.planSmallDescription}>
          {this.props.smallDescription}
        </Typography>
      </div>
    ) : (
      <div className={classes.planContainerTitle}>
        <Typography variant={'h5'} className={classes.planTitle}>
          {intl.formatMessage({ id: this.props.title })}
        </Typography>
        <Typography variant={'body1'} className={classes.planSmallDescription}>
          {this.props.smallDescription}
        </Typography>
      </div>
    );

    return (
      <div className={classes.planContainer}>
        {planTitle}
        <Typography variant={'h5'} className={classes.planPrice}>
          {this.props.price === '0' ? (
            '0€'
          ) : (
            <React.Fragment>
              {`${this.props.price}€`}
              <span className={classes.yearText}>{`/${intl.formatMessage({ id: 'year' })}`}</span>
            </React.Fragment>
          )}
        </Typography>
        {this.props.selectButton && (
          <Button
            color={'primary'}
            variant={'contained'}
            onClick={this.props.handleSelectPlan}
            disableElevation={true}
            className={classes.submitButton}
          >
            {intl.formatMessage({ id: 'select' })}
          </Button>
        )}
        <Grid container spacing={2}>
          {this.props.description.map((text, index) => (
            <Grid key={index} item xs={12}>
              <div className={classes.descriptionItemContainer}>
                <div className={classes.itemIcon}>
                  <Check size={18} />
                </div>
                <Typography variant={'body1'} className={classes.itemText}>
                  {text}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

Plan.defaultProps = {
  selectButton: false,
  actualPlan: false,
  handleSelectPlan: () => {}
};

Plan.propTypes = {
  title: PropTypes.string.isRequired,
  smallDescription: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
    .isRequired,
  selectButton: PropTypes.bool,
  handleSelectPlan: PropTypes.func,
  actualPlan: PropTypes.bool
};

export default withStyles(styles)(injectIntl(Plan));
