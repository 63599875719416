export default [
  {
    id: 1,
    name: 'Afghanistan',
    code: 'AF'
  },
  {
    id: 201,
    name: 'Afrique du Sud',
    code: 'ZA'
  },
  {
    id: 3,
    name: 'Albanie',
    code: 'AL'
  },
  {
    id: 4,
    name: 'Algérie',
    code: 'DZ'
  },
  {
    id: 81,
    name: 'Allemagne',
    code: 'DE'
  },
  {
    id: 6,
    name: 'Andorre',
    code: 'AD'
  },
  {
    id: 7,
    name: 'Angola',
    code: 'AO'
  },
  {
    id: 8,
    name: 'Anguilla',
    code: 'AI'
  },
  {
    id: 9,
    name: 'Antarctique',
    code: 'AQ'
  },
  {
    id: 10,
    name: 'Antigua-et-Barbuda',
    code: 'AG'
  },
  {
    id: 191,
    name: 'Arabie saoudite',
    code: 'SA'
  },
  {
    id: 11,
    name: 'Argentine',
    code: 'AR'
  },
  {
    id: 12,
    name: 'Arménie',
    code: 'AM'
  },
  {
    id: 13,
    name: 'Aruba',
    code: 'AW'
  },
  {
    id: 14,
    name: 'Australie',
    code: 'AU'
  },
  {
    id: 15,
    name: 'Autriche',
    code: 'AT'
  },
  {
    id: 16,
    name: 'Azerbaïdjan',
    code: 'AZ'
  },
  {
    id: 17,
    name: 'Bahamas',
    code: 'BS'
  },
  {
    id: 18,
    name: 'Bahreïn',
    code: 'BH'
  },
  {
    id: 19,
    name: 'Bangladesh',
    code: 'BD'
  },
  {
    id: 20,
    name: 'Barbade',
    code: 'BB'
  },
  {
    id: 22,
    name: 'Belgique',
    code: 'BE'
  },
  {
    id: 23,
    name: 'Belize',
    code: 'BZ'
  },
  {
    id: 25,
    name: 'Bermudes',
    code: 'BM'
  },
  {
    id: 26,
    name: 'Bhoutan',
    code: 'BT'
  },
  {
    id: 150,
    name: 'Birmanie',
    code: 'MM'
  },
  {
    id: 21,
    name: 'Biélorussie',
    code: 'BY'
  },
  {
    id: 27,
    name: 'Bolivie',
    code: 'BO'
  },
  {
    id: 28,
    name: 'Bosnie-Herzégovine',
    code: 'BA'
  },
  {
    id: 29,
    name: 'Botswana',
    code: 'BW'
  },
  {
    id: 33,
    name: 'Brunei',
    code: 'BN'
  },
  {
    id: 31,
    name: 'Brésil',
    code: 'BR'
  },
  {
    id: 34,
    name: 'Bulgarie',
    code: 'BG'
  },
  {
    id: 35,
    name: 'Burkina Faso',
    code: 'BF'
  },
  {
    id: 36,
    name: 'Burundi',
    code: 'BI'
  },
  {
    id: 24,
    name: 'Bénin',
    code: 'BJ'
  },
  {
    id: 37,
    name: 'Cambodge',
    code: 'KH'
  },
  {
    id: 38,
    name: 'Cameroun',
    code: 'CM'
  },
  {
    id: 39,
    name: 'Canada',
    code: 'CA'
  },
  {
    id: 40,
    name: 'Cap-Vert',
    code: 'CV'
  },
  {
    id: 44,
    name: 'Chili',
    code: 'CL'
  },
  {
    id: 45,
    name: 'Chine',
    code: 'CN'
  },
  {
    id: 57,
    name: 'Chypre (pays)',
    code: 'CY'
  },
  {
    id: 48,
    name: 'Colombie',
    code: 'CO'
  },
  {
    id: 49,
    name: 'Comores (pays)',
    code: 'KM'
  },
  {
    id: 116,
    name: 'Corée du Nord',
    code: 'KP'
  },
  {
    id: 117,
    name: 'Corée du Sud',
    code: 'KR'
  },
  {
    id: 53,
    name: 'Costa Rica',
    code: 'CR'
  },
  {
    id: 55,
    name: 'Croatie',
    code: 'HR'
  },
  {
    id: 56,
    name: 'Cuba',
    code: 'CU'
  },
  {
    id: 54,
    name: "Côte d'Ivoire",
    code: 'CI'
  },
  {
    id: 59,
    name: 'Danemark',
    code: 'DK'
  },
  {
    id: 60,
    name: 'Djibouti',
    code: 'DJ'
  },
  {
    id: 61,
    name: 'Dominique',
    code: 'DM'
  },
  {
    id: 203,
    name: 'Espagne',
    code: 'ES'
  },
  {
    id: 68,
    name: 'Estonie',
    code: 'EE'
  },
  {
    id: 208,
    name: 'Eswatini',
    code: 'SZ'
  },
  {
    id: 72,
    name: 'Fidji',
    code: 'FJ'
  },
  {
    id: 73,
    name: 'Finlande',
    code: 'FI'
  },
  {
    id: 74,
    name: 'France',
    code: 'FR'
  },
  {
    id: 78,
    name: 'Gabon',
    code: 'GA'
  },
  {
    id: 79,
    name: 'Gambie',
    code: 'GM'
  },
  {
    id: 82,
    name: 'Ghana',
    code: 'GH'
  },
  {
    id: 83,
    name: 'Gibraltar',
    code: 'GI'
  },
  {
    id: 86,
    name: 'Grenade (pays)',
    code: 'GD'
  },
  {
    id: 85,
    name: 'Groenland',
    code: 'GL'
  },
  {
    id: 84,
    name: 'Grèce',
    code: 'GR'
  },
  {
    id: 87,
    name: 'Guadeloupe',
    code: 'GP'
  },
  {
    id: 88,
    name: 'Guam',
    code: 'GU'
  },
  {
    id: 89,
    name: 'Guatemala',
    code: 'GT'
  },
  {
    id: 90,
    name: 'Guernesey',
    code: 'GG'
  },
  {
    id: 91,
    name: 'Guinée',
    code: 'GN'
  },
  {
    id: 66,
    name: 'Guinée équatoriale',
    code: 'GQ'
  },
  {
    id: 92,
    name: 'Guinée-Bissau',
    code: 'GW'
  },
  {
    id: 93,
    name: 'Guyana',
    code: 'GY'
  },
  {
    id: 75,
    name: 'Guyane',
    code: 'GF'
  },
  {
    id: 80,
    name: 'Géorgie (pays)',
    code: 'GE'
  },
  {
    id: 202,
    name: 'Géorgie du Sud-et-les îles Sandwich du Sud',
    code: 'GS'
  },
  {
    id: 94,
    name: 'Haïti',
    code: 'HT'
  },
  {
    id: 97,
    name: 'Honduras',
    code: 'HN'
  },
  {
    id: 98,
    name: 'Hong Kong',
    code: 'HK'
  },
  {
    id: 99,
    name: 'Hongrie',
    code: 'HU'
  },
  {
    id: 101,
    name: 'Inde',
    code: 'IN'
  },
  {
    id: 102,
    name: 'Indonésie',
    code: 'ID'
  },
  {
    id: 104,
    name: 'Irak',
    code: 'IQ'
  },
  {
    id: 103,
    name: 'Iran',
    code: 'IR'
  },
  {
    id: 105,
    name: 'Irlande (pays)',
    code: 'IE'
  },
  {
    id: 100,
    name: 'Islande',
    code: 'IS'
  },
  {
    id: 107,
    name: 'Israël',
    code: 'IL'
  },
  {
    id: 108,
    name: 'Italie',
    code: 'IT'
  },
  {
    id: 109,
    name: 'Jamaïque',
    code: 'JM'
  },
  {
    id: 110,
    name: 'Japon',
    code: 'JP'
  },
  {
    id: 111,
    name: 'Jersey',
    code: 'JE'
  },
  {
    id: 112,
    name: 'Jordanie',
    code: 'JO'
  },
  {
    id: 113,
    name: 'Kazakhstan',
    code: 'KZ'
  },
  {
    id: 114,
    name: 'Kenya',
    code: 'KE'
  },
  {
    id: 119,
    name: 'Kirghizistan',
    code: 'KG'
  },
  {
    id: 115,
    name: 'Kiribati',
    code: 'KI'
  },
  {
    id: 118,
    name: 'Koweït',
    code: 'KW'
  },
  {
    id: 179,
    name: 'La Réunion',
    code: 'RE'
  },
  {
    id: 120,
    name: 'Laos',
    code: 'LA'
  },
  {
    id: 123,
    name: 'Lesotho',
    code: 'LS'
  },
  {
    id: 121,
    name: 'Lettonie',
    code: 'LV'
  },
  {
    id: 122,
    name: 'Liban',
    code: 'LB'
  },
  {
    id: 124,
    name: 'Liberia',
    code: 'LR'
  },
  {
    id: 125,
    name: 'Libye',
    code: 'LY'
  },
  {
    id: 126,
    name: 'Liechtenstein',
    code: 'LI'
  },
  {
    id: 127,
    name: 'Lituanie',
    code: 'LT'
  },
  {
    id: 128,
    name: 'Luxembourg (pays)',
    code: 'LU'
  },
  {
    id: 129,
    name: 'Macao',
    code: 'MO'
  },
  {
    id: 130,
    name: 'Macédoine du Nord',
    code: 'MK'
  },
  {
    id: 131,
    name: 'Madagascar',
    code: 'MG'
  },
  {
    id: 133,
    name: 'Malaisie',
    code: 'MY'
  },
  {
    id: 132,
    name: 'Malawi',
    code: 'MW'
  },
  {
    id: 134,
    name: 'Maldives',
    code: 'MV'
  },
  {
    id: 135,
    name: 'Mali',
    code: 'ML'
  },
  {
    id: 70,
    name: 'Malouines',
    code: 'FK'
  },
  {
    id: 136,
    name: 'Malte',
    code: 'MT'
  },
  {
    id: 148,
    name: 'Maroc',
    code: 'MA'
  },
  {
    id: 138,
    name: 'Martinique',
    code: 'MQ'
  },
  {
    id: 140,
    name: 'Maurice (pays)',
    code: 'MU'
  },
  {
    id: 139,
    name: 'Mauritanie',
    code: 'MR'
  },
  {
    id: 141,
    name: 'Mayotte',
    code: 'YT'
  },
  {
    id: 142,
    name: 'Mexique',
    code: 'MX'
  },
  {
    id: 144,
    name: 'Moldavie',
    code: 'MD'
  },
  {
    id: 145,
    name: 'Monaco',
    code: 'MC'
  },
  {
    id: 146,
    name: 'Mongolie',
    code: 'MN'
  },
  {
    id: 147,
    name: 'Montserrat',
    code: 'MS'
  },
  {
    id: 149,
    name: 'Mozambique',
    code: 'MZ'
  },
  {
    id: 151,
    name: 'Namibie',
    code: 'NA'
  },
  {
    id: 152,
    name: 'Nauru',
    code: 'NR'
  },
  {
    id: 158,
    name: 'Nicaragua',
    code: 'NI'
  },
  {
    id: 159,
    name: 'Niger',
    code: 'NE'
  },
  {
    id: 160,
    name: 'Nigeria',
    code: 'NG'
  },
  {
    id: 161,
    name: 'Niue',
    code: 'NU'
  },
  {
    id: 164,
    name: 'Norvège',
    code: 'NO'
  },
  {
    id: 156,
    name: 'Nouvelle-Calédonie',
    code: 'NC'
  },
  {
    id: 157,
    name: 'Nouvelle-Zélande',
    code: 'NZ'
  },
  {
    id: 153,
    name: 'Népal',
    code: 'NP'
  },
  {
    id: 165,
    name: 'Oman',
    code: 'OM'
  },
  {
    id: 226,
    name: 'Ouganda',
    code: 'UG'
  },
  {
    id: 233,
    name: 'Ouzbékistan',
    code: 'UZ'
  },
  {
    id: 166,
    name: 'Pakistan',
    code: 'PK'
  },
  {
    id: 167,
    name: 'Palaos',
    code: 'PW'
  },
  {
    id: 168,
    name: 'Palestine',
    code: 'PS'
  },
  {
    id: 169,
    name: 'Panama',
    code: 'PA'
  },
  {
    id: 170,
    name: 'Papouasie-Nouvelle-Guinée',
    code: 'PG'
  },
  {
    id: 171,
    name: 'Paraguay',
    code: 'PY'
  },
  {
    id: 154,
    name: 'Pays-Bas',
    code: 'NL'
  },
  {
    id: 173,
    name: 'Philippines',
    code: 'PH'
  },
  {
    id: 175,
    name: 'Pologne',
    code: 'PL'
  },
  {
    id: 76,
    name: 'Polynésie française',
    code: 'PF'
  },
  {
    id: 177,
    name: 'Porto Rico',
    code: 'PR'
  },
  {
    id: 176,
    name: 'Portugal',
    code: 'PT'
  },
  {
    id: 172,
    name: 'Pérou',
    code: 'PE'
  },
  {
    id: 178,
    name: 'Qatar',
    code: 'QA'
  },
  {
    id: 180,
    name: 'Roumanie',
    code: 'RO'
  },
  {
    id: 229,
    name: 'Royaume-Uni',
    code: 'GB'
  },
  {
    id: 181,
    name: 'Russie',
    code: 'RU'
  },
  {
    id: 182,
    name: 'Rwanda',
    code: 'RW'
  },
  {
    id: 240,
    name: 'République arabe sahraouie démocratique',
    code: 'EH'
  },
  {
    id: 42,
    name: 'République centrafricaine',
    code: 'CF'
  },
  {
    id: 62,
    name: 'République dominicaine',
    code: 'DO'
  },
  {
    id: 50,
    name: 'République du Congo',
    code: 'CG'
  },
  {
    id: 51,
    name: 'République démocratique du Congo',
    code: 'CD'
  },
  {
    id: 184,
    name: 'Saint-Christophe-et-Niévès',
    code: 'KN'
  },
  {
    id: 189,
    name: 'Saint-Marin',
    code: 'SM'
  },
  {
    id: 186,
    name: 'Saint-Pierre-et-Miquelon',
    code: 'PM'
  },
  {
    id: 96,
    name: 'Saint-Siège (État de la Cité du Vatican)',
    code: 'VA'
  },
  {
    id: 187,
    name: 'Saint-Vincent-et-les-Grenadines',
    code: 'VC'
  },
  {
    id: 183,
    name: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    code: 'SH'
  },
  {
    id: 185,
    name: 'Sainte-Lucie',
    code: 'LC'
  },
  {
    id: 199,
    name: 'Salomon',
    code: 'SB'
  },
  {
    id: 65,
    name: 'Salvador',
    code: 'SV'
  },
  {
    id: 188,
    name: 'Samoa',
    code: 'WS'
  },
  {
    id: 5,
    name: 'Samoa américaines',
    code: 'AS'
  },
  {
    id: 190,
    name: 'Sao Tomé-et-Principe',
    code: 'ST'
  },
  {
    id: 194,
    name: 'Seychelles',
    code: 'SC'
  },
  {
    id: 195,
    name: 'Sierra Leone',
    code: 'SL'
  },
  {
    id: 196,
    name: 'Singapour',
    code: 'SG'
  },
  {
    id: 197,
    name: 'Slovaquie',
    code: 'SK'
  },
  {
    id: 198,
    name: 'Slovénie',
    code: 'SI'
  },
  {
    id: 200,
    name: 'Somalie',
    code: 'SO'
  },
  {
    id: 205,
    name: 'Soudan',
    code: 'SD'
  },
  {
    id: 204,
    name: 'Sri Lanka',
    code: 'LK'
  },
  {
    id: 210,
    name: 'Suisse',
    code: 'CH'
  },
  {
    id: 206,
    name: 'Suriname',
    code: 'SR'
  },
  {
    id: 209,
    name: 'Suède',
    code: 'SE'
  },
  {
    id: 207,
    name: 'Svalbard et ile Jan Mayen',
    code: 'SJ'
  },
  {
    id: 211,
    name: 'Syrie',
    code: 'SY'
  },
  {
    id: 192,
    name: 'Sénégal',
    code: 'SN'
  },
  {
    id: 213,
    name: 'Tadjikistan',
    code: 'TJ'
  },
  {
    id: 214,
    name: 'Tanzanie',
    code: 'TZ'
  },
  {
    id: 212,
    name: 'Taïwan / (République de Chine (Taïwan))',
    code: 'TW'
  },
  {
    id: 43,
    name: 'Tchad',
    code: 'TD'
  },
  {
    id: 58,
    name: 'Tchéquie',
    code: 'CZ'
  },
  {
    id: 77,
    name: 'Terres australes et antarctiques françaises',
    code: 'TF'
  },
  {
    id: 32,
    name: "Territoire britannique de l'océan Indien",
    code: 'IO'
  },
  {
    id: 215,
    name: 'Thaïlande',
    code: 'TH'
  },
  {
    id: 216,
    name: 'Timor oriental',
    code: 'TL'
  },
  {
    id: 217,
    name: 'Togo',
    code: 'TG'
  },
  {
    id: 218,
    name: 'Tokelau',
    code: 'TK'
  },
  {
    id: 219,
    name: 'Tonga',
    code: 'TO'
  },
  {
    id: 220,
    name: 'Trinité-et-Tobago',
    code: 'TT'
  },
  {
    id: 221,
    name: 'Tunisie',
    code: 'TN'
  },
  {
    id: 223,
    name: 'Turkménistan',
    code: 'TM'
  },
  {
    id: 222,
    name: 'Turquie',
    code: 'TR'
  },
  {
    id: 225,
    name: 'Tuvalu',
    code: 'TV'
  },
  {
    id: 227,
    name: 'Ukraine',
    code: 'UA'
  },
  {
    id: 232,
    name: 'Uruguay',
    code: 'UY'
  },
  {
    id: 234,
    name: 'Vanuatu',
    code: 'VU'
  },
  {
    id: 235,
    name: 'Venezuela',
    code: 'VE'
  },
  {
    id: 236,
    name: 'Viêt Nam',
    code: 'VN'
  },
  {
    id: 239,
    name: 'Wallis-et-Futuna',
    code: 'WF'
  },
  {
    id: 241,
    name: 'Yémen',
    code: 'YE'
  },
  {
    id: 242,
    name: 'Zambie',
    code: 'ZM'
  },
  {
    id: 243,
    name: 'Zimbabwe',
    code: 'ZW'
  },
  {
    id: 64,
    name: 'Égypte',
    code: 'EG'
  },
  {
    id: 228,
    name: 'Émirats arabes unis',
    code: 'AE'
  },
  {
    id: 63,
    name: 'Équateur (pays)',
    code: 'EC'
  },
  {
    id: 67,
    name: 'Érythrée',
    code: 'ER'
  },
  {
    id: 143,
    name: 'États fédérés de Micronésie (pays)',
    code: 'FM'
  },
  {
    id: 230,
    name: 'États-Unis',
    code: 'US'
  },
  {
    id: 69,
    name: 'Éthiopie',
    code: 'ET'
  },
  {
    id: 30,
    name: 'Île Bouvet',
    code: 'BV'
  },
  {
    id: 46,
    name: 'Île Christmas',
    code: 'CX'
  },
  {
    id: 162,
    name: 'Île Norfolk',
    code: 'NF'
  },
  {
    id: 106,
    name: 'Île de Man',
    code: 'IM'
  },
  {
    id: 41,
    name: 'Îles Caïmans',
    code: 'KY'
  },
  {
    id: 47,
    name: 'Îles Cocos',
    code: 'CC'
  },
  {
    id: 52,
    name: 'Îles Cook',
    code: 'CK'
  },
  {
    id: 71,
    name: 'Îles Féroé',
    code: 'FO'
  },
  {
    id: 95,
    name: 'Îles Heard-et-MacDonald',
    code: 'HM'
  },
  {
    id: 163,
    name: 'Îles Mariannes du Nord',
    code: 'MP'
  },
  {
    id: 137,
    name: 'Îles Marshall (pays)',
    code: 'MH'
  },
  {
    id: 174,
    name: 'Îles Pitcairn',
    code: 'PN'
  },
  {
    id: 224,
    name: 'Îles Turques-et-Caïques',
    code: 'TC'
  },
  {
    id: 237,
    name: 'Îles Vierges britanniques',
    code: 'VG'
  },
  {
    id: 238,
    name: 'Îles Vierges des États-Unis',
    code: 'VI'
  },
  {
    id: 231,
    name: 'Îles mineures éloignées des États-Unis',
    code: 'UM'
  },
  {
    id: 2,
    name: 'Îles Åland',
    code: 'AX'
  }
];
