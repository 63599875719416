import React from 'react';
import PropTypes from 'prop-types';

// REACT ROUTER DOM
import { NavLink } from 'react-router-dom';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Container, Drawer, Hidden, IconButton, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// FEATHER ICONS
import { Menu } from 'react-feather';

// APP IMPORTS
import ROLES from '../../../../../../../../utils/roles';
import ROUTES from '../../../../../../../../utils/routes';
import Sidebar from '../../../../../../../../components/core/Sidebar';
import styles from './styles';

class PublicAppBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDrawerOpened: false
    };
  }

  changeDrawerState = () => {
    this.setState((state) => ({
      isDrawerOpened: !state.isDrawerOpened
    }));
  };

  render() {
    const { classes, intl } = this.props;

    const barItems = [
      {
        route: ROUTES.ROOT,
        title: intl.formatMessage({ id: 'home' })
      },
      {
        route: ROUTES.SPECIES,
        title: intl.formatMessage({ id: 'species' })
      },
      {
        route: ROUTES.PLANS,
        title: intl.formatMessage({ id: 'plans' })
      },
      {
        route: ROUTES.LOGIN,
        title: intl.formatMessage({ id: 'login' })
      }
    ];

    return (
      <React.Fragment>
        <Drawer
          variant={'temporary'}
          open={this.state.isDrawerOpened}
          onClose={this.changeDrawerState}
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
        >
          <Sidebar isFixed={false} role={ROLES.DEFAULT} onItemClick={this.changeDrawerState} />
        </Drawer>
        <Container maxWidth={this.props.maxWidth}>
          <div className={classes.barContainer}>
            <NavLink to={ROUTES.ROOT} className={classes.logoContainer}>
              <img
                src={require('../../../../../../../../assets/images/logo.png')}
                alt={'myownbirds'}
                className={classes.logoImage}
              />
            </NavLink>
            <div className={classes.titlesContainer}>
              {
                /* HIDDEN when the screen width is less than SM */
                <Hidden smDown={true}>
                  {barItems.map((item) => (
                    <NavLink
                      key={item.route}
                      exact
                      to={item.route}
                      className={classes.titlesContainer}
                      activeClassName={classes.activeTitlesContainer}
                    >
                      <Typography variant={'h5'} color={'inherit'}>
                        {item.title}
                      </Typography>
                    </NavLink>
                  ))}
                  <NavLink
                    exact
                    to={ROUTES.REGISTER}
                    className={classes.titlesContainer}
                    activeClassName={classes.activeTitlesContainer}
                  >
                    <div className={classes.registerButton}>
                      <Typography variant={'h5'} color={'inherit'}>
                        {intl.formatMessage({ id: 'startNow' })}
                      </Typography>
                    </div>
                  </NavLink>
                </Hidden>
              }
              {
                /* HIDDEN when the screen width is higher than LG */
                <Hidden mdUp={true}>
                  <IconButton edge={'start'} color={'secondary'} onClick={this.changeDrawerState}>
                    <Menu size={20} color={'#f7b500'} />
                  </IconButton>
                </Hidden>
              }
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

PublicAppBar.propTypes = {
  maxWidth: PropTypes.string.isRequired
};

export default withStyles(styles)(injectIntl(PublicAppBar));
