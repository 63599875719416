import BG from '../../../../../../assets/images/bg.svg';

export default (theme) => ({
  content: {
    flex: 1
  },
  innerContainer: {
    display: 'block',
    zIndex: 1000,
    backgroundColor: '#15375C',
    backgroundImage: `url(${BG})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  contentContainer: {
    display: 'flex',
    position: 'relative',
    marginTop: theme.spacing(4)
  },
  footerContainer: {
    zIndex: 1000,
    backgroundColor: '#030000',
    paddingTop: theme.spacing(21)
  }
});
