export default (theme) => ({
  footerContent: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderStyle: 'solid',
    borderWidth: '1px 0px 0px 0px',
    borderColor: '#FFFFFF1A',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    alignContent: 'center'
  },
  footerTitle: {
    color: '#d4d4d4',
    fontWeight: 500
  },
  link: {
    color: '#d4d4d4',
    fontWeight: 500
  }
});
