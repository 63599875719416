import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// SPINE UI
import { PaperListInformation } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { fromUTCtoLocal } from '../../../../../utils/timeFormat';

class BirdSecondaryInformation extends React.PureComponent {
  getDeathAt = (deathAt) => {
    const { intl } = this.props;

    return `${intl.formatMessage({ id: 'deathAt' })} ${fromUTCtoLocal(deathAt).format('LL')}`;
  };

  getOptions = (options) => {
    if (options.length > 0) {
      const names = options.map((option) => option.name);
      return names.join(', ');
    }

    return '-';
  };

  render() {
    const { intl } = this.props;
    const items = [];
    if (this.props.state === 'death' && this.props.deathAt) {
      items.push({
        title: intl.formatMessage({ id: 'state' }),
        value: this.getDeathAt(this.props.deathAt)
      });
    }

    if (this.props.firstOwner) {
      items.push(
        ...[
          {
            title: intl.formatMessage({ id: 'firstOwner' }),
            value: this.props.firstOwner.name
          },
          {
            title: intl.formatMessage({ id: 'acquiredAt' }),
            value: fromUTCtoLocal(this.props.firstOwner.acquired_at).format('LL')
          }
        ]
      );
    }

    items.push(
      ...[
        {
          title: intl.formatMessage({ id: 'carrierOfMutations' }),
          value: this.getOptions(this.props.splitOptions)
        },
        {
          title: intl.formatMessage({ id: 'possiblyCarrierOfMutations' }),
          value: this.getOptions(this.props.possibleSplitOptions)
        },
        {
          title: intl.formatMessage({ id: 'registeredAt' }),
          value: fromUTCtoLocal(this.props.registeredAt).format('LLL')
        }
      ]
    );

    if (this.props.comments) {
      items.push({
        title: intl.formatMessage({ id: 'observations' }),
        value: (
          <Typography variant={'body1'} color={'textSecondary'}>
            {this.props.comments}
          </Typography>
        )
      });
    }

    return <PaperListInformation items={items} />;
  }
}

BirdSecondaryInformation.defaultProps = {
  deathAt: '',
  firstOwner: null,
  comments: ''
};

BirdSecondaryInformation.propTypes = {
  state: PropTypes.string.isRequired,
  deathAt: PropTypes.string,
  firstOwner: PropTypes.object,
  splitOptions: PropTypes.array.isRequired,
  possibleSplitOptions: PropTypes.array.isRequired,
  registeredAt: PropTypes.string.isRequired,
  comments: PropTypes.string
};

export default injectIntl(BirdSecondaryInformation);
