export default (theme) => ({
  filterFormContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  typeButton: {
    width: '200px',
    marginRight: theme.spacing(1)
  },

  //
  descriptionItemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'flex-start'
  },
  itemIcon: {
    color: theme.palette.common.black,
    marginTop: '0px',
    marginLeft: '-1px',
    marginRight: '10px'
  },
  itemText: {
    color: theme.palette.common.black
  }
});
