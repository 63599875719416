import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// SPINE UI
import { Input } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import styles from './styles';
import MuiPickersUtilsProvider from '../../../../../../components/inputs/MuiPickersUtilsProvider';
import InputDate from '../../../../../../components/inputs/InputDate';

class AddClutchForm extends React.PureComponent {
  render() {
    const { intl } = this.props;
    return (
      <MuiPickersUtilsProvider>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Input
              inputRef={this.props.eggsCountInputRef}
              name={'eggsCount'}
              type={'number'}
              autoComplete={'off'}
              label={intl.formatMessage({ id: 'totalEggs' })}
              value={this.props.eggsCount}
              onChange={this.props.handleInputChange}
              error={!!this.props.errors.eggsCount}
              helperText={this.props.errors.eggsCount || ''}
              required={true}
              disabled={this.props.isLoading}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputDate
              required={true}
              name={'startedAt'}
              label={intl.formatMessage({ id: 'startDate' })}
              value={this.props.startedAt}
              onChange={(date) => this.props.handleDateChange('startedAt', date)}
              okLabel={'Ok'}
              cancelLabel={intl.formatMessage({ id: 'cancel' })}
              error={!!this.props.errors.startedAt}
              helperText={this.props.errors.startedAt || ''}
              disabled={this.props.isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name={'description'}
              type={'text'}
              multiline={true}
              autoComplete={'off'}
              label={intl.formatMessage({ id: 'description' })}
              value={this.props.description}
              onChange={this.props.handleInputChange}
              error={!!this.props.errors.description}
              helperText={this.props.errors.description || ''}
              disabled={this.props.isLoading}
              required={false}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    );
  }
}

AddClutchForm.defaultProps = {
  eggsCount: 0
};

AddClutchForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  // ringInputRef: PropTypes.object.isRequired,
  eggsCountInputRef: PropTypes.object.isRequired,
  eggsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.string.isRequired,
  startedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  errors: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired
};

export default withStyles(styles)(injectIntl(AddClutchForm));
