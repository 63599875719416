import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { withSnackbar } from 'notistack';
import { Grid } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// SPINE UI
import { Content, Paper } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { fetchApi } from '../../../utils/apiSettings';
import { setAppBarTitle } from '../../../store/actions/appActions';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      // data
      iFrameUrl: ''
    };
  }

  componentDidMount() {
    this.props.setAppBarTitle('dashboard');

    this.fetchAdminDashboard();
  }

  fetchAdminDashboard = () => {
    const { intl, enqueueSnackbar } = this.props;

    fetchApi(
      'get',
      `/admin/v1/dashboard`,
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.setState({
          isLoading: false,

          // data
          iFrameUrl: data.iframe_url
        });
      },
      () => {
        enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
          variant: 'error',
          autoHideDuration: 3000
        });

        this.setState({
          isLoading: false
        });
      }
    );
  };

  render() {
    return (
      <Content isLoading={this.state.isLoading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper>
              {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
              <iframe
                src={this.state.iFrameUrl}
                frameBorder={'0'}
                width={'100%'}
                height={'1200px'}
              />
            </Paper>
          </Grid>
        </Grid>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(injectIntl(Dashboard)));
