export default (theme) => ({
  informationText: {
    color: theme.palette.common.black
  },
  informationTextMailTo: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textTransform: 'lowercase'
  }
});
