export default (theme) => ({
  formCard: {
    marginTop: '0px',
    marginBottom: '-48px'
  },
  link: {
    textDecoration: 'none'
  },
  text: {
    fontWeight: 600,
    color: theme.palette.secondary.dark
  }
});
