import React from 'react';
import PropTypes from 'prop-types';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Hidden, IconButton, Drawer } from '@material-ui/core';

// REACT ROUTER
import { withRouter } from 'react-router-dom';

// FEATHER ICONS
import { Menu } from 'react-feather';

// APP IMPORTS
import { fetchApi } from '../../../../../../utils/apiSettings';
import { setUserInformation } from '../../../../../../store/actions/userActions';
import { setAppLocale } from '../../../../../../store/actions/appActions';
import Sidebar from '../../../../../../components/core/Sidebar';
import AppLogo from './components/AppLogo';
import LogoutButton from './components/LogoutButton';
import AppTitle from './components/ToolbarTitle';
import AppBarButton from './components/AppBarButton';
import styles from './styles';
import { DEFAULT_LOCALE, setUserLanguage } from '../../../../../../utils/userSettings';

class PrivateAppContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDrawerOpened: false
    };
  }

  componentDidMount() {
    this.fetchUserInformation();
  }

  fetchUserInformation = () => {
    fetchApi(
      'get',
      '/v1/users/current',
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.props.setUserInformation(data);
        this.props.setAppLocale(data.locale || DEFAULT_LOCALE);
        setUserLanguage(data.locale || DEFAULT_LOCALE);
      },
      () => {}
    );
  };

  changeDrawerState = () => {
    this.setState((state) => ({
      isDrawerOpened: !state.isDrawerOpened
    }));
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar} color={'inherit'} position={'fixed'} elevation={0}>
          <Toolbar variant={'regular'} disableGutters={false}>
            {
              /* HIDE the Drawer navigator when the screen is less than md */
              <Hidden smDown={true}>
                <span className={classes.toolbarLeftInformation}>
                  <AppTitle />
                </span>
                <span className={classes.toolbarRightButtons}>
                  <AppBarButton>
                    <LogoutButton sidebar={false} />
                  </AppBarButton>
                </span>
              </Hidden>
            }
            {
              /* HIDE the Drawer navigator when the screen is greater than md */
              <Hidden mdUp={true}>
                <AppLogo />
                <span className={classes.toolbarRightButtons}>
                  <IconButton edge={'start'} color={'secondary'} onClick={this.changeDrawerState}>
                    <Menu size={20} />
                  </IconButton>
                </span>
              </Hidden>
            }
          </Toolbar>
        </AppBar>
        {
          /* HIDE the Drawer navigator when the screen is less than md */
          <Hidden smDown={true}>
            <Drawer
              variant={'permanent'}
              className={classes.drawer}
              classes={{ paper: classes.drawerPaper }}
            >
              <Sidebar isFixed={true} role={this.props.role} onItemClick={() => {}} />
            </Drawer>
          </Hidden>
        }
        {
          /* HIDE the Drawer navigator when the screen is greater than md */
          <Hidden mdUp={true}>
            <Drawer
              variant={'temporary'}
              open={this.state.isDrawerOpened}
              onClose={this.changeDrawerState}
              className={classes.drawer}
              classes={{ paper: classes.drawerPaper }}
            >
              <Sidebar
                isFixed={false}
                role={this.props.role}
                onItemClick={this.changeDrawerState}
              />
            </Drawer>
          </Hidden>
        }
        <main className={classes.content}>
          <div className={classes.drawerToolbar} />
          {this.props.children}
        </main>
      </div>
    );
  }
}

PrivateAppContainer.propTypes = {
  role: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const mapStateToProps = (state) => ({
  user: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
  setUserInformation: (user) => {
    dispatch(setUserInformation(user));
  },
  setAppLocale: (locale) => {
    dispatch(setAppLocale(locale));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(PrivateAppContainer)));
