// UTILS
import { getColors } from '../../../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  container: {
    display: 'flex',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: colors.logo,
    textDecoration: 'none'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    alignContent: 'center'
  },
  logoInnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
    width: '100px',
    borderRadius: '20px',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
    boxShadow: '0px 0px 15px 2px rgba(0, 0, 0, 0.24)'
  },
  logo: {
    objectFit: 'contain',
    width: '100%',
    borderRadius: '20px'
  },
  title: {
    color: '#fff',
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '4px'
  },
  description: {
    color: '#fff',
    fontWeight: 'normal',
    textTransform: 'lowercase',
    letterSpacing: '3px',
    marginTop: '5px'
  }
});
