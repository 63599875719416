// SPANISH

export default {
  // app
  logout: 'Cerrar sesión',
  view: 'Ver',
  add: 'Adicionar',
  remove: 'Eliminar',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  next: 'Siguiente',
  back: 'Atrás',
  yes: 'Si',
  no: 'No',
  undefined: 'Indefinido',
  edit: 'Editar',
  goBack: 'Regresa',
  close: 'Cerca',
  rowsPerPage: 'Filas por página',
  of: 'de',
  home: 'Inicio',
  startNow: 'Registrarse',

  // sidebar
  dashboard: 'Tablero principal',
  users: 'Usuarios',
  account: 'Cuenta',
  others: 'Otros',
  plans: 'Planes',
  transfers: 'Cesiones',

  // home
  birdsPortal: 'Portal completo para la gestión de aviarios',
  breedersCollaboration: 'Con la colaboración de criadores de aves',
  birdsManagement: 'Gestión de aves',
  couplesAndClutchesManagement: 'Manejo de parejas y embragues',
  birdTransfers: 'Cesión de aves',
  searchBreedersWithSpecificSpecies: 'Búsqueda de criadores con especies específicas',

  mainFunctionalities: 'Principales características',
  availableForBreeder: 'Disponible para todos los criadores',
  manageBirdsStock: 'Gestión del stock de aves',
  manageCouples: 'Gestión de parejas',
  manageClutches: 'Gestión de la temporada de reproducción',
  efficientSearch: 'Búsqueda avanzada',
  birdProfile: 'Ficha individual de los pájaros',
  simpleTransfer: 'Proceso de cesión simplificado',

  // public plans
  increaseProductivity: 'Aumente su productividad y control al criar aves',
  freeDescription:
    'Será gratis para siempre. El plan perfecto para conocer algunas de nuestras funcionalidades.',
  basicDescription:
    'Ideal para quienes quieran iniciar esta actividad y empezar a manejar su aviário con facilidad.',
  premiumDescription: 'Ideal para quienes quieran alcanzar el nivel profesional.',
  platinumDescription: 'El plan perfecto para aquellos que buscan cruzar los limites.',
  manageInfiniteNumberOfBirds: 'Maneja un número infinito de aves',
  manageCouplesUpTo: 'Maneja hasta {number} parejas',
  createTransferRequests: 'Cree pedidos y documentos respectivos para ceder aves a otros criadores',
  searchForOtherBirdsFromOtherBreeders: 'Búsqueda avanzada de aves de otros criadores',
  year: 'año',

  // login
  login: 'Iniciar sesión',
  email: 'Email',
  password: 'Contraseña',
  rememberMe: 'Recuérdame',
  forgotPassword: 'Olvidaste tu contraseña?',
  invalidEmail: 'This email is not valid.',
  emailIsRequired: 'Este correo electrónico no es válido.',
  passwordIsRequired: 'Se requiere la contraseña',
  invalidCredentials: 'Credenciales no válidas.',

  // species
  meetTheSupportedSpecies: 'Conoce las especies presentes en la plataforma',
  weAreKeenToSupportMore: 'Envíenos un correo electrónico a {email} si desea agregar más especies.',

  // register
  perfectPlatform: 'La plataforma perfecta para gestionar tus pájaros',
  ourMission:
    'Nuestra misión es ayudar a los criadores a administrar su aviario fácilmente. Tenemos muchas funciones disponibles:',
  createYourFreeAccount: 'Crea tu cuenta gratuita',
  createAccount: 'Crear cuenta',
  acceptTermsAndConditionsRegister: 'Al seguir adelante, estás de acuerdo con',
  termsAndConditions: 'Términos y condiciones',
  registerErrorEmailAlreadyUsed: 'Este correo electrónico ya se está utilizando.',
  registerErrorStripeProblem: 'Error con API externa. Inténtalo de nuevo.',
  registerErrorConfirmationRegistrationCode: 'Este código de registro no es válido.',
  registerErrorInvalidEmail: 'Este correo electrónico no es válido.',
  registerErrorInvalidRecaptchaToken: 'ReCaptcha no es válido.',
  invalidPassword: 'La contraseña debe tener al menos 5 caracteres',
  registerSuccess: 'Revise su correo electrónico e ingrese el código de registro.',
  registrationCode: 'Código de registro',
  confirmRegistrationCode: 'Confirma código',

  // forgot password
  sendResetInstructions: 'Enviar instrucciones de reinicio',
  backToLogin: 'Atrás para iniciar sesión',
  resetPasswordRequestInfoMessage:
    'Se le ha enviado un correo electrónico para restablecer su contraseña',
  resetPassword: 'Restablecer la contraseña',
  resetRequestErrorMessage: 'La solicitud para restablecer su contraseña ya no es válida',

  // users
  addUser: 'Adicionar usuario',
  noUsers: 'No hay usuarios',
  emailConfirmed: 'Correo electrónico confirmado',
  country: 'País',
  userErrorMessage: 'Usuario no encontrado',

  // users add
  contact: 'Contacto',
  birthday: 'Nacimiento',

  // breeder dashboard
  hiBreeder: 'Hola, {breederName}',
  numberOfBirds: 'Pájaros',
  numberOfCouples: 'Parejas',
  numberOfClutches: 'Posturas',
  youCurrentlyHave:
    'Actualmente tienes el plan {plan}. Si desea suscribirse a otra solución, visite nuestra {plansPage}.',
  plansPage: 'página de planes',
  numberOfTransfers: 'Transferencias',
  numberOfDead: 'Muerto',

  // account
  language: 'Idioma',
  registeredAt: 'Registrado en',
  updatedAt: 'Actualizado en',
  changePassword: 'Cambiar contraseña',
  currentPassword: 'Contraseña actual',
  newPassword: 'Nueva contraseña',
  confirmPassword: 'Confirmar contraseña',
  passwordsDontMatch: 'Las contraseñas no coinciden.',
  editAccount: 'Editar cuenta',
  changedPasswordErrorMessage: 'La contraseña actual no es correcta.',
  changedPasswordSuccessMessage: 'La contraseña se ha cambiado correctamente.',
  changedPersonalInformationInfoMessage: 'Tu información personal fue cambiada.',
  breederIdentifiers: 'Identificadores de criador',
  addBreederIdentifier: 'Agregar identificador',
  setAsDefault: 'Establecer como predeterminado',
  isDefault: 'Valor por defecto',
  breederIdentifier: 'Identificador de criador',
  removeBreederIdentifier: 'Eliminar identificador de criador',
  removeBreederIdentifierSuccessMessage: 'El identificador fue eliminado',
  removeBreederIdentifierErrorMessage: 'No fue posible eliminar el identificador',
  removeBreederIdentifierQuestion: '¿Está seguro de que desea eliminar este identificador?',
  setAsDefaultQuestion:
    '¿Está seguro de que desea establecer este identificador como predeterminado?',
  setAsDefaultBreederIdentifierSuccessMessage: 'El identificador se estableció como predeterminado',
  setAsDefaultBreederIdentifierErrorMessage:
    'No fue posible establecer como predeterminado el identificador',
  editBreederIdentifier: 'Editar identificador de criador',
  editBreederIdentifierSuccessMessage: 'El identificador del criador fue editado',
  breederIdentifierErrorMessage: 'El identificador del criador ya se usa',
  addBreederIdentifierSuccessMessage: 'Se ha agregado un nuevo identificador de criador',
  identifierDescription:
    'El identificador representa un código asignado a un creador por una ' +
    'asociación / federación / otros.',
  identifierDescription2: 'El identificador se utilizará al crear un pájaro.',
  noIdentifiers: 'Sin identificadores',
  closeAccount: 'Cerrar cuenta',
  closeAccountInformation:
    'Cerrar su cuenta es permanente y no se puede deshacer. ' +
    'Esto eliminará permanentemente todos los datos asociados de su usuario. Si tiene alguna ' +
    'suscripción la cancelaremos inmediatamente.',
  closeAccountKeepBirds: 'Se conservará la información relativa a las transferencias de aves.',
  closeAccountEmailWillBeSent:
    'Por razones de seguridad, le enviaremos un correo electrónico con un código que debe insertar ' +
    'para confirmar que desea cerrar su cuenta.',
  confirmationCode: 'Código de confirmación',

  // plans
  choosePlan: 'elegir plan',
  managePlan: 'Administrar plan',
  plan: 'Plano',
  free: 'Gratis',
  basic: 'Básico',
  premium: 'Premium',
  platinum: 'Platino',
  custom: 'Personalizado',
  select: 'Seleccione',
  actualPlan: 'Plan actual',

  // email confirmation
  emailConfirmation: 'Confirmación de correo electrónico',
  emailWasConfirmed: 'Su correo electrónico ha sido confirmado con éxito.',
  emailWasNotConfirmed:
    'Hubo un problema al intentar confirmar su correo electrónico. Si aún no lo ha confirmado, solicite un enlace de confirmación nuevamente.',
  requestConfirmation: 'Solicitar nueva confirmación',

  // admin dashboard
  activeUsers: 'Usuarios activos',
  accountsClosed: 'Cuentas cerradas',
  top10CountriesWithBreeders: 'Top 10 países con criadores activos',
  top10CountriesWithBirds: 'Top 10 países con aves activas',
  top10SpeciesWithBirds: 'Top 10 especies con aves activas',

  // breeder users
  name: 'Nombre',
  addedAt: 'Adicionado a',
  active: 'Ativo',
  addUserIfNotExist:
    'El usuario se agregará a su lista después de que el usuario haya aceptado la solicitud. Asegúrese de que el correo electrónico del usuario sea correcto.',
  addUserRecommendation:
    'Si el usuario ya está registrado en nuestra plataforma, solicítele el correo electrónico.',
  searchBy: 'Buscar por',
  searchByNameEmailCountry: 'Nombre, correo electrónico o país de usuario',

  // breeder birds
  addBird: 'Adicionar pájaro',
  myBirds: 'Mis pájaros',
  searchBirds: 'Buscar aves',
  transferRequests: 'Solicitudes de transferencia',
  breeder: 'Criador',
  ring: 'Anilla',
  gender: 'Sexo',
  specie: 'Especie',
  mutation: 'Mutación',
  noBirds: 'No hay pájaros',
  searchByRing: 'Anilla',
  birthdayStart: 'Inicio de cumpleaños',
  birthdayEnd: 'Fin de cumpleaños',
  genderAndCountry: 'Sexo y País',
  birdsErrorMessage: 'Error al obtener tus pájaros',
  speciesErrorMessage: 'Error al obtener todas las especies',
  mutationsErrorMessage: 'Error al obtener todas las mutaciones',
  filterSearch: 'Filtros',
  addBirdSuccessMessage: 'Se ha registrado un nuevo pájaro',
  addBirdErrorMessage: 'No fue posible agregar un nuevo pájaro',
  observations: 'Observaciones',
  initialData: 'Datos iniciales',
  isYellow: 'Es amarillo',
  isWhiteHead: 'Es cabeza blanca',
  isCarduelisSpecieHeader:
    'Ha seleccionado la especie Carduelis Carduelis. Seleccione si su pájaro es amarillo o es de cabeza blanca.',
  yellow: 'Amarillo',
  whiteHead: 'Cabeza blanca',
  addBreederIdentifierAddNewBirdMessage:
    'Si desea agregar o no tiene un identificador, haga clic en ',
  acquiredAt: 'Adquirido en',
  firstOwner: 'Primer propietario',
  owner: 'Propietario',
  firstOwnerInformation: 'Información del primer propietario',
  firstOwnerInformationMessage:
    'Al agregar un pájaro, puede indicar si es el propietario del pájaro o si lo compró.',
  ringTotal: 'Anillo total',
  ringAlreadyUsed: 'Anillo ya usado',
  number: 'Número',
  extraInformation: 'Información extra',
  breederName: 'Nombre del criador',
  invalidDate: 'Formato de fecha no válido. Debes especificar el día, mes y año (dd/mm/yyyy).',
  birdInformation: 'Información de pájaro',
  carrierOfMutations: 'Portador de',
  possiblyCarrierOfMutations: 'Posiblemente portador de',

  // bird states
  state: 'Estado',
  transferred: 'Cedido',
  transferredAt: 'Cedido a',
  death: 'Muerto',
  deathAt: 'Muerto a',
  all: 'Todos',

  // bird information
  children: 'Crias',
  clutches: 'Posturas',
  parents: 'Progenitores',
  parent: 'Progenitor',
  parent1: 'Padre 1',
  parent2: 'Padre 2',
  acquired: 'Adquirido',
  editBirdSuccessMessage: 'Este pájaro fue editado con éxito.',
  editBirdErrorMessage: 'No fue posible editar este pájaro.',
  documents: 'Documentos',
  editBird: 'Editar pájaro',
  noDocuments: 'Este pájaro no tiene documentos adjuntos',
  document: 'Documento',
  noComments: 'Sin comentarios',
  removeBird: 'Eliminar pájaro',
  onlyOneFieldFillError: 'Solo puedes completar un campo',
  removeBirdQuestion: 'Estás seguro de que quieres eliminar este pájaro?',
  removeBirdSuccessMessage: 'El pájaro fue eliminado con éxito.',
  removeBirdErrorMessage: 'No fue posible eliminar este pájaro.',
  removeBirdHasChildrenErrorMessage:
    'El pájaro tiene hijos. Si desea cambiar la información de las aves, actualice estas secciones a continuación.',
  removeBirdWasTransferredErrorMessage:
    'El pájaro te fue transferido. Si desea cambiar la información de las aves, actualice estas secciones a continuación.',
  declareDeath: 'Declarar muerte',
  declareDeathSuccessMessage: 'El pájaro fue declarado muerto con éxito.',
  removeImageSuccessMessage: 'La imagen se eliminó correctamente.',
  getBirdErrorMessage: 'El pájaro no existe o no tienes acceso a él.',

  transfersHistory: 'Historial de transferencias',
  transfer: 'Transferir',
  transferLine: 'Transferido a {country} en {date}.',
  transferLineFrom: 'Transferido de {country} en {date}.',
  removeImage: 'Eliminar imagen',
  removeImageQuestion: 'Está seguro de que desea eliminar esta imagen?',
  setImageAsDefault: 'Establecer imagen como predeterminada',
  setImageAsDefaultQuestion: 'Está seguro de que desea establecer esta imagen como predeterminada?',

  // bird couples
  couples: 'Parejas',
  noCouples: 'Sin parejas',
  addCoupleErrorMessagePlanDontAllow: 'Compruebe si su plan le permite hacer esto.',
  parentsAreBrothers: 'Estos pájaros son hermanos o medio hermanos.',
  couplesErrorMessage: 'Error al obtener a tus parejas',
  coupleErrorMessage: 'Error al obtener a tu pareja',
  couplesSuccessMessage: 'Se creó una pareja con éxito.',
  inactive: 'Inactivo',
  addCouple: 'Adicionar pareja',
  addCoupleInformation:
    'Las siguientes aves deben estar ya registradas en nuestra plataforma. Si desea registrarlos con anticipación, haga clic ',
  here: 'aquí.',
  chooseParents: 'Elige padres',
  searchByParentsRing: 'Identificador de pareja',
  male: 'Macho',
  female: 'Hembra',
  unknown: 'Desconocido',
  identifier: 'Identificador',
  identifierIsRequired: 'El identificador es obligatorio.',
  identifierMoreThan: 'El identificador debe ser mayor que 0',
  identifierAlreadyUsed: 'Identificador ya utilizado',
  editIdentifier: 'Editar identificador',
  editCouple: 'Editar pareja',
  editCoupleSuccessMessage: 'La pareja se editó con éxito.',
  editCoupleErrorMessage: 'Hubo un problema al intentar editar esta pareja.',
  setCageIdentifierCoupleInactive:
    'Esta pareja está inactiva o estará inactiva. No es posible cambiar el identificador de la jaula.',
  setIsActiveCoupleErrorMessage:
    'No se puede reactivar a esta pareja. Uno de los padres ha fallecido o ha sido trasladado.',
  removeCouple: 'Quitar pareja',
  removeCoupleNotice:
    "Considere establecer esta pareja como inactiva (en 'Editar pareja'). Conservaremos las aves relacionadas, pero quitaremos las garras y perderá la pista de las relaciones de las aves.",
  removeCoupleQuestion: '¿Pretendes eliminar a esta pareja? ¡Esto es irreversible!',
  removeCoupleSuccessMessage: 'La pareja fue eliminada con éxito.',
  removeCoupleErrorMessage: 'Hubo un problema al intentar eliminar a esta pareja.',

  // couple information
  addChild: 'Adicionar cria',
  addClutch: 'Adicionar postura',
  couple: 'Pareja',
  scheduled: 'Programado',
  finished: 'Terminado',
  addChildErrorMessage: 'No fue posible adicionar una cria nueva',
  addChildCoupleInactive:
    'Esta pareja está inactiva. Solo es posible agregar un nuevo hijo para vincularlo a un postura existente.',
  addChildSuccessMessage: 'Se ha adicionado una nueva cria a esta pareja.',
  addCoupleChildInformationHeader:
    'El niño que quedará registrado en esta pareja no estará vinculado a ninguna postura. Si desea esa conexión, haga clic en',
  child: 'Cria',
  cageIdentifier: 'Identificador de jaula',
  cage: 'Jaula',
  qrCodeMessage:
    'Utilice este código QR para identificar a esta pareja y acceder rápidamente a esta página.',

  // clutches
  totalEggs: 'Huevos totales',
  startDate: 'Fecha de inicio',
  description: 'Descripción',
  addClutchSuccessMessage: 'Se agregó una nueva postura a esta pareja.',
  addClutchErrorMessage: 'No fue posible agregar una nueva postura a esta pareja.',
  addClutchCoupleInactive: 'Esta pareja está inactiva. No es posible agregar nuevas posturas.',
  noClutches: 'No hay posturas',
  clutchesErrorMessage: 'Error al obtener las posturas de esta pareja',
  childrenCount: '{eggsCount} huevos y {childrenCount} niños',
  onGoing: 'A transcurrir',

  // clutch
  clutch: 'Postura',
  editClutch: 'Editar postura',
  abortedEggs: 'Huevos abortados',
  fertilizedEggs: 'Huevos fertilizados',
  removeClutch: 'Eliminar postura',
  totalEggsExceeded: 'Se excedió el total de huevos',
  editClutchSuccessMessage: 'Los datos de postura se han actualizado correctamente',
  editClutchErrorMessage: 'No se pudieron actualizar los datos de postura',
  addClutchChildHeader:
    'Solo puede agregar niños a esta postura si agrega más huevos fertilizados.',

  // birds market
  breederEmail: 'Correo electrónico del criador',
  searchByBreederEmail: 'Correo electrónico del criador',
  breederInformation: 'Información del criador',
  bird: 'Pájaro',
  transferRequest: 'Solicitud de transferencia',
  transferRequestSuccessMessage: 'La transferencia se creó con éxito.',
  transferRequestErrorMessage: 'Hubo un problema al intentar crear tu transferencia.',
  transferNumberAlreadyExists: 'Este número ya existe para el año actual.',
  specieRequired: 'Tienes que elegir una especie',
  mutationRequired: 'Tienes que elegir una mutación',
  showInterest: 'Muestra interés',
  sendEmailQuestion:
    'Se enviará un correo electrónico al criador con la información de que tiene ' +
    'interés en la mutación {mutation}. Se compartirá su nombre y correo electrónico. ' +
    '¿Quieres enviarlo?',
  sendEmailSuccessMessage: 'El correo electrónico se envió con éxito.',

  // transfer requests
  createTransfer: 'Crear documento de cesión',
  holder: 'Criador',
  newOwner: 'Nuevo proprietario',
  birdMutation: 'Mutación de pájaro',
  noTransferRequests: 'Sin solicitudes de transferencia',
  transferRequestsErrorMessage: 'Error al obtener sus solicitudes de transferencia',
  chooseBirds: 'Elige pájaros',
  questionBreederUser: 'No es un usuario de la aplicación?',
  addBreederInformation: 'Adicionar información del criador',
  zipCode: 'Código postal',
  address: 'Dirección',
  birds: 'Pájaros',
  sent: 'Expedido',
  received: 'Recibido',
  type: 'Tipo',
  pending: 'Pendiente',
  accepted: 'Aceptado',
  refused: 'Rechazado',
  removed: 'Eliminado',
  transfersUnderMaintenance: 'Cesiones en mantenimiento',
  transfersUnderMaintenanceText:
    'Las transferencias volverán a estar en línea lo antes posible. Estamos trabajando en transferencias específicas para cada país y aseguraremos la mejor experiencia posible para nuestros usuarios. Usted será capaz de:',
  weAreVeryGrateful: 'Estamos muy agradecidos por su confianza en nosotros y en nuestro sitio.',
  pleaseContact: 'Comuníquese con {mailto} si tiene alguna pregunta.',

  // transfer request
  acceptTransfer: 'Aceptar cesión',
  refuseTransfer: 'Rechazar cesión',
  removeTransfer: 'Eliminar cesión',
  downloadTransferDocument: 'Documento de cesión',
  sharedDocuments: 'Documentos compartidos',
  registerDate: 'Fecha de registro',
  transferNumberOfBirds: 'Número de pájaros',
  accessCodeTitle: 'Código de acceso a la información',
  accessCodeMessage:
    'En el proceso de transferencia, es posible proporcionar su información personal al propietario de las aves de una manera muy simple: solo proporcione el código a continuación que se le solicitará durante la creación de la transferencia.',
  accessCodeDuration:
    'Este código se genera automáticamente cada 15 segundos y solo se puede usar una vez.',
  removeTransferMessage: '¿Estás seguro de que deseas eliminar esta transferencia?',
  removeTransferError: 'No se puede eliminar esta transferencia. Ha sido aprobado o rechazado.',
  removeTransferSuccess: 'La transferencia se eliminó con éxito.',

  // create transfer request
  isRegistered: 'Está registrado en la plataforma',
  receptorCode: 'Código de acceso a los datos del nuevo propietario',
  receptorCodeHelperText: 'Este código está en la página de transferencias del usuario.',
  receptorGetData: 'Obtener datos',
  transferNumber: 'Número da cedência',
  createTransferInfo1:
    'El documento de cedência se basa en el país del usuario que lo crea. Gestionamos activamente estos documentos. Si cree que faltan campos obligatorios en su país, indíquelos por correo electrónico a {email}.',
  createTransferInfo2: 'Solo podrás crear una transferencia una vez finalizada la anterior.',
  createTransferInfo3: 'Campos marcados con * son obligatorios.',
  createTransferInfo4:
    'El número de transferencia se genera automáticamente, pero puede editarlo y debe ser único en el año actual.',
  createTransferInfo5:
    'Es posible crear transferencias para personas no registradas en la plataforma. En este caso, la transferencia se cerrará automáticamente y las aves se marcarán como transferidas.',
  createTransferInfo6:
    'Si el nuevo titular está registrado en la plataforma, es necesario que indique el código generado en el área de transferencia para poder obtener sus datos personales. Este proceso permite que las aves se transfieran automáticamente al nuevo propietario tras su aprobación.',
  breederNotFoundOrInvalidCode: 'Criador no encontrado o código inválido.',

  removeTransferRequest: 'Eliminar solicitud de transferencia',
  breederRequesterInformation: 'Información del solicitante',
  breederReceiverInformation: 'Información del receptor',
  stam: 'STAM',
  breederCode: 'Registro Gubernamental del Creador (código)',
  removeTransferRequestQuestion:
    'Está seguro de que desea eliminar la solicitud de transferencia? El receptor no podrá confirmar la transferencia de estas aves.',
  removeTransferRequestSuccessMessage: 'La solicitud de transferencia se eliminó con éxito',
  removeTransferRequestErrorMessage: 'No fue posible eliminar la solicitud de transferencia.',
  addMoreBreederInformation: 'Adicionar información personal',
  addMoreBreederInformationMessage:
    'Es necesario introducir algunos datos en su perfil previamente (dirección, código postal, país). Ingrese estos datos ',

  // public transfer request
  transferRequestConfirmation: 'Confirmación de solicitud de transferencia',
  confirmTransfer: 'Confirmar solicitud de transferencia',
  transferRequestConfirmationSuccessMessage: 'Solicitud de transferencia confirmada',
  transferRequestCancelSuccessMessage: 'Solicitud de transferencia cancelada',
  transferRequestConfirmationErrorMessage: 'No fue posible confirmar la solicitud de transferencia',
  transferRequestCancelErrorMessage: 'No fue posible cancelar la solicitud de transferencia',
  missingDataBreederReceiverErrorMessage:
    'El destinatario aún no ha completado los datos obligatorios en su perfil.',

  // admin
  species: 'Especies',
  addSpecie: 'Adicionar especie',
  noSpecies: 'Sin especies',
  mutations: 'Mutaciones',
  addMutation: 'Adicionar mutación',
  noMutations: 'Sin mutaciones',
  image: 'Imagen',
  addSpecieSuccessMessage: 'Se ha registrado una nueva especie',
  addSpecieErrorMessage: 'Esta especie ya existe.',
  retrieveSpeciesErrorMessage: 'Error al recuperar la especie',
  retrieveSpecieErrorMessage: 'Error al recuperar la especie',
  hasClutchDurationDays: 'Agregar días de duración de la postura',
  days: 'Días',
  clutchDurationDays: 'Duración de la postura (días)',
  addMutationErrorMessage: 'Esta mutación ya existe.',
  editSpecie: 'Editar especie',
  editSpecieSuccessMessage: 'La especie ha sido editada con éxito.',
  removeSpecie: 'Eliminar especie',
  removeSpecieErrorMessage: 'Error al eliminar esta especie',
  removeSpecieQuestion:
    'Estás seguro de que quieres eliminar esta especie? Las mutaciones relacionadas también se eliminarán.',
  retrieveMutationErrorMessage: 'Error al recuperar la mutación',
  editMutation: 'Editar mutación',
  removeMutation: 'Eliminar mutación',
  removeMutationErrorMessage: 'Error al eliminar esta mutación',
  removeMutationQuestion: 'Estás seguro de que quieres eliminar esta mutación?',

  // other error messages
  serverErrorMessage: 'Ocurrió un error interno del servidor. Estamos investigando',

  // input file
  dragAndDrop: 'Arrastrar y soltar',
  or: 'o',
  uploadImage: 'Cargar imagen',
  uploadImages: 'Cargar imagenes',
  uploadFile: 'Cargar archivo',
  uploadFiles: 'Cargar archivos',
  errorFileSize: 'El archivo es demasiado grande',
  errorWhileUploading: 'No se puede cargar este archivo.',

  // hide components
  changeYourPlanAndGetAccess: 'Cambie su plan y obtenga acceso a nuevas funciones',
  askOtherBreederToContactYou:
    'Informe a otro criador que está interesado en una mutación específica y pídale que se comunique con usted',
  generatePdfFile:
    'Generar un documento listo para ser firmado con la información de los criadores y todas las aves que serán cedidas'
};
