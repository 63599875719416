import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';

// REACT ROUTER
import { NavLink } from 'react-router-dom';

// APP IMPORTS
import ROUTES from '../../../../../../../../utils/routes';
import styles from './styles';

class AppLogo extends React.PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <NavLink to={ROUTES.ROOT} className={classes.logoContainer}>
        <img
          src={require('../../../../../../../../assets/images/logo.png')}
          alt={'myownbirds'}
          className={classes.logoImage}
        />
      </NavLink>
    );
  }
}

export default withStyles(styles)(AppLogo);
