import decode from 'jwt-decode';

import ROLES from './roles';

// default settings
export const DEFAULT_LOCALE = 'en_GB';

// local and session storage key names
const TOKEN = 'JWT_TOKEN';
const LANGUAGE = 'APP_LANGUAGE';

export function clearStorage() {
  localStorage.clear();
  sessionStorage.clear();
}

export function getUserToken() {
  return localStorage.getItem(TOKEN) || sessionStorage.getItem(TOKEN);
}

export function setUserToken(token, rememberMe) {
  // save to LOCAL STORAGE
  if (rememberMe) {
    localStorage.setItem(TOKEN, token);
  }
  // save to SESSION STORAGE
  else {
    sessionStorage.setItem(TOKEN, token);
  }
}

export function isRememberMeActive() {
  return !!localStorage.getItem(TOKEN);
}

export function getUserRole() {
  const token = getUserToken();
  if (token) {
    try {
      const tokenData = decode(token);
      const { roles } = tokenData;

      if (roles.find((item) => item === ROLES.ADMIN)) return ROLES.ADMIN;
      if (roles.find((item) => item === ROLES.BREEDER)) return ROLES.BREEDER;
    } catch (e) {
      // console.error(e);
    }
  }
  return ROLES.DEFAULT;
}

export function getUserId() {
  const token = getUserToken();
  if (token) {
    try {
      const tokenData = decode(token);
      return tokenData.id;
    } catch (e) {
      // console.error(e);
    }
  }
  return null;
}

export function getUserLanguage() {
  return localStorage.getItem(LANGUAGE) || sessionStorage.getItem(LANGUAGE);
}

export function setUserLanguage(language) {
  localStorage.setItem(LANGUAGE, language);
  sessionStorage.setItem(LANGUAGE, language);
}
