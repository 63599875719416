// UTILS
import { getColors } from '../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  avatarContainerClickable: {
    cursor: 'pointer'
  },
  avatarContainer: {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.light,
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  avatarContainerBig: {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.common.white,
    width: theme.spacing(20),
    height: theme.spacing(20),
    border: `1px solid ${colors.border}`
  }
});
