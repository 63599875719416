import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import Features from './components/Features';
import MyBirds from './components/MyBirds';
import MyCouples from './components/MyCouple';
import styles from './styles';

class Home extends React.PureComponent {
  render() {
    const { intl, classes } = this.props;

    return (
      <Container maxWidth={false} disableGutters={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Container>
              <div className={classes.headerTextContainer}>
                <div className={classes.headerSmallTitleContainer}>
                  <span className={classes.headerSmallDot} />
                  <Typography variant={'body1'} className={classes.headerSmallTitle}>
                    {intl.formatMessage({ id: 'birdsPortal' })}
                  </Typography>
                </div>
                <div className={classes.headerBigTitleContainer}>
                  <Typography variant={'h2'} className={classes.headerBigTitle}>
                    {intl.formatMessage({ id: 'breedersCollaboration' })}
                  </Typography>
                </div>
              </div>
            </Container>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.contentContainer}>
              <Container>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Features />
                  </Grid>
                  <Grid item xs={12}>
                    <MyBirds />
                  </Grid>
                  <Grid item xs={12}>
                    <MyCouples />
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles)(injectIntl(Home));
