import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { Grid } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// SPINE UI
import { Select } from '@oliveirahugo68/spine-ui';

class FilterForm extends React.PureComponent {
  render() {
    const { intl } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Select
            name={'specie'}
            label={intl.formatMessage({ id: 'specie' })}
            value={this.props.specie}
            onChange={this.props.handleSelectChange}
            error={!!this.props.errors.locale}
            helperText={this.props.errors.specie || ''}
            disabled={this.props.isLoading}
            autoComplete={'off'}
            options={this.props.allSpecies}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            name={'mutation'}
            label={intl.formatMessage({ id: 'mutation' })}
            value={this.props.mutation}
            onChange={this.props.handleSelectChange}
            error={!!this.props.errors.mutation}
            helperText={this.props.errors.mutation || ''}
            disabled={this.props.isLoading || this.props.specie === ''}
            autoComplete={'off'}
            options={this.props.allMutations}
          />
        </Grid>
      </Grid>
    );
  }
}

FilterForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  specie: PropTypes.string.isRequired,
  mutation: PropTypes.string.isRequired,
  allSpecies: PropTypes.array.isRequired,
  allMutations: PropTypes.array.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

export default injectIntl(FilterForm);
