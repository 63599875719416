import { getColors } from '../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  button: {
    [theme.breakpoints.up('md')]: {
      minWidth: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '75%'
    },
    borderStyle: 'solid',
    borderTopWidth: '2px',
    borderRightWidth: '2px',
    borderLeftWidth: '2px',
    borderBottomWidth: '2px',
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.light,
    borderRadius: '24px',
    padding: '14px 30px',
    paddingLeft: '30px',
    outline: 0,
    '&:hover': {
      cursor: 'pointer',
      borderColor: colors.sidebarItem,
      backgroundColor: colors.sidebarItem
    },
    '&:disabled': {
      cursor: 'not-allowed',
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.light
    }
  },
  text: {
    color: '#fff',
    fontFamily: "'Poppins',sans-serif",
    fontWeight: 'bold',
    lineHeight: '1',
    textTransform: 'uppercase'
  }
});
