import { getColors } from '../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  // content
  headerTextContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(12)
  },
  headerSmallTitleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  headerSmallDot: {
    display: 'inline-block',
    height: '12px',
    width: '12px',
    backgroundColor: colors.sidebarItem,
    borderRadius: '50%'
  },
  headerSmallTitle: {
    display: 'inline-block',
    color: '#fff',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '3px',
    verticalAlign: 'baseline',
    paddingLeft: '16px'
  },
  headerBigTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '67%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '85%'
    }
  },
  headerBigTitle: {
    display: 'inline-block',
    color: '#fff',
    fontWeight: 600,
    textAlign: 'left',
    lineHeight: '1.3em'
  },
  contentContainer: {
    backgroundColor: '#fff',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    borderRadius: '20px',
    // borderTop: `4px solid ${colors.sidebarItem}`,
    boxShadow: '0px 64px 120px -40px rgba(0, 0, 0, 0.24)',
    overflow: 'hidden',
    marginBottom: '-48px'
  }
});
