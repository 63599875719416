import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI DATE PICKER
import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider as MaterialUiPickersProvider } from '@material-ui/pickers';

class MuiPickersUtilsProvider extends React.PureComponent {
  render() {
    const { locale } = this.props.app;

    let muiLocale;
    switch (locale) {
      case 'pt_PT':
        moment.locale('pt');
        muiLocale = 'pt';
        break;
      case 'es_ES':
        moment.locale('es');
        muiLocale = 'es';
        break;
      case 'fr_FR':
        moment.locale('fr');
        muiLocale = 'fr';
        break;
      case 'it_IT':
        moment.locale('it');
        muiLocale = 'it';
        break;
      default:
        moment.locale('en');
        muiLocale = 'en';
        break;
    }

    return (
      <MaterialUiPickersProvider utils={MomentUtils} locale={muiLocale}>
        {this.props.children}
      </MaterialUiPickersProvider>
    );
  }
}

const mapStateToProps = (state) => ({ app: state.appReducer });
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(MuiPickersUtilsProvider);
