import moment from 'moment';

// locales
import 'moment/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/en-gb';
import 'moment/locale/es';

// redux store
import store from '../store';

/**
 * Convert datetime from string to a moment object.
 * @param utcDateTime
 * @returns {moment.Moment}
 *
 * Read https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
 */
export function fromUTCtoLocal(utcDateTime) {
  const state = store.getState();
  const { locale } = state.appReducer;

  const momentUtc = utcDateTime ? moment.utc(utcDateTime) : moment.utc();

  if (locale === 'pt_PT') {
    return momentUtc.locale('pt').local();
  }

  if (locale === 'es_ES') {
    return momentUtc.locale('es').local();
  }

  if (locale === 'fr_FR') {
    return momentUtc.locale('fr').local();
  }

  if (locale === 'it_IT') {
    return momentUtc.locale('it').local();
  }

  // en_GB
  return momentUtc.locale('en-gb').local();
}
