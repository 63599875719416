import React from 'react';

// REDUX
import { Provider as ReduxProvider } from 'react-redux';

// MATERIAL UI
import 'react-image-lightbox/style.css';
import { CssBaseline } from '@material-ui/core';

// SPINE UI
import { ThemeProvider } from '@oliveirahugo68/spine-ui';

// REACT ROUTER
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

// APP IMPORTS
import { clearStorage, getUserRole } from '../../utils/userSettings';
import { getPalette } from '../../utils/themes/theme';
import Container from './components/Container';
import Home from '../Public/Home';
import TermsAndConditions from '../Public/TermsAndConditions';
import PrivacyPolicy from '../Public/PrivacyPolicy';
import Login from '../Public/Login';
import Species from '../Public/Species';
import Plans from '../Public/Plans';
import Register from '../Public/Register';
import ForgotPassword from '../Public/ForgotPassword';
import ResetPassword from '../Public/ResetPassword';
import AdminDashboard from '../Admin/Dashboard';
import AdminUsers from '../Admin/Users';
import AdminSpecies from '../Admin/Species';
import Specie from '../Admin/Species/Specie';
import Mutation from '../Admin/Species/Mutation';
import BreederDashboard from '../Breeder/Dashboard';
import BreederBirds from '../Breeder/MyBirds';
import AddBird from '../Breeder/MyBirds/AddBird';
import SearchBirds from '../Breeder/SearchBirds';
import BreederTransferRequests from '../Breeder/TransferRequests';
import BreederTransferCreate from '../Breeder/TransferRequests/CreateTransfer';
import BreederTransfer from '../Breeder/TransferRequests/Transfer';
import BreederCouples from '../Breeder/Couples';
import BreederCouple from '../Breeder/Couples/Couple';
import BreederCoupleParent from '../Breeder/Couples/Couple/Parent';
import BreederCoupleChild from '../Breeder/Couples/Couple/Child';
import BreederCoupleClutch from '../Breeder/Couples/Couple/Clutch';
import BreederCoupleClutchAddChild from '../Breeder/Couples/Couple/Clutch/AddChild';
import BreederCoupleClutchChild from '../Breeder/Couples/Couple/Clutch/Child';
import BreederBird from '../Breeder/MyBirds/Bird';
import Account from '../Account';
import BreederPlans from '../Breeder/Plans';
import ROUTES from '../../utils/routes';
import ROLES from '../../utils/roles';
import store from '../../store';

const UUID_REGEX = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

/**
 * A private route that only renders if the user has the right permission.
 * If the user does not have access he will be redirected to the login page,
 * with from prop.
 *
 * @param Component The component that will be rendered.
 * @param roles {array} The roles that are able to render this component.
 * @param rest The Route props.
 * @returns {*}
 * @constructor
 */
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const role = getUserRole();

      if (role !== ROLES.DEFAULT) {
        if (roles.includes(role)) return <Component {...props} {...rest} />;
      }

      clearStorage();
      return <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: props.location } }} />;
    }}
  />
);

/**
 * A public route that only renders if the user is not authenticated or
 * if the user is authenticated but the routes is not restricted.
 *
 * @param Component The component that will be rendered.
 * @param restricted {boolean} The routes is restricted.
 * @param rest The Route props.
 * @returns {*}
 * @constructor
 */
const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const currentRole = getUserRole();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (restricted) {
          // this means that these users should not access the given public page
          if (currentRole === ROLES.ADMIN) return <Redirect to={ROUTES.ADMIN_DASHBOARD} />;
          if (currentRole === ROLES.BREEDER) return <Redirect to={ROUTES.BREEDER_DASHBOARD} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default class App extends React.PureComponent {
  render() {
    // current user role (check /utils/roles)
    // on of ["ROLE_DEFAULT", "ROLE_ADMIN", ...]
    const role = getUserRole();

    return (
      <ThemeProvider palette={getPalette()}>
        <ReduxProvider store={store}>
          <CssBaseline />
          <BrowserRouter>
            <Container role={role}>
              <Switch>
                <PublicRoute exact restricted={true} component={Home} path={ROUTES.ROOT} />
                <PublicRoute exact restricted={true} component={Login} path={ROUTES.LOGIN} />
                <PublicRoute exact restricted={true} component={Plans} path={ROUTES.PLANS} />
                <PublicRoute exact restricted={true} component={Species} path={ROUTES.SPECIES} />
                <PublicRoute exact restricted={true} component={Register} path={ROUTES.REGISTER} />
                <PublicRoute
                  exact
                  restricted={true}
                  component={TermsAndConditions}
                  path={ROUTES.TERMS_AND_CONDITIONS}
                />
                <PublicRoute
                  exact
                  restricted={true}
                  component={PrivacyPolicy}
                  path={ROUTES.PRIVACY_POLICY}
                />
                <PublicRoute
                  exact
                  restricted={true}
                  component={ForgotPassword}
                  path={ROUTES.FORGOT_PASSWORD}
                />
                <PublicRoute
                  exact
                  restricted={false}
                  component={ResetPassword}
                  path={ROUTES.RESET_PASSWORD}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.ADMIN]}
                  component={AdminDashboard}
                  path={ROUTES.ADMIN_DASHBOARD}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.ADMIN]}
                  component={AdminUsers}
                  path={ROUTES.ADMIN_USERS}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.ADMIN]}
                  component={AdminSpecies}
                  path={ROUTES.ADMIN_SPECIES}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.ADMIN]}
                  component={Specie}
                  path={`${ROUTES.ADMIN_SPECIES}/:id([0-9]+)`}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.ADMIN]}
                  component={Mutation}
                  path={`${ROUTES.ADMIN_SPECIES}/:id([0-9]+)/mutations/:mutationId([0-9]+)`}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={BreederDashboard}
                  path={ROUTES.BREEDER_DASHBOARD}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={BreederBirds}
                  path={ROUTES.BREEDER_MY_BIRDS}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={BreederBird}
                  path={`${ROUTES.BREEDER_MY_BIRDS}/:birdId(${UUID_REGEX})`}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={AddBird}
                  path={ROUTES.BREEDER_MY_BIRDS_ADD_BIRD}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={BreederCouples}
                  path={ROUTES.BREEDER_COUPLES}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={BreederCouple}
                  path={`${ROUTES.BREEDER_COUPLES}/:id(${UUID_REGEX})`}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={BreederCoupleParent}
                  path={`${ROUTES.BREEDER_COUPLES}/:coupleId(${UUID_REGEX})/parent/:birdId(${UUID_REGEX})`}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={BreederCoupleChild}
                  path={`${ROUTES.BREEDER_COUPLES}/:coupleId(${UUID_REGEX})/children/:birdId(${UUID_REGEX})`}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={BreederCoupleClutch}
                  path={`${ROUTES.BREEDER_COUPLES}/:coupleId(${UUID_REGEX})/clutches/:clutchId(${UUID_REGEX})`}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={BreederCoupleClutchAddChild}
                  path={`${ROUTES.BREEDER_COUPLES}/:coupleId(${UUID_REGEX})/clutches/:clutchId(${UUID_REGEX})/children/add`}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={BreederCoupleClutchChild}
                  path={`${ROUTES.BREEDER_COUPLES}/:coupleId(${UUID_REGEX})/clutches/:clutchId(${UUID_REGEX})/children/:birdId(${UUID_REGEX})`}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={SearchBirds}
                  path={ROUTES.BREEDER_SEARCH_BIRDS}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={BreederTransferRequests}
                  path={ROUTES.BREEDER_TRANSFER_REQUESTS}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={BreederTransferCreate}
                  path={ROUTES.BREEDER_TRANSFER_REQUESTS_CREATE}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={BreederTransfer}
                  path={`${ROUTES.BREEDER_TRANSFER_REQUESTS}/:transferId(${UUID_REGEX})`}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.ADMIN, ROLES.BREEDER]}
                  component={Account}
                  path={ROUTES.ACCOUNT}
                />
                <PrivateRoute
                  exact
                  roles={[ROLES.BREEDER]}
                  component={BreederPlans}
                  path={`${ROUTES.BREEDER_PLANS}`}
                />
                {/* When the ROOT route match just redirect to a specific route based on the role */}
                {role === ROLES.ADMIN && (
                  <Redirect from={ROUTES.ROOT} to={ROUTES.ADMIN_DASHBOARD} />
                )}
                {role === ROLES.BREEDER && (
                  <Redirect from={ROUTES.ROOT} to={ROUTES.BREEDER_DASHBOARD} />
                )}
                {role === ROLES.DEFAULT && <Redirect from={ROUTES.ROOT} to={ROUTES.ROOT} />}
              </Switch>
            </Container>
          </BrowserRouter>
        </ReduxProvider>
      </ThemeProvider>
    );
  }
}
