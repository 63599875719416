// AXIOS
import axios from 'axios';

// APP IMPORTS
import { getUserToken, setUserToken, isRememberMeActive, clearStorage } from './userSettings';

let isFetchingNewAccessToken = false;
let pendingRequests = [];

function fetchPendingRequests(newToken) {
  // fetch each request
  pendingRequests.map((request) => request(newToken));

  // reset variables
  isFetchingNewAccessToken = false;
  pendingRequests = [];
}

function addPendingRequest(request) {
  pendingRequests.push(request);
}

function getNewAccessToken() {
  isFetchingNewAccessToken = true;

  // get a new token
  axios
    .post(
      '/v1/token/refresh',
      {},
      {
        baseURL: process.env.REACT_APP_API_URL,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      const { data } = response;
      if (data) {
        setUserToken(data.token, isRememberMeActive());
      }

      // finish pending requests
      fetchPendingRequests(data.token);
    })
    .catch(() => {
      clearStorage();
      window.location.reload();
    });
}

function interceptorSuccessHandler(response) {
  return response;
}

function interceptorErrorHandler(error) {
  const {
    config,
    response: { status, data }
  } = error;

  // check what happened
  if (status === 401) {
    // token expired
    if (data.message === 'Expired JWT Token') {
      // check if we are already fetching a new access token
      if (!isFetchingNewAccessToken) {
        getNewAccessToken();
      }

      return new Promise((resolve) => {
        addPendingRequest((newToken) => {
          // if there is a payload we need to parse it again
          if (config.data) {
            config.data = JSON.parse(config.data);
          }

          resolve(
            axios({
              ...config,
              headers: {
                Authorization: `Bearer ${newToken}`
              }
            })
          );
        });
      });
    }
    // malformed token
    if (data.message === 'JWT Token not found' || data.message === 'Invalid JWT Token') {
      clearStorage();
      window.location.reload();
    }
  }

  // it is a normal error that needs to be passed
  return Promise.reject(error);
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.response.use(
  (response) => interceptorSuccessHandler(response),
  (error) => interceptorErrorHandler(error)
);

export const fetchApi = (
  method,
  endpoint,
  headers,
  payload,
  withCredentials,
  successHandler,
  errorHandler,
  onUploadProgress = () => {}
) => {
  axiosInstance
    .request({
      method: method,
      url: endpoint,
      data: payload,
      withCredentials: withCredentials,
      onUploadProgress: (event) => onUploadProgress(event),
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'application/json',
        ...headers
      }
    })
    .then((response) => successHandler(response))
    .catch((error) => {
      errorHandler(error);
    });
};
