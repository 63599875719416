import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Button,
  LinearProgress,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  Typography,
  Grid
} from '@material-ui/core';

// FEATHER ICONS
import { File, UploadCloud, X, Check } from 'react-feather';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// BROWSER IMAGE COMPRESSION
import imageCompression from 'browser-image-compression';

// APP IMPORTS
import styles from './styles';

class InputFile extends React.PureComponent {
  onClickOrDropAddFiles = (event) => {
    const input = event.target;

    const options = {
      maxSizeMB: this.props.maxNumberOfBytesPerFile / 1000000,
      maxWidthOrHeight: 640,
      useWebWorker: true
    };

    const pendingFiles = Array.from(input.files)
      .filter((file) => file.size <= this.props.maxNumberOfBytesPerFile)
      .map((file) => {
        return new Promise((resolve) => {
          imageCompression(file, options)
            .then((compressedFile) => {
              resolve({
                file: compressedFile,
                fileName: file.name,
                fileURL: URL.createObjectURL(file),
                percentageCompleted: 0,
                error: false,
                errorMessage: ''
              });
            })
            .catch(() => {
              resolve({
                file: file,
                fileName: file.name,
                fileURL: URL.createObjectURL(file),
                percentageCompleted: 0,
                error: false,
                errorMessage: ''
              });
            });
        });
      });

    Promise.all(pendingFiles).then((data) => {
      const filesToUploadAndFailed = [
        ...data,
        ...Array.from(input.files)
          .filter((file) => file.size > this.props.maxNumberOfBytesPerFile)
          .map((file) => ({
            file: file,
            fileName: file.name,
            fileURL: URL.createObjectURL(file),
            percentageCompleted: 0,
            error: true,
            errorMessage: 'errorFileSize'
          }))
      ];

      this.props.handleAddFiles(filesToUploadAndFailed);
    });
  };

  renderDragAndDrop = () => {
    const { intl, classes } = this.props;

    return (
      <div className={classes.dragAndDropContainer}>
        <UploadCloud size={50} />
        <Typography variant={'h2'} color={'primary'} className={classes.dragAndDropTitle}>
          {intl.formatMessage({ id: 'dragAndDrop' })}
        </Typography>
        <Typography variant={'h2'} color={'primary'} className={classes.dragAndDropTitle}>
          {intl.formatMessage({ id: 'or' })}
        </Typography>
        <Button
          type={'button'}
          size={'small'}
          variant={'contained'}
          color={'primary'}
          className={classes.fileButton}
        >
          {intl.formatMessage({ id: this.props.inputTitle })}
        </Button>
        <Typography variant={'h2'} color={'primary'} className={classes.dragAndDropMessage}>
          {this.props.mimeType === 'image/jpeg,image/png' ? '(jpg, jpeg, png' : '(txt, pdf, word'}
          {` - max ${parseFloat(
            (this.props.maxNumberOfBytesPerFile / 1000000).toFixed(1).toString()
          )} MB)`}
        </Typography>
        <input
          type={'file'}
          accept={this.props.mimeType}
          multiple={this.props.allowMultipleFiles}
          onChange={this.onClickOrDropAddFiles}
          className={classes.fileInput}
        />
      </div>
    );
  };

  renderFile = (key, file) => {
    const { intl, classes } = this.props;

    return (
      <ListItem key={key} className={`${classes.listItem} ${file.error && classes.listItemError}`}>
        <ListItemAvatar>
          {file.percentageCompleted === 100 ? (
            <Avatar>
              <Check size={22} />
            </Avatar>
          ) : (
            <Avatar>
              <File size={22} />
            </Avatar>
          )}
        </ListItemAvatar>
        <div style={{ width: '100%' }}>
          <Typography
            variant={'body1'}
            color={'primary'}
            className={`${classes.listTitle} ${file.error && classes.listTitleError}`}
          >
            {file.fileName}
          </Typography>
          {file.percentageCompleted === 100 && (
            <Typography variant={'body1'} color={'primary'} className={classes.listMessage}>
              {`${parseFloat((file.file.size / 1000000).toFixed(1).toString())} MB`}
            </Typography>
          )}
          {file.error && (
            <Typography variant={'body1'} color={'primary'} className={classes.listMessageError}>
              {intl.formatMessage({ id: file.errorMessage })}
            </Typography>
          )}
          {!file.error && file.percentageCompleted !== 100 && (
            <LinearProgress
              value={file.percentageCompleted}
              variant={'determinate'}
              style={{ height: 10 }}
            />
          )}
        </div>
        <ListItemSecondaryAction>
          <IconButton
            edge={'end'}
            onClick={() => this.props.handleRemoveFileWithError(file.fileURL)}
          >
            <X size={15} />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={1}>
          {(this.props.allowMultipleFiles ||
            (!this.props.allowMultipleFiles && this.props.pendingFiles.length === 0)) && (
            <Grid item xs={12}>
              {this.renderDragAndDrop()}
            </Grid>
          )}
          {this.props.pendingFiles.length > 0 && (
            <Grid item xs={12}>
              <List disablePadding={true}>
                {this.props.pendingFiles.map((item, k) => this.renderFile(k, item))}
              </List>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

InputFile.defaultProps = {
  maxNumberOfBytesPerFile: 6000000 // 5.0MB
};

InputFile.propTypes = {
  inputTitle: PropTypes.oneOf(['uploadFile', 'uploadFiles', 'uploadImage', 'uploadImages'])
    .isRequired,
  mimeType: PropTypes.oneOf([
    'image/jpeg,image/png',
    'text/plain,application/pdf,application/msword'
  ]).isRequired,
  maxNumberOfBytesPerFile: PropTypes.number,
  allowMultipleFiles: PropTypes.bool.isRequired,
  handleAddFiles: PropTypes.func.isRequired,
  handleRemoveFileWithError: PropTypes.func.isRequired,
  pendingFiles: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.object,
      fileName: PropTypes.string.isRequired,
      fileURL: PropTypes.any,
      percentageCompleted: PropTypes.number.isRequired,
      error: PropTypes.bool.isRequired,
      errorMessage: PropTypes.string
    })
  ).isRequired
};

export default withStyles(styles)(injectIntl(InputFile));
