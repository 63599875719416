export default (theme) => ({
  sectionTitle: {
    color: theme.palette.common.black,
    textTransform: 'uppercase'
  },
  text: {
    color: theme.palette.common.black
  },
  here: {
    color: theme.palette.primary.main,
    fontWeight: 700
  },

  // ring information
  ringContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  ringInnerContainer: {
    minWidth: '120px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 6,
    padding: theme.spacing(1),
    backgroundColor: '#c6e2ff',
    textAlign: 'center'
  },
  ringText: {
    color: theme.palette.primary.dark,
    textTransform: 'uppercase'
  },
  ringSmallText: {
    color: theme.palette.primary.dark,
    marginTop: '2px',
    marginBottom: 0
  },

  // split options
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  },

  // footer
  formButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  rightButton: {
    marginLeft: theme.spacing(1)
  }
});
