import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Grid, Container, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// QUERY STRING
import queryString from 'query-string';

// REACT ROUTER
import { NavLink } from 'react-router-dom';

// SPINE UI
import { Input, LoadingSpinner } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { fetchApi } from '../../../utils/apiSettings';
import ROUTES from '../../../utils/routes';
import PublicPaper from '../../../components/core/PublicPaper';
import PublicButton from '../../../components/buttons/PublicButton';
import styles from './styles';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: true,

      // query params
      email: '',
      token: '',

      // request password form
      newPassword: '',
      confirmPassword: '',
      errors: {}
    };

    this.newPasswordRef = React.createRef();
  }

  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);

    if (!queryParams.email || !queryParams.token) this.props.history.push(ROUTES.LOGIN);

    this.setState(
      {
        email: queryParams.email,
        token: queryParams.token
      },
      () => this.validateRequest()
    );
  }

  validateRequest = () => {
    let endpoint = '/v1/accounts/reset_password_validate_request?';
    endpoint += queryString.stringify({
      // pagination
      email: this.state.email,
      token: this.state.token
    });

    fetchApi(
      'get',
      endpoint,
      {},
      {},
      false,
      () => {
        this.setState(
          {
            isLoading: false,
            isFetching: false
          },
          () => this.newPasswordRef.current.focus()
        );
      },
      () => {
        const { intl, enqueueSnackbar } = this.props;
        enqueueSnackbar(intl.formatMessage({ id: 'resetRequestErrorMessage' }), {
          variant: 'error',
          autoHideDuration: 5000
        });
        this.props.history.push(ROUTES.LOGIN);
      }
    );
  };

  handleInputChange = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    this.setState({
      [input.name]: value,
      errors: {}
    });
  };

  handleOnSubmit = async (event) => {
    event.preventDefault();
    const { enqueueSnackbar, intl } = this.props;

    this.setState(
      {
        isLoading: true
      },
      () => {
        fetchApi(
          'put',
          '/v1/accounts/reset_password',
          {},
          {
            email: this.state.email,
            token: this.state.token,
            password: this.state.newPassword
          },
          true,
          () => {
            enqueueSnackbar(intl.formatMessage({ id: 'changedPasswordSuccessMessage' }), {
              variant: 'success',
              autoHideDuration: 5000
            });

            setTimeout(() => {
              this.props.history.push(ROUTES.LOGIN);
            }, 2500);
          },
          (error) => {
            const { response } = error;

            this.setState({ isLoading: false });

            if (response && response.status === 404) {
              this.setState({
                errors: {
                  email: intl.formatMessage({ id: 'userNotFound' })
                }
              });
            } else if (response && response.status === 400) {
              enqueueSnackbar(intl.formatMessage({ id: 'resetRequestErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 5000
              });
            } else {
              enqueueSnackbar(intl.formatMessage({ id: 'generalErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 5000
              });
            }

            this.props.history.push(ROUTES.LOGIN);
          }
        );
      }
    );
  };

  render() {
    const { intl, classes } = this.props;

    return (
      <Container>
        <div className={classes.formCard}>
          <PublicPaper title={'resetPassword'}>
            {this.state.isLoading ? (
              <div className={classes.loadingContainer}>
                <LoadingSpinner />
              </div>
            ) : (
              <form autoComplete={'on'} onSubmit={this.handleOnSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} hidden={true}>
                    <Input
                      name={'email'}
                      type={'email'}
                      autoComplete={'off'}
                      label={intl.formatMessage({ id: 'email' })}
                      value={this.state.email}
                      onChange={() => {}}
                      error={!!this.state.errors.email}
                      helperText={this.state.errors.email || ''}
                      required={true}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      inputRef={this.newPasswordRef}
                      name={'newPassword'}
                      type={'password'}
                      autoComplete={'new-password'}
                      label={intl.formatMessage({ id: 'newPassword' })}
                      value={this.state.newPassword}
                      onChange={this.handleInputChange}
                      error={!!this.state.errors.newPassword}
                      helperText={this.state.errors.newPassword || ''}
                      required={true}
                      disabled={this.state.isFetching}
                      inputProps={{ minLength: 5 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      name={'confirmPassword'}
                      type={'password'}
                      autoComplete={'new-password'}
                      label={intl.formatMessage({ id: 'confirmPassword' })}
                      value={this.state.confirmPassword}
                      onChange={this.handleInputChange}
                      error={!!this.state.errors.confirmPassword}
                      helperText={this.state.errors.confirmPassword || ''}
                      required={true}
                      disabled={this.state.isFetching}
                      inputProps={{ minLength: 5 }}
                    />
                  </Grid>
                  <Grid item xs={12} />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <PublicButton
                      title={'confirm'}
                      isLoading={this.state.isLoading || this.state.isFetching}
                      disabled={
                        this.state.newPassword === '' ||
                        this.state.newPassword !== this.state.confirmPassword
                      }
                    />
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <NavLink to={ROUTES.LOGIN} className={classes.link}>
                      <Typography variant={'body1'} className={classes.text}>
                        {intl.formatMessage({ id: 'backToLogin' })}
                      </Typography>
                    </NavLink>
                  </Grid>
                </Grid>
              </form>
            )}
          </PublicPaper>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(withSnackbar(injectIntl(ResetPassword)));
