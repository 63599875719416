import React from 'react';
import PropTypes from 'prop-types';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// MATERIAL UI
import { Grid } from '@material-ui/core';

// SPINE UI
import { Input } from '@oliveirahugo68/spine-ui';

class ChangePassword extends React.PureComponent {
  render() {
    const { intl } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            inputRef={this.props.currentPasswordInputRef}
            name={'currentPassword'}
            type={'password'}
            autoComplete={'current-password'}
            label={intl.formatMessage({ id: 'currentPassword' })}
            value={this.props.currentPassword}
            onChange={this.props.handleInputChange}
            error={!!this.props.errors.currentPassword}
            helperText={this.props.errors.currentPassword || ''}
            required={true}
            disabled={this.props.isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name={'newPassword'}
            type={'password'}
            autoComplete={'new-password'}
            label={intl.formatMessage({ id: 'newPassword' })}
            value={this.props.newPassword}
            onChange={this.props.handleInputChange}
            error={!!this.props.errors.newPassword}
            helperText={this.props.errors.newPassword || ''}
            required={true}
            disabled={this.props.isLoading}
            inputProps={{ minLength: 5 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name={'confirmPassword'}
            type={'password'}
            autoComplete={'new-password'}
            label={intl.formatMessage({ id: 'confirmPassword' })}
            value={this.props.confirmPassword}
            onChange={this.props.handleInputChange}
            error={!!this.props.errors.confirmPassword}
            helperText={this.props.errors.confirmPassword || ''}
            required={true}
            disabled={this.props.isLoading}
            inputProps={{
              minLength: 5
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

ChangePassword.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  currentPasswordInputRef: PropTypes.object.isRequired,
  currentPassword: PropTypes.string.isRequired,
  newPassword: PropTypes.string.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired
};

export default injectIntl(ChangePassword);
