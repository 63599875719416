import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// QUERY STRING
import queryString from 'query-string';

// YUP
import * as Yup from 'yup';

// SPINE UI
import { Select, Input, FormDialog, Content, Table } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { setAppBarTitle } from '../../../store/actions/appActions';
import { fetchApi } from '../../../utils/apiSettings';
import {
  getMutationName,
  getCountries,
  getCountryName,
  getGenderName
} from '../../../utils/appSettings';
import TooltipIconButton from '../../../components/buttons/TooltipIconButton';
import FilterForm from './components/FilterForm';
import ROUTES from '../../../utils/routes';
import BirdAvatar from '../../../components/core/BirdAvatar';
import BirdItem from '../../../components/core/BirdItem';
import styles from './styles';

class MyBirds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      birds: [],

      // search form
      searchBy: '',

      // sort
      orderBy: 'ring',
      orderDirection: 'asc',

      // pagination
      totalCount: 0,
      page: 0,
      rowsPerPage: 10,

      // filter form
      state: 'active',
      mutation: '',
      gender: '',
      specie: '',
      country: '',
      isFilterFormOpened: false,

      allSpecies: [],
      allMutations: [],
      errors: {}
    };

    this.searchByInputRef = React.createRef();
  }

  componentDidMount() {
    this.props.setAppBarTitle('myBirds');

    this.parseURLWithPagination(() => {
      Promise.all([this.fetchBirds(), this.fetchSpecies()]).then(() => {
        this.setState({
          isLoading: false
        });
      });
    });
  }

  fetchBirds = async () => {
    const { enqueueSnackbar, intl } = this.props;

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const schema = Yup.object().shape({
        state: Yup.string().notRequired(),
        gender: Yup.string().notRequired(),
        mutation: Yup.string().notRequired(),
        country: Yup.string().notRequired()
      });

      const dataToSubmit = {
        state: this.state.state,
        gender: this.state.gender,
        mutation: this.state.mutation,
        country: this.state.country
      };

      try {
        await schema.validate(dataToSubmit, { abortEarly: false });

        let endpoint = '/breeders/v1/birds?';
        endpoint += queryString.stringify({
          page_number: this.state.page,
          per_page: this.state.rowsPerPage,
          order_field: this.state.orderBy,
          order_direction: this.state.orderDirection
        });

        if (this.state.state) {
          endpoint += `&${queryString.stringify({ state: this.state.state })}`;
        }
        if (this.state.searchBy) {
          endpoint += `&${queryString.stringify({ ring: this.state.searchBy })}`;
        }
        if (this.state.gender) {
          endpoint += `&${queryString.stringify({ gender: this.state.gender })}`;
        }
        if (this.state.mutation) {
          endpoint += `&${queryString.stringify({ mutation_id: this.state.mutation })}`;
        }
        if (this.state.country) {
          endpoint += `&${queryString.stringify({ country_id: this.state.country })}`;
        }

        fetchApi(
          'get',
          endpoint,
          {},
          {},
          false,
          (response) => {
            const { data } = response;

            this.setState(
              {
                birds: data.data,
                totalCount: data.total_count,
                errors: {}
              },
              () => resolve()
            );
          },
          (error) => {
            const { response } = error;

            if (response.status === 400) {
              enqueueSnackbar(intl.formatMessage({ id: 'birdsErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            } else {
              enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            }
          }
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach((error) => {
            errorMessages[error.path] = error.message;
          });

          this.setState({
            errors: errorMessages
          });

          resolve();
        }
      }
    });
  };

  fetchSpecies = () => {
    const { enqueueSnackbar, intl } = this.props;

    return new Promise((resolve) => {
      fetchApi(
        'get',
        '/v1/public/species',
        {},
        {},
        false,
        (response) => {
          const { data } = response;

          const speciesFormatted = data.map((specie) => ({
            key: specie.id,
            value: specie.id,
            name: specie.name,
            mutations: specie.mutations.map((mutation) => ({
              key: mutation.id,
              value: mutation.id,
              name: mutation.name
            }))
          }));

          const mutationsFormatted = speciesFormatted.length && speciesFormatted[0].mutations;

          this.setState(
            {
              allSpecies: speciesFormatted,
              allMutations: mutationsFormatted
            },
            () => {
              resolve(data);
            }
          );
        },
        (error) => {
          const { response } = error;

          if (response.status === 400) {
            enqueueSnackbar(intl.formatMessage({ id: 'speciesErrorMessage' }), {
              variant: 'error',
              autoHideDuration: 4000
            });
          } else {
            enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
              variant: 'error',
              autoHideDuration: 4000
            });
          }
        }
      );
    });
  };

  parseURLWithPagination = (handle) => {
    const parsed = queryString.parse(window.location.search);
    if (parsed) {
      this.setState(
        {
          state: parsed.state || 'active',
          page: parsed.page_number !== undefined ? parseInt(parsed.page_number, 10) : 0,
          rowsPerPage: parsed.per_page !== undefined ? parseInt(parsed.per_page, 10) : 10,
          orderBy: parsed.order_field || 'ring',
          orderDirection: parsed.order_direction || 'asc',
          searchBy: parsed.search_by || ''
        },
        () => {
          handle();
        }
      );
    }
  };

  setURLWithPagination = () => {
    this.props.history.push(
      `${window.location.pathname}?${queryString.stringify({
        state: this.state.state,
        page_number: this.state.page,
        per_page: this.state.rowsPerPage,
        order_field: this.state.orderBy,
        order_direction: this.state.orderDirection,
        search_by: this.state.searchBy
      })}`
    );
  };

  openFilterForm = () => {
    this.setState({
      isFilterFormOpened: true
    });
  };

  cancelFilterForm = () => {
    this.setState({
      errors: {},
      isFilterFormOpened: false
    });
  };

  confirmFilterForm = () => {
    this.setState(
      {
        isFetching: true
      },
      () => {
        this.setURLWithPagination();
        Promise.all([this.fetchBirds()]).then(() => {
          this.setState({
            isFetching: false,
            isFilterFormOpened: false
          });
        });
      }
    );
  };

  handleSearchBy = (event) => {
    const input = event.target;

    this.setState(
      {
        searchBy: input.value,
        errors: {},

        mutation: '',
        gender: '',
        specie: '',
        country: ''
      },
      () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setState(
            {
              isFetching: true
            },
            () => {
              this.setURLWithPagination();
              Promise.all([this.fetchBirds()]).then(() => {
                this.setState({
                  isFetching: false
                });
                this.searchByInputRef.current.focus();
              });
            }
          );
        }, 500);
      }
    );
  };

  handleInputChange = (event) => {
    const input = event.target;

    this.setState({
      [input.name]: input.value,
      errors: {}
    });
  };

  handleSelectChange = (event) => {
    const input = event.target;

    if (input.name === 'specie' && input.value !== '') {
      this.setState((state) => {
        const specie = state.allSpecies.find((item) => item.value === parseInt(input.value, 10));

        return {
          [input.name]: parseInt(input.value, 10),
          mutation: '',
          allMutations: specie ? specie.mutations : [],
          errors: {}
        };
      });
    } else if (input.name === 'mutation' && input.value !== '') {
      this.setState({
        [input.name]: parseInt(input.value, 10),
        errors: {}
      });
    } else {
      this.setState({
        [input.name]: input.value,
        errors: {}
      });
    }
  };

  handleSelectStateChange = (event) => {
    const input = event.target;

    this.setState(
      {
        isFetching: true,
        [input.name]: input.value,
        errors: {}
      },
      () => {
        this.setURLWithPagination();
        Promise.all([this.fetchBirds()]).then(() => {
          this.setState({
            isFetching: false
          });
        });
      }
    );
  };

  handleTableChange = (page, perPage, sortBy, sortDirection) => {
    this.setState(
      {
        isFetching: true,
        page: page,
        rowsPerPage: perPage,
        orderBy: sortBy,
        orderDirection: sortDirection
      },
      () => {
        this.setURLWithPagination();
        Promise.all([this.fetchBirds()]).then(() => {
          this.setState({
            isFetching: false
          });
        });
      }
    );
  };

  render() {
    const { intl, classes } = this.props;

    const buttons = [
      {
        label: intl.formatMessage({ id: 'addBird' }),
        disabled: this.state.isLoading || this.state.isFetching,
        color: 'primary',
        variant: 'contained',
        onClick: () => {
          this.props.history.push(ROUTES.BREEDER_MY_BIRDS_ADD_BIRD);
        }
      },
      {
        label: intl.formatMessage({ id: 'filterSearch' }),
        disabled: this.state.isLoading || this.state.isFetching,
        color: 'primary',
        variant: 'outlined',
        onClick: this.openFilterForm
      }
    ];

    return (
      <Content
        isLoading={this.state.isLoading}
        isFetching={this.state.isFetching}
        buttons={buttons}
      >
        <FormDialog
          isOpened={this.state.isFilterFormOpened}
          title={intl.formatMessage({ id: 'filterSearch' })}
          maxWidth={'sm'}
          onConfirm={this.confirmFilterForm}
          onCancel={this.cancelFilterForm}
          onConfirmDisabled={
            this.state.isFetching || (this.state.specie !== '' && this.state.mutation === '')
          }
          onCancelDisabled={this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <FilterForm
            isLoading={this.state.isLoading || this.state.isFetching}
            mutation={this.state.mutation}
            specie={this.state.specie}
            country={this.state.country}
            gender={this.state.gender}
            allMutations={this.state.allMutations}
            allSpecies={this.state.allSpecies}
            allCountries={getCountries(this.props.user.user ? this.props.user.user.locale : null)}
            errors={this.state.errors}
            handleSelectChange={this.handleSelectChange}
          />
        </FormDialog>
        {!this.state.isLoading && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.filterFormContainer}>
                <div className={classes.stateButton}>
                  <Select
                    showEmpty={false}
                    name={'state'}
                    label={intl.formatMessage({ id: 'state' })}
                    value={this.state.state}
                    onChange={this.handleSelectStateChange}
                    disabled={this.state.isLoading || this.state.isFetching}
                    autoComplete={'off'}
                    options={[
                      {
                        key: 'all',
                        value: 'all',
                        name: intl.formatMessage({ id: 'all' })
                      },
                      {
                        key: 'active',
                        value: 'active',
                        name: intl.formatMessage({ id: 'active' })
                      },
                      // {
                      //   key: 'transferred',
                      //   value: 'transferred',
                      //   name: intl.formatMessage({ id: 'transferred' })
                      // },
                      {
                        key: 'death',
                        value: 'death',
                        name: intl.formatMessage({ id: 'death' })
                      }
                    ]}
                  />
                </div>
                <div className={classes.filterInput}>
                  <Input
                    inputRef={this.searchByInputRef}
                    name={'searchBy'}
                    type={'text'}
                    autoComplete={'off'}
                    label={intl.formatMessage({ id: 'searchBy' })}
                    placeholder={intl.formatMessage({ id: 'searchByRing' })}
                    value={this.state.searchBy}
                    onChange={this.handleSearchBy}
                    disabled={this.state.isFetching}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Table
                webHeaders={[
                  {
                    title: ' ',
                    align: 'left'
                  },
                  {
                    title: intl.formatMessage({ id: 'ring' }),
                    align: 'left',
                    sort: true,
                    sortKey: 'ring'
                  },
                  {
                    title: intl.formatMessage({ id: 'year' }),
                    align: 'left',
                    sort: true,
                    sortKey: 'year'
                  },
                  {
                    title: intl.formatMessage({ id: 'gender' }),
                    align: 'left',
                    sort: true,
                    sortKey: 'gender'
                  },
                  { title: intl.formatMessage({ id: 'mutation' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'country' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'state' }), align: 'left' },
                  { title: '', align: 'right' }
                ]}
                webData={this.state.birds.map((item) => [
                  <BirdAvatar images={item.image ? [item.image] : []} />,
                  item.ring,
                  item.year,
                  getGenderName(item.gender, this.props.intl),
                  getMutationName(item, this.props.intl),
                  getCountryName(
                    item.country.id,
                    this.props.user.user ? this.props.user.user.locale : null
                  ),
                  // eslint-disable-next-line no-nested-ternary
                  intl.formatMessage({ id: item.state }),
                  <TooltipIconButton
                    title={'view'}
                    iconName={'chevron-right'}
                    handleClick={() => {
                      this.props.history.push(`${ROUTES.BREEDER_MY_BIRDS}/${item.bird_id}`);
                    }}
                  />
                ])}
                noDataMessage={intl.formatMessage({ id: 'noBirds' })}
                // handle changes
                onChange={this.handleTableChange}
                // sorting
                useSorting={true}
                sortBy={this.state.orderBy}
                sortDirection={this.state.orderDirection}
                // pagination
                usePagination={true}
                totalCount={this.state.totalCount}
                page={this.state.page}
                rowsPerPage={this.state.rowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                // mobiles
                renderMobile={true}
                mobileData={this.state.birds.map((item) => (
                  <BirdItem
                    imagePreviewPath={item.image ? item.image : null}
                    ring={item.ring}
                    year={item.year}
                    gender={getGenderName(item.gender, this.props.intl)}
                    mutationName={getMutationName(item, this.props.intl)}
                    countryName={getCountryName(
                      item.country.id,
                      this.props.user.user ? this.props.user.user.locale : null
                    )}
                    onClick={() => {
                      this.props.history.push(`${ROUTES.BREEDER_MY_BIRDS}/${item.bird_id}`);
                    }}
                  />
                ))}
              />
            </Grid>
          </Grid>
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer,
  user: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(injectIntl(MyBirds))));
