import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// REACT FEATHER
import { ChevronRight } from 'react-feather';

// APP IMPORTS
import { fromUTCtoLocal } from '../../../../../../utils/timeFormat';
import styles from './styles';

class ClutchItem extends React.PureComponent {
  render() {
    const { classes, intl } = this.props;

    return (
      <React.Fragment>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className={classes.container} onClick={this.props.onClick}>
          <div className={classes.innerContainer}>
            <div className={classes.startedAtContainer}>
              <Typography variant={'h6'} className={classes.startedAtText}>
                {fromUTCtoLocal(this.props.startedAt).format('LL')}
              </Typography>
            </div>
            <div>
              <Typography variant={'body1'} className={classes.countText}>
                {intl.formatMessage(
                  { id: 'childrenCount' },
                  {
                    eggsCount: this.props.eggsCount,
                    childrenCount: this.props.childrenCount
                  }
                )}
              </Typography>
            </div>
            <div>
              <Typography variant={'body1'} className={classes.hasFinishedText}>
                {this.props.hasFinished
                  ? intl.formatMessage({ id: 'finished' })
                  : intl.formatMessage({ id: 'onGoing' })}
              </Typography>
            </div>
          </div>
          <div className={classes.button}>
            <IconButton color={'default'} size={'small'} onClick={this.props.onClick}>
              <ChevronRight size={20} />
            </IconButton>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ClutchItem.propTypes = {
  startedAt: PropTypes.string.isRequired,
  hasFinished: PropTypes.bool.isRequired,
  eggsCount: PropTypes.number.isRequired,
  childrenCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default withStyles(styles)(injectIntl(ClutchItem));
