import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// FEATHER ICONS
import { Twitter, Heart, Target, Search } from 'react-feather';

// APP IMPORTS
import styles from './styles';

class Features extends React.PureComponent {
  render() {
    const { classes, intl } = this.props;

    return (
      <div className={classes.content}>
        <Grid
          container
          spacing={3}
          direction={'row'}
          justify={'space-between'}
          alignItems={'flex-start'}
        >
          <Grid item xs={12} sm={6} lg={3}>
            <div className={classes.container}>
              <div className={classes.item}>
                <div className={classes.itemCard}>
                  <Twitter size={70} strokeWidth={'2'} />
                </div>
                <Typography variant={'h6'} className={classes.title}>
                  {intl.formatMessage({ id: 'birdsManagement' })}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <div className={classes.container}>
              <div className={classes.item}>
                <div className={classes.itemCard}>
                  <Heart size={70} strokeWidth={'2'} />
                </div>
                <Typography variant={'h6'} className={classes.title}>
                  {intl.formatMessage({ id: 'couplesAndClutchesManagement' })}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <div className={classes.container}>
              <div className={classes.item}>
                <div className={classes.itemCard}>
                  <Target size={70} strokeWidth={'2'} />
                </div>
                <Typography variant={'h6'} className={classes.title}>
                  {intl.formatMessage({ id: 'birdTransfers' })}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <div className={classes.container}>
              <div className={classes.item}>
                <div className={classes.itemCard}>
                  <Search size={70} strokeWidth={'2'} />
                </div>
                <Typography variant={'h6'} className={classes.title}>
                  {intl.formatMessage({ id: 'searchBreedersWithSpecificSpecies' })}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(Features));
