import React from 'react';

// REDUX
import { connect } from 'react-redux';

// SPINE UI
import { Content, Table, FormDialog } from '@oliveirahugo68/spine-ui';

// MATERIAL UI
import { withSnackbar } from 'notistack';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// YUP
import * as Yup from 'yup';

// APP IMPORTS
import { fetchApi } from '../../../utils/apiSettings';
import { setAppBarTitle } from '../../../store/actions/appActions';
import { fromUTCtoLocal } from '../../../utils/timeFormat';
import ROUTES from '../../../utils/routes';
import TooltipIconButton from '../../../components/buttons/TooltipIconButton';
import AddSpecie from './components/AddSpecie';

class Species extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      species: [],

      // add species form
      specieName: '',
      clutchDurationDays: 0,
      hasClutchDurationDays: false,
      errors: {},
      isAddSpecieOpened: false
    };

    this.nameInputRef = React.createRef();
  }

  componentDidMount() {
    this.props.setAppBarTitle('species');

    this.fetchSpecies();
  }

  fetchSpecies = () => {
    fetchApi(
      'get',
      '/admin/v1/species',
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.setState({
          species: data,
          isLoading: false
        });
      },
      () => {}
    );
  };

  openAddSpecie = () => {
    this.setState(
      {
        isAddSpecieOpened: true,
        specieName: '',
        clutchDurationDays: 0,
        hasClutchDurationDays: false,
        errors: {}
      },
      () => {
        this.nameInputRef.current.focus();
      }
    );
  };

  cancelAddSpecie = () => {
    this.setState({
      isAddSpecieOpened: false,
      specieName: '',
      clutchDurationDays: 0,
      hasClutchDurationDays: false,
      errors: {}
    });
  };

  handleAddSpecie = async () => {
    const { enqueueSnackbar, intl } = this.props;

    const schema = Yup.object().shape({
      specieName: Yup.string().required(),
      hasClutchDurationDays: Yup.bool(),
      clutchDurationDays: Yup.number().when('hasClutchDurationDays', {
        is: true,
        then: Yup.number().moreThan(0).required()
      })
    });

    const dataToSubmit = {
      specieName: this.state.specieName,
      clutchDurationDays: this.state.clutchDurationDays,
      hasClutchDurationDays: this.state.hasClutchDurationDays
    };

    try {
      await schema.validate(dataToSubmit, { abortEarly: false });

      this.setState(
        {
          isFetching: true
        },
        () =>
          fetchApi(
            'post',
            '/admin/v1/species',
            {},
            {
              name: dataToSubmit.specieName,
              clutch_duration_days: dataToSubmit.clutchDurationDays,
              has_clutch_duration_days: dataToSubmit.hasClutchDurationDays
            },
            false,
            (response) => {
              const { data } = response;

              enqueueSnackbar(intl.formatMessage({ id: 'addSpecieSuccessMessage' }), {
                variant: 'success',
                autoHideDuration: 4000
              });

              this.props.history.push(`${ROUTES.ADMIN_SPECIES}/${data.id}`);
            },
            (error) => {
              const { response } = error;

              if (response.status === 409) {
                this.setState({
                  isFetching: false,
                  errors: {
                    specieName: intl.formatMessage({ id: 'addSpecieErrorMessage' })
                  }
                });
              } else {
                this.setState({ isFetching: false });

                enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                  variant: 'error',
                  autoHideDuration: 4000
                });
              }
            }
          )
      );
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        this.setState({
          errors: errorMessages
        });
      }
    }
  };

  handleInputChange = (event) => {
    const input = event.target;

    if (input.name === 'hasClutchDurationDays') {
      this.setState((state) => ({
        hasClutchDurationDays: !state.hasClutchDurationDays,
        errors: {}
      }));
    } else {
      this.setState({
        [input.name]: input.value,
        errors: {}
      });
    }
  };

  render() {
    const { intl } = this.props;

    const buttons = [
      {
        label: intl.formatMessage({ id: 'addSpecie' }),
        disabled: this.state.isLoading,
        color: 'primary',
        variant: 'contained',
        onClick: this.openAddSpecie
      }
    ];

    return (
      <Content
        isFetching={this.state.isFetching}
        isLoading={this.state.isLoading}
        buttons={buttons}
      >
        <FormDialog
          isOpened={this.state.isAddSpecieOpened}
          title={intl.formatMessage({ id: 'addSpecie' })}
          maxWidth={'sm'}
          onConfirm={this.handleAddSpecie}
          onCancel={this.cancelAddSpecie}
          onConfirmDisabled={this.state.isFetching}
          onCancelDisabled={this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <AddSpecie
            isLoading={this.state.isLoading || this.state.isFetching}
            nameInputRef={this.nameInputRef}
            specieName={this.state.specieName}
            clutchDurationDays={this.state.clutchDurationDays}
            hasClutchDurationDays={this.state.hasClutchDurationDays}
            errors={this.state.errors}
            handleInputChange={this.handleInputChange}
          />
        </FormDialog>
        {!this.state.isLoading && (
          <Table
            webHeaders={[
              { title: 'id', align: 'left' },
              {
                title: intl.formatMessage({ id: 'name' }),
                align: 'left'
              },
              {
                title: intl.formatMessage({ id: 'mutations' }),
                align: 'left'
              },
              { title: intl.formatMessage({ id: 'clutchDurationDays' }), align: 'left' },
              { title: intl.formatMessage({ id: 'registeredAt' }), align: 'left' },
              { title: '', align: 'right' }
            ]}
            webData={this.state.species.map((item) => [
              item.id,
              item.name,
              item.mutations.length,
              item.clutch_duration_days ? item.clutch_duration_days : '-',
              fromUTCtoLocal(item.created_at).format('LL'),
              <TooltipIconButton
                title={'view'}
                iconName={'more-horizontal'}
                handleClick={() => {
                  this.props.history.push(`${ROUTES.ADMIN_SPECIES}/${item.id}`);
                }}
              />
            ])}
            noDataMessage={intl.formatMessage({ id: 'noSpecies' })}
          />
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(injectIntl(Species)));
