import { createTheme } from '@material-ui/core';

const defaultTheme = createTheme();
const sharedProps = {
  fontFamily: "'Source Sans Pro', sans-serif",
  fontStyle: 'normal',
  fontDisplay: 'swap'
};

// https://mui.com/customization/default-theme/
export default {
  h1: {
    ...defaultTheme.typography.h1,
    ...sharedProps,
    fontWeight: 'bold'
    // fontSize: '5.85rem'
  },
  h2: {
    ...defaultTheme.typography.h2,
    ...sharedProps,
    fontWeight: 'bold'
    // fontSize: '3.60rem'
  },
  h3: {
    ...defaultTheme.typography.h3,
    ...sharedProps,
    fontWeight: 'bold'
    // fontSize: '2.85rem'
  },
  h4: {
    ...defaultTheme.typography.h4,
    ...sharedProps,
    fontWeight: 'bold'
    // fontSize: '1.975rem'
  },
  h5: {
    ...defaultTheme.typography.h5,
    ...sharedProps,
    fontWeight: 'bold'
    // fontSize: '1.35rem'
  },
  h6: {
    ...defaultTheme.typography.h6,
    ...sharedProps,
    fontWeight: 'bold'
    // fontSize: '1.1rem'
  },
  subtitle1: {
    ...defaultTheme.typography.subtitle1,
    ...sharedProps
    // fontSize: '0.85rem'
  },
  subtitle2: {
    ...defaultTheme.typography.subtitle2,
    ...sharedProps
    // fontSize: '0.725rem'
  },
  body1: {
    ...defaultTheme.typography.body1,
    ...sharedProps
    // fontSize: '0.85rem'
  },
  body2: {
    ...defaultTheme.typography.body2,
    ...sharedProps
    // fontSize: '0.725rem'
  },
  button: {
    ...defaultTheme.typography.button,
    ...sharedProps,
    fontWeight: '600'
    // fontSize: '0.725rem'
  },
  caption: {
    ...defaultTheme.typography.caption,
    ...sharedProps
    // fontSize: '0.6rem'
  },
  ...sharedProps
};
