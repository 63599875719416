import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';

// APP IMPORTS
import styles from './styles';

class AppBarButton extends React.PureComponent {
  render() {
    const { classes } = this.props;

    return <span className={classes.button}>{this.props.children}</span>;
  }
}

export default withStyles(styles)(AppBarButton);
