// UTILS
import { getColors } from '../../../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    border: `1px solid ${colors.border}`,
    borderRadius: '6px',
    backgroundColor: theme.palette.common.white
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    marginRight: theme.spacing(1)
  },
  avatar: {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.light,
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    marginLeft: theme.spacing(1)
  },
  innerContainer: {
    width: '100%'
  },
  stateContainer: {
    maxWidth: '220px'
  },
  stateText: {
    color: theme.palette.primary.main,
    // fontSize: '14px',
    fontWeight: 700,
    // textTransform: 'uppercase',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  breedersText: {
    color: theme.palette.secondary.dark,
    // fontSize: '12px',
    fontWeight: 500
  },
  createdAtText: {
    color: theme.palette.common.black,
    // fontSize: '12px',
    fontWeight: 500
  }
});
