// UTILS
import { red, green } from '@material-ui/core/colors';
import { getColors } from '../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  formCard: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 64px 120px -40px rgba(0, 0, 0, 0.24)',
    borderRadius: '26px',
    overflow: 'hidden',
    marginTop: '0px',
    marginBottom: '-48px'
  },
  formLeftTopContainer: {
    height: '100%',
    padding: theme.spacing(6),
    // backgroundColor: '#fff',
    backgroundImage:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%237e7e7e' fill-opacity='0.06'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")"
  },
  formRightBottomContainer: {
    height: '100%',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6)
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    boxShadow: '5px 0px 120px -32px rgba(0, 0, 0, 0.16)'
  },
  createYourFreeAccountTitle: {
    color: theme.palette.common.black,
    fontWeight: 600,
    marginBottom: theme.spacing(4)
  },
  termsAndConditionsTextBold: {
    fontWeight: '700',
    textTransform: 'lowercase',
    textDecoration: 'none',
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main
  },
  submitButton: {
    marginTop: '-10px',
    minWidth: '200px',
    borderStyle: 'solid',
    borderTopWidth: '2px',
    borderRightWidth: '2px',
    borderLeftWidth: '2px',
    borderBottomWidth: '2px',
    color: '#fff',
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.light,
    borderRadius: '24px',
    padding: '14px 30px',
    paddingLeft: '30px',
    fontFamily: "'Poppins',sans-serif",
    fontWeight: '600',
    lineHeight: '1',
    textTransform: 'uppercase',
    outline: 0,
    '&:hover': {
      cursor: 'pointer',
      borderColor: colors.sidebarItem,
      backgroundColor: colors.sidebarItem
    },
    '&:disabled': {
      cursor: 'default',
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.light
    }
  },

  registerError: {
    display: 'flex',
    flexDirection: 'row',
    border: '2px solid red',
    borderRadius: '6px',
    padding: theme.spacing(2),
    backgroundColor: red[100]
  },
  errorAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.common.white,
    backgroundColor: red[600],
    marginRight: theme.spacing(1)
  },
  textErrorInformation: {
    fontWeight: '700',
    color: red[600]
  },
  registerSuccess: {
    display: 'flex',
    flexDirection: 'row',
    border: '2px solid green',
    borderRadius: '6px',
    padding: theme.spacing(2),
    backgroundColor: green[100]
  },
  successAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.common.white,
    backgroundColor: green[600],
    marginRight: theme.spacing(1)
  },
  textSuccessInformation: {
    fontWeight: '700',
    color: green[600]
  },

  // left side
  perfectPlatformTitle: {
    display: 'inline-block',
    color: theme.palette.common.black,
    fontWeight: 600,
    textAlign: 'left',
    textTransform: 'uppercase',
    lineHeight: '1.3em',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4)
    }
  },
  ourMissionText: {
    color: theme.palette.common.black,
    fontWeight: 500,
    textAlign: 'left',
    lineHeight: '1.3em',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(6)
  },
  featuresContainer: {
    marginTop: theme.spacing(2)
  },
  descriptionItemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start'
  },
  itemIcon: {
    color: theme.palette.common.black,
    marginTop: '3px',
    marginLeft: '-1px',
    marginRight: '4px'
  },
  itemText: {
    color: theme.palette.common.black,
    textTransform: 'uppercase',
    fontWeight: 700
  }
});
