import { getColors } from '../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '-48px'
  },
  speciesContainer: {
    display: 'flex',
    position: 'relative',
    marginTop: theme.spacing(2)
  },
  meetSpeciesTitle: {
    color: '#fff',
    fontWeight: 700,
    textAlign: 'center',
    lineHeight: '1.5em'
  },
  weAreKeenTitle: {
    color: '#fff',
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: '1.5em',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(20),
      marginRight: theme.spacing(20)
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4)
    }
  },
  weAreKeenEmail: {
    color: colors.sidebarItem
  },
  card: {
    borderRadius: '20px',
    borderTop: `4px solid ${colors.sidebarItem}`,
    boxShadow: '0px 64px 120px -40px rgba(0, 0, 0, 0.24)',
    overflow: 'hidden',
    backgroundColor: '#fff',
    marginTop: theme.spacing(4),
    padding: theme.spacing(2)
  },

  // table
  tableCellHeader: {
    color: theme.palette.common.black,
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontWeight: 'bold'
  },
  tableCellBorderLeft: {
    borderLeft: `1px solid ${theme.palette.divider}`
  },
  tableCellNoBorderBottom: {
    borderBottom: `0`
  },
  tableRowBorderBottom: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  specieText: {
    color: theme.palette.common.black,
    fontWeight: '600',
    verticalAlign: 'top'
  },
  mutationText: {
    color: theme.palette.common.black
  }
});
