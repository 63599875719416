import React from 'react';
import { connect } from 'react-redux';

// MATERIAL UI
import { Typography, Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// YUP
import * as Yup from 'yup';

// SPINE UI
import {
  FormDialog,
  Content,
  Table,
  InformationDialog,
  PaperListInformation
} from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { fromUTCtoLocal } from '../../../../../utils/timeFormat';
import { fetchApi } from '../../../../../utils/apiSettings';
import { setAppBarTitle, setAppLocale } from '../../../../../store/actions/appActions';
import { getCountryName, getGenderName, getMutationName } from '../../../../../utils/appSettings';
import ROUTES from '../../../../../utils/routes';
import EditClutch from './components/EditClutch';
import BirdAvatar from '../../../../../components/core/BirdAvatar';
import TooltipIconButton from '../../../../../components/buttons/TooltipIconButton';
import BirdItem from '../../../../../components/core/BirdItem';

class Clutch extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      clutchId: 0,
      coupleId: null,

      canAddChild: false,

      clutch: {
        eggsCount: '',
        abortedCount: '',
        fertilizedCount: '',
        startedAt: '',
        description: '',
        children: []
      },

      // edit clutch
      abortedCount: '',
      fertilizedCount: '',
      description: '',
      isEditClutchOpened: false,

      // add bird form
      errors: {},
      isInformationAddBirdOpened: false
    };

    this.fertilizedEggsInputRef = React.createRef();
  }

  componentDidMount() {
    this.props.setAppBarTitle('clutch');

    this.setState(
      {
        clutchId: this.props.match.params.clutchId,
        coupleId: this.props.match.params.coupleId
      },
      () => this.fetchClutch()
    );
  }

  fetchClutch = () => {
    const { intl, enqueueSnackbar } = this.props;

    fetchApi(
      'get',
      `breeders/v1/couples/${this.state.coupleId}/clutches/${this.state.clutchId}`,
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.setState({
          isLoading: false,
          clutch: {
            eggsCount: data.eggs_count,
            abortedCount: data.aborted_count,
            fertilizedCount: data.fertilized_count,
            startedAt: data.started_at,
            description: data.description,
            hasFinished: data.has_finished,
            children: data.children
          },
          abortedCount: data.aborted_count.toString(),
          fertilizedCount: data.fertilized_count.toString(),
          description: data.description,
          canAddChild: data.children.length + 1 <= data.fertilized_count
        });
      },
      (error) => {
        const { response } = error;

        this.setState({
          isLoading: false
        });

        if (response.status === 400) {
          enqueueSnackbar(intl.formatMessage({ id: 'birdsErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        } else {
          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });

          this.props.history.push(ROUTES.ROOT);
        }
      }
    );
  };

  openEditClutch = () => {
    this.setState(
      {
        isEditClutchOpened: true
      },
      () => this.fertilizedEggsInputRef.current.focus()
    );
  };

  handleEditClutch = async () => {
    const { enqueueSnackbar, intl } = this.props;

    const schema = Yup.object().shape({
      fertilizedCount: Yup.number().notRequired().min(0),
      abortedCount: Yup.number().notRequired().min(0),
      description: Yup.string().notRequired()
    });

    const dataToSubmit = {
      fertilizedCount: parseInt(this.state.fertilizedCount, 10),
      abortedCount: parseInt(this.state.abortedCount, 10),
      description: this.state.description
    };

    try {
      await schema.validate(dataToSubmit, { abortEarly: false });

      if (dataToSubmit.fertilizedCount + dataToSubmit.abortedCount > this.state.clutch.eggsCount) {
        this.setState({
          errors: {
            abortedCount: intl.formatMessage({ id: 'totalEggsExceeded' }),
            fertilizedCount: intl.formatMessage({ id: 'totalEggsExceeded' })
          }
        });
      } else {
        this.setState(
          {
            isLoading: true,
            isEditClutchOpened: false
          },
          () => {
            fetchApi(
              'put',
              `/breeders/v1/couples/${this.state.coupleId}/clutches/${this.state.clutchId}`,
              {},
              {
                aborted_count: dataToSubmit.abortedCount,
                fertilized_count: dataToSubmit.fertilizedCount,
                not_fertilized_count: 0,
                description: dataToSubmit.description
              },
              false,
              (response) => {
                const { data } = response;

                this.setState({
                  isLoading: false,
                  clutch: {
                    eggsCount: data.eggs_count,
                    abortedCount: data.aborted_count,
                    fertilizedCount: data.fertilized_count,
                    startedAt: data.started_at,
                    description: data.description,
                    children: data.children,
                    hasFinished: data.has_finished
                  },
                  abortedCount: data.aborted_count.toString(),
                  fertilizedCount: data.fertilized_count.toString(),
                  description: data.description,
                  canAddChild: data.children.length + 1 <= data.fertilized_count
                });

                enqueueSnackbar(intl.formatMessage({ id: 'editClutchSuccessMessage' }), {
                  variant: 'success',
                  autoHideDuration: 4000
                });
              },
              (error) => {
                const { response } = error;

                this.setState({
                  isLoading: false
                });

                if (response.status === 400 || response.status === 404) {
                  enqueueSnackbar(intl.formatMessage({ id: 'editClutchErrorMessage' }), {
                    variant: 'error',
                    autoHideDuration: 4000
                  });
                } else {
                  enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                    variant: 'error',
                    autoHideDuration: 4000
                  });
                }
              }
            );
          }
        );
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        this.setState({
          errors: errorMessages
        });
      }
    }
  };

  handleInputChange = (event) => {
    const input = event.target;

    this.setState({
      [input.name]: input.value,
      errors: {}
    });
  };

  handleSelectChange = (event) => {
    const input = event.target;

    if (input.name === 'specie' && input.value !== '') {
      this.setState(
        {
          [input.name]: input.value,
          errors: {},
          isFetching: true
        },
        () => this.fetchAllMutations()
      );
    } else {
      this.setState({
        [input.name]: input.value,
        errors: {}
      });
    }
  };

  render() {
    const { intl } = this.props;

    const buttons = [
      {
        label: intl.formatMessage({ id: 'addChild' }),
        disabled: this.state.isLoading || !this.state.canAddChild,
        color: 'primary',
        variant: 'contained',
        onClick: () => {
          this.props.history.push(
            `${ROUTES.BREEDER_COUPLES}/${this.state.coupleId}/clutches/${this.state.clutchId}/children/add`
          );
        }
      },
      {
        label: intl.formatMessage({ id: 'editClutch' }),
        disabled: this.state.isLoading,
        color: 'primary',
        variant: 'outlined',
        onClick: this.openEditClutch
      }
      /*
      {
        title: 'removeClutch',
        translateTitle: true,
        disabled: this.state.isLoading,
        color: 'remove',
        variant: 'outlined',
        onClick: () => {}
      }
       */
    ];

    const breadcrumbs = [
      {
        title: intl.formatMessage({ id: 'couples' }),
        active: false,
        href: ROUTES.BREEDER_COUPLES,
        onClick: () => {
          this.props.history.push(ROUTES.BREEDER_COUPLES);
        }
      },
      {
        title: intl.formatMessage({ id: 'couple' }),
        href: `${ROUTES.BREEDER_COUPLES}/${this.state.coupleId}`,
        active: false,
        onClick: () => {
          this.props.history.push(`${ROUTES.BREEDER_COUPLES}/${this.state.coupleId}`);
        }
      },
      {
        title: intl.formatMessage({ id: 'clutch' }),
        active: true
      }
    ];

    return (
      <Content isLoading={this.state.isLoading} buttons={buttons} breadcrumbs={breadcrumbs}>
        <InformationDialog
          isOpened={this.state.isInformationAddBirdOpened}
          title={intl.formatMessage({ id: 'addChild' })}
          maxWidth={'sm'}
          onCancel={() =>
            this.setState({
              isInformationAddBirdOpened: false
            })
          }
          closeLabel={intl.formatMessage({ id: 'close' })}
          onCancelDisabled={this.state.isLoading || this.state.isFetching}
        >
          <Grid item xs={12}>
            <Typography variant={'h5'} color={'inherit'}>
              {intl.formatMessage({ id: 'addClutchChildHeader' })}
            </Typography>
          </Grid>
        </InformationDialog>
        <FormDialog
          isOpened={this.state.isEditClutchOpened}
          title={intl.formatMessage({ id: 'editClutch' })}
          maxWidth={'sm'}
          onConfirm={this.handleEditClutch}
          onCancel={() => {
            this.setState({
              isEditClutchOpened: false
            });
          }}
          onConfirmDisabled={this.state.isFetching}
          onCancelDisabled={this.state.isLoading || this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <EditClutch
            isLoading={this.state.isLoading || this.state.isFetching}
            fertilizedEggsInputRef={this.fertilizedEggsInputRef}
            fertilizedCount={this.state.fertilizedCount}
            abortedCount={this.state.abortedCount}
            description={this.state.description}
            errors={this.state.errors}
            handleInputChange={this.handleInputChange}
          />
        </FormDialog>
        {!this.state.isLoading && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <PaperListInformation
                items={[
                  {
                    title: intl.formatMessage({ id: 'totalEggs' }),
                    value: this.state.clutch.eggsCount
                  },
                  {
                    title: intl.formatMessage({ id: 'fertilizedEggs' }),
                    value: this.state.clutch.fertilizedCount
                  },
                  {
                    title: intl.formatMessage({ id: 'abortedEggs' }),
                    value: this.state.clutch.abortedCount
                  },
                  {
                    title: intl.formatMessage({ id: 'startDate' }),
                    value: fromUTCtoLocal(this.state.clutch.startedAt).format('LL')
                  },
                  {
                    title: intl.formatMessage({ id: 'finished' }),
                    value: this.state.clutch.hasFinished
                      ? intl.formatMessage({ id: 'yes' })
                      : intl.formatMessage({ id: 'no' })
                  },
                  {
                    title: intl.formatMessage({ id: 'description' }),
                    value: (
                      <Typography variant={'body1'} color={'textSecondary'}>
                        {this.state.clutch.description !== '' ? this.state.clutch.description : '-'}
                      </Typography>
                    )
                  }
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Table
                noDataMessage={intl.formatMessage({ id: 'noBirds' })}
                webHeaders={[
                  { title: ' ', align: 'left' },
                  { title: intl.formatMessage({ id: 'ring' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'year' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'gender' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'mutation' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'country' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'state' }), align: 'left' },
                  { title: '', align: 'right' }
                ]}
                webData={this.state.clutch.children.map((item) => [
                  <BirdAvatar images={item.default_image ? [item.default_image] : []} />,
                  item.ring,
                  item.year,
                  getGenderName(item.gender, this.props.intl),
                  getMutationName(item, this.props.intl),
                  getCountryName(
                    item.country.id,
                    this.props.user.user ? this.props.user.user.locale : null
                  ),
                  intl.formatMessage({ id: item.state }),
                  <TooltipIconButton
                    title={'view'}
                    iconName={'chevron-right'}
                    handleClick={() => {
                      this.props.history.push(
                        `${ROUTES.BREEDER_COUPLES}/${this.state.coupleId}/clutches/${this.state.clutchId}/children/${item.bird_id}`
                      );
                    }}
                  />
                ])}
                renderMobile={true}
                mobileData={this.state.clutch.children.map((item) => (
                  <BirdItem
                    imagePreviewPath={item.default_image ? item.default_image : null}
                    ring={item.ring}
                    year={item.year}
                    gender={getGenderName(item.gender, this.props.intl)}
                    mutationName={getMutationName(item, this.props.intl)}
                    countryName={getCountryName(
                      item.country.id,
                      this.props.user.user ? this.props.user.user.locale : null
                    )}
                    onClick={() => {
                      this.props.history.push(
                        `${ROUTES.BREEDER_COUPLES}/${this.state.coupleId}/clutches/${this.state.clutchId}/children/${item.bird_id}`
                      );
                    }}
                  />
                ))}
              />
            </Grid>
          </Grid>
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer,
  user: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  },
  setAppLocale: (locale) => {
    dispatch(setAppLocale(locale));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(injectIntl(Clutch)));
