import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import styles from './styles';

class MyCouples extends React.PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={4} justify={'flex-end'}>
        <Grid item xs={12} md={8}>
          <img
            src={require('../../../../../assets/images/my_couples.png')}
            alt={'MyCouples'}
            className={classes.image}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(injectIntl(MyCouples));
