// UTILS
import { getColors } from '../../../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  content: {
    display: 'flex',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  },
  container: {
    display: 'flex',
    padding: theme.spacing(1)
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  },
  itemCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '125px',
    width: '125px',
    borderRadius: '10px',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 0px 15px 2px rgba(0, 0, 0, 0.10)',
    textDecoration: 'none',
    marginBottom: theme.spacing(2),
    color: colors.sidebarItem
  },
  title: {
    textAlign: 'center',
    fontWeight: 'light',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    color: theme.palette.common.black
  }
});
