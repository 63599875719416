import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Container, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import styles from './styles';

class PrivacyPolicy extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes } = this.props;

    return (
      <Container>
        <div className={classes.card}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant={'h3'} className={classes.title}>
                Privacy Policy
              </Typography>
              <Typography variant={'body1'} className={classes.version}>
                This version was created on January 23, 2021.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                YOUTEK, legal person Nº226008533, headquartered in Rua Central Nº70, Geraz do Lima -
                Viana do Castelo, is a national trademark registered under Nº546391 and holder of
                the website myownbirds.com.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                YOUTEK is committed to protecting your privacy , having for this purpose prepared
                this Privacy Policy in order to make known its commitment and respect for the rules
                regarding privacy and the protection of personal data that we collect and treat in
                respect with applicable standards.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                YOUTEK strives to comply with Regulation 2016/679 of the European Parliament and of
                the Council, of 27 April 2016, complying with the applicable legal rules that are
                imposed on it with regard to the processing of data provided to it .
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                1. Scope
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                1.1. This Privacy Policy applies exclusively to the collection and processing of all
                personal data that is carried out by YOUTEK.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                2. Personal data
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                2.2. Personal data is information, of any nature and support , relating to a natural
                or legal person , identified or identifiable. An identifiable person is one who can
                be identified, directly or indirectly, namely by reference to an identification
                number or to one or more specific elements of his physical, physiological,
                psychological, economic, cultural or social identity.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                3. Responsible for treatment
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                3.1. YOUTEK is the entity responsible for the collection and processing of personal
                data, being obliged to use them only for the purposes described and with respect for
                the applicable rules.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                4. Purposes and consent
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                4.1. YOUTEK collects your data solely and exclusively for the following purposes:
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                i. Registration on the Website/Subscriptions (www.myownbirds.com);
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                ii. Provision of services or information;
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                iii. Development and maintenance of our website;
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                iv. Ensure network and information security;
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                v. Provision of technical assistance;
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                vi. Fraud prevention and detection;
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                vii. Processing of service subscriptions and orders;
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                viii. Sending a newsletter, via email,alerting to new services, campaigns,
                promotional offers, events and policy changes (direct marketing);
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                ix. Sending SMS or MMS messages, alerting for new services, campaigns, promotional
                offers, events and policy changes (direct marketing);
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                4.2. YOUTEK treats your personal data in a transparent and lawful manner, only for
                the purposes described above.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                4.3. YOUTEK can process your personal data for other specific, explicit and
                legitimate purposes, provided you have obtained your express consent.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                4.4. YOUTEK may process your data for other purposes, if necessary to comply with
                legal obligations.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                4.5. YOUTEK keeps the personal data collected only for the minimum period necessary
                for the purposes that motivated its collection.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                5. Data collected and form of collection
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                5.1. YOUTEK collects personal data (name, email, telephone number, address,
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                breeder number, ring's codes) expressly provided by the people who contact it
                through its website, and these data are treated in a way that YOUTEK can satisfy
                your requests.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                6. Security measures
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                6.1. YOUTEK undertakes to apply sufficient technical, organizational, and security
                measures to protect the personal data provided to it , committing itself, in the
                event of a personal data breach, to communicate to the supervisory authority, within
                a maximum of 72 hours, in legal terms.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                6.2. When exchanging information electronically, because your data may circulate on
                an open network, you risk being seen and used by unauthorized third parties.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                7. Right of access
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                7.1. The data subject will be able to access his personal data at all times, as well
                as oppose its use, upon written request addressed to YOUTEK, to email
                info@myownbirds.com.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                8. Right of rectification
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                8.1. The data subject may rectify, update and/or modify his data at any time, upon
                written request addressed to YOUTEK, to info@myownbirds.com.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                9. Right to portability
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                9.1. If desired, the data subject may request the portability of his data to a third
                party, upon written request addressed to YOUTEK, to info@myownbirds.com.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                10. Right to forgetting and opposition
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                10.1. The data subject may request the deletion of his personal data, or object to
                its treatment, upon written request addressed to YOUTEK, to info@myownbirds.com.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                11. Subcontracting
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                11.1. YOUTEK it committed to not subcontract without ensuring that the
                subcontractors comply with all the rules governing the protection of personal data.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                11.2. YOUTEK has a subcontractor, Google Analytics, which fulfills all obligations
                under the General Data Protection Regulation.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                12. Changes to the Privacy Policy
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                12.1. YOUTEK reserves the right to readjust or change this Privacy Policy at any
                time, these changes being duly publicized.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                You must keep in mind that, in certain cases, your request may not be promptly
                satisfied, being clarified about the legal reasons that impose the maintenance of
                the processing of your data, namely, legal obligations.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                YOUTEK further informs that, under legal terms, it has the right to submit a
                complaint to the National Data Protection Commission (www.cnpd.pt).
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(injectIntl(PrivacyPolicy));
