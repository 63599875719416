import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import styles from './styles';

class PublicPaper extends React.PureComponent {
  render() {
    const { intl, classes } = this.props;

    return (
      <div className={classes.paper}>
        <Grid container spacing={0}>
          {this.props.showLeftContainer && (
            <Grid item xs={12} md={this.props.showLeftContainer ? 6 : 12}>
              <div className={`${classes.paperContainer} ${classes.paperLeftContainer}`} />
            </Grid>
          )}
          <Grid item xs={12} md={this.props.showLeftContainer ? 6 : 12}>
            <div
              className={
                this.props.showLeftContainer
                  ? `${classes.paperContainer} ${classes.paperRightContainer}`
                  : classes.paperContainer
              }
            >
              <Grid container spacing={2}>
                {this.props.title && (
                  <Grid item xs={12}>
                    <Typography variant={'h4'} className={classes.title}>
                      {this.props.translateTitle
                        ? intl.formatMessage({ id: this.props.title })
                        : this.props.title}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <React.Fragment>{this.props.children}</React.Fragment>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

PublicPaper.defaultProps = {
  title: '',
  translateTitle: true,
  showLeftContainer: true
};

PublicPaper.propTypes = {
  title: PropTypes.string,
  translateTitle: PropTypes.bool,
  showLeftContainer: PropTypes.bool
};

export default withStyles(styles)(injectIntl(PublicPaper));
