import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';

// REACT FEATHER
import { ChevronRight, Trash2 } from 'react-feather';

// APP IMPORTS
import BirdAvatar from '../BirdAvatar';
import styles from './styles';

class BirdItem extends React.PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={`${classes.container} ${
            this.props.withBorder ? classes.containerWithBorder : ''
          } ${this.props.onClick ? classes.containerWithPointer : ''}`}
          onClick={this.props.onClick}
        >
          <div className={classes.avatarContainer}>
            <BirdAvatar images={this.props.imagePreviewPath ? [this.props.imagePreviewPath] : []} />
          </div>
          <div className={classes.innerContainer}>
            <div className={classes.ringContainer}>
              <Typography variant={'h6'} className={classes.ringText}>
                {this.props.ring}
              </Typography>
            </div>
            <div>
              <Typography variant={'body1'} className={classes.mutationText}>
                {this.props.mutationName}
              </Typography>
            </div>
            <div>
              <Typography variant={'body1'} className={classes.yearAndGenderAndCountryText}>
                {`${this.props.year} | ${this.props.gender} | ${this.props.countryName}`}
              </Typography>
            </div>
          </div>
          {this.props.onClick && (
            <div className={classes.button}>
              <IconButton color={'default'} size={'small'} onClick={this.props.onClick}>
                <ChevronRight size={20} />
              </IconButton>
            </div>
          )}
          {this.props.removeClick && (
            <div className={classes.button}>
              <IconButton color={'default'} size={'small'} onClick={this.props.removeClick}>
                <Trash2 size={20} />
              </IconButton>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

BirdItem.defaultProps = {
  withBorder: true,
  imagePreviewPath: null,
  onClick: null,
  removeClick: null
};

BirdItem.propTypes = {
  withBorder: PropTypes.bool,
  imagePreviewPath: PropTypes.object,
  ring: PropTypes.string.isRequired,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  gender: PropTypes.string.isRequired,
  mutationName: PropTypes.string.isRequired,
  countryName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  removeClick: PropTypes.func
};

export default withStyles(styles)(BirdItem);
