import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import { fetchApi } from '../../../utils/apiSettings';
import styles from './styles';

class Species extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      species: []
    };
  }

  componentDidMount() {
    fetchApi(
      'get',
      '/v1/public/species',
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.setState({ species: data });
      },
      () => {}
    );
  }

  render() {
    const { intl, classes } = this.props;

    const mutationsHeader = `${classes.tableCellHeader} ${classes.tableCellBorderLeft}`;
    const speciesText = `${classes.specieText} ${classes.tableRowBorderBottom}`;
    const mutationText = `${classes.mutationText} ${classes.tableCellBorderLeft} ${classes.tableCellNoBorderBottom}`;
    const mutationTextWithBorder = `${mutationText} ${classes.tableRowBorderBottom}`;

    return (
      <Container>
        <div className={classes.contentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h3'} className={classes.meetSpeciesTitle}>
                {intl.formatMessage({ id: 'meetTheSupportedSpecies' })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h5'} className={classes.weAreKeenTitle}>
                {intl.formatMessage(
                  { id: 'weAreKeenToSupportMore' },
                  {
                    email: <span className={classes.weAreKeenEmail}>info@myownbirds.com</span>
                  }
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.card}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCellHeader}>
                        <Typography variant={'h4'} color={'inherit'}>
                          {intl.formatMessage({ id: 'species' })}
                        </Typography>
                      </TableCell>
                      <TableCell className={mutationsHeader}>
                        <Typography variant={'h4'} color={'inherit'}>
                          {intl.formatMessage({ id: 'mutations' })}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.species.map((specie) => (
                      <React.Fragment key={`specie${specie.id}`}>
                        <TableRow>
                          <TableCell
                            className={speciesText}
                            rowSpan={
                              specie.mutations.length === 0 ? 2 : specie.mutations.length + 1
                            }
                          >
                            <Typography variant={'h6'} color={'inherit'}>
                              {specie.name}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {specie.mutations.length === 0 ? (
                          <TableRow>
                            <TableCell className={mutationTextWithBorder}>-</TableCell>
                          </TableRow>
                        ) : (
                          <React.Fragment>
                            {specie.mutations.map((mutation, index) => (
                              <TableRow key={`mutation${mutation.id}`}>
                                <TableCell
                                  className={
                                    index === specie.mutations.length - 1
                                      ? mutationTextWithBorder
                                      : mutationText
                                  }
                                >
                                  <Typography variant={'body1'} color={'inherit'}>
                                    {mutation.name}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(injectIntl(Species));
