import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { Grid } from '@material-ui/core';

// SPINE UI
import { Content, Table } from '@oliveirahugo68/spine-ui';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// QUERY STRING
import queryString from 'query-string';

// APP IMPORTS
import { setAppBarTitle } from '../../../store/actions/appActions';
import { fromUTCtoLocal } from '../../../utils/timeFormat';
import { fetchApi } from '../../../utils/apiSettings';
import SearchForm from '../../../components/inputs/SearchForm';

const PER_PAGE_DEFAULT = 25;
const PAGE_SIZES = [15, PER_PAGE_DEFAULT, 50, 100];

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      users: [],
      total: 0,

      // search form
      searchBy: '',

      // sorting
      sortBy: 'id',
      sortDirection: 'desc',

      // pagination
      page: 0,
      perPage: PER_PAGE_DEFAULT
    };
  }

  componentDidMount() {
    this.props.setAppBarTitle('users');

    this.parsePaginationAndFiltersFromURLParameters(() => {
      Promise.all([this.fetchUsers()]).then(async () => {
        this.setState({
          isLoading: false
        });
      });
    });
  }

  parsePaginationAndFiltersFromURLParameters = (handle) => {
    const parsed = queryString.parse(window.location.search);
    if (parsed) {
      this.setState(
        {
          // pagination
          page: parsed.page !== undefined ? parseInt(parsed.page, 10) : 0,
          perPage: parsed.per_page !== undefined ? parseInt(parsed.per_page, 10) : PER_PAGE_DEFAULT,
          sortBy: parsed.sort_by || 'name',
          sortDirection: parsed.sort_direction || 'desc'
        },
        () => {
          handle();
        }
      );
    }
  };

  setURLParameters = () => {
    this.props.history.push(
      `${window.location.pathname}?${queryString.stringify({
        // pagination
        page: this.state.page,
        per_page: this.state.perPage,
        sort_by: this.state.sortBy,
        sort_direction: this.state.sortDirection
      })}`
    );
  };

  fetchUsers = () => {
    let endpoint = `/admin/v1/breeders?`;
    endpoint += queryString.stringify({
      // pagination
      page_number: this.state.page,
      per_page: this.state.perPage,
      order_field: this.state.sortBy,
      order_direction: this.state.sortDirection
    });

    if (this.state.searchBy !== '') endpoint += `&search_by=${this.state.searchBy}`;

    return new Promise((resolve) => {
      fetchApi(
        'get',
        endpoint,
        {},
        {},
        false,
        (response) => {
          const { data } = response;

          this.setState(
            {
              users: data.data,
              total: data.total_count
            },
            () => {
              resolve();
            }
          );
        },
        () => {}
      );
    });
  };

  handleTableChange = (page, perPage, sortBy, sortDirection) => {
    this.setState(
      {
        isFetching: true,
        page: page,
        perPage: perPage,
        sortBy: sortBy,
        sortDirection: sortDirection
      },
      () => {
        this.setURLParameters();
        Promise.all([this.fetchUsers()]).then(() => {
          this.setState({ isFetching: false });
        });
      }
    );
  };

  handleSearchBy = (event) => {
    const input = event.target;

    this.setState(
      {
        searchBy: input.value
      },
      () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setState(
            {
              isFetching: true
            },
            () => {
              Promise.all([this.fetchUsers()]).then(() => {
                this.setState({
                  isFetching: false
                });
              });
            }
          );
        }, 500);
      }
    );
  };

  handleInputChange = (event) => {
    const input = event.target;

    this.setState({
      [input.name]: input.value
    });
  };

  render() {
    const { intl } = this.props;

    return (
      <Content isLoading={this.state.isLoading} isFetching={this.state.isFetching}>
        {!this.state.isLoading && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SearchForm
                placeholder={intl.formatMessage({ id: 'searchByNameEmailCountry' })}
                isLoading={this.state.isLoading || this.state.isFetching}
                nameInputRef={this.nameInputRef}
                searchBy={this.state.searchBy}
                handleInputChange={this.handleSearchBy}
              />
            </Grid>
            <Grid item xs={12}>
              <Table
                webHeaders={[
                  { title: 'id', align: 'left', sort: true },
                  {
                    title: intl.formatMessage({ id: 'name' }),
                    align: 'left',
                    sort: true,
                    sortKey: 'name'
                  },
                  {
                    title: intl.formatMessage({ id: 'email' }),
                    align: 'left',
                    sort: true,
                    sortKey: 'email'
                  },
                  { title: intl.formatMessage({ id: 'plan' }), align: 'left', sort: false },
                  { title: intl.formatMessage({ id: 'country' }), align: 'left', sort: false },
                  { title: intl.formatMessage({ id: 'addedAt' }), align: 'left', sort: false }
                ]}
                webData={this.state.users.map((item) => [
                  item.id,
                  item.name,
                  item.email,
                  item.plan ? intl.formatMessage({ id: item.plan.toLowerCase() }) : '-',
                  item.country ? item.country.name : '-',
                  fromUTCtoLocal(item.created_at).format('LL')
                ])}
                noDataMessage={intl.formatMessage({ id: 'noUsers' })}
                // handle changes
                onChange={this.handleTableChange}
                // sorting
                useSorting={true}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                // pagination
                usePagination={true}
                totalCount={this.state.total}
                page={this.state.page}
                rowsPerPage={this.state.perPage}
                rowsPerPageOptions={PAGE_SIZES}
              />
            </Grid>
          </Grid>
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Users));
