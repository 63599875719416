import React from 'react';
import PropTypes from 'prop-types';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { SnackbarProvider } from 'notistack';

// INTERNALIZATION
import { IntlProvider } from 'react-intl';

// APP IMPORTS
import { setAppLocale } from '../../../../store/actions/appActions';
import PrivateAppContainer from './components/PrivateAppContainer';
import PublicAppContainer from './components/PublicAppContainer';
import ROLES from '../../../../utils/roles';
import enTranslation from '../../../../assets/locales/en';
import ptTranslation from '../../../../assets/locales/pt';
import esTranslation from '../../../../assets/locales/es';
import frTranslation from '../../../../assets/locales/fr';
import itTranslation from '../../../../assets/locales/it';
import { getUserLanguage } from '../../../../utils/userSettings';

class Container extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      languages: {
        en: enTranslation,
        pt: ptTranslation,
        es: esTranslation,
        fr: frTranslation,
        it: itTranslation
      }
    };
  }

  componentDidMount() {
    // set the browser language by default
    // const browserLanguage = navigator.language;
    //
    // if (browserLanguage.includes('pt')) {
    //   this.props.setAppLocale('pt_PT');
    // } else if (browserLanguage.includes('es')) {
    //   this.props.setAppLocale('es_ES');
    // } else if (browserLanguage.includes('fr')) {
    //   this.props.setAppLocale('fr_FR');
    // } else {
    //   this.props.setAppLocale('en_GB');
    // }
  }

  convertLocaleToLanguage = (locale) => {
    switch (locale) {
      case 'en_GB':
        return 'en';
      case 'pt_PT':
        return 'pt';
      case 'es_ES':
        return 'es';
      case 'fr_FR':
        return 'fr';
      case 'it_IT':
        return 'it';
      default:
        return 'en';
    }
  };

  render() {
    const userStorageLanguage = getUserLanguage();
    let currentLanguage = null;

    if (userStorageLanguage && this.props.role === ROLES.DEFAULT)
      currentLanguage = this.convertLocaleToLanguage(userStorageLanguage);
    else currentLanguage = this.convertLocaleToLanguage(this.props.app.locale);

    return (
      <IntlProvider locale={currentLanguage} messages={this.state.languages[currentLanguage]}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          {this.props.role === ROLES.DEFAULT ? (
            <PublicAppContainer>{this.props.children}</PublicAppContainer>
          ) : (
            <PrivateAppContainer role={this.props.role}>{this.props.children}</PrivateAppContainer>
          )}
        </SnackbarProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppLocale: (locale) => {
    dispatch(setAppLocale(locale));
  }
});

Container.propTypes = {
  role: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
