import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { Grid } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// FEATHER ICONS
import { Twitter, Heart, Repeat } from 'react-feather';

// SPINE UI
import { Content, PaperInformation } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { withSnackbar } from 'notistack';
import { setAppBarTitle } from '../../../store/actions/appActions';
import { fetchApi } from '../../../utils/apiSettings';
import ROUTES from '../../../utils/routes';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      birds: 0,
      couples: 0,
      transfers: 0
    };
  }

  componentDidMount() {
    this.props.setAppBarTitle('dashboard');

    this.fetchDashboard();
  }

  fetchDashboard = () => {
    const { intl, enqueueSnackbar } = this.props;

    fetchApi(
      'get',
      '/breeders/v1/dashboard',
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.setState({
          isLoading: false,
          birds: data.active_birds,
          couples: data.active_couples,
          transfers: data.transfers
        });
      },
      (error) => {
        const { response } = error;

        if (response.status === 400) {
          enqueueSnackbar(intl.formatMessage({ id: 'birdsErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        } else {
          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });

          this.props.history.push(ROUTES.ROOT);
        }
      }
    );
  };

  render() {
    const { intl } = this.props;

    return (
      <Content isLoading={this.state.isLoading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4} xl={3}>
                <PaperInformation
                  title={intl.formatMessage({ id: 'numberOfBirds' })}
                  information={this.state.birds}
                  icon={Twitter}
                  clickable={true}
                  onClick={() => {
                    this.props.history.push(ROUTES.BREEDER_MY_BIRDS);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={3}>
                <PaperInformation
                  title={intl.formatMessage({ id: 'numberOfCouples' })}
                  information={this.state.couples}
                  icon={Heart}
                  clickable={true}
                  onClick={() => {
                    this.props.history.push(ROUTES.BREEDER_COUPLES);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={3}>
                <PaperInformation
                  title={intl.formatMessage({ id: 'numberOfTransfers' })}
                  information={this.state.transfers}
                  icon={Repeat}
                  clickable={true}
                  onClick={() => {
                    this.props.history.push(ROUTES.BREEDER_TRANSFER_REQUESTS);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer,
  user: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(injectIntl(Dashboard)));
