// UTILS
import { getColors } from '../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  tabsInnerContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  avatarContainer: {
    position: 'relative'
  },
  avatarUploadContainer: {
    cursor: 'pointer',
    position: 'absolute',
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${colors.border}`,
    marginTop: theme.spacing(-4),
    marginLeft: theme.spacing(12),
    '&:hover': {
      backgroundColor: theme.palette.secondary.light
    }
  },
  lightboxButton: {
    marginRight: '10px',
    marginBottom: '-5px',
    cursor: 'pointer'
  }
});
