import DOTS from '../../../assets/images/dots-bg.png';
import { getColors } from '../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${DOTS})`,
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',
    marginBottom: '-48px'
  },
  increaseProductivityTitle: {
    color: '#fff',
    fontWeight: 700,
    marginTop: theme.spacing(3),
    textAlign: 'center',
    lineHeight: '1.5em'
  },
  plansCard: {
    borderRadius: '20px',
    borderTop: `4px solid ${colors.sidebarItem}`,
    boxShadow: '0px 64px 120px -40px rgba(0, 0, 0, 0.24)',
    overflow: 'hidden',
    backgroundColor: '#fff',
    marginTop: theme.spacing(4),
    padding: theme.spacing(2)
  },
  gridContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridAutoColumns: '1fr',
      gridAutoFlow: 'column'
    }
  },
  gridBorderItem: {
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${colors.border}`
    },
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${colors.border}`
    }
  }
});
