import React from 'react';
import PropTypes from 'prop-types';

// REDUX
import { connect } from 'react-redux';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// SPINE UI
import { PaperListInformation } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { fromUTCtoLocal } from '../../../../../utils/timeFormat';
import { getCountryName, getGenderName } from '../../../../../utils/appSettings';

class BirdMainInformation extends React.PureComponent {
  getMutationName = (mutation, isYellow, isWhiteHead) => {
    const { intl } = this.props;

    if (isYellow && isWhiteHead) {
      return `${intl.formatMessage({ id: 'yellow' })} - ${intl.formatMessage({
        id: 'whiteHead'
      })} - ${mutation.name}`;
    }
    if (isYellow && !isWhiteHead) {
      return `${intl.formatMessage({ id: 'yellow' })} - ${mutation.name}`;
    }
    if (!isYellow && isWhiteHead) {
      return `${intl.formatMessage({ id: 'whiteHead' })} - ${mutation.name}`;
    }
    return mutation.name;
  };

  render() {
    const { intl } = this.props;

    return (
      <PaperListInformation
        items={[
          {
            title: intl.formatMessage({ id: 'ring' }),
            value: this.props.ring
          },
          {
            title: intl.formatMessage({ id: 'specie' }),
            value: this.props.specie.name
          },
          {
            title: intl.formatMessage({ id: 'mutation' }),
            value: this.getMutationName(
              this.props.mutation,
              this.props.isYellow,
              this.props.isWhiteHead
            )
          },
          {
            title: intl.formatMessage({ id: 'country' }),
            value: getCountryName(
              this.props.countryId,
              this.props.user.user ? this.props.user.user.locale : null
            )
          },
          {
            title: intl.formatMessage({ id: 'gender' }),
            value: getGenderName(this.props.gender, intl)
          },
          {
            title: intl.formatMessage({ id: 'birthday' }),
            value: fromUTCtoLocal(this.props.birthday).format('LL')
          }
        ]}
      />
    );
  }
}

BirdMainInformation.defaultProps = {
  isYellow: false,
  isWhiteHead: false
};

BirdMainInformation.propTypes = {
  ring: PropTypes.string.isRequired,
  birthday: PropTypes.string.isRequired,
  countryId: PropTypes.number.isRequired,
  gender: PropTypes.string.isRequired,
  specie: PropTypes.object.isRequired,
  mutation: PropTypes.object.isRequired,
  isYellow: PropTypes.bool,
  isWhiteHead: PropTypes.bool
};

const mapStateToProps = (state) => ({
  app: state.appReducer,
  user: state.userReducer
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BirdMainInformation));
