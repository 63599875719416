import actionsType from './types';

export function setAppBarTitle(title, translateTitle = true) {
  return {
    type: actionsType.SET_APP_BAR_TITLE,
    title: title,
    translateTitle: translateTitle
  };
}

export function setAppLocale(locale) {
  return {
    type: actionsType.SET_APP_LOCALE,
    locale: locale
  };
}
