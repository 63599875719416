import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Divider, Grid, Typography } from '@material-ui/core';

// REACT ROUTER
import { Link } from 'react-router-dom';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// QUERY STRING
import queryString from 'query-string';

// SPINE UI
import { Select, Input, FormDialog } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { fetchApi } from '../../../../../utils/apiSettings';
import ROUTES from '../../../../../utils/routes';
import InputDate from '../../../../../components/inputs/InputDate';
import MuiPickersUtilsProvider from '../../../../../components/inputs/MuiPickersUtilsProvider';
import styles from './styles';

class AddCoupleFormNew extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,

      // form
      cage: 1,
      startedAt: null,
      maleSelectedId: '',
      femaleSelectedId: '',

      specieId: '',
      errors: {},

      // data
      males: [],
      females: []
    };
  }

  fetchBirds = (specieId, gender) => {
    return new Promise((resolve) => {
      let endpoint = '/breeders/v1/birds?';
      endpoint += queryString.stringify({
        per_page: 1000,
        order_field: 'ring',
        order_direction: 'asc',
        state: 'active',
        gender: gender,
        specie_id: specieId
      });

      fetchApi(
        'get',
        endpoint,
        {},
        {},
        false,
        (response) => {
          const { data } = response;

          if (gender === 'M') {
            this.setState(
              {
                males: data.data
              },
              () => resolve()
            );
          } else {
            this.setState(
              {
                females: data.data
              },
              () => resolve()
            );
          }
        },
        () => {}
      );
    });
  };

  addCouple = () => {
    const { enqueueSnackbar, intl } = this.props;

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'post',
          '/breeders/v1/couples',
          {},
          {
            cage: this.state.cage,
            started_at: this.state.startedAt,
            male_bird_id: this.state.maleSelectedId,
            female_bird_id: this.state.femaleSelectedId
          },
          false,
          (response) => {
            const { data } = response;

            enqueueSnackbar(intl.formatMessage({ id: 'couplesSuccessMessage' }), {
              variant: 'success',
              autoHideDuration: 4000
            });

            this.props.history.push(`${ROUTES.BREEDER_COUPLES}/${data.couple_id}`);
          },
          (error) => {
            const { response } = error;

            this.setState({ isFetching: false });

            if (response.status === 403) {
              // Your plan does not allow you to create more couples.
              enqueueSnackbar(intl.formatMessage({ id: 'addCoupleErrorMessagePlanDontAllow' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            } else if (response.status === 400) {
              if (response.data.message === 'The cage identifier is already being used.') {
                this.setState({
                  errors: {
                    cage: intl.formatMessage({ id: 'identifierAlreadyUsed' })
                  }
                });
              } else if (response.data.message === 'These birds are related to each other.') {
                this.setState({
                  errors: {
                    maleSelectedId: intl.formatMessage({ id: 'parentsAreBrothers' }),
                    femaleSelectedId: intl.formatMessage({ id: 'parentsAreBrothers' })
                  }
                });
              } else {
                enqueueSnackbar(intl.formatMessage({ id: 'addCoupleErrorMessage' }), {
                  variant: 'error',
                  autoHideDuration: 4000
                });
              }
            } else {
              enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            }
          }
        );
      }
    );
  };

  handleInputChange = (event) => {
    const input = event.target;

    this.setState({
      [input.name]: input.value,
      errors: {}
    });
  };

  handleDateChange = (name, date) => {
    this.setState({
      [name]: date,
      errors: {}
    });
  };

  handleSelectChange = (event) => {
    const input = event.target;

    this.setState({
      [input.name]: input.value,
      errors: {}
    });
  };

  handleSelectSpecie = (event) => {
    const input = event.target;
    this.setState({ specieId: input.value, isFetching: true }, () => {
      Promise.all([
        this.fetchBirds(this.state.specieId, 'M'),
        this.fetchBirds(this.state.specieId, 'F')
      ])
        .then(() => {
          this.setState({ isFetching: false });
        })
        .catch(() => {
          this.props.cancelAddCouple();
        });
    });
  };

  render() {
    const { intl, classes } = this.props;

    return (
      <FormDialog
        isOpened={this.props.isAddCoupleOpened}
        title={intl.formatMessage({ id: 'addCouple' })}
        maxWidth={'md'}
        onConfirm={this.addCouple}
        onCancel={this.props.cancelAddCouple}
        onConfirmDisabled={
          this.props.isLoading ||
          this.state.isFetching ||
          !this.state.maleSelectedId ||
          !this.state.femaleSelectedId ||
          !this.state.cage
        }
        onCancelDisabled={this.state.isFetching}
        confirmLabel={intl.formatMessage({ id: 'confirm' })}
        cancelLabel={intl.formatMessage({ id: 'cancel' })}
        autoComplete={'off'}
      >
        <MuiPickersUtilsProvider>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'body1'} color={'secondary'}>
                {intl.formatMessage({ id: 'addCoupleInformation' })}
                <Link to={ROUTES.BREEDER_MY_BIRDS} className={classes.hereLink}>
                  {intl.formatMessage({ id: 'here' })}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                name={'cage'}
                type={'number'}
                autoComplete={'off'}
                label={intl.formatMessage({ id: 'cageIdentifier' })}
                value={this.state.cage}
                onChange={this.handleInputChange}
                required={true}
                error={!!this.state.errors.cage}
                helperText={this.state.errors.cage || ''}
                disabled={this.props.isLoading || this.state.isFetching}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputDate
                required={true}
                name={'startedAt'}
                label={intl.formatMessage({ id: 'startDate' })}
                value={this.state.startedAt}
                onChange={(date) => this.handleDateChange('startedAt', date)}
                okLabel={'Ok'}
                cancelLabel={intl.formatMessage({ id: 'cancel' })}
                error={!!this.state.errors.startedAt}
                helperText={this.state.errors.startedAt || ''}
                disabled={this.props.isLoading || this.state.isFetching}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                name={'specieId'}
                label={intl.formatMessage({ id: 'specie' })}
                value={this.state.specieId}
                onChange={this.handleSelectSpecie}
                required={false}
                disabled={this.props.isLoading || this.state.isFetching}
                autoComplete={'off'}
                options={this.props.species}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Select
                    name={'maleSelectedId'}
                    label={intl.formatMessage({ id: 'male' })}
                    value={this.state.maleSelectedId}
                    onChange={this.handleSelectChange}
                    required={true}
                    disabled={this.props.isLoading || this.state.isFetching || !this.state.specieId}
                    autoComplete={'off'}
                    options={this.state.males.map((item) => ({
                      key: item.bird_id,
                      value: item.bird_id,
                      name: `${item.ring} - ${item.year}`
                    }))}
                    error={!!this.state.errors.maleSelectedId}
                    helperText={this.state.errors.maleSelectedId || ''}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    name={'femaleSelectedId'}
                    label={intl.formatMessage({ id: 'female' })}
                    value={this.state.femaleSelectedId}
                    onChange={this.handleSelectChange}
                    required={true}
                    disabled={this.props.isLoading || this.state.isFetching || !this.state.specieId}
                    autoComplete={'off'}
                    options={this.state.females.map((item) => ({
                      key: item.bird_id,
                      value: item.bird_id,
                      name: `${item.ring} - ${item.year}`
                    }))}
                    error={!!this.state.errors.femaleSelectedId}
                    helperText={this.state.errors.femaleSelectedId || ''}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </FormDialog>
    );
  }
}

AddCoupleFormNew.propTypes = {
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAddCoupleOpened: PropTypes.bool.isRequired,
  cancelAddCouple: PropTypes.func.isRequired,
  species: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default withStyles(styles)(withSnackbar(injectIntl(AddCoupleFormNew)));
