import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// FEATHER ICONS
import { LogOut } from 'react-feather';

// APP IMPORTS
import { clearStorage } from '../../../../../../../../utils/userSettings';
import styles from './styles';

class LogoutButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  handleLogout = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        // TODO: send request to the backend

        clearStorage();
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    );
  };

  render() {
    const { classes, intl } = this.props;

    return (
      <Button
        type={'button'}
        color={'secondary'}
        variant={'outlined'}
        onClick={this.handleLogout}
        className={classes.logoutButton}
        startIcon={!this.state.isLoading ? <LogOut size={17} /> : null}
      >
        {this.state.isLoading ? (
          <CircularProgress size={20} />
        ) : (
          intl.formatMessage({ id: 'logout' })
        )}
      </Button>
    );
  }
}

export default withStyles(styles)(injectIntl(LogoutButton));
