export default {
  white: '#FFFFFF',
  black: '#24262a',

  background: '#f3f2ef',
  border: '#d9d8d6',

  primaryLight: '#1581f5',
  primaryMain: '#0366d0',
  primaryDark: '#0f3c6d',

  secondaryLight: '#f4f4f4',
  secondaryMain: '#9e9e9e',
  secondaryDark: '#616161',

  toolbar: '#fff',
  logo: '#15375C',
  sidebar: '#0f3c6d',
  sidebarItem: '#f7b500',
  sidebarItemActive: '#fff',

  tableBackgroundColor: '#e9e9e9',
  tableRowBackgroundColor: '#f7f7f7'
};
