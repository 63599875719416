import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './pages/App';
import * as serviceWorker from './serviceWorker';

// define app version here to attempt cache busting
global.appVersion = process.env.REACT_APP_VERSION;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
});

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
