import actionsType from '../actions/types';

const INITIAL_STATE = {
  user: null
};

export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsType.SET_USER_INFORMATION:
      return {
        ...state,
        user: action.user
      };

    default:
      return state;
  }
}
