import React from 'react';
import PropTypes from 'prop-types';

// SPINE UI
import { Input } from '@oliveirahugo68/spine-ui';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

class SearchForm extends React.PureComponent {
  render() {
    const { intl } = this.props;

    return (
      <Input
        name={'searchBy'}
        type={'text'}
        autoComplete={'off'}
        label={intl.formatMessage({ id: 'searchBy' })}
        value={this.props.searchBy}
        placeholder={this.props.placeholder}
        onChange={this.props.handleInputChange}
        disabled={this.props.isLoading}
      />
    );
  }
}

SearchForm.propTypes = {
  placeholder: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchBy: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired
};

export default injectIntl(SearchForm);
