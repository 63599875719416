import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import styles from './styles';

class WaitTransferToComplete extends React.PureComponent {
  render() {
    const { intl, classes } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant={'body1'} className={classes.text}>
            {intl.formatMessage({ id: 'createTransferInfo2' })}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(injectIntl(WaitTransferToComplete));
