import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { IconButton, Tooltip } from '@material-ui/core';

// FEATHER ICONS
import { Edit2, Trash2, MoreHorizontal, ChevronRight } from 'react-feather';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

class TooltipIconButton extends React.PureComponent {
  render() {
    const { intl } = this.props;
    const { title, translateTitle, size, iconName, iconSize, handleClick } = this.props;

    return (
      <Tooltip title={translateTitle ? intl.formatMessage({ id: title }) : title}>
        <IconButton color={'default'} onClick={handleClick} size={size}>
          {iconName === 'edit' && <Edit2 size={iconSize} />}
          {iconName === 'trash' && <Trash2 size={iconSize} />}
          {iconName === 'more-horizontal' && <MoreHorizontal size={iconSize} />}
          {iconName === 'chevron-right' && <ChevronRight size={iconSize} />}
        </IconButton>
      </Tooltip>
    );
  }
}

TooltipIconButton.defaultProps = {
  translateTitle: true,
  size: 'medium',
  iconSize: 20
};

TooltipIconButton.propTypes = {
  title: PropTypes.string.isRequired,
  translateTitle: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  iconName: PropTypes.oneOf(['edit', 'trash', 'more-horizontal', 'chevron-right']).isRequired,
  iconSize: PropTypes.number,
  handleClick: PropTypes.func.isRequired
};

export default injectIntl(TooltipIconButton);
