import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';

// MATERIAL UI DATE PICKER
import { KeyboardDatePicker } from '@material-ui/pickers';

// APP IMPORTS
import styles from './styles';

function InputDate({ name, label, value, onChange, inputRef, error, helperText, ...props }) {
  return (
    <KeyboardDatePicker
      inputRef={inputRef}
      name={name}
      label={label}
      placeholder={'dd/mm/yyyy'}
      format={'DD/MM/YYYY'}
      inputVariant={'outlined'}
      value={value}
      onChange={(date) => onChange(date)}
      error={error}
      helperText={helperText}
      InputLabelProps={{ shrink: true }}
      variant={'outlined'}
      margin={'normal'}
      fullWidth={true}
      disableFuture={true}
      autoOk={false}
      {...props}
    />
  );
}

InputDate.defaultProps = {
  inputRef: React.createRef(),
  error: false,
  helperText: '',
  value: ''
};

InputDate.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  inputRef: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

export default withStyles(styles)(InputDate);
