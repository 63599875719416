import React from 'react';
import PropTypes from 'prop-types';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// SPINE UI
import { PaperListInformation } from '@oliveirahugo68/spine-ui';

class BreederInformation extends React.PureComponent {
  render() {
    const { intl } = this.props;

    const breederItems = [
      { title: intl.formatMessage({ id: 'country' }), value: this.props.country },
      {
        title: intl.formatMessage({ id: 'address' }),
        value: this.props.address
      },
      {
        title: intl.formatMessage({ id: 'zipCode' }),
        value: this.props.zipCode
      }
    ];

    if (this.props.countryId === 176) {
      breederItems.push(
        {
          title: 'Número ICNF',
          value: this.props.ptIcnf
        },
        {
          title: 'NIF',
          value: this.props.ptNif
        },
        {
          title: 'BI / CC',
          value: this.props.ptCc
        }
      );
    }

    return <PaperListInformation items={breederItems} />;
  }
}

BreederInformation.defaultProps = {
  countryId: 0,
  country: '',
  address: '',
  zipCode: '',
  ptIcnf: '',
  ptNif: '',
  ptCc: ''
};

BreederInformation.propTypes = {
  countryId: PropTypes.number,
  country: PropTypes.string,
  address: PropTypes.string,
  zipCode: PropTypes.string,
  ptIcnf: PropTypes.string,
  ptNif: PropTypes.string,
  ptCc: PropTypes.string
};

export default injectIntl(BreederInformation);
