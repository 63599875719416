import actionsType from '../actions/types';
import { DEFAULT_LOCALE } from '../../utils/userSettings';

const INITIAL_STATE = {
  title: null,
  translateTitle: true,
  locale: DEFAULT_LOCALE
};

export default function appReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsType.SET_APP_BAR_TITLE:
      return {
        ...state,
        title: action.title,
        translateTitle: action.translateTitle
      };

    case actionsType.SET_APP_LOCALE:
      return {
        ...state,
        locale: action.locale
      };

    default:
      return state;
  }
}
