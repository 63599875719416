import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { Grid } from '@material-ui/core';

// SPINE UI
import { Input, Checkbox } from '@oliveirahugo68/spine-ui';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

class AddSpecie extends React.PureComponent {
  render() {
    const { intl } = this.props;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Input
            inputRef={this.props.nameInputRef}
            name={'specieName'}
            type={'text'}
            autoComplete={'name'}
            label={intl.formatMessage({ id: 'name' })}
            value={this.props.specieName}
            onChange={this.props.handleInputChange}
            error={!!this.props.errors.specieName}
            helperText={this.props.errors.specieName || ''}
            required={true}
            disabled={this.props.isLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            name={'hasClutchDurationDays'}
            label={intl.formatMessage({ id: 'hasClutchDurationDays' })}
            value={this.props.hasClutchDurationDays}
            onChange={this.props.handleInputChange}
            disabled={this.props.isLoading}
          />
        </Grid>
        {this.props.hasClutchDurationDays && (
          <Grid item xs={6}>
            <Input
              name={'clutchDurationDays'}
              type={'number'}
              autoComplete={'off'}
              label={intl.formatMessage({ id: 'days' })}
              value={this.props.clutchDurationDays}
              onChange={this.props.handleInputChange}
              error={!!this.props.errors.clutchDurationDays}
              helperText={this.props.errors.clutchDurationDays || ''}
              disabled={this.props.isLoading}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

AddSpecie.defaultProps = {};

AddSpecie.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  nameInputRef: PropTypes.object.isRequired,
  specieName: PropTypes.string.isRequired,
  clutchDurationDays: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hasClutchDurationDays: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired
};

export default injectIntl(AddSpecie);
