export default (theme) => ({
  paper: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 64px 120px -40px rgba(0, 0, 0, 0.24)',
    borderRadius: '26px',
    overflow: 'hidden'
  },
  paperContainer: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6)
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  paperLeftContainer: {
    height: '100%',
    backgroundImage:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='105' viewBox='0 0 80 105'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='death-star' fill='%237e7e7e' fill-opacity='0.09'%3E%3Cpath d='M20 10a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V10zm15 35a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zM20 75a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V75zm30-65a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V10zm0 65a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V75zM35 10a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10zM5 45a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zm0-35a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10zm60 35a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zm0-35a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")"
  },
  paperRightContainer: {
    boxShadow: '-32px 0px 120px -32px rgba(0, 0, 0, 0.16)'
  },
  title: {
    color: theme.palette.common.black,
    fontWeight: 600,
    marginBottom: theme.spacing(2)
  }
});
