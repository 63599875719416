import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { ListItem, ListItemText, Typography } from '@material-ui/core';

// REACT FEATHER
import { Download } from 'react-feather';

class DocumentItem extends React.PureComponent {
  render() {
    const { title, path } = this.props;

    return (
      <ListItem
        button={true}
        onClick={() => window.open(process.env.REACT_APP_DOCUMENTS_URL + path)}
      >
        <ListItemText>
          <Typography variant={'body1'} color={'primary'}>
            {title}
          </Typography>
        </ListItemText>
        <Download />
      </ListItem>
    );
  }
}

DocumentItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
};

export default DocumentItem;
