import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// REACT FEATHER
import { AlertTriangle, ChevronRight } from 'react-feather';

// QUERY STRING
import queryString from 'query-string';

// SPINE UI
import { Content, Paper, Table, Select, Input, Button } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { DEFAULT_LOCALE } from '../../../../utils/userSettings';
import { setAppBarTitle } from '../../../../store/actions/appActions';
import {
  getCountries,
  getCountryName,
  getGenderName,
  getMutationName
} from '../../../../utils/appSettings';
import { fetchApi } from '../../../../utils/apiSettings';
import ROUTES from '../../../../utils/routes';
import BirdItem from '../../../../components/core/BirdItem';
import TooltipIconButton from '../../../../components/buttons/TooltipIconButton';
import BirdAvatar from '../../../../components/core/BirdAvatar';
import styles from './styles';

class CreateTransfer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      // form
      errors: {},
      transferNumber: '',
      birds: [],
      availableBirds: [],
      species: [],

      // bird form
      specieId: '',
      birdId: '',

      // default requester form
      requesterLocale: DEFAULT_LOCALE,
      requesterName: '',
      requesterEmail: '',
      requesterContact: '',
      requesterCountryId: '',
      requesterAddress: '',
      requesterZipCode: '',

      // portugal requester form
      requesterPtIcnf: '',
      requesterPtNif: '',
      requesterPtCC: '',

      // ----------------------------------------
      // default receptor form
      isRegistered: 'false',
      // receptorBreederId: '',
      receptorCode: '',
      receptorName: '',
      receptorEmail: '',
      receptorContact: '',
      receptorCountryId: '',
      receptorAddress: '',
      receptorZipCode: '',

      // portugal receptor form
      receptorPtIcnf: '',
      receptorPtNif: '',
      receptorPtCC: ''
    };
  }

  componentDidMount() {
    this.props.setAppBarTitle('createTransfer');

    Promise.all([
      this.fetchUserInformation(),
      this.fetchSpecies(),
      this.fetchPossibleNextTransferNumber()
    ]).then(() => {
      this.setState({
        isLoading: false
      });
    });
  }

  fetchUserInformation = () => {
    const { intl, enqueueSnackbar } = this.props;

    return new Promise((resolve) => {
      fetchApi(
        'get',
        '/breeders/v1/account',
        {},
        {},
        false,
        (response) => {
          const { data } = response;

          this.setState(
            {
              // requester information
              requesterLocale: data.locale,
              requesterName: data.name,
              requesterEmail: data.email,
              requesterContact: data.contact || '',
              requesterCountryId: data.country ? data.country.id : '',
              requesterAddress: data.address || '',
              requesterZipCode: data.zip_code || '',
              requesterPtIcnf: data.pt_icnf || '',
              requesterPtNif: data.pt_nif || '',
              requesterPtCC: data.pt_cc || ''
            },
            () => {
              resolve();
            }
          );
        },
        () => {
          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
          this.props.history.push(ROUTES.ROOT);
        }
      );
    });
  };

  fetchSpecies = () => {
    const { intl, enqueueSnackbar } = this.props;

    return new Promise((resolve) => {
      fetchApi(
        'get',
        '/v1/public/species',
        {},
        {},
        false,
        (response) => {
          const { data } = response;

          this.setState({ species: data }, () => {
            resolve();
          });
        },
        () => {
          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
          this.props.history.push(ROUTES.ROOT);
        }
      );
    });
  };

  fetchPossibleNextTransferNumber = () => {
    return new Promise((resolve) => {
      fetchApi(
        'get',
        '/breeders/v1/transfers/generate_next_transfer_number',
        {},
        {},
        false,
        (response) => {
          const { data } = response;

          this.setState(
            {
              transferNumber: data.number
            },
            () => {
              resolve();
            }
          );
        },
        () => {
          this.setState(
            {
              transferNumber: ''
            },
            () => {
              resolve();
            }
          );
        }
      );
    });
  };

  findBreederBasedOnEmailAndAccessCode = () => {
    const { intl, enqueueSnackbar } = this.props;

    this.setState(
      {
        isFetching: true
      },
      () => {
        let endpoint = '/breeders/v1/breeders/breeder_code?';
        endpoint += queryString.stringify({
          email: this.state.receptorEmail,
          code: this.state.receptorCode
        });

        fetchApi(
          'get',
          endpoint,
          {},
          {},
          false,
          (response) => {
            const { data } = response;

            this.setState({
              isFetching: false,

              // receptor information
              // receptorBreederId: data.breeder_id,
              receptorName: data.name,
              receptorEmail: data.email,
              receptorContact: data.contact || '',
              receptorCountryId: data.country ? data.country.id : '',
              receptorAddress: data.address || '',
              receptorZipCode: data.zip_code || '',

              // portugal receptor form
              receptorPtIcnf: data.pt_icnf || '',
              receptorPtNif: data.pt_nif || '',
              receptorPtCC: data.pt_cc || ''
            });
          },
          () => {
            // NOT FOUND
            enqueueSnackbar(intl.formatMessage({ id: 'breederNotFoundOrInvalidCode' }), {
              variant: 'error',
              autoHideDuration: 3000
            });

            this.setState({
              isFetching: false
            });
          }
        );
      }
    );
  };

  fetchBirds = () => {
    const { enqueueSnackbar, intl } = this.props;

    this.setState(
      {
        isFetching: true,
        availableBirds: []
      },
      () => {
        let endpoint = '/breeders/v1/birds?';
        endpoint += queryString.stringify({
          page_number: 0,
          per_page: 1000,
          order_field: this.state.orderBy,
          order_direction: this.state.orderDirection,
          state: 'active',
          specie_id: this.state.specieId
        });

        fetchApi(
          'get',
          endpoint,
          {},
          {},
          false,
          (response) => {
            const { data } = response;

            this.setState({
              isFetching: false,
              availableBirds: data.data
            });
          },
          () => {
            this.setState({ isFetching: false });
            enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
              variant: 'error',
              autoHideDuration: 4000
            });
          }
        );
      }
    );
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { intl, enqueueSnackbar } = this.props;
    const countries = getCountries(this.state.requesterLocale);

    this.setState(
      {
        isFetching: true
      },
      () => {
        window.scrollTo(0, 0);

        fetchApi(
          'post',
          '/breeders/v1/transfers',
          {},
          {
            number: this.state.transferNumber,
            bird_ids: this.state.birds.map((bird) => bird.bird_id),
            requester_data: {
              name: this.state.requesterName,
              email: this.state.requesterEmail,
              contact: this.state.requesterContact,
              address: this.state.requesterAddress,
              zip_code: this.state.requesterZipCode,
              country: {
                id: this.state.requesterCountryId,
                name: countries.find((item) => item.value === this.state.requesterCountryId).name
              },

              // portugal
              pt_icnf: this.state.requesterPtIcnf,
              pt_cc: this.state.requesterPtCC,
              pt_nif: this.state.requesterPtNif
            },
            receptor_data: {
              name: this.state.receptorName,
              email: this.state.receptorEmail,
              contact: this.state.receptorContact,
              address: this.state.receptorAddress,
              zip_code: this.state.receptorZipCode,
              country: {
                id: this.state.receptorCountryId,
                name: countries.find((item) => item.value === this.state.receptorCountryId).name
              },

              // portugal
              pt_icnf: this.state.receptorPtIcnf,
              pt_cc: this.state.receptorPtCC,
              pt_nif: this.state.receptorPtNif
            }
          },
          false,
          (response) => {
            const { data } = response;
            this.props.history.push(`${ROUTES.BREEDER_TRANSFER_REQUESTS}/${data.transfer_id}`);

            enqueueSnackbar(intl.formatMessage({ id: 'transferRequestSuccessMessage' }), {
              variant: 'success',
              autoHideDuration: 4000
            });
          },
          (error) => {
            const { response } = error;

            this.setState({ isFetching: false });

            if (response.status === 500) {
              enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            } else if (response.status === 409) {
              this.setState({
                errors: {
                  transferNumber: intl.formatMessage({ id: 'transferNumberAlreadyExists' })
                }
              });
              enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            } else {
              enqueueSnackbar(intl.formatMessage({ id: 'transferRequestErrorMessage' }), {
                variant: 'error',
                autoHideDuration: 4000
              });
            }
          }
        );
      }
    );
  };

  handleInputChange = (event) => {
    const input = event.target;

    this.setState({
      [input.name]: input.value,
      errors: {}
    });
  };

  handleSelectChange = (event) => {
    const input = event.target;

    this.setState(
      {
        [input.name]: input.value,
        errors: {}
      },
      () => {
        if (input.name === 'specieId') {
          this.fetchBirds();
        }
      }
    );
  };

  handleAddBirdToTransferBirdsList = () => {
    this.setState((state) => {
      const birdsFilter = state.availableBirds.filter((bird) => bird.bird_id === state.birdId);

      return {
        birdId: '',
        birds: [...state.birds, ...birdsFilter],
        availableBirds: state.availableBirds.filter((bird) => bird.bird_id !== state.birdId)
      };
    });
  };

  handleRemoveBirdTransferBirdsList = (birdId) => {
    this.setState((state) => {
      const birdsFilter = state.birds.filter((bird) => bird.bird_id === birdId);

      return {
        birds: state.birds.filter((bird) => bird.bird_id !== birdId),
        availableBirds: [...birdsFilter, ...state.availableBirds]
      };
    });
  };

  renderInformationText = (item, text) => {
    const { classes } = this.props;

    return (
      <div className={classes.descriptionItemContainer}>
        <div className={classes.itemIcon}>{item}</div>
        <Typography variant={'body1'} className={classes.itemText}>
          {text}
        </Typography>
      </div>
    );
  };

  renderRequesterForm = () => {
    const { intl } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} />
        <Grid item xs={12}>
          <Select
            showEmpty={true}
            required={true}
            name={'requesterCountryId'}
            label={intl.formatMessage({ id: 'country' })}
            value={this.state.requesterCountryId}
            onChange={this.handleSelectChange}
            disabled={this.state.isFetching}
            options={getCountries(this.state.requesterLocale)}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required={true}
            name={'requesterName'}
            type={'text'}
            label={intl.formatMessage({ id: 'name' })}
            value={this.state.requesterName}
            onChange={this.handleInputChange}
            error={!!this.state.errors.requesterName}
            helperText={this.state.errors.requesterName || ''}
            disabled={this.state.isFetching}
            inputProps={{ maxLength: 250 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required={true}
            name={'requesterEmail'}
            type={'email'}
            label={intl.formatMessage({ id: 'email' })}
            value={this.state.requesterEmail}
            onChange={this.handleInputChange}
            error={!!this.state.errors.requesterEmail}
            helperText={this.state.errors.requesterEmail || ''}
            disabled={this.state.isFetching}
            inputProps={{ maxLength: 250 }}
            placeholder={'example@email.com'}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required={true}
            name={'requesterContact'}
            type={'tel'}
            label={intl.formatMessage({ id: 'contact' })}
            value={this.state.requesterContact}
            onChange={this.handleInputChange}
            error={!!this.state.errors.requesterContact}
            helperText={this.state.errors.requesterContact || ''}
            disabled={this.state.isFetching}
            inputProps={{ maxLength: 35 }}
            placeholder={'+111 123 456 789'}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required={true}
            name={'requesterAddress'}
            type={'text'}
            label={intl.formatMessage({ id: 'address' })}
            value={this.state.requesterAddress}
            onChange={this.handleInputChange}
            error={!!this.state.errors.requesterAddress}
            helperText={this.state.errors.requesterAddress || ''}
            disabled={this.state.isFetching}
            inputProps={{ maxLength: 1000 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            required={true}
            name={'requesterZipCode'}
            type={'text'}
            label={intl.formatMessage({ id: 'zipCode' })}
            value={this.state.requesterZipCode}
            onChange={this.handleInputChange}
            error={!!this.state.errors.requesterZipCode}
            helperText={this.state.errors.requesterZipCode || ''}
            disabled={this.state.isFetching}
            inputProps={{ maxLength: 15 }}
          />
        </Grid>
        {(this.state.requesterCountryId === 176 || this.state.requesterCountryId === '176') && (
          <React.Fragment>
            <Grid item xs={12}>
              <Input
                required={true}
                name={'requesterPtIcnf'}
                type={'text'}
                label={'Número ICNF'}
                value={this.state.requesterPtIcnf}
                onChange={this.handleInputChange}
                error={!!this.state.errors.requesterPtIcnf}
                helperText={this.state.errors.requesterPtIcnf || ''}
                disabled={this.state.isFetching}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                required={true}
                name={'requesterPtCC'}
                type={'text'}
                label={'BI / CC'}
                value={this.state.requesterPtCC}
                onChange={this.handleInputChange}
                error={!!this.state.errors.requesterPtCC}
                helperText={this.state.errors.requesterPtCC || ''}
                disabled={this.state.isFetching}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                required={true}
                name={'requesterPtNif'}
                type={'text'}
                label={'NIF'}
                value={this.state.requesterPtNif}
                onChange={this.handleInputChange}
                error={!!this.state.errors.requesterPtNif}
                helperText={this.state.errors.requesterPtNif || ''}
                disabled={this.state.isFetching}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    );
  };

  renderReceptorForm = () => {
    const { intl, classes } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} />
        <Grid item xs={12}>
          <div className={classes.receptorContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  showEmpty={false}
                  required={true}
                  name={'isRegistered'}
                  label={intl.formatMessage({ id: 'isRegistered' })}
                  value={this.state.isRegistered}
                  onChange={this.handleSelectChange}
                  disabled={this.state.isFetching}
                  options={[
                    {
                      key: 'yes',
                      value: 'true',
                      name: intl.formatMessage({ id: 'yes' })
                    },
                    {
                      key: 'no',
                      value: 'false',
                      name: intl.formatMessage({ id: 'no' })
                    }
                  ]}
                />
              </Grid>
              {this.state.isRegistered === 'true' && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Input
                      autoComplete={'off'}
                      required={false}
                      name={'receptorEmail'}
                      type={'email'}
                      label={intl.formatMessage({ id: 'email' })}
                      value={this.state.receptorEmail}
                      onChange={this.handleInputChange}
                      error={!!this.state.errors.receptorEmail}
                      helperText={this.state.errors.receptorEmail || ''}
                      disabled={this.state.isFetching}
                      inputProps={{ maxLength: 250 }}
                      placeholder={'example@email.com'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      autoComplete={'off'}
                      required={false}
                      name={'receptorCode'}
                      type={'text'}
                      label={intl.formatMessage({ id: 'receptorCode' })}
                      value={this.state.receptorCode}
                      onChange={this.handleInputChange}
                      error={!!this.state.errors.receptorCode}
                      helperText={
                        this.state.errors.receptorCode ||
                        intl.formatMessage({ id: 'receptorCodeHelperText' })
                      }
                      disabled={this.state.isFetching}
                      inputProps={{ minLength: 4, maxLength: 6 }}
                      placeholder={'123456'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type={'button'}
                      label={intl.formatMessage({ id: 'receptorGetData' })}
                      variant={'contained'}
                      color={'primary'}
                      onClick={() => {
                        this.findBreederBasedOnEmailAndAccessCode();
                      }}
                      disabled={
                        this.state.isFetching ||
                        this.state.receptorEmail === '' ||
                        this.state.receptorCode === '' ||
                        this.state.receptorEmail === this.state.requesterEmail
                      }
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Input
            required={true}
            name={'receptorName'}
            type={'text'}
            label={intl.formatMessage({ id: 'name' })}
            value={this.state.receptorName}
            onChange={this.handleInputChange}
            error={!!this.state.errors.receptorName}
            helperText={this.state.errors.receptorName || ''}
            disabled={this.state.isFetching}
            inputProps={{ maxLength: 250 }}
          />
        </Grid>
        {!this.state.isRegistered && (
          <Grid item xs={12}>
            <Input
              autoComplete={'off'}
              required={true}
              name={'receptorEmail'}
              type={'email'}
              label={intl.formatMessage({ id: 'email' })}
              value={this.state.receptorEmail}
              onChange={this.handleInputChange}
              error={!!this.state.errors.receptorEmail}
              helperText={this.state.errors.receptorEmail || ''}
              disabled={this.state.isFetching}
              inputProps={{ maxLength: 250 }}
              placeholder={'example@email.com'}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Input
            required={true}
            name={'receptorContact'}
            type={'tel'}
            label={intl.formatMessage({ id: 'contact' })}
            value={this.state.receptorContact}
            onChange={this.handleInputChange}
            error={!!this.state.errors.receptorContact}
            helperText={this.state.errors.receptorContact || ''}
            disabled={this.state.isFetching}
            inputProps={{ maxLength: 35 }}
            placeholder={'+111 123 456 789'}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            required={true}
            name={'receptorAddress'}
            type={'text'}
            label={intl.formatMessage({ id: 'address' })}
            value={this.state.receptorAddress}
            onChange={this.handleInputChange}
            error={!!this.state.errors.receptorAddress}
            helperText={this.state.errors.receptorAddress || ''}
            disabled={this.state.isFetching}
            inputProps={{ maxLength: 1000 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            autoComplete={'off'}
            required={true}
            name={'receptorZipCode'}
            type={'text'}
            label={intl.formatMessage({ id: 'zipCode' })}
            value={this.state.receptorZipCode}
            onChange={this.handleInputChange}
            error={!!this.state.errors.receptorZipCode}
            helperText={this.state.errors.receptorZipCode || ''}
            disabled={this.state.isFetching}
            inputProps={{ maxLength: 15 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            showEmpty={true}
            required={true}
            name={'receptorCountryId'}
            label={intl.formatMessage({ id: 'country' })}
            value={this.state.receptorCountryId}
            onChange={this.handleSelectChange}
            disabled={this.state.isFetching}
            options={getCountries(this.state.requesterLocale)}
          />
        </Grid>
        {(this.state.requesterCountryId === 176 || this.state.requesterCountryId === '176') && (
          <React.Fragment>
            <Grid item xs={12}>
              <Input
                required={false}
                name={'receptorPtIcnf'}
                type={'text'}
                label={'Número ICNF'}
                value={this.state.receptorPtIcnf}
                onChange={this.handleInputChange}
                error={!!this.state.errors.receptorPtIcnf}
                helperText={this.state.errors.receptorPtIcnf || ''}
                disabled={this.state.isFetching}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                required={
                  // only required if the RECEPTOR country is Portugal
                  this.state.receptorCountryId === 176 || this.state.receptorCountryId === '176'
                }
                name={'receptorPtCC'}
                type={'text'}
                label={'BI / CC'}
                value={this.state.receptorPtCC}
                onChange={this.handleInputChange}
                error={!!this.state.errors.receptorPtCC}
                helperText={this.state.errors.receptorPtCC || ''}
                disabled={this.state.isFetching}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                required={
                  // only required if the RECEPTOR country is Portugal
                  this.state.receptorCountryId === 176 || this.state.receptorCountryId === '176'
                }
                name={'receptorPtNif'}
                type={'text'}
                label={'NIF'}
                value={this.state.receptorPtNif}
                onChange={this.handleInputChange}
                error={!!this.state.errors.receptorPtNif}
                helperText={this.state.errors.receptorPtNif || ''}
                disabled={this.state.isFetching}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    );
  };

  renderAddBirdForm = () => {
    const { intl } = this.props;

    return (
      <Grid container spacing={2} alignContent={'center'} alignItems={'center'}>
        <Grid item xs={12} md={4}>
          <Select
            showEmpty={true}
            required={false}
            name={'specieId'}
            label={intl.formatMessage({ id: 'species' })}
            value={this.state.specieId}
            onChange={this.handleSelectChange}
            disabled={this.state.isFetching}
            options={this.state.species.map((specie) => {
              return {
                key: specie.id,
                value: specie.id,
                name: specie.name
              };
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            showEmpty={true}
            required={false}
            name={'birdId'}
            label={intl.formatMessage({ id: 'birds' })}
            value={this.state.birdId}
            onChange={this.handleSelectChange}
            disabled={this.state.isFetching}
            options={this.state.availableBirds.map((bird) => {
              return {
                key: bird.bird_id,
                value: bird.bird_id,
                name: bird.ring
              };
            })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            type={'button'}
            label={intl.formatMessage({ id: 'addBird' })}
            size={'large'}
            color={'primary'}
            variant={'contained'}
            disabled={this.state.isFetching || !(this.state.specieId && this.state.birdId)}
            onClick={this.handleAddBirdToTransferBirdsList}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { intl, classes } = this.props;

    const breadcrumbs = [
      {
        title: intl.formatMessage({ id: 'transfers' }),
        active: false,
        href: ROUTES.BREEDER_TRANSFER_REQUESTS,
        onClick: () => {
          this.props.history.push(ROUTES.BREEDER_TRANSFER_REQUESTS);
        }
      },
      {
        title: intl.formatMessage({ id: 'createTransfer' }),
        translateTitle: true,
        active: true
      }
    ];

    return (
      <Content
        isLoading={this.state.isLoading}
        isFetching={this.state.isFetching}
        breadcrumbs={breadcrumbs}
      >
        <form autoComplete={'on'} onSubmit={this.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {this.renderInformationText(
                      <AlertTriangle size={18} />,
                      intl.formatMessage(
                        { id: 'createTransferInfo1' },
                        {
                          email: (
                            <a
                              href={'mailto:info@myownbirds.com'}
                              target={'_top'}
                              className={classes.informationTextMailTo}
                            >
                              info@myownbirds.com
                            </a>
                          )
                        }
                      )
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {this.renderInformationText(
                      <ChevronRight size={18} />,
                      intl.formatMessage({ id: 'createTransferInfo2' })
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {this.renderInformationText(
                      <ChevronRight size={18} />,
                      intl.formatMessage({ id: 'createTransferInfo3' })
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      required={true}
                      name={'transferNumber'}
                      type={'text'}
                      label={intl.formatMessage({ id: 'transferNumber' })}
                      value={this.state.transferNumber}
                      onChange={this.handleInputChange}
                      error={!!this.state.errors.transferNumber}
                      helperText={
                        this.state.errors.transferNumber ||
                        intl.formatMessage({ id: 'createTransferInfo4' })
                      }
                      disabled={this.state.isFetching}
                      inputProps={{ maxLength: 25 }}
                      placeholder={'2021 1'}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                direction={'row'}
                justifyContent={'center'}
                alignItems={'stretch'}
              >
                <Grid item xs={12} md={6}>
                  <Paper title={intl.formatMessage({ id: 'holder' })}>
                    {this.renderRequesterForm()}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper title={intl.formatMessage({ id: 'newOwner' })}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} />
                      <Grid item xs={12}>
                        {this.renderInformationText(
                          <ChevronRight size={18} />,
                          intl.formatMessage({ id: 'createTransferInfo5' })
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderInformationText(
                          <ChevronRight size={18} />,
                          intl.formatMessage({ id: 'createTransferInfo6' })
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {this.renderReceptorForm()}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper title={'birds'}>
                <Grid container spacing={1}>
                  <Grid item xs={12} />
                  <Grid item xs={12}>
                    {this.renderAddBirdForm()}
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={12}>
                    <Table
                      noDataMessage={intl.formatMessage({ id: 'noBirds' })}
                      webHeaders={[
                        { title: intl.formatMessage({ id: 'image' }), align: 'left' },
                        { title: intl.formatMessage({ id: 'ring' }), align: 'left' },
                        { title: intl.formatMessage({ id: 'year' }), align: 'left' },
                        { title: intl.formatMessage({ id: 'gender' }), align: 'left' },
                        { title: intl.formatMessage({ id: 'specie' }), align: 'left' },
                        {
                          title: intl.formatMessage({ id: 'country' }),
                          align: 'left'
                        },
                        { title: '', align: 'left' }
                      ]}
                      webData={this.state.birds.map((item) => [
                        <BirdAvatar images={item.image ? [item.image] : []} />,
                        item.ring,
                        item.year,
                        getGenderName(item.gender, intl),
                        getMutationName(item, intl),
                        getCountryName(item.country.id, this.state.requesterLocale),
                        <TooltipIconButton
                          title={'remove'}
                          iconName={'trash'}
                          handleClick={() => this.handleRemoveBirdTransferBirdsList(item.bird_id)}
                        />
                      ])}
                      renderMobile={true}
                      mobileData={this.state.birds.map((item) => (
                        <BirdItem
                          imagePreviewPath={item.image ? item.image : null}
                          ring={item.ring}
                          year={item.year}
                          gender={getGenderName(item.gender, intl)}
                          mutationName={getMutationName(item, intl)}
                          countryName={getCountryName(item.country.id, this.state.requesterLocale)}
                          removeClick={() => this.handleRemoveBirdTransferBirdsList(item.bird_id)}
                        />
                      ))}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.formButtonsContainer}>
                <Button
                  type={'button'}
                  label={intl.formatMessage({ id: 'cancel' })}
                  variant={'outlined'}
                  color={'secondary'}
                  onClick={() => {
                    this.props.history.push(ROUTES.BREEDER_TRANSFER_REQUESTS);
                  }}
                  disabled={this.state.isFetching}
                />
                <Button
                  type={'submit'}
                  label={intl.formatMessage({ id: 'confirm' })}
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => {}}
                  disabled={this.state.isFetching || this.state.birds.length === 0}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(injectIntl(CreateTransfer))));
