import React from 'react';
import PropTypes from 'prop-types';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// MATERIAL UI
import { Grid } from '@material-ui/core';

// SPINE UI
import { Input, Select } from '@oliveirahugo68/spine-ui';

class EditAccount extends React.PureComponent {
  render() {
    const { intl } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            inputRef={this.props.nameInputRef}
            name={'name'}
            type={'text'}
            autoComplete={'off'}
            label={intl.formatMessage({ id: 'name' })}
            value={this.props.name}
            onChange={this.props.handleInputChange}
            error={!!this.props.errors.name}
            helperText={this.props.errors.name || ''}
            required={true}
            disabled={this.props.isLoading}
            inputProps={{ maxLength: 250 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            showEmpty={false}
            name={'locale'}
            label={intl.formatMessage({ id: 'language' })}
            required={true}
            value={this.props.locale}
            onChange={this.props.handleSelectChange}
            error={!!this.props.errors.locale}
            helperText={this.props.errors.locale || ''}
            disabled={this.props.isLoading}
            autoComplete={'off'}
            options={[
              {
                key: 'pt_PT',
                value: 'pt_PT',
                name: 'Português'
              },
              {
                key: 'en_GB',
                value: 'en_GB',
                name: 'English'
              },
              {
                key: 'es_ES',
                value: 'es_ES',
                name: 'Español'
              },
              {
                key: 'fr_FR',
                value: 'fr_FR',
                name: 'Français'
              },
              {
                key: 'it_IT',
                value: 'it_IT',
                name: 'Italiano'
              }
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            name={'contact'}
            type={'tel'}
            label={intl.formatMessage({ id: 'contact' })}
            value={this.props.contact}
            onChange={this.props.handleInputChange}
            error={!!this.props.errors.contact}
            helperText={this.props.errors.contact || ''}
            disabled={this.props.isLoading}
            inputProps={{ maxLength: 35 }}
            placeholder={'+111 123 456 789'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            name={'country'}
            label={intl.formatMessage({ id: 'country' })}
            value={this.props.country}
            onChange={this.props.handleSelectChange}
            error={!!this.props.errors.country}
            helperText={this.props.errors.country || ''}
            disabled={this.props.isLoading}
            autoComplete={'off'}
            options={this.props.allCountries}
            required={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            name={'zipCode'}
            type={'text'}
            label={intl.formatMessage({ id: 'zipCode' })}
            value={this.props.zipCode}
            onChange={this.props.handleInputChange}
            error={!!this.props.errors.zipCode}
            helperText={this.props.errors.zipCode || ''}
            disabled={this.props.isLoading}
            inputProps={{
              maxLength: 10
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name={'address'}
            type={'text'}
            label={intl.formatMessage({ id: 'address' })}
            value={this.props.address}
            onChange={this.props.handleInputChange}
            error={!!this.props.errors.address}
            helperText={this.props.errors.address || ''}
            disabled={this.props.isLoading}
            inputProps={{
              maxLength: 1000
            }}
          />
        </Grid>
        {(this.props.country === 176 || this.props.country === '176') && (
          <React.Fragment>
            <Grid item xs={12}>
              <Input
                required={false}
                name={'ptIcnf'}
                type={'text'}
                label={'Número ICNF'}
                value={this.props.ptIcnf}
                onChange={this.props.handleInputChange}
                error={!!this.props.errors.ptIcnf}
                helperText={this.props.errors.ptIcnf || ''}
                disabled={this.props.isLoading}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                required={false}
                name={'ptCc'}
                type={'text'}
                label={'BI / CC'}
                value={this.props.ptCc}
                onChange={this.props.handleInputChange}
                error={!!this.props.errors.ptCc}
                helperText={this.props.errors.ptCc || ''}
                disabled={this.props.isLoading}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                required={false}
                name={'ptNif'}
                type={'text'}
                label={'NIF'}
                value={this.props.ptNif}
                onChange={this.props.handleInputChange}
                error={!!this.props.errors.ptNif}
                helperText={this.props.errors.ptNif || ''}
                disabled={this.props.isLoading}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    );
  }
}

EditAccount.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  nameInputRef: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  contact: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  country: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  allCountries: PropTypes.array.isRequired,
  ptIcnf: PropTypes.string.isRequired,
  ptNif: PropTypes.string.isRequired,
  ptCc: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired
};

export default injectIntl(EditAccount);
