import { red } from '@material-ui/core/colors';

export default (theme) => ({
  dragAndDropContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '6px',
    border: '2px dashed rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(3),
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
    transition: '0.2s',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.025)'
    },
    cursor: 'pointer'
  },
  dragAndDropTitle: {
    fontSize: '20px'
  },
  dragAndDropMessage: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: 1.4,
    marginTop: theme.spacing(1)
  },
  fileButton: {
    marginTop: theme.spacing(1)
  },
  fileInput: {
    position: 'absolute',
    left: '0',
    top: '0',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    opacity: '0',
    '&:focus': {
      outline: 'none'
    }
  },
  listItem: {
    border: '1px dashed rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',
    backgroundColor: 'rgba(0, 0, 0, 0.025)',
    marginBottom: theme.spacing(1)
  },
  listItemError: {
    borderColor: red[500],
    backgroundColor: red[100]
  },
  listTitle: {
    lineHeight: 1.4,
    fontWeight: 700
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis'
  },
  listTitleError: {
    color: red[800]
  },
  listMessage: {
    lineHeight: 1
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis'
  },
  listMessageError: {
    color: red[800]
  }
});
