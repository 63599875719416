export default [
  // TOTAL of 241 countries
  {
    id: 1,
    name: 'Afghanistan',
    code: 'AF'
  },
  {
    id: 3,
    name: 'Albania',
    code: 'AL'
  },
  {
    id: 4,
    name: 'Algeria',
    code: 'DZ'
  },
  {
    id: 6,
    name: 'Andorra',
    code: 'AD'
  },
  {
    id: 7,
    name: 'Angola',
    code: 'AO'
  },
  {
    id: 8,
    name: 'Anguilla',
    code: 'AI'
  },
  {
    id: 9,
    name: 'Antartide',
    code: 'AQ'
  },
  {
    id: 10,
    name: 'Antigua e Barbuda',
    code: 'AG'
  },
  {
    id: 191,
    name: 'Arabia Saudita',
    code: 'SA'
  },
  {
    id: 11,
    name: 'Argentina',
    code: 'AR'
  },
  {
    id: 12,
    name: 'Armenia',
    code: 'AM'
  },
  {
    id: 13,
    name: 'Aruba',
    code: 'AW'
  },
  {
    id: 14,
    name: 'Australia',
    code: 'AU'
  },
  {
    id: 15,
    name: 'Austria',
    code: 'AT'
  },
  {
    id: 16,
    name: 'Azerbaigian',
    code: 'AZ'
  },
  {
    id: 17,
    name: 'Bahamas',
    code: 'BS'
  },
  {
    id: 18,
    name: 'Bahrein',
    code: 'BH'
  },
  {
    id: 19,
    name: 'Bangladesh',
    code: 'BD'
  },
  {
    id: 20,
    name: 'Barbados',
    code: 'BB'
  },
  {
    id: 22,
    name: 'Belgio',
    code: 'BE'
  },
  {
    id: 23,
    name: 'Belize',
    code: 'BZ'
  },
  {
    id: 24,
    name: 'Benin',
    code: 'BJ'
  },
  {
    id: 25,
    name: 'Bermuda',
    code: 'BM'
  },
  {
    id: 26,
    name: 'Bhutan',
    code: 'BT'
  },
  {
    id: 21,
    name: 'Bielorussia',
    code: 'BY'
  },
  {
    id: 150,
    name: 'Birmania',
    code: 'MM'
  },
  {
    id: 27,
    name: 'Bolivia',
    code: 'BO'
  },
  {
    id: 28,
    name: 'Bosnia ed Erzegovina',
    code: 'BA'
  },
  {
    id: 29,
    name: 'Botswana',
    code: 'BW'
  },
  {
    id: 31,
    name: 'Brasile',
    code: 'BR'
  },
  {
    id: 33,
    name: 'Brunei',
    code: 'BN'
  },
  {
    id: 34,
    name: 'Bulgaria',
    code: 'BG'
  },
  {
    id: 35,
    name: 'Burkina Faso',
    code: 'BF'
  },
  {
    id: 36,
    name: 'Burundi',
    code: 'BI'
  },
  {
    id: 37,
    name: 'Cambogia',
    code: 'KH'
  },
  {
    id: 38,
    name: 'Camerun',
    code: 'CM'
  },
  {
    id: 39,
    name: 'Canada',
    code: 'CA'
  },
  {
    id: 40,
    name: 'Capo Verde',
    code: 'CV'
  },
  {
    id: 43,
    name: 'Ciad',
    code: 'TD'
  },
  {
    id: 44,
    name: 'Cile',
    code: 'CL'
  },
  {
    id: 45,
    name: 'Cina',
    code: 'CN'
  },
  {
    id: 57,
    name: 'Cipro',
    code: 'CY'
  },
  {
    id: 96,
    name: 'Città del Vaticano',
    code: 'VA'
  },
  {
    id: 48,
    name: 'Colombia',
    code: 'CO'
  },
  {
    id: 49,
    name: 'Comore',
    code: 'KM'
  },
  {
    id: 116,
    name: 'Corea del Nord',
    code: 'KP'
  },
  {
    id: 117,
    name: 'Corea del Sud',
    code: 'KR'
  },
  {
    id: 53,
    name: 'Costa Rica',
    code: 'CR'
  },
  {
    id: 54,
    name: "Costa d'Avorio",
    code: 'CI'
  },
  {
    id: 55,
    name: 'Croazia',
    code: 'HR'
  },
  {
    id: 56,
    name: 'Cuba',
    code: 'CU'
  },
  {
    id: 59,
    name: 'Danimarca',
    code: 'DK'
  },
  {
    id: 61,
    name: 'Dominica',
    code: 'DM'
  },
  {
    id: 63,
    name: 'Ecuador',
    code: 'EC'
  },
  {
    id: 64,
    name: 'Egitto',
    code: 'EG'
  },
  {
    id: 65,
    name: 'El Salvador',
    code: 'SV'
  },
  {
    id: 228,
    name: 'Emirati Arabi Uniti',
    code: 'AE'
  },
  {
    id: 67,
    name: 'Eritrea',
    code: 'ER'
  },
  {
    id: 68,
    name: 'Estonia',
    code: 'EE'
  },
  {
    id: 69,
    name: 'Etiopia',
    code: 'ET'
  },
  {
    id: 72,
    name: 'Figi',
    code: 'FJ'
  },
  {
    id: 173,
    name: 'Filippine',
    code: 'PH'
  },
  {
    id: 73,
    name: 'Finlandia',
    code: 'FI'
  },
  {
    id: 74,
    name: 'Francia',
    code: 'FR'
  },
  {
    id: 71,
    name: 'Fær Øer',
    code: 'FO'
  },
  {
    id: 78,
    name: 'Gabon',
    code: 'GA'
  },
  {
    id: 79,
    name: 'Gambia',
    code: 'GM'
  },
  {
    id: 80,
    name: 'Georgia',
    code: 'GE'
  },
  {
    id: 202,
    name: 'Georgia del Sud e Isole Sandwich Australi',
    code: 'GS'
  },
  {
    id: 81,
    name: 'Germania',
    code: 'DE'
  },
  {
    id: 82,
    name: 'Ghana',
    code: 'GH'
  },
  {
    id: 109,
    name: 'Giamaica',
    code: 'JM'
  },
  {
    id: 110,
    name: 'Giappone',
    code: 'JP'
  },
  {
    id: 83,
    name: 'Gibilterra',
    code: 'GI'
  },
  {
    id: 60,
    name: 'Gibuti',
    code: 'DJ'
  },
  {
    id: 112,
    name: 'Giordania',
    code: 'JO'
  },
  {
    id: 84,
    name: 'Grecia',
    code: 'GR'
  },
  {
    id: 86,
    name: 'Grenada',
    code: 'GD'
  },
  {
    id: 85,
    name: 'Groenlandia',
    code: 'GL'
  },
  {
    id: 87,
    name: 'Guadalupa',
    code: 'GP'
  },
  {
    id: 88,
    name: 'Guam',
    code: 'GU'
  },
  {
    id: 89,
    name: 'Guatemala',
    code: 'GT'
  },
  {
    id: 90,
    name: 'Guernsey',
    code: 'GG'
  },
  {
    id: 91,
    name: 'Guinea',
    code: 'GN'
  },
  {
    id: 66,
    name: 'Guinea Equatoriale',
    code: 'GQ'
  },
  {
    id: 92,
    name: 'Guinea-Bissau',
    code: 'GW'
  },
  {
    id: 93,
    name: 'Guyana',
    code: 'GY'
  },
  {
    id: 75,
    name: 'Guyana francese',
    code: 'GF'
  },
  {
    id: 94,
    name: 'Haiti',
    code: 'HT'
  },
  {
    id: 97,
    name: 'Honduras',
    code: 'HN'
  },
  {
    id: 98,
    name: 'Hong Kong',
    code: 'HK'
  },
  {
    id: 101,
    name: 'India',
    code: 'IN'
  },
  {
    id: 102,
    name: 'Indonesia',
    code: 'ID'
  },
  {
    id: 103,
    name: 'Iran',
    code: 'IR'
  },
  {
    id: 104,
    name: 'Iraq',
    code: 'IQ'
  },
  {
    id: 105,
    name: 'Irlanda',
    code: 'IE'
  },
  {
    id: 100,
    name: 'Islanda',
    code: 'IS'
  },
  {
    id: 30,
    name: 'Isola Bouvet',
    code: 'BV'
  },
  {
    id: 162,
    name: 'Isola Norfolk',
    code: 'NF'
  },
  {
    id: 106,
    name: 'Isola di Man',
    code: 'IM'
  },
  {
    id: 46,
    name: 'Isola di Natale',
    code: 'CX'
  },
  {
    id: 41,
    name: 'Isole Cayman',
    code: 'KY'
  },
  {
    id: 47,
    name: 'Isole Cocos (Keeling)',
    code: 'CC'
  },
  {
    id: 52,
    name: 'Isole Cook',
    code: 'CK'
  },
  {
    id: 70,
    name: 'Isole Falkland',
    code: 'FK'
  },
  {
    id: 95,
    name: 'Isole Heard e McDonald',
    code: 'HM'
  },
  {
    id: 163,
    name: 'Isole Marianne Settentrionali',
    code: 'MP'
  },
  {
    id: 137,
    name: 'Isole Marshall',
    code: 'MH'
  },
  {
    id: 174,
    name: 'Isole Pitcairn',
    code: 'PN'
  },
  {
    id: 199,
    name: 'Isole Salomone',
    code: 'SB'
  },
  {
    id: 238,
    name: 'Isole Vergini americane',
    code: 'VI'
  },
  {
    id: 237,
    name: 'Isole Vergini britanniche',
    code: 'VG'
  },
  {
    id: 231,
    name: 'Isole minori esterne degli Stati Uniti',
    code: 'UM'
  },
  {
    id: 2,
    name: 'Isole Åland',
    code: 'AX'
  },
  {
    id: 107,
    name: 'Israele',
    code: 'IL'
  },
  {
    id: 108,
    name: 'Italia',
    code: 'IT'
  },
  {
    id: 111,
    name: 'Jersey',
    code: 'JE'
  },
  {
    id: 113,
    name: 'Kazakistan',
    code: 'KZ'
  },
  {
    id: 114,
    name: 'Kenya',
    code: 'KE'
  },
  {
    id: 119,
    name: 'Kirghizistan',
    code: 'KG'
  },
  {
    id: 115,
    name: 'Kiribati',
    code: 'KI'
  },
  {
    id: 118,
    name: 'Kuwait',
    code: 'KW'
  },
  {
    id: 120,
    name: 'Laos',
    code: 'LA'
  },
  {
    id: 123,
    name: 'Lesotho',
    code: 'LS'
  },
  {
    id: 121,
    name: 'Lettonia',
    code: 'LV'
  },
  {
    id: 122,
    name: 'Libano',
    code: 'LB'
  },
  {
    id: 124,
    name: 'Liberia',
    code: 'LR'
  },
  {
    id: 125,
    name: 'Libia',
    code: 'LY'
  },
  {
    id: 126,
    name: 'Liechtenstein',
    code: 'LI'
  },
  {
    id: 127,
    name: 'Lituania',
    code: 'LT'
  },
  {
    id: 128,
    name: 'Lussemburgo',
    code: 'LU'
  },
  {
    id: 129,
    name: 'Macao',
    code: 'MO'
  },
  {
    id: 130,
    name: 'Macedonia del Nord',
    code: 'MK'
  },
  {
    id: 131,
    name: 'Madagascar',
    code: 'MG'
  },
  {
    id: 132,
    name: 'Malawi',
    code: 'MW'
  },
  {
    id: 133,
    name: 'Malaysia',
    code: 'MY'
  },
  {
    id: 134,
    name: 'Maldive',
    code: 'MV'
  },
  {
    id: 135,
    name: 'Mali',
    code: 'ML'
  },
  {
    id: 136,
    name: 'Malta',
    code: 'MT'
  },
  {
    id: 148,
    name: 'Marocco',
    code: 'MA'
  },
  {
    id: 138,
    name: 'Martinica',
    code: 'MQ'
  },
  {
    id: 139,
    name: 'Mauritania',
    code: 'MR'
  },
  {
    id: 140,
    name: 'Mauritius',
    code: 'MU'
  },
  {
    id: 141,
    name: 'Mayotte',
    code: 'YT'
  },
  {
    id: 142,
    name: 'Messico',
    code: 'MX'
  },
  {
    id: 143,
    name: 'Micronesia',
    code: 'FM'
  },
  {
    id: 144,
    name: 'Moldavia',
    code: 'MD'
  },
  {
    id: 145,
    name: 'Monaco',
    code: 'MC'
  },
  {
    id: 146,
    name: 'Mongolia',
    code: 'MN'
  },
  {
    id: 147,
    name: 'Montserrat',
    code: 'MS'
  },
  {
    id: 149,
    name: 'Mozambico',
    code: 'MZ'
  },
  {
    id: 151,
    name: 'Namibia',
    code: 'NA'
  },
  {
    id: 152,
    name: 'Nauru',
    code: 'NR'
  },
  {
    id: 153,
    name: 'Nepal',
    code: 'NP'
  },
  {
    id: 158,
    name: 'Nicaragua',
    code: 'NI'
  },
  {
    id: 159,
    name: 'Niger',
    code: 'NE'
  },
  {
    id: 160,
    name: 'Nigeria',
    code: 'NG'
  },
  {
    id: 161,
    name: 'Niue',
    code: 'NU'
  },
  {
    id: 164,
    name: 'Norvegia',
    code: 'NO'
  },
  {
    id: 156,
    name: 'Nuova Caledonia',
    code: 'NC'
  },
  {
    id: 157,
    name: 'Nuova Zelanda',
    code: 'NZ'
  },
  {
    id: 165,
    name: 'Oman',
    code: 'OM'
  },
  {
    id: 154,
    name: 'Paesi Bassi',
    code: 'NL'
  },
  {
    id: 166,
    name: 'Pakistan',
    code: 'PK'
  },
  {
    id: 167,
    name: 'Palau',
    code: 'PW'
  },
  {
    id: 168,
    name: 'Palestina',
    code: 'PS'
  },
  {
    id: 169,
    name: 'Panama',
    code: 'PA'
  },
  {
    id: 170,
    name: 'Papua Nuova Guinea',
    code: 'PG'
  },
  {
    id: 171,
    name: 'Paraguay',
    code: 'PY'
  },
  {
    id: 172,
    name: 'Perù',
    code: 'PE'
  },
  {
    id: 76,
    name: 'Polinesia francese',
    code: 'PF'
  },
  {
    id: 175,
    name: 'Polonia',
    code: 'PL'
  },
  {
    id: 177,
    name: 'Porto Rico',
    code: 'PR'
  },
  {
    id: 176,
    name: 'Portogallo',
    code: 'PT'
  },
  {
    id: 178,
    name: 'Qatar',
    code: 'QA'
  },
  {
    id: 51,
    name: 'RD del Congo',
    code: 'CD'
  },
  {
    id: 229,
    name: 'Regno Unito',
    code: 'GB'
  },
  {
    id: 58,
    name: 'Rep. Ceca',
    code: 'CZ'
  },
  {
    id: 42,
    name: 'Rep. Centrafricana',
    code: 'CF'
  },
  {
    id: 62,
    name: 'Rep. Dominicana',
    code: 'DO'
  },
  {
    id: 50,
    name: 'Rep. del Congo',
    code: 'CG'
  },
  {
    id: 179,
    name: 'Riunione',
    code: 'RE'
  },
  {
    id: 180,
    name: 'Romania',
    code: 'RO'
  },
  {
    id: 182,
    name: 'Ruanda',
    code: 'RW'
  },
  {
    id: 181,
    name: 'Russia',
    code: 'RU'
  },
  {
    id: 240,
    name: 'Sahara Occidentale',
    code: 'EH'
  },
  {
    id: 184,
    name: 'Saint Kitts e Nevis',
    code: 'KN'
  },
  {
    id: 185,
    name: 'Saint Lucia',
    code: 'LC'
  },
  {
    id: 187,
    name: 'Saint Vincent e Grenadine',
    code: 'VC'
  },
  {
    id: 186,
    name: 'Saint-Pierre e Miquelon',
    code: 'PM'
  },
  {
    id: 188,
    name: 'Samoa',
    code: 'WS'
  },
  {
    id: 5,
    name: 'Samoa Americane',
    code: 'AS'
  },
  {
    id: 189,
    name: 'San Marino',
    code: 'SM'
  },
  {
    id: 183,
    name: "Sant'Elena, Ascensione e Tristan da Cunha",
    code: 'SH'
  },
  {
    id: 192,
    name: 'Senegal',
    code: 'SN'
  },
  {
    id: 194,
    name: 'Seychelles',
    code: 'SC'
  },
  {
    id: 195,
    name: 'Sierra Leone',
    code: 'SL'
  },
  {
    id: 196,
    name: 'Singapore',
    code: 'SG'
  },
  {
    id: 211,
    name: 'Siria',
    code: 'SY'
  },
  {
    id: 197,
    name: 'Slovacchia',
    code: 'SK'
  },
  {
    id: 198,
    name: 'Slovenia',
    code: 'SI'
  },
  {
    id: 200,
    name: 'Somalia',
    code: 'SO'
  },
  {
    id: 203,
    name: 'Spagna',
    code: 'ES'
  },
  {
    id: 204,
    name: 'Sri Lanka',
    code: 'LK'
  },
  {
    id: 230,
    name: 'Stati Uniti',
    code: 'US'
  },
  {
    id: 201,
    name: 'Sudafrica',
    code: 'ZA'
  },
  {
    id: 205,
    name: 'Sudan',
    code: 'SD'
  },
  {
    id: 206,
    name: 'Suriname',
    code: 'SR'
  },
  {
    id: 207,
    name: 'Svalbard e Jan Mayen',
    code: 'SJ'
  },
  {
    id: 209,
    name: 'Svezia',
    code: 'SE'
  },
  {
    id: 210,
    name: 'Svizzera',
    code: 'CH'
  },
  {
    id: 208,
    name: 'Swaziland',
    code: 'SZ'
  },
  {
    id: 190,
    name: 'São Tomé e Príncipe',
    code: 'ST'
  },
  {
    id: 213,
    name: 'Tagikistan',
    code: 'TJ'
  },
  {
    id: 212,
    name: 'Taiwan',
    code: 'TW'
  },
  {
    id: 214,
    name: 'Tanzania',
    code: 'TZ'
  },
  {
    id: 77,
    name: 'Terre australi e antartiche francesi',
    code: 'TF'
  },
  {
    id: 32,
    name: "Territorio britannico dell'Oceano Indiano",
    code: 'IO'
  },
  {
    id: 215,
    name: 'Thailandia',
    code: 'TH'
  },
  {
    id: 216,
    name: 'Timor Est',
    code: 'TL'
  },
  {
    id: 217,
    name: 'Togo',
    code: 'TG'
  },
  {
    id: 218,
    name: 'Tokelau',
    code: 'TK'
  },
  {
    id: 219,
    name: 'Tonga',
    code: 'TO'
  },
  {
    id: 220,
    name: 'Trinidad e Tobago',
    code: 'TT'
  },
  {
    id: 221,
    name: 'Tunisia',
    code: 'TN'
  },
  {
    id: 222,
    name: 'Turchia',
    code: 'TR'
  },
  {
    id: 223,
    name: 'Turkmenistan',
    code: 'TM'
  },
  {
    id: 224,
    name: 'Turks e Caicos',
    code: 'TC'
  },
  {
    id: 225,
    name: 'Tuvalu',
    code: 'TV'
  },
  {
    id: 227,
    name: 'Ucraina',
    code: 'UA'
  },
  {
    id: 226,
    name: 'Uganda',
    code: 'UG'
  },
  {
    id: 99,
    name: 'Ungheria',
    code: 'HU'
  },
  {
    id: 232,
    name: 'Uruguay',
    code: 'UY'
  },
  {
    id: 233,
    name: 'Uzbekistan',
    code: 'UZ'
  },
  {
    id: 234,
    name: 'Vanuatu',
    code: 'VU'
  },
  {
    id: 235,
    name: 'Venezuela',
    code: 'VE'
  },
  {
    id: 236,
    name: 'Vietnam',
    code: 'VN'
  },
  {
    id: 239,
    name: 'Wallis e Futuna',
    code: 'WF'
  },
  {
    id: 241,
    name: 'Yemen',
    code: 'YE'
  },
  {
    id: 242,
    name: 'Zambia',
    code: 'ZM'
  },
  {
    id: 243,
    name: 'Zimbabwe',
    code: 'ZW'
  }
];
