import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// SPINE UI
import { Input, Select } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import MuiPickersUtilsProvider from '../../../../inputs/MuiPickersUtilsProvider';
import styles from './styles';

class EditBirdForm extends React.PureComponent {
  render() {
    const { intl } = this.props;

    return (
      <MuiPickersUtilsProvider>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Input
              required={true}
              name={'ring'}
              type={'text'}
              label={intl.formatMessage({ id: 'ring' })}
              value={this.props.ring}
              onChange={this.props.handleInputChange}
              error={!!this.props.errors.ring}
              helperText={this.props.errors.ring || ''}
              disabled={true}
              placeholder={'ABC 1'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              showEmpty={false}
              required={true}
              name={'gender'}
              label={intl.formatMessage({ id: 'gender' })}
              value={this.props.gender}
              onChange={this.props.handleSelectChange}
              disabled={this.props.isLoading || this.props.bird.gender !== 'U'}
              options={[
                {
                  key: 'M',
                  value: 'M',
                  name: 'M'
                },
                {
                  key: 'F',
                  value: 'F',
                  name: 'F'
                },
                {
                  key: 'U',
                  value: 'U',
                  name: intl.formatMessage({ id: 'unknown' })
                }
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              required={false}
              name={'comments'}
              type={'text'}
              multiline={true}
              label={intl.formatMessage({ id: 'observations' })}
              value={this.props.comments}
              onChange={this.props.handleInputChange}
              error={!!this.props.errors.comments}
              helperText={this.props.errors.comments || ''}
              disabled={this.props.isLoading}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    );
  }
}

EditBirdForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  bird: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  // form
  ring: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  comments: PropTypes.string.isRequired
};

export default withStyles(styles)(injectIntl(EditBirdForm));
