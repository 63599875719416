import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// REACT ROUTER DOM
import { Link } from 'react-router-dom';

// APP IMPORTS
import ROUTES from '../../../../../../../../utils/routes';
import styles from './styles';

class PublicFooter extends React.PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.footerContent}>
        <Typography variant={'h6'} className={classes.footerTitle}>
          {`Copyright © ${new Date().getFullYear()} MyOwnBirds`}
        </Typography>
        <Typography variant={'h6'} align={'right'} className={classes.footerTitle}>
          <Link to={ROUTES.TERMS_AND_CONDITIONS} className={classes.link}>
            Terms and Conditions
          </Link>
          {' | '}
          <Link to={ROUTES.PRIVACY_POLICY} className={classes.link}>
            Privacy Policy
          </Link>
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(PublicFooter));
