// UTILS
import { getColors } from '../../../../../../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  container: {
    zIndex: 1000,
    backgroundColor: colors.background
  },
  languageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '25px'
  },
  languageText: {
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: theme.spacing(1),
    color: '#000',
    fontWeight: '600',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  }
});
