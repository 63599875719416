import React from 'react';
import { connect } from 'react-redux';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Avatar, CircularProgress, Container, Grid, Typography } from '@material-ui/core';

// SPINE UI
import { Input, Checkbox } from '@oliveirahugo68/spine-ui';

// GOOGLE RECAPTCHA
import ReCAPTCHA from 'react-google-recaptcha';

// REACT FEATHER
import { X, Check } from 'react-feather';

// REACT ROUTER
import { Link } from 'react-router-dom';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// YUP VALIDATOR
import * as Yup from 'yup';

// APP IMPORTS
import { fetchApi } from '../../../utils/apiSettings';
import { setAppLocale } from '../../../store/actions/appActions';
import { setUserToken } from '../../../utils/userSettings';
import ROUTES from '../../../utils/routes';
import styles from './styles';

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      // form
      name: '',
      email: '',
      password: '',
      recaptchaToken: '',
      registrationCode: '',
      acceptTermsAndConditionsRegister: false,

      registerError: '',
      registerSuccess: false,
      registrationCodeForm: false,
      errors: {}
    };

    this.recaptchaRef = React.createRef();
    this.registrationCodeRef = React.createRef();
    this.nameInputRef = React.createRef();
  }

  componentDidMount() {
    this.nameInputRef.current.focus();
  }

  handleInputChange = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    this.setState({
      [input.name]: value,
      errors: {}
    });
  };

  handleRecaptchaChange = (value) => {
    this.setState({
      recaptchaToken: value
    });
  };

  handleExpiredRecaptcha = () => {
    this.setState({
      recaptchaToken: ''
    });
  };

  handleOnSubmit = async (event) => {
    event.preventDefault();

    const { intl } = this.props;
    const dataToSubmit = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      recaptcha_token: this.state.recaptchaToken,
      register_code: this.state.registrationCode
    };

    const schema = Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string()
        .email(intl.formatMessage({ id: 'invalidEmail' }))
        .required(intl.formatMessage({ id: 'emailIsRequired' })),
      password: Yup.string()
        .min(5, intl.formatMessage({ id: 'invalidPassword' }))
        .required(intl.formatMessage({ id: 'passwordIsRequired' })),
      recaptcha_token: Yup.string().required(),
      register_code: Yup.string().notRequired()
    });

    try {
      await schema.validate(dataToSubmit, { abortEarly: false });

      this.setState(
        {
          isLoading: true
        },
        () => {
          if (this.state.registrationCodeForm) {
            fetchApi(
              'patch',
              '/v1/accounts/register',
              {},
              {
                email: dataToSubmit.email,
                password: dataToSubmit.password,
                register_code: dataToSubmit.register_code
              },
              false,
              (response) => {
                const { data } = response;

                setUserToken(data.jwt_token, true);

                window.location.reload();
              },
              (error) => {
                const { response } = error;

                if (response.status === 400) {
                  this.setState({
                    isLoading: false,
                    registerError: 'registerErrorStripeProblem',
                    registerSuccess: false
                  });
                } else if (response.status === 404) {
                  this.setState({
                    isLoading: false,
                    registerError: 'registerErrorConfirmationRegistrationCode',
                    registerSuccess: false
                  });
                } else {
                  this.setState({
                    isLoading: false,
                    registerError: 'serverErrorMessage',
                    registerSuccess: false
                  });
                }
              }
            );
          } else {
            // transform user name (remove spaces and capitalize first letter)
            const toUserName = (phrase) => {
              return phrase
                .toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
            };

            fetchApi(
              'post',
              '/v1/accounts/register',
              {},
              {
                name: toUserName(dataToSubmit.name.trim()),
                email: dataToSubmit.email.trim(),
                recaptcha_token: dataToSubmit.recaptcha_token,
                locale: this.props.app.locale ? this.props.app.locale : 'en_GB'
              },
              false,
              () => {
                this.setState(
                  {
                    isLoading: false,
                    registerSuccess: true,
                    registerError: '',
                    registrationCodeForm: true
                  },
                  () => this.registrationCodeRef.current.focus()
                );
              },
              (error) => {
                const { response } = error;
                if (response.status === 400) {
                  if (response.data.message === 'This email is already being used.') {
                    this.setState({
                      isLoading: false,
                      registerError: 'registerErrorEmailAlreadyUsed',
                      registerSuccess: false
                    });
                  } else {
                    this.setState({
                      isLoading: false,
                      registerError: 'registerErrorInvalidRecaptchaToken',
                      registerSuccess: false
                    });
                  }
                } else {
                  this.setState({
                    isLoading: false,
                    registerError: 'serverErrorMessage',
                    registerSuccess: false
                  });
                }
                this.recaptchaRef.reset();
              }
            );
          }
        }
      );
    } catch (err) {
      this.recaptchaRef.reset();

      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        this.setState({
          errors: errorMessages
        });
      }
    }
  };

  renderFeatures = () => {
    const { intl, classes } = this.props;

    const features = [
      'manageBirdsStock',
      'manageCouples',
      'manageClutches',
      'efficientSearch',
      'birdProfile',
      'simpleTransfer'
    ];

    return (
      <div className={classes.featuresContainer}>
        <Grid container spacing={2}>
          {features.map((feature) => (
            <Grid key={feature} item xs={12}>
              <div className={classes.descriptionItemContainer}>
                <div className={classes.itemIcon}>
                  <Check size={18} />
                </div>
                <Typography variant={'h6'} className={classes.itemText}>
                  {intl.formatMessage({ id: feature })}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  renderLeft = () => {
    const { intl, classes } = this.props;

    return (
      <div className={classes.formLeftTopContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h4'} className={classes.perfectPlatformTitle}>
              {intl.formatMessage({ id: 'perfectPlatform' })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'body1'} className={classes.ourMissionText}>
              {intl.formatMessage({ id: 'ourMission' })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {this.renderFeatures()}
          </Grid>
        </Grid>
      </div>
    );
  };

  renderForm = () => {
    const { intl, classes } = this.props;

    return (
      <Grid container spacing={4}>
        {this.state.registerError !== '' && (
          <Grid item xs={12}>
            <div className={classes.registerError}>
              <Avatar className={classes.errorAvatar}>
                <X size={15} />
              </Avatar>
              <Typography variant={'h6'} color={'primary'} className={classes.textErrorInformation}>
                {intl.formatMessage({ id: this.state.registerError })}
              </Typography>
            </div>
          </Grid>
        )}
        {this.state.registerSuccess && (
          <Grid item xs={12}>
            <div className={classes.registerSuccess}>
              <Avatar className={classes.successAvatar}>
                <Check size={15} />
              </Avatar>
              <Typography
                variant={'h6'}
                color={'primary'}
                className={classes.textSuccessInformation}
              >
                {intl.formatMessage({
                  id: 'registerSuccess'
                })}
              </Typography>
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <Input
            inputRef={this.nameInputRef}
            name={'name'}
            type={'text'}
            autoComplete={'name'}
            label={intl.formatMessage({ id: 'name' })}
            value={this.state.name}
            onChange={this.handleInputChange}
            required={true}
            disabled={this.state.isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name={'email'}
            type={'email'}
            autoComplete={'email'}
            label={intl.formatMessage({ id: 'email' })}
            value={this.state.email}
            onChange={this.handleInputChange}
            error={!!this.state.errors.email}
            helperText={this.state.errors.email || ''}
            required={true}
            disabled={this.state.isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name={'password'}
            type={'password'}
            autoComplete={'current-password'}
            label={intl.formatMessage({ id: 'password' })}
            value={this.state.password}
            onChange={this.handleInputChange}
            error={!!this.state.errors.password}
            helperText={this.state.errors.password || ''}
            required={true}
            disabled={this.state.isLoading}
            inputProps={{ minLength: 5 }}
          />
        </Grid>
        <Grid item xs={12}>
          <ReCAPTCHA
            ref={(el) => {
              this.recaptchaRef = el;
            }}
            onChange={this.handleRecaptchaChange}
            onErrored={this.handleExpiredRecaptcha}
            onExpired={this.handleExpiredRecaptcha}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          />
          <Checkbox
            name={'acceptTermsAndConditionsRegister'}
            label={
              <span>
                {intl.formatMessage({ id: 'acceptTermsAndConditionsRegister' })}
                <Link
                  target={'_blank'}
                  to={ROUTES.TERMS_AND_CONDITIONS}
                  className={classes.termsAndConditionsTextBold}
                >
                  {intl.formatMessage({ id: 'termsAndConditions' })}
                </Link>
                .
              </span>
            }
            disabled={this.state.isLoading}
            value={this.state.acceptTermsAndConditionsRegister}
            onChange={this.handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <button
            type={'submit'}
            className={classes.submitButton}
            disabled={!this.state.acceptTermsAndConditionsRegister}
          >
            {this.state.isLoading ? (
              <CircularProgress size={20} />
            ) : (
              intl.formatMessage({ id: 'createAccount' })
            )}
          </button>
        </Grid>
      </Grid>
    );
  };

  renderRegistrationCodeForm = () => {
    const { intl, classes } = this.props;

    return (
      <Grid container spacing={4}>
        {this.state.registerError !== '' && (
          <Grid item xs={12}>
            <div className={classes.registerError}>
              <Avatar className={classes.errorAvatar}>
                <X size={15} />
              </Avatar>
              <Typography variant={'h6'} color={'primary'} className={classes.textErrorInformation}>
                {intl.formatMessage({ id: this.state.registerError })}
              </Typography>
            </div>
          </Grid>
        )}
        {this.state.registerSuccess && (
          <Grid item xs={12}>
            <div className={classes.registerSuccess}>
              <Avatar className={classes.successAvatar}>
                <Check size={15} />
              </Avatar>
              <Typography
                variant={'h6'}
                color={'primary'}
                className={classes.textSuccessInformation}
              >
                {intl.formatMessage({
                  id: 'registerSuccess'
                })}
              </Typography>
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <Input
            inputRef={this.registrationCodeRef}
            name={'registrationCode'}
            type={'text'}
            autoComplete={'off'}
            placeholder={'A123'}
            label={intl.formatMessage({ id: 'registrationCode' })}
            value={this.state.registrationCode}
            onChange={this.handleInputChange}
            error={!!this.state.errors.registrationCode}
            helperText={this.state.errors.registrationCode || ''}
            required={true}
            disabled={this.state.isLoading}
          />
          <button type={'submit'} className={classes.submitButton}>
            {this.state.isLoading ? (
              <CircularProgress size={20} />
            ) : (
              intl.formatMessage({ id: 'confirmRegistrationCode' })
            )}
          </button>
        </Grid>
      </Grid>
    );
  };

  renderRight = () => {
    const { intl, classes } = this.props;

    return (
      <div className={classes.formRightBottomContainer}>
        <Typography variant={'body1'} className={classes.createYourFreeAccountTitle}>
          {intl.formatMessage({ id: 'createYourFreeAccount' })}
        </Typography>
        <form autoComplete={'on'} onSubmit={this.handleOnSubmit}>
          {!this.state.registrationCodeForm ? this.renderForm() : this.renderRegistrationCodeForm()}
        </form>
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Container>
        <div className={classes.formCard}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              {this.renderRight()}
            </Grid>
            <Grid item xs={12} md={6}>
              {this.renderLeft()}
            </Grid>
          </Grid>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppLocale: (locale) => {
    dispatch(setAppLocale(locale));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(Register)));
