import React from 'react';

// REDUX
import { connect } from 'react-redux';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import BirdProfileNew from '../../../../../components/core/BirdProfileNew';
import { setAppBarTitle } from '../../../../../store/actions/appActions';

class Child extends React.PureComponent {
  componentDidMount() {
    this.props.setAppBarTitle('bird');
  }

  render() {
    return (
      <BirdProfileNew
        breadcrumbType={'coupleChild'}
        match={this.props.match}
        history={this.props.history}
      />
    );
  }
}

const mapStateToProps = (state) => ({ app: state.appReducer });

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Child));
