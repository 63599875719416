// UTILS
import { getSizes, getColors } from '../../../../../../utils/themes/theme';

const sizes = getSizes();
const colors = getColors();

export default (theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh'
  },

  // toolbar
  appBar: {
    background: colors.toolbar
  },
  toolbarLeftInformation: {
    marginLeft: sizes.sidebarWidth
  },
  toolbarRightButtons: {
    marginLeft: 'auto'
  },

  // sidebar
  drawer: {
    height: '100%',
    width: sizes.sidebarWidth
  },
  drawerPaper: {
    width: sizes.sidebarWidth,
    borderRight: 0,
    backgroundColor: colors.sidebar
  },
  drawerToolbar: {
    ...theme.mixins.toolbar
    // height: sizes.appBarHeight
  },

  // main content
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%'
  }
});
