import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Container, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import styles from './styles';

class TermsAndConditions extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes } = this.props;

    return (
      <Container>
        <div className={classes.card}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant={'h3'} className={classes.title}>
                Terms and Conditions of Use and Sale
              </Typography>
              <Typography variant={'body1'} className={classes.version}>
                This version was created on January 23, 2021.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                YOUTEK, legal person Nº226008533, headquartered in Rua Central Nº70, Geraz do Lima -
                Viana do Castelo, is a national trademark registered under Nº546391 and namely the
                provision of services related to these activities.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                These Terms and Conditions of Use and Sale, along with the Privacy Policy regulate
                the conditions under which it can make use of the website myownbirds.com, and
                operational business transactions, therefore, we advise you to read carefully before
                access and use the website.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                Using the website, you accept, without reservation, the present TERMS AND CONDITIONS
                OF USE AND SALE and agree to respect them.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                In case you do not agree with these Terms and Conditions of Use and Sale You may not
                use the website.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                YOUTEK reserves, however, the right to change is Terms and Conditions of Use and
                Sale, forcing to publish any changes to this site.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                1. Definitions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                1.1. "Customer" - any natural person aged at least 18 years old , or a legally
                represented legal person, who subscribes to a plan under the commitment that all
                information related to his identification and other data necessary for the
                fulfillment and processing of the respective order are true , updated and complete.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                1.2. "Cookie" - small file sent to your computer's Customer that is stored on your
                disk. By creating an account on the web site , the computer Customer keep a cookie
                that will allow it to be automatically identified that always come back to visit the
                site. The cookies are also used to our system can follow the footsteps of Customer a
                session subscription plan. If the Customer not accept the use of cookies, you can
                not use this site.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                1.3. "Personal data" - information, of any nature and support, relating to an
                identified or identifiable natural or legal person . An identifiable person is one
                who can be identified, directly or indirectly, namely by reference to an
                identification number or to one or more specific elements of his physical,
                physiological, psychological, economic, cultural or social identity.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                1.4. "Intellectual Property" - all intellectual property rights in software and
                content made available through this website are the property of YOUTEK and are
                licensed and protected by law. All of these rights are reserved to YOUTEK and its
                suppliers. The Customer can save, print or display this content site only for your
                personal use.It is not permitted to publish, manipulate, distribute or reproduce, in
                any format, any content on this website , nor to link it to any business or company
                without the express authorization of YOUTEK.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                1.5. "Web site" - myownbirds.com website
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                1.6. "Treatment of personal data" - defined under the terms of the Privacy Policy.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                2. Scope and Acceptance
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                2.1. The use of the website, as well as the associated services, depends on the
                acceptance of these Terms and Conditions of Use and Sale, therefore, do not accept
                these Terms and Conditions of Use and Sale you will not be able to use the website.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                2.2. By accepting the Terms and Conditions of Use and Sale, you make this acceptance
                on your personal behalf.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                2.3. Adherence to the services available on the website is regulated only by these
                general conditions of sale.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                2.3. YOUTEK can occasionally modify provisions of your general conditions, so you
                are advised to re-read them each time you visit the site.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                3. Use of the site
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                3.1. By using this website you declare that you will comply with these Terms and
                Conditions of Use and that you have the legal capacity to be bound.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                3.2. Different annual plans may be subscribed, properly presented and described on
                the website, giving access to features explicitly identified in each of the plans
                and the price shown.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                3.3. YOUTEK reserves the right to freely apply prices in exchange for annual
                subscriptions, as well as to apply commercial discounts.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                3.4. The commercial discounts applied have the duration expressly indicated for this
                purpose, which does not mean the obligation of YOUTEK practice the discount price
                indefinitely.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                3.5. To subscribe to an annual plan, you must select the desired plan, confirming
                it.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                3.6. Upon completion of the process of subscription in the personal account of the
                Customer, this has a period indicated in the payment method selected, to
                settle.After this period, if there is no payment confirmation, the subscription will
                be canceled.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                3.7. The completion of the subscription represents full and complete acceptance of
                the prices and description of the subscribed plan, as well as the general
                conditions.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                3.8. YOUTEK provides the following method of payment for subscriptions made on the
                website: Stripe.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                3.9. Payments are always made in euros, even when prices are shown in other
                currencies. Some banking entities may charge exchange rates. YOUTEK is totally
                unaware of this fact and assumes no responsibility for it.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                4. Plan cancellation and account subscription
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                4.1. The Customer can only cancel your subscription if it has not yet been paid.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                4.2. The Client can delete his user account at any time, however, he is not entitled
                to be reimbursed for the monetary amount supported with the subscription of the
                plan.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                5. Obligations of website users and its plans
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                5.1. The User/Customer undertakes not to use the website for illicit purposes.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                5.2. The User/Customer cannot use the website to:
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                a) Any behavior that violates the law, local, national or international;
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                b) Any behavior that is illegal or fraudulent, or that has any illegal or fraudulent
                purpose or effect, obliging itself, in particular, to refrain from any behavior or
                conduct and / or to perform any act that, directly or indirectly, constitutes or may
                be liable to constitute an abusive and fraudulent exploitation or exploitation, for
                its own benefit or for the benefit of third parties or any other behavior that
                produces or is likely to produce such effects.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                c) Transmitting, or seeking to send, any type of unsolicited or unauthorized
                advertising or promotional content or any other form of similar request
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                ("spam").
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                6. Site accessibility
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                6.1. YOUTEK reserves the right to cancel or amend the service it provides through
                the website, by notice to customers that do not comply with these Terms and
                Conditions of Use and Sale.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                6.2. YOUTEK shall not be liable if for any reason the website is not available in l
                a given time or for certain periods of time , whatever the duration of the outage,
                and reserves also the right to restrict access to some areas of the site for
                technical reasons or to comply with legal obligations.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                6.3. The User/Customer recognizes that the website and services are a tool, looking
                for YOUTEK ensure that the access occurs in a continuous availability basis, but
                that such availability ensures fa c to occur, which the customer accepts and
                recognizes.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                6.4. By accepting these Terms and Conditions of Use and Sale, you declare that you
                will not use the website and the services associated with its use for any illegal
                and/or unlawful purposes, which constitute fraud or which are not authorized.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                7. Intellectual property
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                7.1. The User/Customer acknowledges that ownership and title over all intellectual
                property rights, including, in particular, rights over the website , copyright,
                trademarks and trade secrets, are the property of YOUTEK.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                7.2. The User/Customer will not copy, translate, disassemble or decompile, nor
                create or attempt to create, by reverse engineering or otherwise, the source code of
                the website provided under these Terms and Conditions of Use and Services.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                7.3. The User/Customer will not remove any intellectual property notices on the
                website.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                7.4. The User/Costumer is expressly prohibited from the following actions:
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                a) Use the website to store or transmit illegal or illicit material;
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                b) Use the website to store or transmit material that violates the rights of third
                parties, namely logos, symbols or private information of third parties;
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                c) Interfering or interrupting the integrity or performance of the service;
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                d) Access or use the website to build a competing service and/or product or copy any
                resource, function or graphic for competition purposes;
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                e) Use the site for commercial purposes.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                7.5. If you violate any of the aforementioned provisions, in addition to your right
                to YOUTEK to immediately resolve these Terms and Conditions of Use and Services, the
                User/Customer undertakes to compensate YOUTEK for the damage caused.
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                7.6. These Terms and Conditions of Use and Sale not assigns to the User/Customer any
                rights to use any marks and/or logos belonging to YOUTEK.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                8. Responsibility
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                8.1. YOUTEK is not responsible for inconveniences or damages caused to the
                User/Customer for using the Internet network, such as service failures, external
                intrusion caused by abnormalities malware computer viruses or any other the
                technical nature of the situation.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                9. Applicable law and dispute resolution
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                9.1. The law applicable to any contractual relationship established through the
                website www.myownbirds.com is Portuguese law. For the resolution of any and all
                conflicts arising from any contract entered into under the Terms of Use and Sale,
                the Judicial Court of the District of Viana do Castelo will be competent, with the
                express waiver of any other.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.subTitle}>
                10. Applicable law and dispute resolution
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} className={classes.text}>
                10.1. Tn the event of a consumer dispute, the consumer may use the following
                alternative dispute resolution entity:
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                - Consumer Disputes Arbitration Center - Viana do Castelo (CIAB - Arbitral Tribunal)
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                - Address: Av. Rocha Paris Nº103, 4900-394 Viana do Castelo, Portugal
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                - Email: ciab.viana@cm-viana-castelo.pt
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                - Phone: +351 258 809 335
              </Typography>
              <Typography variant={'body1'} className={classes.text}>
                - www.ciab.pt
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(injectIntl(TermsAndConditions));
