export default (theme) => ({
  descriptionItemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start'
  },
  itemIcon: {
    color: theme.palette.common.black,
    marginTop: '2px',
    marginLeft: '-1px',
    marginRight: '4px'
  },
  itemText: {
    color: theme.palette.common.black
  },
  informationTextMailTo: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textTransform: 'lowercase'
  },
  receptorContainer: {
    backgroundColor: '#e0ebff',
    padding: theme.spacing(2),
    borderRadius: '6px'
  },
  formButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
});
