import React from 'react';

// REDUX
import { connect } from 'react-redux';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import { setAppBarTitle } from '../../../../store/actions/appActions';
import AddBirdForm from '../../../../components/forms/AddBirdForm';
import ROUTES from '../../../../utils/routes';

class AddBird extends React.PureComponent {
  componentDidMount() {
    this.props.setAppBarTitle('addBird');
  }

  handleOnSuccess = (birdId) => {
    this.props.history.push(`${ROUTES.BREEDER_MY_BIRDS}/${birdId}`);
  };

  handleOnCancel = () => {
    this.props.history.push(ROUTES.BREEDER_MY_BIRDS);
  };

  render() {
    return (
      <AddBirdForm
        shouldDisplayFirstOwnerForm={true}
        onCancel={this.handleOnCancel}
        onSuccess={this.handleOnSuccess}
      />
    );
  }
}

const mapStateToProps = (state) => ({ app: state.appReducer });

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddBird));
