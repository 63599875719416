import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Typography
} from '@material-ui/core';

// REDUX
import { connect } from 'react-redux';

// REACT ROUTER
import { NavLink } from 'react-router-dom';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// FEATHER ICONS
import {
  Sliders,
  Users,
  User,
  Twitter,
  Search,
  Feather,
  Heart,
  CreditCard,
  Star,
  Repeat
} from 'react-feather';

// APP IMPORTS
import { getColors } from '../../../utils/themes/theme';
import ROUTES from '../../../utils/routes';
import ROLES from '../../../utils/roles';
import LogoutButton from '../../../pages/App/components/Container/components/PrivateAppContainer/components/LogoutButton';
import Header from './components/Header';
import styles from './styles';

const ForwardNavLink = React.forwardRef((props, ref) => (
  <NavLink {...props} innerRef={ref} style={{ textDecoration: 'none' }} />
));

class Sidebar extends React.PureComponent {
  /**
   * Render the Sidebar list based on the given items.
   * @param items {array}
   * @param others {array}
   * @returns {*}
   */
  renderItems = (items, others = []) => {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <List component={'div'} disablePadding={true} className={classes.itemsContainer}>
          {items.map((item) => (
            <ListItem
              key={item.key}
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={ForwardNavLink}
              to={item.to}
              onClick={() => {
                this.props.onItemClick(item.key);
              }}
            >
              <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary={item.title} />
              {item.specialIcon ? item.specialIcon : <React.Fragment />}
            </ListItem>
          ))}
        </List>
        {others.length > 0 &&
          others.map((other) => (
            <React.Fragment key={other.title}>
              <Typography variant={'body2'} color={'primary'} className={classes.groupTitle}>
                {other.title}
              </Typography>
              <List component={'div'} disablePadding={true} className={classes.itemsContainer}>
                {other.items.map((item) => (
                  <ListItem
                    key={item.key}
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={ForwardNavLink}
                    to={item.to}
                    onClick={() => {
                      this.props.onItemClick(item.key);
                    }}
                  >
                    <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary={item.title}
                    />
                  </ListItem>
                ))}
              </List>
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  };

  renderPublicItems = () => {
    const { classes, intl } = this.props;

    const items = [
      {
        key: 'species',
        to: ROUTES.SPECIES,
        title: intl.formatMessage({ id: 'species' })
      },
      {
        key: 'plans',
        to: ROUTES.PLANS,
        title: intl.formatMessage({ id: 'plans' })
      },
      {
        key: 'login',
        to: ROUTES.LOGIN,
        title: intl.formatMessage({ id: 'login' })
      }
    ];

    return (
      <List component={'div'} disablePadding={true} className={classes.itemsContainer}>
        {items.map((item) => (
          <ListItem
            key={item.key}
            activeClassName={classes.publicActiveListItem}
            className={classes.publicListItem}
            component={ForwardNavLink}
            to={item.to}
            onClick={() => this.props.onItemClick()}
          >
            <ListItemText classes={{ primary: classes.publicListItemText }} primary={item.title} />
          </ListItem>
        ))}
        <ListItem
          className={classes.publicListItemStartNow}
          component={ForwardNavLink}
          to={ROUTES.REGISTER}
          onClick={() => this.props.onItemClick()}
        >
          <ListItemText
            classes={{ primary: classes.publicListItemTextStartNow }}
            primary={intl.formatMessage({ id: 'startNow' })}
          />
        </ListItem>
      </List>
    );
  };

  renderPrivateItems = () => {
    const { classes, intl } = this.props;

    const iconSize = 20;
    const colors = getColors();

    const adminItems = [
      {
        key: 'dashboard',
        to: ROUTES.ADMIN_DASHBOARD,
        icon: <Sliders size={iconSize} />,
        title: intl.formatMessage({ id: 'dashboard' })
      },
      {
        key: 'users',
        to: ROUTES.ADMIN_USERS,
        icon: <Users size={iconSize} />,
        title: intl.formatMessage({ id: 'users' })
      },
      {
        key: 'species',
        to: ROUTES.ADMIN_SPECIES,
        icon: <Feather size={iconSize} />,
        title: intl.formatMessage({ id: 'species' })
      },
      {
        key: 'account',
        to: ROUTES.ACCOUNT,
        icon: <User size={iconSize} />,
        title: intl.formatMessage({ id: 'account' })
      }
    ];

    const breederItems = [
      {
        key: 'dashboard',
        to: ROUTES.BREEDER_DASHBOARD,
        icon: <Sliders size={iconSize} />,
        title: intl.formatMessage({ id: 'dashboard' })
      },
      {
        key: 'myBirds',
        to: ROUTES.BREEDER_MY_BIRDS,
        icon: <Twitter size={iconSize} />,
        title: intl.formatMessage({ id: 'myBirds' })
      },
      {
        key: 'couples',
        to: ROUTES.BREEDER_COUPLES,
        icon: <Heart size={iconSize} />,
        title: intl.formatMessage({ id: 'couples' })
      },
      {
        key: 'transferRequests',
        to: ROUTES.BREEDER_TRANSFER_REQUESTS,
        icon: <Repeat size={iconSize} />,
        title: intl.formatMessage({ id: 'transfers' }),
        specialIcon:
          this.props.user.user &&
          this.props.user.user.plan &&
          ['FREE'].includes(this.props.user.user.plan) ? (
            <Star color={colors.sidebarItemActive} size={iconSize} strokeWidth={'3'} />
          ) : null
      },
      {
        key: 'searchBirds',
        to: ROUTES.BREEDER_SEARCH_BIRDS,
        icon: <Search size={iconSize} />,
        title: intl.formatMessage({ id: 'searchBirds' }),
        specialIcon:
          this.props.user.user &&
          this.props.user.user.plan &&
          ['FREE', 'BASIC'].includes(this.props.user.user.plan) ? (
            <Star color={colors.sidebarItemActive} size={iconSize} strokeWidth={'3'} />
          ) : null
      },
      {
        key: 'account',
        to: ROUTES.ACCOUNT,
        icon: <User size={iconSize} />,
        title: intl.formatMessage({ id: 'account' })
      },
      {
        key: 'plans',
        to: ROUTES.BREEDER_PLANS,
        icon: <CreditCard size={iconSize} />,
        title: intl.formatMessage({ id: 'plans' })
      }
    ];

    return (
      <React.Fragment>
        {this.props.role === ROLES.ADMIN && this.renderItems(adminItems)}
        {this.props.role === ROLES.BREEDER && this.renderItems(breederItems)}
        <Hidden mdUp={true}>
          <Grid className={classes.footerButtonsContainer} container alignItems={'center'}>
            <Grid item xs={12}>
              <div className={classes.logoutButton}>
                <LogoutButton sidebar={true} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ height: '32px' }} />
            </Grid>
          </Grid>
        </Hidden>
      </React.Fragment>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <nav className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Header isFixed={this.props.isFixed} onItemClick={this.props.onItemClick} />
          </Grid>
          <Grid item xs={12}>
            {this.props.role === ROLES.DEFAULT
              ? this.renderPublicItems()
              : this.renderPrivateItems()}
          </Grid>
        </Grid>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  isFixed: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  onItemClick: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.userReducer
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(injectIntl(Sidebar)));
