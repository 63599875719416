export default {
  // public routes
  ROOT: '/',
  LOGIN: '/login',
  SPECIES: '/species',
  PLANS: '/plans',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot_password',
  EMAIL_CONFIRMATION: '/email_confirmation',
  RESET_PASSWORD: '/reset_password',
  TERMS_AND_CONDITIONS: '/terms',
  PRIVACY_POLICY: '/privacy',

  // common
  ACCOUNT: '/account',

  // admin
  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_USERS: '/admin/users',
  ADMIN_SPECIES: '/admin/species',

  // breeder
  BREEDER_DASHBOARD: '/breeder/dashboard',
  BREEDER_MY_BIRDS: '/breeder/birds',
  BREEDER_MY_BIRDS_ADD_BIRD: '/breeder/birds/add',
  BREEDER_COUPLES: '/breeder/couples',
  BREEDER_SEARCH_BIRDS: '/breeder/search_birds',
  BREEDER_TRANSFER_REQUESTS: '/breeder/transfers',
  BREEDER_TRANSFER_REQUESTS_CREATE: '/breeder/transfers/create',
  BREEDER_PLANS: '/breeder/plans'
};
