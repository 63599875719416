import React from 'react';
import PropTypes from 'prop-types';

// MATERIAL UI
import { Grid } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// SPINE UI
import { Input } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import InputFile from '../../../../../../components/inputs/InputFile';

class AddMutation extends React.PureComponent {
  render() {
    const { intl } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            inputRef={this.props.nameInputRef}
            name={'name'}
            type={'text'}
            autoComplete={'name'}
            label={intl.formatMessage({ id: 'name' })}
            value={this.props.name}
            onChange={this.props.handleInputChange}
            error={!!this.props.errors.name}
            helperText={this.props.errors.name || ''}
            required={true}
            disabled={this.props.isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <InputFile
            inputTitle={'uploadImage'}
            pendingFiles={this.props.pendingFiles}
            handleAddFiles={this.props.handleAddFiles}
            handleRemoveFileWithError={this.props.handleRemoveFileWithError}
            mimeType={'image/jpeg,image/png'}
            allowMultipleFiles={false}
          />
        </Grid>
      </Grid>
    );
  }
}

AddMutation.defaultProps = {};

AddMutation.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  nameInputRef: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  // image: PropTypes.string.isRequired,
  handleAddFiles: PropTypes.func.isRequired,
  handleRemoveFileWithError: PropTypes.func.isRequired,
  pendingFiles: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      percentageCompleted: PropTypes.number.isRequired
    })
  ).isRequired,
  errors: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired
};

export default injectIntl(AddMutation);
