// UTILS
import { getColors } from '../../../utils/themes/theme';

const colors = getColors();

export default (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.border}`,
    borderRadius: '6px',
    backgroundColor: theme.palette.common.white
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.light,
    borderBottom: `1px solid ${colors.border}`,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1)
  },
  partnersContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  partnerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: theme.spacing(1)
  },
  partnerContainerWithBorder: {
    width: '100%',
    borderRight: `1px solid ${colors.border}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  informationContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  ringContainer: {
    maxWidth: '120px'
  },
  headerText: {
    color: theme.palette.common.black,
    fontWeight: 700,
    textTransform: 'uppercase',
    lineHeight: '1.3em'
  },
  ringText: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textTransform: 'uppercase',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  mutationText: {
    color: theme.palette.secondary.dark,
    fontWeight: 500
  },
  genderText: {
    color: theme.palette.common.black,
    fontWeight: 500
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center'
  }
});
