import { grey } from '@material-ui/core/colors';
import COLORS from './colors';

export default {
  common: {
    black: COLORS.black,
    white: COLORS.white
  },
  primary: {
    main: COLORS.primaryMain,
    light: COLORS.primaryLight,
    dark: COLORS.primaryDark
  },
  secondary: {
    main: COLORS.secondaryMain,
    light: COLORS.secondaryLight,
    dark: COLORS.secondaryDark
  },
  background: {
    default: COLORS.background,
    paper: COLORS.white,
    dark: grey[900]
  },
  text: {
    primary: COLORS.primaryMain,
    secondary: grey[900],
    disabled: COLORS.primaryLight,
    hint: COLORS.primaryLight
  },
  border: COLORS.border,
  divider: COLORS.border
};
