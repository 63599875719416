import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Container, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// APP IMPORTS
import Plan from './components/Plan';
import styles from './styles';

class Plans extends React.PureComponent {
  renderPlans = () => {
    const { intl, classes } = this.props;

    const plans = [
      {
        title: 'free',
        smallDescription: intl.formatMessage({ id: 'freeDescription' }),
        price: '0',
        descriptions: [
          intl.formatMessage({ id: 'manageInfiniteNumberOfBirds' }),
          intl.formatMessage({ id: 'manageCouplesUpTo' }, { number: 2 })
        ],
        withBorder: true
      },
      {
        title: 'basic',
        smallDescription: intl.formatMessage({ id: 'basicDescription' }),
        price: '5,90',
        descriptions: [
          intl.formatMessage({ id: 'manageInfiniteNumberOfBirds' }),
          intl.formatMessage({ id: 'manageCouplesUpTo' }, { number: 20 }),
          intl.formatMessage({ id: 'createTransferRequests' })
        ],
        withBorder: true
      },
      {
        title: 'premium',
        smallDescription: intl.formatMessage({ id: 'premiumDescription' }),
        price: '16,90',
        descriptions: [
          intl.formatMessage({ id: 'manageInfiniteNumberOfBirds' }),
          intl.formatMessage({ id: 'manageCouplesUpTo' }, { number: 60 }),
          intl.formatMessage({ id: 'createTransferRequests' }),
          intl.formatMessage({ id: 'searchForOtherBirdsFromOtherBreeders' })
        ],
        withBorder: true
      },
      {
        title: 'platinum',
        smallDescription: intl.formatMessage({ id: 'platinumDescription' }),
        price: '39,90',
        descriptions: [
          intl.formatMessage({ id: 'manageInfiniteNumberOfBirds' }),
          intl.formatMessage({ id: 'manageCouplesUpTo' }, { number: 300 }),
          intl.formatMessage({ id: 'createTransferRequests' }),
          intl.formatMessage({ id: 'searchForOtherBirdsFromOtherBreeders' })
        ],
        withBorder: false
      }
    ];

    return (
      <Grid container spacing={2} className={classes.gridContainer}>
        {plans.map((item) => (
          <Grid
            key={item.title}
            item
            xs={12}
            className={item.withBorder ? classes.gridBorderItem : ''}
          >
            <Plan
              title={item.title}
              smallDescription={item.smallDescription}
              price={item.price}
              description={item.descriptions}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  render() {
    const { intl, classes } = this.props;

    return (
      <Container>
        <div className={classes.contentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h4'} className={classes.increaseProductivityTitle}>
                {intl.formatMessage({ id: 'increaseProductivity' })}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.plansCard}>{this.renderPlans()}</div>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(injectIntl(Plans));
