import React from 'react';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Container, Typography } from '@material-ui/core';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// REACT ROUTER
import { NavLink } from 'react-router-dom';

// SPINE UI
import { Input, Checkbox } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { setUserToken } from '../../../utils/userSettings';
import { fetchApi } from '../../../utils/apiSettings';
import ROUTES from '../../../utils/routes';
import PublicPaper from '../../../components/core/PublicPaper';
import PublicButton from '../../../components/buttons/PublicButton';
import styles from './styles';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      // form
      email: '',
      password: '',
      rememberMe: true,
      errors: {}
    };

    this.emailInputRef = React.createRef();
  }

  componentDidMount() {
    this.emailInputRef.current.focus();
  }

  handleInputChange = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    this.setState({
      [input.name]: value,
      errors: {}
    });
  };

  handleOnSubmit = async (event) => {
    event.preventDefault();

    this.setState(
      {
        isLoading: true
      },
      () => {
        fetchApi(
          'post',
          '/v1/login_check',
          {},
          {
            username: this.state.email,
            password: this.state.password,
            remember_me: this.state.rememberMe
          },
          true,
          (response) => {
            const { data } = response;

            setUserToken(data.token, this.state.rememberMe);
            window.location.reload();
          },
          (error) => {
            const { response } = error;
            const { intl } = this.props;

            if (
              response &&
              response.data &&
              response.data.message &&
              response.data.message === 'Invalid credentials.'
            ) {
              this.setState({
                isLoading: false,
                errors: {
                  email: intl.formatMessage({ id: 'invalidCredentials' }),
                  password: intl.formatMessage({ id: 'invalidCredentials' })
                }
              });
            }
          }
        );
      }
    );
  };

  render() {
    const { intl, classes } = this.props;

    return (
      <Container>
        <div className={classes.formCard}>
          <PublicPaper title={'login'}>
            <form autoComplete={'on'} onSubmit={this.handleOnSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input
                    inputRef={this.emailInputRef}
                    name={'email'}
                    type={'email'}
                    autoComplete={'email'}
                    label={intl.formatMessage({ id: 'email' })}
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    error={!!this.state.errors.email}
                    helperText={this.state.errors.email || ''}
                    required={true}
                    disabled={this.state.isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    name={'password'}
                    type={'password'}
                    autoComplete={'current-password'}
                    label={intl.formatMessage({ id: 'password' })}
                    value={this.state.password}
                    onChange={this.handleInputChange}
                    error={!!this.state.errors.password}
                    helperText={this.state.errors.password || ''}
                    required={true}
                    disabled={this.state.isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    name={'rememberMe'}
                    label={
                      <React.Fragment>{intl.formatMessage({ id: 'rememberMe' })}</React.Fragment>
                    }
                    disabled={this.state.isLoading}
                    value={this.state.rememberMe}
                    onChange={this.handleInputChange}
                    error={!!this.state.errors.rememberMe}
                    helperText={this.state.errors.rememberMe || ''}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <PublicButton title={'login'} isLoading={this.state.isLoading} />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <NavLink to={ROUTES.FORGOT_PASSWORD} className={classes.link}>
                    <Typography variant={'body1'} className={classes.text}>
                      {intl.formatMessage({ id: 'forgotPassword' })}
                    </Typography>
                  </NavLink>
                </Grid>
              </Grid>
            </form>
          </PublicPaper>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(injectIntl(Login));
