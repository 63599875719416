import React from 'react';

// REDUX
import { connect } from 'react-redux';

// SPINE UI
import { Content, Table, FormDialog, ConfirmationDialog } from '@oliveirahugo68/spine-ui';

// MATERIAL UI
import { withStyles } from '@material-ui/core/styles';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// YUP
import * as Yup from 'yup';

// APP IMPORTS
import { withSnackbar } from 'notistack';
import { setAppBarTitle } from '../../../../store/actions/appActions';
import { fetchApi } from '../../../../utils/apiSettings';
import { fromUTCtoLocal } from '../../../../utils/timeFormat';
import ROUTES from '../../../../utils/routes';
import TooltipIconButton from '../../../../components/buttons/TooltipIconButton';
import AddMutation from './components/AddMutation';
import BirdAvatar from '../../../../components/core/BirdAvatar';
import AddSpecie from '../components/AddSpecie';
import styles from './styles';

class Specie extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      specie: null,

      // edit specie form
      isEditSpecieOpened: false,
      specieName: '',
      clutchDurationDays: 0,
      hasClutchDurationDays: false,

      // add mutation form
      isAddMutationOpened: false,
      name: '',
      images: [],
      pendingImages: [],

      // remove specie
      isRemoveSpecieOpened: false,

      errors: {}
    };

    this.nameInputRef = React.createRef();
    this.specieNameInputRef = React.createRef();
  }

  componentDidMount() {
    this.props.setAppBarTitle('specie');

    this.fetchSpecie();
  }

  fetchSpecie = () => {
    fetchApi(
      'get',
      `/admin/v1/species/${this.props.match.params.id}`,
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.setState(
          {
            isLoading: false,
            isFetching: false,
            specie: data
          },
          () => {
            this.props.setAppBarTitle(data.name, false);
          }
        );
      },
      (error) => {
        const { response } = error;
        const { enqueueSnackbar, intl } = this.props;

        if (response.status === 404) {
          enqueueSnackbar(intl.formatMessage({ id: 'retrieveSpecieErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        } else {
          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        }

        this.props.history.push(ROUTES.ADMIN_SPECIES);
      }
    );
  };

  openAddMutation = () => {
    this.setState(
      {
        isAddMutationOpened: true,
        name: '',
        images: [],
        errors: {}
      },
      () => {
        this.nameInputRef.current.focus();
      }
    );
  };

  cancelAddMutation = () => {
    this.setState({
      isAddMutationOpened: false,
      name: '',
      images: [],
      errors: {}
    });
  };

  handleAddImage = (newPendingResources) => {
    const { intl, enqueueSnackbar } = this.props;

    if (newPendingResources === 0) {
      enqueueSnackbar(intl.formatMessage({ id: 'addImageErrorMessage' }), {
        variant: 'error'
      });
    } else {
      this.setState(
        (state) => ({
          pendingImages: [...state.pendingImages, ...newPendingResources]
        }),
        () => {
          // upload each resource automatically
          newPendingResources.forEach((image) => {
            if (!image.error) {
              this.uploadImage(image);
            }
          });
        }
      );
    }
  };

  uploadImage = (image) => {
    const formData = new FormData();
    formData.append('image', image.file);

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'post',
          '/v1/images',
          {
            'Content-Type': 'multipart/form-data'
          },
          formData,
          false,
          (response) => {
            const { data } = response;

            this.setState((state) => ({
              // add file to the list of existing files (already uploaded)
              images: [
                ...state.images,
                {
                  // keep names with underscore so we can handle files that
                  // already exist (that came from another API request)
                  // and this new file
                  id: data.id,
                  path: data.path,
                  preview_path: data.preview_path
                }
              ],
              // set pending files to 100 (or remove them if needed)
              pendingImages: [
                ...state.pendingImages.map((item) => {
                  if (item.fileURL === image.fileURL)
                    return {
                      ...item,
                      error: false,
                      errorMessage: '',
                      percentageCompleted: 100
                    };
                  return item;
                })
              ],
              isFetching: false
            }));
          },
          () => {
            this.setState((state) => ({
              pendingImages: [
                ...state.pendingImages.map((item) => {
                  if (item.fileURL === image.fileURL)
                    return {
                      ...item,
                      error: true,
                      errorMessage: 'errorWhileUploading'
                    };
                  return item;
                })
              ],
              isFetching: false
            }));
          },
          (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentageCompleted = Math.round((loaded * 100) / total);

            if (percentageCompleted < 100) {
              this.setState((state) => ({
                pendingImages: [
                  ...state.pendingImages.map((item) => {
                    if (item.fileURL === image.fileURL)
                      return {
                        file: image.file,
                        fileName: image.fileName,
                        fileURL: image.fileURL,
                        percentageCompleted: Math.round((loaded * 100) / total),
                        error: false,
                        errorMessage: ''
                      };
                    return item;
                  })
                ]
              }));
            }
          }
        );
      }
    );
  };

  /**
   * Remove image that had a problem during upload.
   * @param imageURL
   */
  handleRemoveImageWithError = (imageURL) => {
    this.setState((state) => ({
      pendingImages: state.pendingImages.filter((item) => item.fileURL !== imageURL)
    }));
  };

  handleAddMutation = async () => {
    const { enqueueSnackbar, intl } = this.props;

    const schema = Yup.object().shape({
      name: Yup.string().required(),
      imageIds: Yup.array().notRequired()
    });

    const dataToSubmit = {
      name: this.state.name,
      imageIds: this.state.images
    };

    try {
      await schema.validate(dataToSubmit, { abortEarly: false });

      this.setState(
        {
          isFetching: true
        },
        () => {
          fetchApi(
            'post',
            `/admin/v1/species/${this.state.specie.id}/mutations`,
            {},
            {
              name: dataToSubmit.name,
              image_id: dataToSubmit.imageIds.length > 0 ? dataToSubmit.imageIds[0].id : null
            },
            false,
            (response) => {
              const { data } = response;

              // TODO: add proper message
              enqueueSnackbar('TODO', {
                variant: 'success',
                autoHideDuration: 4000
              });

              this.props.history.push(
                `${ROUTES.ADMIN_SPECIES}/${this.state.specie.id}/mutations/${data.id}`
              );
            },
            (error) => {
              const { response } = error;

              if (response.status === 409) {
                this.setState({
                  isFetching: false,
                  errors: {
                    name: intl.formatMessage({ id: 'addMutationErrorMessage' })
                  }
                });
              } else {
                this.setState({ isFetching: false });

                enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                  variant: 'error',
                  autoHideDuration: 4000
                });
              }
            }
          );
        }
      );
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        this.setState({
          errors: errorMessages
        });
      }
    }
  };

  handleInputChange = (event) => {
    const input = event.target;

    if (input.name === 'hasClutchDurationDays') {
      this.setState((state) => ({
        hasClutchDurationDays: !state.hasClutchDurationDays,
        errors: {}
      }));
    } else {
      this.setState({
        [input.name]: input.value,
        errors: {}
      });
    }
  };

  openEditSpecie = () => {
    this.setState(
      (state) => ({
        isEditSpecieOpened: true,
        specieName: state.specie.name,
        clutchDurationDays: state.specie.clutch_duration_days
          ? state.specie.clutch_duration_days
          : 0,
        hasClutchDurationDays: !!state.specie.clutch_duration_days,
        errors: {}
      }),
      () => {
        this.specieNameInputRef.current.focus();
      }
    );
  };

  cancelEditSpecie = () => {
    this.setState({
      isEditSpecieOpened: false,
      specieName: '',
      clutchDurationDays: 0,
      hasClutchDurationDays: false,
      errors: {}
    });
  };

  handleEditSpecie = async () => {
    const { enqueueSnackbar, intl } = this.props;

    const schema = Yup.object().shape({
      specieName: Yup.string().required(),
      hasClutchDurationDays: Yup.bool(),
      clutchDurationDays: Yup.number().when('hasClutchDurationDays', {
        is: true,
        then: Yup.number().moreThan(0).required()
      })
    });

    const dataToSubmit = {
      specieName: this.state.specieName,
      clutchDurationDays: this.state.clutchDurationDays,
      hasClutchDurationDays: this.state.hasClutchDurationDays
    };

    try {
      await schema.validate(dataToSubmit, { abortEarly: false });

      this.setState(
        {
          isFetching: true
        },
        () => {
          fetchApi(
            'put',
            `/admin/v1/species/${this.state.specie.id}`,
            {},
            {
              name: dataToSubmit.specieName,
              clutch_duration_days: dataToSubmit.clutchDurationDays,
              has_clutch_duration_days: dataToSubmit.hasClutchDurationDays
            },
            false,
            (response) => {
              const { data } = response;

              enqueueSnackbar(intl.formatMessage({ id: 'editSpecieSuccessMessage' }), {
                variant: 'success',
                autoHideDuration: 4000
              });

              this.setState({
                isFetching: false,
                specie: data,
                isEditSpecieOpened: false
              });
            },
            (error) => {
              const { response } = error;

              if (response.status === 409) {
                this.setState({
                  isFetching: false,
                  errors: {
                    specieName: intl.formatMessage({ id: 'addSpecieErrorMessage' })
                  }
                });
              } else {
                this.setState({ isFetching: false });

                enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
                  variant: 'error',
                  autoHideDuration: 4000
                });
              }
            }
          );
        }
      );
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        this.setState({
          errors: errorMessages
        });
      }
    }
  };

  confirmRemoveSpecie = () => {
    const { enqueueSnackbar, intl } = this.props;

    this.setState(
      {
        isFetching: true
      },
      () => {
        fetchApi(
          'delete',
          `/admin/v1/species/${this.state.specie.id}`,
          {},
          {},
          false,
          () => {
            this.props.history.push(ROUTES.ADMIN_SPECIES);
          },
          () => {
            this.setState({ isFetching: false });

            enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
              variant: 'error',
              autoHideDuration: 4000
            });
          }
        );
      }
    );
  };

  render() {
    const { intl } = this.props;

    const buttons = [
      {
        label: intl.formatMessage({ id: 'addMutation' }),
        disabled: this.state.isLoading,
        color: 'primary',
        variant: 'contained',
        onClick: this.openAddMutation
      },
      {
        label: intl.formatMessage({ id: 'editSpecie' }),
        disabled: this.state.isLoading,
        color: 'primary',
        variant: 'outlined',
        onClick: this.openEditSpecie
      },
      {
        label: intl.formatMessage({ id: 'removeSpecie' }),
        translateTitle: true,
        disabled: this.state.isLoading,
        color: 'red',
        variant: 'outlined',
        onClick: () => {
          this.setState({
            isRemoveSpecieOpened: true
          });
        }
      }
    ];

    const breadcrumbs = [
      {
        title: intl.formatMessage({ id: 'species' }),
        active: false,
        href: ROUTES.ADMIN_SPECIES,
        onClick: () => {
          this.props.history.push(ROUTES.ADMIN_SPECIES);
        }
      },
      {
        title: this.state.specie ? this.state.specie.name : intl.formatMessage({ id: 'specie' }),
        active: true
      }
    ];

    return (
      <Content
        isFetching={this.state.isFetching}
        isLoading={this.state.isLoading}
        breadcrumbs={breadcrumbs}
        buttons={buttons}
      >
        <FormDialog
          isOpened={this.state.isEditSpecieOpened}
          title={intl.formatMessage({ id: 'editSpecie' })}
          maxWidth={'sm'}
          onConfirm={this.handleEditSpecie}
          onCancel={this.cancelEditSpecie}
          onConfirmDisabled={this.state.isFetching}
          onCancelDisabled={this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <AddSpecie
            isLoading={this.state.isLoading || this.state.isFetching}
            nameInputRef={this.specieNameInputRef}
            specieName={this.state.specieName}
            clutchDurationDays={this.state.clutchDurationDays}
            hasClutchDurationDays={this.state.hasClutchDurationDays}
            errors={this.state.errors}
            handleInputChange={this.handleInputChange}
          />
        </FormDialog>
        <ConfirmationDialog
          isOpened={this.state.isRemoveSpecieOpened}
          title={intl.formatMessage({ id: 'removeSpecie' })}
          maxWidth={'sm'}
          message={intl.formatMessage({ id: 'removeSpecieQuestion' })}
          onConfirm={this.confirmRemoveSpecie}
          onCancel={() => {
            this.setState({
              isRemoveSpecieOpened: false
            });
          }}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          disabled={this.state.isLoading || this.state.isFetching}
        />
        <FormDialog
          isOpened={this.state.isAddMutationOpened}
          title={intl.formatMessage({ id: 'addMutation' })}
          maxWidth={'sm'}
          onConfirm={this.handleAddMutation}
          onCancel={this.cancelAddMutation}
          onConfirmDisabled={this.state.isFetching}
          onCancelDisabled={this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <AddMutation
            isLoading={this.state.isLoading || this.state.isFetching}
            nameInputRef={this.nameInputRef}
            name={this.state.name}
            pendingFiles={this.state.pendingImages}
            handleAddFiles={this.handleAddImage}
            handleRemoveFileWithError={this.handleRemoveImageWithError}
            errors={this.state.errors}
            handleInputChange={this.handleInputChange}
          />
        </FormDialog>
        {!this.state.isLoading && (
          <Table
            webHeaders={[
              { title: 'id', align: 'left', sort: true, sortKey: 'id' },
              { title: intl.formatMessage({ id: 'image' }), align: 'left' },
              {
                title: intl.formatMessage({ id: 'name' }),
                align: 'left',
                sort: true,
                sortKey: 'name'
              },
              { title: intl.formatMessage({ id: 'registeredAt' }), align: 'left' },
              { title: '', align: 'right' }
            ]}
            webData={this.state.specie.mutations.map((item) => [
              item.id,
              <BirdAvatar images={item.image ? [item.image] : []} />,
              item.name,
              fromUTCtoLocal(item.created_at).format('LL'),
              <TooltipIconButton
                title={'view'}
                iconName={'more-horizontal'}
                handleClick={() => {
                  this.props.history.push(
                    `${ROUTES.ADMIN_SPECIES}/${this.state.specie.id}/mutations/${item.id}`
                  );
                }}
              />
            ])}
            noDataMessage={intl.formatMessage({ id: 'noMutations' })}
          />
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title, translateTitle = true) => {
    dispatch(setAppBarTitle(title, translateTitle));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(injectIntl(Specie))));
