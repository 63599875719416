import React from 'react';

// REDUX
import { connect } from 'react-redux';

// MATERIAL UI
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// QUERY STRING
import queryString from 'query-string';

// INTERNALIZATION
import { injectIntl } from 'react-intl';

// SPINE UI
import { Select, Input, FormDialog, Content, Table } from '@oliveirahugo68/spine-ui';

// APP IMPORTS
import { fetchApi } from '../../../utils/apiSettings';
import { setAppBarTitle } from '../../../store/actions/appActions';
import ROUTES from '../../../utils/routes';
import TooltipIconButton from '../../../components/buttons/TooltipIconButton';
import AddCoupleFormNew from './components/AddCoupleFormNew';
import FilterForm from './components/FilterForm';
import CoupleItem from '../../../components/core/CoupleItem';
import styles from './styles';

class Couples extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFetching: false,

      couples: [],
      species: [],

      // pagination
      orderBy: 'cage',
      orderDirection: 'asc',
      totalCount: 0,
      page: 0,
      rowsPerPage: 10,

      // filter form
      searchBy: '',
      state: 'active',
      allMutations: [],
      mutation: '',
      specie: '',
      isFilterFormOpened: false,
      errors: {},

      // add couple form
      isAddCoupleOpened: false
    };

    this.searchByInputRef = React.createRef();
  }

  componentDidMount() {
    this.props.setAppBarTitle('couples');

    this.parseURLWithPagination(() => {
      Promise.all([this.fetchCouples(), this.fetchSpecies()]).then(() => {
        this.setState({
          isLoading: false
        });
      });
    });
  }

  fetchCouples = () => {
    const { enqueueSnackbar, intl } = this.props;

    let endpoint = `/breeders/v1/couples?`;
    endpoint += queryString.stringify({
      page_number: this.state.page,
      per_page: this.state.rowsPerPage,
      order_field: this.state.orderBy,
      order_direction: this.state.orderDirection
    });

    if (this.state.state) {
      endpoint += `&${queryString.stringify({ state: this.state.state })}`;
    }
    if (this.state.mutation) {
      endpoint += `&${queryString.stringify({ mutation_id: this.state.mutation })}`;
    }
    if (this.state.searchBy) {
      endpoint += `&${queryString.stringify({ search_by: this.state.searchBy })}`;
    }

    return new Promise((resolve) => {
      fetchApi(
        'get',
        endpoint,
        {},
        {},
        false,
        (response) => {
          const { data } = response;

          this.setState(
            {
              couples: data.data,
              totalCount: data.total_count
            },
            () => {
              resolve();
            }
          );
        },
        (error) => {
          resolve();

          const { response } = error;

          if (response.status === 400) {
            enqueueSnackbar(intl.formatMessage({ id: 'couplesErrorMessage' }), {
              variant: 'error',
              autoHideDuration: 4000
            });
          } else {
            enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
              variant: 'error',
              autoHideDuration: 4000
            });
          }
        }
      );
    });
  };

  fetchSpecies = () => {
    return new Promise((resolve) => {
      fetchApi(
        'get',
        '/v1/public/species',
        {},
        {},
        false,
        (response) => {
          const { data } = response;

          this.setState(
            {
              species: data,
              isFetching: false
            },
            () => {
              resolve();
            }
          );
        },
        () => {}
      );
    });
  };

  fetchAllMutations = () => {
    const { enqueueSnackbar, intl } = this.props;

    fetchApi(
      'get',
      `/v1/public/species/${this.state.specie}`,
      {},
      {},
      false,
      (response) => {
        const { data } = response;

        this.setState({
          allMutations: data.mutations,
          mutation: '',
          isFetching: false
        });
      },
      (error) => {
        const { response } = error;

        this.setState({ isFetching: false });

        if (response.status === 400) {
          enqueueSnackbar(intl.formatMessage({ id: 'mutationsErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        } else {
          enqueueSnackbar(intl.formatMessage({ id: 'serverErrorMessage' }), {
            variant: 'error',
            autoHideDuration: 4000
          });
        }
      }
    );
  };

  parseURLWithPagination = (handle) => {
    const parsed = queryString.parse(window.location.search);
    if (parsed) {
      this.setState(
        {
          state: parsed.state || 'active',
          page: parsed.page_number !== undefined ? parseInt(parsed.page_number, 10) : 0,
          rowsPerPage: parsed.per_page !== undefined ? parseInt(parsed.per_page, 10) : 10,
          orderBy: parsed.order_field || 'cage',
          orderDirection: parsed.order_direction || 'asc',
          searchBy: parsed.search_by || ''
        },
        () => {
          handle();
        }
      );
    }
  };

  setURLWithPagination = () => {
    this.props.history.push(
      `${window.location.pathname}?${queryString.stringify({
        state: this.state.state,
        page_number: this.state.page,
        per_page: this.state.rowsPerPage,
        order_field: this.state.orderBy,
        order_direction: this.state.orderDirection,
        search_by: this.state.searchBy
      })}`
    );
  };

  openAddCouple = () => {
    this.setState({ isAddCoupleOpened: true });
  };

  cancelAddCouple = () => {
    this.setState({ isAddCoupleOpened: false });
  };

  cancelFilterForm = () => {
    this.setState({
      errors: {},
      isFilterFormOpened: false
    });
  };

  confirmFilterForm = () => {
    this.setState(
      {
        isFetching: true,
        isFilterFormOpened: false
      },
      () => {
        this.setURLWithPagination();
        Promise.all([this.fetchCouples()]).then(() => {
          this.setState({
            isFetching: false
          });
        });
      }
    );
  };

  handleSearchBy = (event) => {
    const input = event.target;

    this.setState(
      {
        searchBy: input.value,
        errors: {}
      },
      () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setState(
            {
              isFetching: true
            },
            () => {
              this.setURLWithPagination();
              Promise.all([this.fetchCouples()]).then(() => {
                this.setState({
                  isFetching: false
                });
                this.searchByInputRef.current.focus();
              });
            }
          );
        }, 500);
      }
    );
  };

  handleInputChange = (event) => {
    const input = event.target;

    this.setState({
      [input.name]: input.value,
      errors: {}
    });
  };

  handleSelectChange = (event) => {
    const input = event.target;

    if (input.value !== '') {
      this.setState(
        {
          [input.name]: input.value,
          errors: {},
          isFetching: true
        },
        () => this.fetchAllMutations()
      );
    } else {
      this.setState({
        specie: '',
        mutation: ''
      });
    }
  };

  handleSelectStateChange = (event) => {
    const input = event.target;

    this.setState(
      {
        isFetching: true,
        [input.name]: input.value,
        errors: {}
      },
      () => {
        this.setURLWithPagination();
        Promise.all([this.fetchCouples()]).then(() => {
          this.setState({
            isFetching: false
          });
        });
      }
    );
  };

  handleTableChange = (page, perPage, sortBy, sortDirection) => {
    this.setState(
      {
        isFetching: true,
        page: page,
        rowsPerPage: perPage,
        orderBy: sortBy,
        orderDirection: sortDirection
      },
      () => {
        this.setURLWithPagination();
        Promise.all([this.fetchCouples()]).then(() => {
          this.setState({
            isFetching: false
          });
        });
      }
    );
  };

  render() {
    const { intl, classes } = this.props;

    const buttons = [
      {
        label: intl.formatMessage({ id: 'addCouple' }),
        disabled: this.state.isLoading || this.state.isFetching,
        color: 'primary',
        variant: 'contained',
        onClick: this.openAddCouple
      }
      // {
      //   label: intl.formatMessage({ id: 'filterSearch' }),
      //   disabled: this.state.isLoading || this.state.isFetching,
      //   color: 'primary',
      //   variant: 'outlined',
      //   onClick: this.openFilterForm
      // }
    ];

    return (
      <Content
        isLoading={this.state.isLoading}
        isFetching={this.state.isFetching}
        buttons={buttons}
      >
        <AddCoupleFormNew
          history={this.props.history}
          isAddCoupleOpened={this.state.isAddCoupleOpened}
          isLoading={this.state.isLoading || this.state.isFetching}
          cancelAddCouple={this.cancelAddCouple}
          species={this.state.species.map((item) => ({
            key: item.id,
            value: item.id,
            name: item.name
          }))}
        />
        <FormDialog
          isOpened={this.state.isFilterFormOpened}
          title={intl.formatMessage({ id: 'filterSearch' })}
          maxWidth={'sm'}
          onConfirm={this.confirmFilterForm}
          onCancel={this.cancelFilterForm}
          onConfirmDisabled={
            this.state.isFetching || (this.state.specie !== '' && this.state.mutation === '')
          }
          onCancelDisabled={this.state.isFetching}
          confirmLabel={intl.formatMessage({ id: 'confirm' })}
          cancelLabel={intl.formatMessage({ id: 'cancel' })}
          autoComplete={'off'}
        >
          <FilterForm
            isLoading={this.state.isLoading || this.state.isFetching}
            state={this.state.state}
            mutation={this.state.mutation}
            specie={this.state.specie}
            allMutations={this.state.allMutations.map((mutation) => {
              return {
                key: mutation.id,
                value: mutation.id,
                name: mutation.name
              };
            })}
            allSpecies={this.state.species.map((specie) => {
              return {
                key: specie.id,
                value: specie.id,
                name: specie.name
              };
            })}
            errors={this.state.errors}
            handleInputChange={this.handleInputChange}
            handleSelectChange={this.handleSelectChange}
          />
        </FormDialog>
        {!this.state.isLoading && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.filterFormContainer}>
                <div className={classes.stateButton}>
                  <Select
                    showEmpty={false}
                    name={'state'}
                    label={intl.formatMessage({ id: 'state' })}
                    value={this.state.state}
                    onChange={this.handleSelectStateChange}
                    disabled={this.state.isLoading || this.state.isFetching}
                    autoComplete={'off'}
                    options={[
                      {
                        key: 'all',
                        value: 'all',
                        name: intl.formatMessage({ id: 'all' })
                      },
                      {
                        key: 'active',
                        value: 'active',
                        name: intl.formatMessage({ id: 'active' })
                      },
                      {
                        key: 'inactive',
                        value: 'inactive',
                        name: intl.formatMessage({ id: 'inactive' })
                      }
                    ]}
                  />
                </div>
                <div className={classes.filterInput}>
                  <Input
                    inputRef={this.searchByInputRef}
                    name={'searchBy'}
                    type={'number'}
                    autoComplete={'off'}
                    label={intl.formatMessage({ id: 'searchBy' })}
                    placeholder={intl.formatMessage({ id: 'cageIdentifier' })}
                    value={this.state.searchBy}
                    onChange={this.handleSearchBy}
                    disabled={this.state.isFetching}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Table
                webHeaders={[
                  {
                    title: intl.formatMessage({ id: 'cage' }),
                    align: 'left',
                    sort: true,
                    sortKey: 'cage'
                  },
                  {
                    title: intl.formatMessage({ id: 'year' }),
                    align: 'left',
                    sort: true,
                    sortKey: 'year'
                  },
                  { title: intl.formatMessage({ id: 'specie' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'male' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'female' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'clutches' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'children' }), align: 'left' },
                  { title: intl.formatMessage({ id: 'state' }), align: 'left' },
                  { title: '', align: 'right' }
                ]}
                webData={this.state.couples.map((item) => [
                  item.identifier,
                  item.year,
                  item.partners[0].specie.name, // the specie name should be the same on both partners
                  item.partners[0].ring,
                  item.partners[1].ring,
                  item.clutches_count,
                  item.children_count,
                  intl.formatMessage({ id: item.is_active ? 'active' : 'inactive' }),
                  <TooltipIconButton
                    title={'view'}
                    iconName={'chevron-right'}
                    handleClick={() => {
                      this.props.history.push(`${ROUTES.BREEDER_COUPLES}/${item.couple_id}`);
                    }}
                  />
                ])}
                noDataMessage={intl.formatMessage({ id: 'noCouples' })}
                // handle changes
                onChange={this.handleTableChange}
                // sorting
                useSorting={true}
                sortBy={this.state.orderBy}
                sortDirection={this.state.orderDirection}
                // pagination
                usePagination={true}
                totalCount={this.state.totalCount}
                page={this.state.page}
                rowsPerPage={this.state.rowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                // mobiles
                renderMobile={true}
                mobileData={this.state.couples.map((item) => (
                  <CoupleItem
                    cage={item.identifier}
                    year={item.year}
                    specieName={item.partners[0].specie.name}
                    male={item.partners[0]}
                    female={item.partners[1]}
                    onClick={() => {
                      this.props.history.push(`${ROUTES.BREEDER_COUPLES}/${item.couple_id}`);
                    }}
                  />
                ))}
              />
            </Grid>
          </Grid>
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.appReducer,
  user: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
  setAppBarTitle: (title) => {
    dispatch(setAppBarTitle(title));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(injectIntl(Couples))));
