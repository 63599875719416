// UTILS
import { getSizes, getColors } from '../../../../../../../../utils/themes/theme';

const sizes = getSizes();
const colors = getColors();

export default (theme) => ({
  barContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    alignContent: 'center',
    backgroundColor: 'transparent',
    height: '120px',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    margin: 0
  },
  logoContainer: {
    height: '100%'
  },
  logoImage: {
    height: '100%'
  },
  titlesContainer: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(255,255,255,0.64)',
    fontWeight: '600',
    marginLeft: theme.spacing(3),
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',
      fontWeight: '700'
    },
    transition: 'cubic-bezier(0.4, 0, 0.2, 1) 100ms'
  },
  activeTitlesContainer: {
    color: '#fff',
    fontWeight: '700',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  registerButton: {
    color: '#fff',
    backgroundColor: colors.sidebarItem,
    padding: '14px 24px',
    borderRadius: '24px',
    fontWeight: '600',
    transition: 'cubic-bezier(0.4, 0, 0.2, 1) 100ms',
    '&:hover': {
      backgroundColor: theme.palette.common.black
    }
  },

  // sidebar
  drawer: {
    height: '100%',
    width: sizes.sidebarWidth
  },
  drawerPaper: {
    width: sizes.sidebarWidth,
    borderRight: 0,
    // filter: 'blur(8px)',
    backgroundColor: 'transparent'
  }
});
